import { VStack } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import UserProfileCard from '../../components/UserProfileCard';
import { useDispatch, useSelector } from 'react-redux';
import { getAnotherUserProfile, getUserFollower, getUserFollowing } from '../../store/user/user.action';
import { GET_ANOTHER_USER_PROFILE_SUCCESS } from '../../store/user/user.types';

const UserProfile = () => {
const {userId} = useParams();
const dispatch  = useDispatch();
const [currentUserId,setCurrentUserId]  = useState(null);
const {  Token } = useSelector((store) => store.authData);
const { searchedUser,userFollowing,userFollower } = useSelector((store) => store.userData); 
useEffect(()=>{
  setCurrentUserId(userId);
    dispatch(getAnotherUserProfile(Token,userId)).then((res)=>{
      if(res.type===GET_ANOTHER_USER_PROFILE_SUCCESS){
        console.log('successsfully here become',userId);
        dispatch(getUserFollower(Token,userId));
        dispatch(getUserFollowing(Token,userId));
      }
    })
  },[userId]);

  return (
    <VStack w={["100%", "100%", "87%", "78%"]} pb={'1rem'}>
         <UserProfileCard  currentUserId={currentUserId} followers={userFollower} followings={userFollowing} userData={searchedUser}/>
    </VStack>      
  )
}

export default UserProfile
