import { Button, HStack, Modal, ModalBody, ModalContent, ModalOverlay, Text, VStack, useToast } from '@chakra-ui/react'
import React from 'react'
import axios from 'axios';
import { DELETE, apiRequest } from '../../../utils/api';
import { useDispatch, useSelector } from 'react-redux';
import { DELETE_USER_PROFILE_ERROR, DELETE_USER_PROFILE_LOADING, DELETE_USER_PROFILE_SUCCESS } from '../../../store/user/user.types';


const DeleteUserModal = ({deleteModal,setDeleteModal,deleteUserId,isReset ,setSearched }) => {
    const { AdminToken } = useSelector((store) => store.authData);
    const dispatch = useDispatch();
    const handleDeletePost = async()=>{
        dispatch({type:DELETE_USER_PROFILE_LOADING})
        apiRequest({endpoint:`users/deleteUser/${deleteUserId}`,type:DELETE,token:AdminToken}).then((res)=>{
            if(isReset.length){
                setSearched({});
            }
            dispatch({type:DELETE_USER_PROFILE_SUCCESS,payload:deleteUserId});
        }).catch((err)=>{
            err = err.response?.data?.message
            ? err.response?.data?.message
            : err.message;      
            dispatch({type:DELETE_USER_PROFILE_ERROR,payload:err});
        })
            setDeleteModal(false);
    };
  return (
    <Modal size={'md'} isOpen={deleteModal} isCentered >
    <ModalOverlay />
    <ModalContent>
        <ModalBody w={'100%'}>
            <VStack minH={'20vh'} align={'center'} justify={'center'}>
                <Text>Are you sure to delete post?</Text>
                <HStack gap={'1rem'}>
                    <Button colorScheme='red' onClick={handleDeletePost}>Confirm</Button>
                    <Button colorScheme={'twitter'} onClick={()=>setDeleteModal(false)}>Cancel</Button>
                </HStack>
            </VStack>
        </ModalBody>
    </ModalContent>
</Modal>
)
}

export default DeleteUserModal