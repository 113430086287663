import { Button, HStack, Input, VStack, useMediaQuery } from "@chakra-ui/react";
import React, { useContext, useRef, useState } from "react";
import { GoSmiley } from "react-icons/go";
import { themePrimaryColor } from "../utils/theme";
import { useDispatch, useSelector } from "react-redux";
import { createCommentApi } from "../store/comment/comment.action";
import { AppContext } from "../context/AppContext";
import EmojiPicker from "emoji-picker-react";

const CommentInput = ({ postId, setHandlePostModal, h = "auto" }) => {
  const [comment, setComment] = useState("");
  const [isLargerThan650] = useMediaQuery("(min-width: 650px)");
  const [isLargerThan450] = useMediaQuery("(min-width: 450px)");
  const [isLargerThan766] = useMediaQuery("(min-width: 766px)");
  const { Token } = useSelector((store) => store.authData);
  const { profileData } = useSelector((store) => store.userData);
  const { setCurrentPostCommentCount } = useContext(AppContext);
  const inputRef = useRef(null);
  const dispatch = useDispatch();
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const handleCreateComment = (event) => {
    event.preventDefault();
    dispatch(
      createCommentApi(
        Token,
        { userId: profileData.id, content: comment, postId },
        {
          email: profileData.email,
          profilePicture: profileData.profilePicture,
          role: profileData.role,
        }
      )
    );
    setCurrentPostCommentCount((pre) => Number(pre)+1);
    setComment("");
    isLargerThan766&&setHandlePostModal(true);
    setShowEmojiPicker(false);
  };
  const handleEmojiClick = (event) => {
    // Insert the clicked emoji at the cursor position
    const cursorPosition = inputRef.current.selectionStart;
    const newValue =
      comment.slice(0, cursorPosition) +
      event.emoji +
      comment.slice(cursorPosition);

      setComment(newValue);
  };
  const toolgeEmoji = ()=>{
    setShowEmojiPicker(!showEmojiPicker)
  }
  return (
    <form style={{ width: "100%" }} onSubmit={handleCreateComment}>
      <HStack position={'relative'} borderTop={"0.2px solid grey"} h={h} w={"100%"}>
      {showEmojiPicker && (
          <VStack position={'absolute'} bottom={isLargerThan450?"3rem":"3.5rem"} >
            <EmojiPicker  height={isLargerThan450?'350px':"290px"} width={isLargerThan450?'350px':"88vw"}  searchDisabled={true} skinTonesDisabled={true} onEmojiClick={handleEmojiClick} />
          </VStack>
          )}  
        <Button
          background={"white !important"}
          p={"0"}
          h={"auto"}
          fontSize={["1.2rem", "1.3rem", "1.4rem", "1.5rem"]}
          onClick={toolgeEmoji}
        >
          <GoSmiley />
        </Button>
        <Input
          p={"0"}
          onChange={(e) => setComment(e.target.value)}
          value={comment}
          ref={inputRef}
          type="text"
          border={"none !important"}
          outline={"none !important"}
          placeholder="Add a comment..."
          onClick={()=>setShowEmojiPicker(false)}
          boxShadow={"none !important"}
        />
        <Button
          fontSize={"0.9rem"}
          type="submit"
          color={themePrimaryColor}
          isDisabled={!comment.trim().length}
          background={"white !important"}
        >
          Post
        </Button>
      </HStack>
    </form>
  );
};

export default CommentInput;
