// import firebase from 'firebase/app';

import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
const firebaseConfig = {
  apiKey: "AIzaSyA78i6IZ3GtGua-PhVUAOeU4R2tlJJzjQE",
  authDomain: "drodel-chat.firebaseapp.com",
  projectId: "drodel-chat",
  storageBucket: "drodel-chat.appspot.com",
  messagingSenderId: "554229580899",
  appId: "1:554229580899:web:9824f5dcb4200fb6132456"
};
// const firebaseConfig = {
//   apiKey: "AIzaSyCOzoTZ_EZklWPK1xWYU7f73LWGnaIzt7U",
//   authDomain: "chat-app-aceb0.firebaseapp.com",
//   projectId: "chat-app-aceb0",
//   storageBucket: "chat-app-aceb0.appspot.com",
//   messagingSenderId: "647105464378",
//   appId: "1:647105464378:web:c9d5bdd1879eb8ca5d4d03"
// };
// const firebaseConfig = {
//   apiKey: "AIzaSyBzmp46wb_totQ5TpzorvgDlRfLkXEHQ8A",
//   authDomain: "fashion-111bc.firebaseapp.com",
//   projectId: "fashion-111bc",
//   storageBucket: "fashion-111bc.appspot.com",
//   messagingSenderId: "856638402112",
//   appId: "1:856638402112:web:cb2e1d0423d3d9bfc179a5"
// };

// Initialize Firebase
const app = initializeApp(firebaseConfig);

const db = getFirestore(app);
const storage = getStorage(app);

export { db, storage };
