import {
  Badge,
  Button,
  HStack,
  Textarea,
  VStack,
  useToast,
} from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import { themePrimaryColor } from "../utils/theme";
import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";
import { storage } from "../firebaseFile";

import { uploadFileOnFireBase } from "../utils/utils";
import UploadImageModal from "./UploadImageModal";
import { createPostApi } from "../store/post/post.action";
import { useDispatch, useSelector } from "react-redux";
import { DESIGNER_ROLE } from "../utils/constant";
import UploadVideoModal from "./UploadVideoModal";
import {
  CREATE_POST_ERROR,
  CREATE_POST_SUCCESS,
} from "../store/post/post.types";
// const uploadImagesOnFirebase = async (files) => {
//   const imagesStoredUrls = await Promise.all(files.map(async (file, index) => {
//     const storageRef = ref(storage, `files/${file.name}`);
//     const uploadTask = uploadBytesResumable(storageRef, file);
//     let downloadUrl = await new Promise((resolve, reject) => {
//       uploadTask.on(
//         "state_changed",
//         (snapshot) => {
//           const progress = Math.round(
//             (snapshot.bytesTransferred / snapshot.totalBytes) * 100
//           );
//           console.log(progress, file.name, 'image number', index + 1);
//         },
//         (error) => {
//           console.log(error);
//           reject(error); // Reject the promise in case of an error
//         },
//         async () => {
//           const url = await getDownloadURL(uploadTask.snapshot.ref);
//           resolve(url); // Resolve the promise with the download URL
//         }
//       );
//     });
//     return downloadUrl;
//   }))
//   return imagesStoredUrls;
// }

const AddTimeLinePost = () => {
  const [caption, setCaption] = useState("");
  const captionRef = useRef(null);
  const toast = useToast();
  const { Token } = useSelector((store) => store.authData);
  const { profileData } = useSelector((store) => store.userData);
  const { message, error } = useSelector((store) => store.postData);
  const [uploadImagesPostModal, setImagesUploadPostModal] = useState(false);
  const [videosUploadModal, setVideosUploadPostModal] = useState(false);
  const [imagesFiles, setImagesFiles] = useState([]);
  const [videosFiles, setVideosFiles] = useState([]);
  const [imagesPreview, setImagesPreview] = useState([]);
  const [videoPreview, setVideoPreview] = useState("");
  const dispatch = useDispatch();

  const handlePost = async () => {
    if (imagesFiles.length || videoPreview?.length) {
      let allImages = [];
      if (imagesFiles.length) {
        allImages = await uploadFileOnFireBase(imagesFiles);
      }
      let video = [];
      if (videoPreview.length) {
        video = await uploadFileOnFireBase(videosFiles);
      }
      console.log(video, "checking video files here or not");
      dispatch(
        createPostApi(Token, {
          caption: captionRef.current.value ?? "",
          imageUrls: allImages,
          userId: profileData.id,
          videoUrl: video.length ? video[0] : null,
        })
      ).then((res) => {
        if (res.type === CREATE_POST_SUCCESS) {
          toast({
            title: res.payload,
            status: "success",
            isClosable: true,
            duration: 1800,
          });
        } else if (res.type === CREATE_POST_ERROR) {
          toast({
            title: res.payload,
            status: "error",
            isClosable: true,
            duration: 1800,
          });
        }
      });
      captionRef.current.value = "";
      setImagesFiles([]);
      setImagesPreview([]);
      setVideoPreview("");
      setVideosFiles([]);
    }
  };
  return (
    <VStack bgColor={"white"} p={"2rem"} w={"100%"}>
      <Textarea ref={captionRef} placeholder="write something you feel like" />
      <HStack
        w={"100%"}
        align={["end", "end", "start", "start"]}
        flexDirection={["column-reverse", "column-reverse", "row", "row"]}
      >
        <HStack w={["100%", "100%", "90%", "90%"]} flexWrap={"wrap"}>
          {imagesFiles?.map((file) => (
            <Badge
              maxW={"100%"}
              fontSize={["0.55rem", "0.6rem", "0.7rem", "0.7rem"]}
              overflow={"hidden"}
              colorScheme="green"
            >
              {file.name}
            </Badge>
          ))}
          {videosFiles.length && (
            <Badge
              fontSize={["0.55rem", "0.6rem", "0.7rem", "0.7rem"]}
              colorScheme={"orange"}
            >
              {videosFiles[0].name}
            </Badge>
          )}
        </HStack>
        <Button
          onClick={handlePost}
          bgColor={"#56a193"}
          rounded={"5rem"}
          color={"white"}
          p={["0.4rem 0.5rem", "0.6rem", "0.68rem", "0.68rem"]}
          fontSize={["0.8rem", "0.9rem", "1rem", "1rem"]}
          h={"auto"}
        >
          Send Post
        </Button>
      </HStack>
      <HStack
        w={"100%"}
        justify={"end"}
        p={["0", "0.2rem ", "1rem 0", "1rem 0"]}
      >
        <Button
          bgColor={themePrimaryColor}
          onClick={() => setVideosUploadPostModal(true)}
          rounded={"5rem"}
          color={"white"}
          p={["0.4rem 0.5rem", "0.6rem", "0.68rem", "0.68rem"]}
          fontSize={["0.8rem", "0.9rem", "1rem", "1rem"]}
          h={"auto"}
        >
          Add Short Video
        </Button>
        <Button
          onClick={() => setImagesUploadPostModal(true)}
          bgColor={themePrimaryColor}
          rounded={"5rem"}
          color={"white"}
          p={["0.4rem 0.5rem", "0.6rem", "0.68rem", "0.68rem"]}
          fontSize={["0.8rem", "0.9rem", "1rem", "1rem"]}
          h={"auto"}
        >
          Add Photos
        </Button>
      </HStack>
      <UploadImageModal
        imagesPreview={imagesPreview}
        setImagesPreview={setImagesPreview}
        setFiles={setImagesFiles}
        isOpen={uploadImagesPostModal}
        setOpenModal={setImagesUploadPostModal}
      />
      <UploadVideoModal
        videoPreview={videoPreview}
        setFiles={setVideosFiles}
        isOpen={videosUploadModal}
        setOpenModal={setVideosUploadPostModal}
        setVideoPreview={setVideoPreview}
      />
    </VStack>
  );
};

export default AddTimeLinePost;
