import {  GET, POST, apiRequest } from "../../utils/api";
import { ADD_SAVED_POST_ERROR, ADD_SAVED_POST_LOADING, ADD_SAVED_POST_SUCCESS, GET_SAVED_POSTS_ERROR, GET_SAVED_POSTS_LOADING, GET_SAVED_POSTS_SUCCESS, UPDATE_SAVED_POST_ERROR, UPDATE_SAVED_POST_LOADING, UPDATE_SAVED_POST_SUCCESS } from "./saved.types";
import { unAuthorisedApi } from "../auth/auth.action";
import { getAllUsersBehalfId, getObjectsWithUniqueUserIds } from "../../utils/utils";

export const getSavedPosts = (token,userId) => async (dispatch) => {
  try {
    dispatch({ type: GET_SAVED_POSTS_LOADING});
    const res = await apiRequest({ type: GET, endpoint: `posts/getAllSavedPostsByUser/${userId}`, token });
    const uniqueUserIds = getObjectsWithUniqueUserIds(res.data.data);
    const uniqueUsers = await getAllUsersBehalfId(uniqueUserIds,token);
    const likedResposne = await apiRequest({ type: GET, endpoint: `posts/likespostById/${userId}`, token });
    likedResposne.data.data.forEach(likedPost => {
      const postIndex = res.data.data.findIndex(post => post.id === likedPost.id);
      if (postIndex !== -1) {
        res.data.data[postIndex].isLikedByMe = true;
      }
   });
    const uniqueUserDataMap = {};
    uniqueUsers.forEach(user => {
      uniqueUserDataMap[user.id] = {
            email: user.email,
            profilePicture: user.profilePicture,
            role: user.role,
            fullName:user.fullName
        };
    });
    const usersInfoAddedInSavedPostData = res.data.data.map(item => {
      const userData = uniqueUserDataMap[item.userId];
      return {
          ...item,
          userData: userData
      };
    });
    return dispatch({ type: GET_SAVED_POSTS_SUCCESS, payload: usersInfoAddedInSavedPostData});
  } catch (err) {
    err = err.response?.data?.message
      ? err.response?.data?.message
      : err.message;
    console.log(err,'checking error here');
    dispatch(unAuthorisedApi(err));
    return dispatch({
      type: GET_SAVED_POSTS_ERROR,
      payload: err
    });
  }
};
export const addSavedPost = (token,data) => async (dispatch) => {
  try {
    dispatch({ type: ADD_SAVED_POST_LOADING});
    const res = await apiRequest({ type: POST, endpoint: 'posts/savePost ', token,data });
    console.log(res.data,'checking post added my data here');
    return dispatch({ type: ADD_SAVED_POST_SUCCESS, payload: res.data.message});
  } catch (err) {
    err = err.response?.data?.message
      ? err.response?.data?.message
      : err.message;
    dispatch(unAuthorisedApi(err));
    return dispatch({
      type: ADD_SAVED_POST_ERROR,
      payload: err
    });
  }
};
export const updateSavedPosts = (token,data) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_SAVED_POST_LOADING});
    const res = await apiRequest({ type: GET, endpoint: `posts/postById/${data.postId}`, token});
    let updatedData = res.data.data;
    updatedData = {...updatedData,userData:data.userData,isLikedByMe:data.isLikedByMe};
    console.log(updatedData,'data for update');
    return dispatch({ type: UPDATE_SAVED_POST_SUCCESS, payload: updatedData});
  } catch (err) {
    err = err.response?.data?.message
      ? err.response?.data?.message
      : err.message;
    dispatch(unAuthorisedApi(err));
    return dispatch({
      type: UPDATE_SAVED_POST_ERROR,
      payload: err
    });
  }
};
