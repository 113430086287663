import { Box, HStack, Image, Text, useToast } from "@chakra-ui/react";
import React, { useContext, useEffect, useState } from "react";
import "@splidejs/react-splide/css";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import { v4 } from "uuid";
import UploadImageModal from "./UploadImageModal";
import { uploadFileOnFireBase } from "../utils/utils";
import '../App.css';
import { useDispatch, useSelector } from "react-redux";
import { updateUserProfile } from "../store/user/user.action";
import { UPDATE_USER_PROFILE_ERROR, UPDATE_USER_PROFILE_SUCCESS } from "../store/user/user.types";
import { FaEdit } from "react-icons/fa";
import { themePrimaryColor } from "../utils/theme";
import { AppContext } from "../context/AppContext";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-fade";
import { EffectFade,Autoplay } from "swiper/modules";

const BackgroundImage = ({
  backGroundPic,
  userId,
  handlePhotoFullView
}) => {
  const [uploadProfileImage, setUploadProfileImage] = useState(false);
  const { Token } = useSelector((store) => store.authData);
  const { profileData } = useSelector((store) => store.userData);
  const { tempBackgroundUrl, setTempBackgroundUrl } = useContext(AppContext);
  const toast = useToast();
  const [uploadImage, setUploadImage] = useState([]);
  const [imagesPreview, setImagesPreview] = useState([]);
  const dispatch = useDispatch();
  useEffect(() => {
    if (uploadImage?.length) {
      handleUpdateProfile();
    }
  }, [uploadImage]);

  const handleUpdateProfile = async () => {
    let profileImageUrl = await uploadFileOnFireBase(uploadImage);
    setTempBackgroundUrl(profileImageUrl);
    dispatch(updateUserProfile(Token, { userId: profileData.id, backGroundPic: profileImageUrl })).then((res) => {
      if (res?.type === UPDATE_USER_PROFILE_ERROR) {
        toast({ status: 'error', title: res?.payload ?? "Error in updating backgroundImage", isClosable: true, duration: 1800 });
      } else if (res?.type === UPDATE_USER_PROFILE_SUCCESS) {
        toast({ status: 'success', title: res?.payload ?? "background Image Updated", isClosable: true, duration: 1800 });
        setImagesPreview([]);
      }
    })

  };
  return (
    <HStack w={"100%"} position={"relative"}>
      {userId === profileData.id && <Box
        cursor={"pointer"}
        as="span"
        onClick={() => setUploadProfileImage(true)}
        position={"absolute"}
        top={"-0.5rem"}
        fontSize={['1rem', '1.1rem', '1.2rem', "1.2rem"]}
        zIndex={1000}
        right={"-0.3rem"}
        color={themePrimaryColor}
      >
        <FaEdit />
      </Box>}
      <Swiper
        spaceBetween={30}
        effect={"fade"}
        loop={true}
        modules={[EffectFade,Autoplay]}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        style={{width:'100%'}}
        className="mySwiper"
      >
           {/* <SwiperSlide>
              <Image
                rounded={"0.5rem"}
                objectFit={"cover"}
                h={['30vh', '38vh', '40vh', "45vh"]}
                // onClick={()=>handlePhotoFullView(tempBackgroundUrl?.length?tempBackgroundUrl:backGroundPic,false)}
                src={tempBackgroundUrl?.length?tempBackgroundUrl:backGroundPic}
                w={"100%"}
              />
            </SwiperSlide> */}
            {/* <SwiperSlide>
              <Image
                rounded={"0.5rem"}
                objectFit={"cover"}
                h={['30vh', '38vh', '40vh', "45vh"]}
                // onClick={()=>handlePhotoFullView(tempBackgroundUrl?.length?tempBackgroundUrl:backGroundPic,false)}
                src={"https://swiperjs.com/demos/images/nature-3.jpg"}
                w={"100%"}
              />
            </SwiperSlide> */}
       {
          tempBackgroundUrl?.length&& typeof tempBackgroundUrl==='object' ?tempBackgroundUrl?.map((el)=>(
          <SwiperSlide>
              <Image
                rounded={"0.5rem"}
                objectFit={"cover"}
                h={['30vh', '38vh', '40vh', "45vh"]}
                // onClick={()=>handlePhotoFullView(tempBackgroundUrl?.length?tempBackgroundUrl:backGroundPic,false)}
                src={el}
                w={"100%"}
              />
          </SwiperSlide>
          )):typeof backGroundPic ==='object' && backGroundPic?.map((el)=>(
        <SwiperSlide>
          <Image
               rounded={"0.5rem"}
                objectFit={"cover"}
                h={['30vh', '38vh', '40vh', "45vh"]}
                // onClick={()=>handlePhotoFullView(tempBackgroundUrl?.length?tempBackgroundUrl:backGroundPic,false)}
                src={el}
                w={"100%"}
              />
        </SwiperSlide>
          ))
        }
      </Swiper>

      <UploadImageModal
        isUploadSingle={false}
        setFiles={setUploadImage}
        isOpen={uploadProfileImage}
        setOpenModal={setUploadProfileImage}
        imagesPreview={imagesPreview}
        setImagesPreview={setImagesPreview}
      />
    </HStack>
  );
};

export default BackgroundImage;