import axios from "axios";
import { GET, POST, UPDATE, apiRequest, baseurl } from "../../utils/api";
import { GET_ALL_USER_PROFILE_ERROR, GET_ALL_USER_PROFILE_LOADING, GET_ALL_USER_PROFILE_SUCCESS, GET_ANOTHER_USER_PROFILE_ERROR, GET_ANOTHER_USER_PROFILE_LOADING, GET_ANOTHER_USER_PROFILE_SUCCESS, GET_MY_FOLLOWER_ERROR, GET_MY_FOLLOWER_LOADING, GET_MY_FOLLOWER_SUCCESS, GET_MY_FOLLOWING_ERROR, GET_MY_FOLLOWING_LOADING, GET_MY_FOLLOWING_SUCCESS, GET_USER_FOLLOWER_ERROR, GET_USER_FOLLOWER_LOADING, GET_USER_FOLLOWER_SUCCESS, GET_USER_FOLLOWING_ERROR, GET_USER_FOLLOWING_LOADING, GET_USER_FOLLOWING_SUCCESS, UPDATE_USER_FOLLOWER_ERROR, UPDATE_USER_FOLLOWER_LOADING, UPDATE_USER_FOLLOWER_SUCCESS, UPDATE_USER_FOLLOWING_ERROR, UPDATE_USER_FOLLOWING_LOADING, UPDATE_USER_FOLLOWING_SUCCESS, UPDATE_USER_PROFILE_ERROR, UPDATE_USER_PROFILE_LOADING, UPDATE_USER_PROFILE_SUCCESS, USER_PROFILE_ERROR, USER_PROFILE_LOADING, USER_PROFILE_SUCCESS } from "./user.types";
import { unAuthorisedApi } from "../auth/auth.action";
export const getUserProfile = (token) => async (dispatch) => {
  dispatch({ type: USER_PROFILE_LOADING });
  try {
    const res = await apiRequest({ type: GET, endpoint: 'auth/get-user-by-token', token });
    return dispatch({ type: USER_PROFILE_SUCCESS, payload: res.data });
  } catch (err) {
    err = err.response?.data?.message
      ? err.response?.data?.message
      : err.message;
    dispatch(unAuthorisedApi(err));
    return dispatch({
      type: USER_PROFILE_ERROR,
      payload: err
    });
  }
};
export const getAllUserProfile = (token) => async (dispatch) => {
  dispatch({ type: GET_ALL_USER_PROFILE_LOADING });
  try {
    const res = await apiRequest({ type: GET, endpoint: 'users/getAllUsers', token });
    res.data.data = res.data.data?.filter((el)=>el.email!==process.env.REACT_APP_ADMIN_ID);
    return dispatch({ type: GET_ALL_USER_PROFILE_SUCCESS, payload: res.data.data });
  } catch (err) {
    err = err.response?.data?.message
      ? err.response?.data?.message
      : err.message;
    dispatch(unAuthorisedApi(err));
    return dispatch({
      type: GET_ALL_USER_PROFILE_ERROR,
      payload: err
    });
  }
};
export const getMyFollowing = (token,id) => async (dispatch) => {
  dispatch({ type: GET_MY_FOLLOWING_LOADING });
  try {    
    const res = await apiRequest({ type: GET, endpoint: `users/getUserFollowing/${id}`, token });
    return dispatch({ type: GET_MY_FOLLOWING_SUCCESS, payload: res.data.following });
  } catch (err) {
    err = err.response?.data?.message
      ? err.response?.data?.message
      : err.message;
    dispatch(unAuthorisedApi(err));
    return dispatch({
      type: GET_MY_FOLLOWING_ERROR,
      payload: err
    });
  }
};
export const getMyFollower = (token,id) => async (dispatch) => {
  dispatch({ type: GET_MY_FOLLOWER_LOADING });
  try {
    const res = await apiRequest({ type: GET, endpoint: `users/getUserFollowers/${id}`, token });
    return dispatch({ type: GET_MY_FOLLOWER_SUCCESS, payload: res.data.followers });
  } catch (err) {
    err = err.response?.data?.message
      ? err.response?.data?.message
      : err.message;
    dispatch(unAuthorisedApi(err));
    return dispatch({
      type: GET_MY_FOLLOWER_ERROR,
      payload: err
    });
  }
};
export const getUserFollowing = (token,id) => async (dispatch) => {
  dispatch({ type: GET_USER_FOLLOWING_LOADING });
  try {    
    const res = await apiRequest({ type: GET, endpoint: `users/getUserFollowing/${id}`, token });
    
    return dispatch({ type: GET_USER_FOLLOWING_SUCCESS, payload: res.data.following });
  } catch (err) {
    err = err.response?.data?.message
      ? err.response?.data?.message
      : err.message;
    dispatch(unAuthorisedApi(err));
    return dispatch({
      type: GET_USER_FOLLOWING_ERROR,
      payload: err
    });
  }
};
export const getUserFollower = (token,id) => async (dispatch) => {
  dispatch({ type: GET_USER_FOLLOWER_LOADING });
  try {
    const res = await apiRequest({ type: GET, endpoint: `users/getUserFollowers/${id}`, token });
    return dispatch({ type: GET_USER_FOLLOWER_SUCCESS, payload: res.data.followers });
  } catch (err) {
    err = err.response?.data?.message
      ? err.response?.data?.message
      : err.message;
    dispatch(unAuthorisedApi(err));
    return dispatch({
      type: GET_USER_FOLLOWER_ERROR,
      payload: err
    });
  }
};
export const getAnotherUserProfile = (token,id) => async (dispatch) => {
  dispatch({ type: GET_ANOTHER_USER_PROFILE_LOADING });
  try {
    const res = await apiRequest({ type: GET, endpoint: `users/getUserById/${id}`, token });
    return dispatch({ type: GET_ANOTHER_USER_PROFILE_SUCCESS, payload: res.data.data });
  } catch (err) {
    err = err.response?.data?.message
      ? err.response?.data?.message
      : err.message;
    dispatch(unAuthorisedApi(err));
    return dispatch({
      type: GET_ANOTHER_USER_PROFILE_ERROR,
      payload: err
    });
  }
};
export const addFollowing = (token,data) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_USER_FOLLOWING_LOADING});
    const res = await apiRequest({ type: UPDATE, endpoint: 'users/addFollowing', token,data });
    return dispatch({ type: UPDATE_USER_FOLLOWING_SUCCESS, payload: res.data.message});
  } catch (err) {
    err = err.response?.data?.message
      ? err.response?.data?.message
      : err.message;
    dispatch(unAuthorisedApi(err));
    return dispatch({
      type: UPDATE_USER_FOLLOWING_ERROR,
      payload: err
    });
  }
};
export const addFollower = (token,data) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_USER_FOLLOWER_LOADING});
    const res = await apiRequest({ type: UPDATE, endpoint: 'users/addFollower', token,data });
    return dispatch({ type: UPDATE_USER_FOLLOWER_SUCCESS, payload: res.data.message});
  } catch (err) {
    err = err.response?.data?.message
      ? err.response?.data?.message
      : err.message;
    dispatch(unAuthorisedApi(err));
    return dispatch({
      type: UPDATE_USER_FOLLOWER_ERROR,
      payload: err
    });
  }
};
export const removeFollowing = (token,data) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_USER_FOLLOWING_LOADING});
    const res = await apiRequest({ type: UPDATE, endpoint: 'users/removeFollowing', token,data });
    return dispatch({ type: UPDATE_USER_FOLLOWING_SUCCESS, payload:{followingId:data.followingId,status:'remove'}});
  } catch (err) {
    err = err.response?.data?.message
      ? err.response?.data?.message
      : err.message;
    dispatch(unAuthorisedApi(err));
    return dispatch({
      type: UPDATE_USER_FOLLOWING_ERROR,
      payload: err
    });
  }
};
export const removeFollower = (token,data) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_USER_FOLLOWER_LOADING});
    const res = await apiRequest({ type: UPDATE, endpoint: 'users/removeFollower', token,data });
    return dispatch({ type: UPDATE_USER_FOLLOWER_SUCCESS, payload:{followerId:data.followingId,status:'remove'}});
  } catch (err) {
    err = err.response?.data?.message
      ? err.response?.data?.message
      : err.message;
    console.log('checking error',err);
    dispatch(unAuthorisedApi(err));
    return dispatch({
      type: UPDATE_USER_FOLLOWER_ERROR,
      payload: err
    });
  }
};
export const updateUserProfile = (token,data) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_USER_PROFILE_LOADING});
    const res = await apiRequest({ type: UPDATE, endpoint: 'users/updateUser', token,data });
    return dispatch({ type: UPDATE_USER_PROFILE_SUCCESS, payload: res.data.message});
  } catch (err) {
    err = err.response?.data?.message
      ? err.response?.data?.message
      : err.message;
    dispatch(unAuthorisedApi(err));
    return dispatch({
      type: UPDATE_USER_PROFILE_ERROR,
      payload: err
    });
  }
};