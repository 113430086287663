import { Heading, VStack, Button, Box, Avatar, Image as ChakraImage, useMediaQuery } from "@chakra-ui/react";
import React, { useContext, useState } from "react";
import { AiFillHome } from "react-icons/ai";
import { BiSearch, BiMoviePlay } from "react-icons/bi";
import { FaTelegramPlane,FaFireAlt } from "react-icons/fa";
import { MdOutlineAddBox, MdFavoriteBorder } from "react-icons/md";
import { IoMdNotificationsOutline } from "react-icons/io";
import { IoSettingsOutline } from "react-icons/io5";
import { CiSettings } from "react-icons/ci";
import Logo from "../images/Logo.png";
import { themePrimaryColor } from "../utils/theme";
import { useLocation, useNavigate } from "react-router-dom";
import { v4 } from "uuid";
import { useSelector } from "react-redux";
import { DESIGNER_ROLE } from "../utils/constant";
import { AppContext } from "../context/AppContext";
const LeftSideBar = ({onClose}) => {
  const [active, setActive] = useState("");
  const { profileData } = useSelector((store) => store.userData);
  const {setFullScreen,setFooterComponent} = useContext(AppContext);
  const [isLargerThen992] = useMediaQuery('(min-width: 992px)')
  const [isLargerThan775] = useMediaQuery('(min-width: 769px)')
  const navigate = useNavigate();
  const location = useLocation();
  const ButtonsList = [
    {
      title: "Home",
      icon: <Box fontSize={['1.5rem','1.9rem','2rem','1.5rem']} as={AiFillHome}/>,
      link: "/",
    },
    {
      title: "Profile",
      icon: <Box as="span">
        <Avatar size={'sm'} />
      </Box>,
      link: "/profile",
    },
    {
      title: "Search",
      icon: <Box as={BiSearch} fontSize={['1.5rem','1.9rem','2rem','1.5rem']}/>,
      link: "/search",
    },
    {
      title:"Photos",
      icon: <Box as={MdOutlineAddBox} color={'#E9246A'} fontSize={['1.5rem','1.9rem','2rem','1.5rem']}/>,
      link: "/photos",
    },
    {
      title: "ShortVideoClips",
      icon: <Box as={BiMoviePlay} color={'#E9246A'} fontSize={['1.5rem','1.9rem','2rem','1.5rem']}/>,
      link: "/shortvideos",
    },
    {
      title: "Collection",
      icon: <Box as={MdFavoriteBorder} color={'#ff1d2c'}fontSize={['1.5rem','1.9rem','2rem','1.5rem']}/>,
      link: "/collection",
    },
    {
      title: "Trending",
      icon: <Box as={FaFireAlt} color={'#FBB741'} fontSize={['1.5rem','1.9rem','2rem','1.5rem']}/>,
      link: "/trending",
    },
    {
      title: "Chat",
      icon: <Box as={FaTelegramPlane}fontSize={['1.5rem','1.9rem','2rem','1.5rem']}/>,
      link: "/message",
    },
    {
      title: "Notifications",
      icon:<Box as={IoMdNotificationsOutline}fontSize={['1.5rem','1.9rem','2rem','1.5rem']}/> ,
      link: "/notification",
    },
    {
      title: "Setting",
      icon:  <Box as={IoSettingsOutline}fontSize={['1.5rem','1.9rem','2rem','1.5rem']}/>,
      link: "/setting",
    },
  ];

  return (
    <VStack
      bgColor={"white"}
      minH={"100vh"}
      maxH={"100vh"}
      gap={['2.4rem','2.5rem','2.6rem','2.8rem']}
      pt={'1rem'}
      // borderRight={isLargerThan775?`1px solid ${themePrimaryColor}`:'1px solid transparent'}
      position={'sticky'}
      left={0}
      top={0}
      w={['100%','100%','13%',"22%"]}
      boxShadow={'10px 0 15px -20px rgba(0, 0, 0, 0.3)'}
    >
      <ChakraImage
        w={['5rem','5rem','6rem',"7.5rem"]}
        onClick={()=>{navigate('/');setActive('/')}}
        cursor={'pointer'}
        // style={{
        //   backgroundImage:
        //     "linear-gradient(to right, rgb(37 151 217) 0%, rgb(163 73 191) 51%, rgb(37 151 217) 100%)",
        //   transition: "0.5s",
        //   backgroundSize: "200% auto",
        //   color: "white",
        //   boxShadow: "0 0 20px #eee",
        //   display: "block",
        // }}
        // _hover={{ backgroundPosition: "right center" }}
        src={Logo}
        // border={`1px solid ${themePrimaryColor}`}
      />
      <VStack w={["87.5%"]} gap={'0.6rem'} align={"center"}>
        {
          ButtonsList?.map((el) => {
            return (isLargerThen992||!isLargerThan775)?      
            <Button
              _hover={{
                background: themePrimaryColor,
                color: 'white'
              }}
              key={v4()}
              gap={"0.6rem"}
              fontWeight={400}
              style={(active === el.link||location.pathname==el.link) ? { background: themePrimaryColor, color: 'white' } : {}}
              rounded={"0.5rem"}
              fontSize={['1rem','1rem','1.15rem',"1.15rem"]}
              p={['1rem',"1.4rem 0.6rem"]}
              w={['90%','90%','15%',"90%"]}
              justifyContent={"start"}
              leftIcon={el.icon}
              colorScheme="white"
              color={"black"}
              onClick={() => {
                setFullScreen('');
                setFooterComponent(null);
                setActive(el.link);
                navigate(el.link)
                if(onClose)onClose();
              }}
            >
              {el.title}
            </Button>:<Button               _hover={{
                background: themePrimaryColor,
                color: 'white'
              }}
              key={v4()}
              gap={"0.6rem"}
              fontWeight={400}
              style={active === el.link ? { background: themePrimaryColor, color: 'white' } : {}}
              rounded={"0.5rem"}
              fontSize={"1.15rem"}
              p={['1rem',"1.4rem 0.6rem"]}
              justifyContent={"start"}
              colorScheme="white"
              color={"black"}
              onClick={() => {
                setActive(el.link);
                navigate(el.link)
              }}>{el.icon}</Button>
  })
        }
      </VStack>
    </VStack>
  );
};

export default LeftSideBar;
