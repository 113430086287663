import {
  VStack,
  useDisclosure,
  Image as ChakraImage,
  Spinner
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import Post from "./Post.jsx";
import { useDispatch, useSelector } from "react-redux";
import { v4 } from "uuid";
import Empty from "./Empty.jsx";
import { themePrimaryColor } from "../utils/theme.js";
import moment from "moment";

const MainProfile = () => {
  const { allPosts,get_all_posts_error,
  get_all_posts_loading,getUserPosts } = useSelector((store) => store.postData);
  
  return (
    <VStack w={['100%','100%','87%',"78%"]}  gap={'2.3rem'} pb={'1rem'} pt={'1.5rem'}>
        {get_all_posts_loading ? (
        <VStack minH={"65vh"} align={"center"} justify={"center"}>
          <Spinner color={themePrimaryColor} thickness="4px" size={"xl"} />
        </VStack>
      ) : allPosts.length ? (
        allPosts?.map((post,index) => (
          <Post postTime={post.createdAt} leftOrRight={index%2==0} key={v4()} videoUrl={post.videoUrl} isLikedByMe={post.isLikedByMe} caption={post.caption} userData={post.userData} postId={post.id} imageUrls={post.imageUrls?.filter((el)=>el.length)} likesCount={post.likesCount??0} sharesCount={post.sharesCount} commentsCount={post.commentsCount} />
        ))
      ) : (
        <Empty title="No posts available" />
      )}
    </VStack>
  );
};

export default MainProfile;
