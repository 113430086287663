import { HStack, Spinner, Text, VStack,Heading } from "@chakra-ui/react";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getNotifications } from "../../store/notification/notification.action";
import Empty from "../../components/Empty";
import { themePrimaryColor } from "../../utils/theme";

const Notifications = () => {
  const dispatch = useDispatch();
  const { Token } = useSelector((store) => store.authData);
  const { profileData } = useSelector((store) => store.userData);
  const { notifications, get_notifications_loading, get_notifications_error } =
    useSelector((store) => store.notificationData);
  useEffect(() => {
    if (Token && profileData.email.length) {
      dispatch(getNotifications(Token, profileData.id));
    }
  }, [Token]);
  useEffect(() => {
   console.log(get_notifications_loading,'notification loading')
  }, [get_notifications_loading]);

  return (
    <VStack w={["100%", "100%", "87%", "78%"]} pb={"1rem"}>
   
      <Heading color={themePrimaryColor} pt={'1rem'} fontSize={"2rem"} fontWeight={100} fontFamily={" Lora, serif"}>
         Notifications
        </Heading>
      {!get_notifications_loading &&
          notifications.length === 0 ? (
            <Empty title="No Notification Yet!" />
          ) :get_notifications_loading?  <VStack minH={"65vh"} align={"center"} justify={"center"}>
          <Spinner color={themePrimaryColor} thickness="4px" size={"xl"} />
        </VStack>:(
          <VStack bgColor={'white'} py={'1rem'} w={['90%','80%','60%','60%']} overflowY={"auto"} h={"97vh"} overflowX={"hidden"}>
            {notifications?.map((notification)=>(
                <HStack borderRadius={'0.2rem'} w={'100%'} border={`1px solid ${themePrimaryColor}`} p={'0.8rem 0.5rem'}>
                    <Text fontSize={['0.9rem','0.95rem','1rem','1rem']} className={'bree-serif-regular'} fontWeight={500}>{notification?.notificationMessage}</Text>
                </HStack>
            ))}
          </VStack>
          )}
    </VStack>
  );
};

export default Notifications;
