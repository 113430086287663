import React from "react";
import { FaInstagram, FaFacebook, FaYoutube } from "react-icons/fa";
import { BsTwitterX } from "react-icons/bs";
import { HStack, Heading, VStack ,Button} from "@chakra-ui/react";
import { Link } from "react-router-dom";

const FollowUS = () => {
  return (
    <HStack my={'3rem'} w={'100%'}  px={['2rem','3rem','4rem','6rem']}  justify={['center','end','end','end']}>
      <VStack gap={'2rem'}  justify={['center','center','end','end']}>
        <HStack justify={'space-between'} w={'100%'}>
        <Link about="_blank" to={'https://www.instagram.com/drodelfashion/?next=%2Faccounts%2Fsignup%2F'}>
          <FaInstagram style={{color:"#B831B7" ,fontSize:'1.5rem'}} />
        </Link>
        <Link about="_blank" to={'https://www.facebook.com/people/Drodel-Fashion/61556974562414/'}>
          <FaFacebook  style={{color:"#0867FE",fontSize:'1.5rem' }}/>
        </Link>
        <Link to={'#'} >
          <FaYoutube style={{color:"#FF0203",fontSize:'1.5rem' }} />
        </Link>
        <Link about="_blank" to={'https://twitter.com/DrodelFashion'}>
          <BsTwitterX style={{fontSize:'1.2rem'}}/>
        </Link>

        </HStack>
      <Button bgColor={"#0064E0 !important"}  color={'white'} px={'3rem'}>Follow Us</Button>
      </VStack>
    </HStack>
  );
};

export default FollowUS;
