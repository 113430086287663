import { GET_NOTIFICAITONS_ERROR, GET_NOTIFICAITONS_LOADING, GET_NOTIFICAITONS_SUCCESS } from "./notification.types";


const initialState = {
  notifications:[],
  get_notifications_loading: true,
  get_notifications_error: '',
};
export const notificationReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_NOTIFICAITONS_LOADING: {
      return {
        ...state,
        get_notifications_loading:true,
      };
    }
    case GET_NOTIFICAITONS_SUCCESS: {
      return {
        ...state,
        get_notifications_error: '',
        get_notifications_loading: false,
        notifications: payload
      };
    }
    case GET_NOTIFICAITONS_ERROR: {
      return {
        ...state,
        get_notifications_error: payload,
        get_notifications_loading: false,
      };
    }
    default: {
      return state;
    }
  }
};
