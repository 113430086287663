import { Box, HStack, Text, VStack } from '@chakra-ui/react'
import React from 'react'
import { Link } from 'react-router-dom'

const Footer = () => {
  return (
    <VStack gap={'2rem'} w={'100%'} p={'1rem'} py={'2.2rem'}>
        <HStack flexWrap={'wrap'} justify={'center'} fontSize={'0.9rem'} gap={['2rem','2.2rem','2.4rem','2.8rem']} fontWeight={600}>
            <Link to={'/terms-conditions'}>Terms & Conditions</Link>
            <Box as='span' h={'1.1rem'} p={'0.1rem'} bg={'black'}></Box>
            <Link to={'/privacy'}>Privacy Policy</Link>
            <Box as='span' h={'1.1rem'} p={'0.1rem'} bg={'black'}></Box>
            <Link to={'/aboutus'}>About us</Link>
            <Box as='span' h={'1.1rem'} p={'0.1rem'} bg={'black'}></Box>
            <Link to={'/contact'}>Contact us</Link>
        </HStack>
        <HStack fontSize={'0.9rem'} fontWeight={600}>
           <Text textAlign={'center'}> Copyright © 2024 for Drodel Fashion |  Designed  & Developed by  <Box p={0} textDecoration={'1px solid underline'} m={0} as={Link} target='_blank' display={'inline'} to={'https://samnictech.in/'}>SamnicTech</Box></Text> 
          </HStack>
    </VStack>
  )
}

export default Footer