import {
  Box,
  Button,
  FormControl,
  FormLabel,
  HStack,
  Heading,
  Image,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Text,
  Textarea,
  Toast,
  VStack,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import { ReactImageTurntable } from "react-image-turntable";
import TrialRoomGrid from "../../components/TrialRoomGrid";
import { v4 } from "uuid";
import { IoMdArrowRoundBack } from "react-icons/io";
import { themePrimaryColor } from "../../utils/theme";
import Swal from "sweetalert2";
import { AiOutlineCloudUpload } from "react-icons/ai";
import { FaCheckDouble } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { getTrialPosts } from "../../store/post/post.action";
import Empty from "../../components/Empty";
import TrialPost from "../../components/TrialPost";
import { POST, apiRequest } from "../../utils/api";
import { uploadFileOnFireBase } from "../../utils/utils";

const TrialRoom = () => {
  const toast = useToast();
  const [imagesFiles, setImagesFiles] = useState([]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { trialRoomPosts,get_trial_posts_loading,
    get_trial_posts_error } = useSelector((store) => store.postData);
  const { Token } = useSelector((store) => store.authData);
  const inputRef = useRef();
  const [formData,setFormData] = useState({
    title:'',
    description:""
  })
  const {
    profileData
  } = useSelector((store) => store.userData);
  const dispatch = useDispatch();
  const handleSubmitContact = async(e) => {
    e.preventDefault();
    if(!imagesFiles.length){
      toast({ status: "warning", title: "Please select images",isClosable:true,duration:'1200' });
      return;
    };
    let allImages = await uploadFileOnFireBase(imagesFiles);
    let data = {
      ...formData,
      imageUrls:allImages,
      userId:profileData.id
    }
    const res = await apiRequest({ type: POST, endpoint: 'posts/createTrialQuery', data,token:Token });
    onClose();
    setTimeout(() => {
      Swal.fire({
        icon: "success",
        title: "Your form has been submitted",
        text:"We'll create trial room in 24 hours",
        showConfirmButton: false,
        timer: 2000,
      });
    }, 500);
    setImagesFiles([]);
  };
  const handleChangeImage = (e) => {
    let files = [...e.target.files];
    console.log(files.length,'checking length of files');
    if (files.length < 35) {
      toast({ status: "info", title: "Minimum images should be 36",isClosable:true,duration:'1200' });
      return;
    }
    setImagesFiles(files);
  };

  useEffect(() => {
    if (Token && profileData.email.length) {
      dispatch(getTrialPosts(Token, profileData.id));
    };
  }, [profileData]);
  useEffect(()=>{
    console.log(trialRoomPosts,'trialRooomPosts')
  },[trialRoomPosts])
  return (
    <VStack
      w={["100%", "100%", "87%", "78%"]}
      pb={"1rem"}
      position={"relative"}
    >
      <Heading
        color={themePrimaryColor}
        pt={"1rem"}
        fontSize={"2rem"}
        fontWeight={100}
        fontFamily={" Lora, serif"}
      >
        3D Creations
      </Heading>
      <Button
        onClick={onOpen}
        bgColor={themePrimaryColor}
        h={"auto"}
        py={["0.6rem", "0.7rem", "0.7rem", "0.7rem"]}
        fontSize={["0.8rem", "0.9rem", "1rem", "1rem"]}
        mt={"2rem"}
        color={"white"}
      >
        Contact Drodel Fashion To Add 3D Creations
      </Button>
      {
        get_trial_posts_loading&&<VStack minH={"65vh"} align={"center"} justify={"center"}>
         <Spinner color={themePrimaryColor} thickness="4px" size={"xl"} />
       </VStack>
      }
      {
      (!get_trial_posts_loading&&trialRoomPosts.length)?trialRoomPosts?.map((post)=>(
        <TrialPost key={v4()} postTime={post.createdAt} title={post.title} isTrial={post.isTrial} isLikedByMe={post.isLikedByMe} caption={post.caption} userData={post.userData} postId={post.id} imageUrls={post.imageUrls?.filter((el)=>el.length)} likesCount={post.likesCount??0} sharesCount={post.sharesCount} commentsCount={post.commentsCount} />
      )): get_trial_posts_loading?<></>:<Empty title='No 3D Creations Yet'/>
      }
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>3D Creations Product</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <form style={{width:"100%"}} onSubmit={handleSubmitContact}>
            <FormControl>
              <FormLabel m={'0'}>Product title</FormLabel>
              <Input required onChange={(e)=>setFormData({...formData,title:e.target.value})} placeholder="Title" />
            </FormControl>
            <FormControl mt={4}>
              <FormLabel m={0}>Product Description</FormLabel>
              <Textarea required onChange={(e)=>setFormData({...formData,description:e.target.value})} placeholder={"Description..."} />
            </FormControl>
            <FormControl mt={4}>
              <HStack w={"100%"} justify={"center"} top={"35%"}>
                <Box
                  w={["100%"]}
                  h={"6rem"}
                  cursor={"pointer"}
                  rounded={"0.2rem"}
                  border={`1px solid ${themePrimaryColor}`}
                  display={"flex"}
                  flexDirection={"column"}
                  alignItems={"center"}
                  as={"label"}
                  justifyContent={'center'}
                  htmlFor="my_video"
                >
                  { imagesFiles.length?<Text fontWeight={'600'}>{imagesFiles.length} Images has been selected <Box color={'green'} display={'inline'} as={FaCheckDouble}/></Text>:
                    <>
                      <Box
                        as={AiOutlineCloudUpload}
                        color={themePrimaryColor}
                        fontSize={["2.8rem", "2.8rem", "3rem", "3.5rem"]}
                      />
                      Click here to upload trial images
                    </>
                  }
                </Box>
              </HStack>
              <Input
                ref={inputRef}
                accept="image/*"
                display={"none"}
                id="my_video"
                onChange={handleChangeImage}
                type="file"
                multiple={true}
              />
            </FormControl>
            <HStack mt={4} w={'100%'} justify={'end'}>
            <Button
              color={"white"}
              bgColor={themePrimaryColor}
              mr={3}
              ml={'auto'}
              type="submit"
            >
              Submit
            </Button>
            </HStack>
            </form>
          </ModalBody>
          <ModalFooter>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </VStack>
  );
};

export default TrialRoom;
