import { legacy_createStore, combineReducers, applyMiddleware } from 'redux';
import { thunk } from "redux-thunk";
import { authReducer } from './auth/auth.reducer';
import { userReducer } from './user/user.reducer';
import { postReducer } from './post/post.reducer';
import { commentReducer } from './comment/comment.reducer';
import { savedReducer } from './saved/saved.reducer';
import { notificationReducer } from './notification/notification.reducer';

const rootReducer = combineReducers({
    authData: authReducer,
    userData: userReducer,
    postData:postReducer,
    commentData:commentReducer,
    savedData:savedReducer,
    notificationData:notificationReducer
});

export const store = legacy_createStore(rootReducer, applyMiddleware(thunk));
