import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  VStack,
  Button,
  useToast,
  Image as ChakraImage,
} from "@chakra-ui/react";
import { Formik, Field } from "formik";
import React, { useState } from "react";
import Logo from "../../images/Logo.png";
import { useNavigate } from "react-router-dom";
import Footer from "../../components/Footer";
import Swal from "sweetalert2";
import { POST, apiRequest } from "../../utils/api";
import { themePrimaryColor } from "../../utils/theme";
const ForgotPassword = () => {
  const [show, setShow] = useState(false);
  const toast = useToast();
  let navigate = useNavigate();
  const handleResetPassword = async (email) => {
    try {
      const res = await apiRequest({
        type: POST,
        endpoint: "auth/forgotPassword",
        data: { email },
        token: "",
      });
      console.log(res.data, "resposne data here");
      Swal.fire({
        icon: "success",
        title: "Email send successfully",
        showConfirmButton: false,
        timer: 1500,
      });
    } catch (error) {
      console.log(error, "checking error forgot ");
    }
  };
  return (
    <VStack w={"100%"} p={["0.5rem", "1rem", "1rem", "1rem"]} gap={"2rem"}>
      <VStack
        w={["90%", "70%", "48%", "32%"]}
        border={"1px solid rgb(219, 219, 219)"}
        py={"2rem"}
      >
        Confirm
        <ChakraImage
          w={"10rem"}
          cursor={"pointer"}
          onClick={() => navigate("/")}
          // style={{
          //   backgroundImage:
          //     "linear-gradient(to right, rgb(37 151 217) 0%, rgb(163 73 191) 51%, rgb(37 151 217) 100%)",
          //   transition: "0.5s",
          //   backgroundSize: "200% auto",
          //   color: "white",
          //   boxShadow: "0 0 20px #eee",
          //   display: "block",
          // }}
          // _hover={{ backgroundPosition: "right center" }}
          // border={`1px solid ${themePrimaryColor}`}
          src={Logo}
        />
        <Heading
          fontSize={["1.7rem", "1.85rem", "1.9rem", "2rem"]}
          textAlign={"center"}
          fontWeight={100}
          fontFamily={" Lora, serif"}
        >
          Reset Password
        </Heading>
        <VStack mt={"1rem"} width={["93%", "93%", "90%", "90%"]}>
          <Formik
            initialValues={{
              email: "",
            }}
            onSubmit={(values) => {
              let { email } = values;
              handleResetPassword(email);
            }}
          >
            {({ handleSubmit, errors, touched }) => (
              <form style={{ width: "100%" }} onSubmit={handleSubmit}>
                <VStack
                  gap={["1.1rem", "1.3rem", "1.4rem", "1.4rem"]}
                  align="flex-start"
                >
                  <FormControl
                    position={"relative"}
                    isInvalid={!!errors.userName && touched.userName}
                  >
                    <FormLabel m={"0"} htmlFor="email">
                      Email
                    </FormLabel>
                    <Field
                      as={Input}
                      id="email"
                      width={"100%"}
                      name="email"
                      type="email"
                      variant="outline"
                      borderRadius={"0"}
                      border="1.5px solid black"
                      _hover={{ background: "#ffffff", color: "black" }}
                      validate={(value) => {
                        let error;
                        if (!value) {
                          error = "Required";
                        }
                        return error;
                      }}
                    />
                    <FormErrorMessage position={"absolute"} mt={"0.2rem"}>
                      {errors.email}
                    </FormErrorMessage>
                  </FormControl>
                  <Button
                    _hover={{
                      color: "color",
                      background: "#0064E0",
                    }}
                    type="submit"
                    bg="black"
                    color={"white"}
                    width="full"
                    outline={"none"}
                    border={"none"}
                    cursor={"pointer"}
                    rounded={"0rem"}
                    // h="48px"
                    mt={"1rem"}
                    letterSpacing={"3px"}
                  >
                    Send Reset Mail
                  </Button>
                </VStack>
              </form>
            )}
          </Formik>
        </VStack>
      </VStack>
      <Footer />
    </VStack>
    // D33C99
  );
};

export default ForgotPassword;
