import {
  Avatar,
  Box,
  Button,
  HStack,
  Input,
  InputGroup,
  InputRightElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  VStack,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useContext, useEffect, useState } from "react";
import {
  DESIGNER_ROLE,
  MODEL_ROLE,
  RoleName,
  USER_ROLE,
} from "../utils/constant";
import { themePrimaryColor } from "../utils/theme";
import { FaSearch } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import {
  addFollower,
  addFollowing,
  getAnotherUserProfile,
  getMyFollower,
  getMyFollowing,
  getUserFollower,
  removeFollower,
  removeFollowing,
} from "../store/user/user.action";
import { useNavigate } from "react-router-dom";
import FollowCard from "./FollowCard";
import {
  UPDATE_USER_FOLLOWER_SUCCESS,
  UPDATE_USER_FOLLOWING_SUCCESS,
} from "../store/user/user.types";
import CreationGrid from "./CreationGrid";
import { getPostByAnotherUser } from "../store/post/post.action";
import BackgroundImage from "./BackgroundImage";
import { AppContext } from "../context/AppContext";
import PhotoFullView from "./PhotoFullView";
import VideoGrid from "./VideoGrid";
import Empty from "./Empty";
import Post from "./Post";
import { v4 } from "uuid";

const UserProfileCard = ({
  userData,
  followings,
  followers,
  isMe = false,
  currentUserId,
}) => {
  const [followerOrFollowing, setFollowerOrFollowing] = useState("");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { setCurrentChatUser } = useContext(AppContext);
  const { profileData, myFollowing, searched_user_loading } = useSelector(
    (store) => store.userData
  );
  const {
    userPosts,
    get_posts_by_another_user_loading,
    get_posts_by_another_user_error,
  } = useSelector((store) => store.postData);
  const { Token } = useSelector((store) => store.authData);
  const [isFollowing, setIsFollowing] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const [displayFullViewImage, setDisplayFullViewImage] = useState({
    image: null,
    isAvatar: false,
  });
  const [searchTerm, setSearchTerm] = useState("");
  const [showCreationVideo, setShowCreationVideo] = useState(false);
  const [openPhotoModal, setOpenPhotoModal] = useState(false);
  const handleOpen = (position) => {
    setFollowerOrFollowing(position);
    let setDefaultData =
      (position == "Followers" ? followers : followings) ?? [];
    setSearchResults(setDefaultData);
    onOpen();
  };

  useEffect(() => {
    if (
      userData.email?.length &&
      currentUserId == userData.id &&
      profileData.email?.length &&
      !isMe
    ) {
      dispatch(getPostByAnotherUser(Token, profileData.id, userData.id));
    }
  }, [userData, profileData]);

  const handleFollowButton = () => {
    if (isFollowing) {
      dispatch(
        removeFollowing(Token, {
          userId: profileData.id,
          followingId: userData.id,
        })
      ).then((res) => {
        if (res.type === UPDATE_USER_FOLLOWING_SUCCESS) {
          dispatch(
            removeFollower(Token, {
              userId: userData.id,
              followerId: profileData.id,
            })
          ).then((res2) => {
            if (res2.type === UPDATE_USER_FOLLOWER_SUCCESS) {
              dispatch(getUserFollower(Token, userData.id));
            }
          });
        }
      });
      setIsFollowing(false);
    } else {
      dispatch(
        addFollowing(Token, {
          userId: profileData.id,
          followingId: userData.id,
        })
      ).then((res) => {
        if (res.type === UPDATE_USER_FOLLOWING_SUCCESS) {
          dispatch(
            addFollower(Token, {
              userId: userData.id,
              followerId: profileData.id,
            })
          ).then((res2) => {
            if (res2.type === UPDATE_USER_FOLLOWER_SUCCESS) {
              dispatch(getUserFollower(Token, userData.id));
            }
          });
          dispatch(getMyFollowing(Token, profileData.id));
        }
      });
      setIsFollowing(true);
    }
  };
  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
    if (!event.target.value.length) {
      let setDefaultData =
        (followerOrFollowing == "Followers" ? followers : followings) ?? [];
      setSearchResults(setDefaultData);
      return;
    }
    const searchData =
      (followerOrFollowing == "Followers" ? followers : followings) || [];
    const filteredResults = searchData?.filter(
      (user) =>
        user.fullName
          .toLowerCase()
          .includes(event.target.value.toLowerCase()) ||
        user.email.toLowerCase().includes(event.target.value.toLowerCase())
    );
    setSearchResults(filteredResults);
  };
  useEffect(() => {
    if (userData.email?.length) {
      let followingOrNot = myFollowing?.some(
        (following) => following.id == userData.id
      );
      setIsFollowing(followingOrNot);
    }
  }, [myFollowing, userData]);
  const handlePhotoFullView = (image, avatar) => {
    setDisplayFullViewImage({ image, avatar });
    setOpenPhotoModal(true);
  };
  // useEffect(()=>{
  //   console.log(userPosts,'checknig userpost here status per user what',userData.email);
  // },[userPosts])
  return (
    <VStack w={"100%"} pb={"1rem"} bgColor={"white"}>
      <VStack
        w={"100%"}
        p={["0.5rem", "0.5rem", "2rem", "2rem"]}
        bgColor={'#E6EEFA'}
        borderBottomRadius={['3rem','3rem','5rem','5rem']}
        pb={['3rem','3rem','4rem','4rem']}
      >
        <HStack
          w={"100%"}
          minH={
            USER_ROLE == userData.role
              ? ["22vh", "30vh", "34vh", "39vh"]
              : ["30vh", "38vh", "42vh", "47vh"]
          }
          position={"relative"}
        >
          {(DESIGNER_ROLE == userData.role || MODEL_ROLE == userData.role) && (
            <BackgroundImage
              userId={userData.id}
              backGroundPic={userData.backGroundPic}
              handlePhotoFullView={handlePhotoFullView}
            />
          )}
          <Avatar
            cursor={"pointer"}
            border={'5px solid white'}
            zIndex={1000}
            onClick={() => handlePhotoFullView(userData.profilePicture, true)}
            bottom={"-2.5rem"}
            left={
              ["40%", "44%", "45%", "45%"]
            }
            size={["xl", "xl", "2xl", "2xl"]}
            position={"absolute"}
            src={userData.profilePicture}
          />
        </HStack>
        <HStack
          w={"100%"}
          align={"start"}
          justify={["space-evenly"]}
          // border={'1px solid black'}

        >
          <VStack gap={'0.4rem'} _hover={{fontWeight:700}} onClick={() => handleOpen("Followers")} cursor={"pointer"}>
            <Text fontWeight={700}> {userData?.followerCount}</Text>
            <Text className="poppins">Followers</Text>
          </VStack>
          <VStack gap={'0.4rem'} _hover={{fontWeight:700}} onClick={() => handleOpen("Following")}
            cursor={"pointer"}>
            <Text fontWeight={700}>{userData?.followingCount}</Text>
            <Text className="poppins">Following</Text>
          </VStack>
        </HStack>
        <VStack
          mt={"2rem"}
          w={"100%"}
          align={"start"}
          justify={["space-between", "space-between", "start", "start"]}
        >
          <VStack
            w={'100%'}
            gap={"0"}
            // border={'1px solid black'}
            align={"center"}
          >
            <Text fontFamily={700} fontSize={["1.29rem", "1.35rem", "1.4rem", "1.5rem"]} className="poppins">
              {userData.fullName}
            </Text>
            {/* <Text fontSize={"0.9rem"}>{RoleName(userData.role)}</Text> */}
            {/* {userData?.bio?.trim()?.length && (
              <>
              <Text fontSize={"1rem"} fontWeight={600}>
                Bio
              </Text>
                <Text  fontSize={"0.9rem"}>
                {userData?.bio}
              </Text>
              </>
            )} */}
            <Text color={'#6C7A9C'} className="poppins" w={['95%','95%','50%','50%']} textAlign={'center'}>
              {`Welcome to the world of ${userData?.fullName}, a dynamic ${RoleName(userData.role)} ${userData?.location?.trim()?.length && `from ${userData.location}`}. Combining creativity with technical expertise, ${userData?.fullName} ${userData?.educationalQualification?.trim()?.length&&`holds a ${userData?.educationalQualification}`}  ${userData?.brandName?.trim()?.length && `${userData?.educationalQualification?.trim()?.length?"and":""} and leads the innovative fashion brand, ${userData?.brandName}.`} `}
              {/* {`My name is ${userData?.fullName} ${userData?.location?.trim()?.length && `I'm from ${userData.location}`} I'm  a ${RoleName(userData.role)} ${userData?.brandName?.trim()?.length && `my brand name is ${userData.brandName}`}  ${userData?.educationalQualification?.trim()?.length && `my qualification is ${userData?.educationalQualification}`}.`} */}
            </Text>
            {/* {profileData.role === DESIGNER_ROLE &&
              userData?.brandName?.trim()?.length && (
                <Text fontSize={"0.9rem"}>
                  <Box fontWeight={600} as="span">
                    Brand Name
                  </Box>{" "}
                  {userData.brandName}
                </Text>
              )} */}
            {/* {userData?.gender?.trim()?.length && (
              <Text fontSize={"0.9rem"}>
                {" "}
                <Box fontWeight={600} as="span">
                  Gender
                </Box>{" "}
                {userData.gender}
              </Text>
            )} */}
            {/* {userData?.location?.trim()?.length && (
              <Text fontSize={"0.9rem"}>
                {" "}
                <Box fontWeight={600} as="span">
                  Location
                </Box>{" "}
                {userData.location}
              </Text>
            )}
            {userData?.educationalQualification?.trim()?.length && (
              <Text fontSize={"0.9rem"}>
                <Box fontWeight={600} as="span">
                  Qualification
                </Box>{" "}
                {userData.educationalQualification}
              </Text>
            )} */}
          </VStack>
          <HStack
            w={'100%'}
            align={"start"}
            flexWrap={"wrap"}
            justifyContent={'center'}
            p={["1rem", "2rem", "2.8rem 0", "3rem 0"]}
          >
            {DESIGNER_ROLE === userData.role && (
              <Button
                bgColor={themePrimaryColor}
                rounded={"5rem"}
                color={"white"}
                p={["0.4rem 0.5rem", "0.6rem", "0.68rem", "0.68rem"]}
                fontSize={["0.8rem", "0.9rem", "1rem", "1rem"]}
                h={"auto"}
                onClick={() => {
                  if (userData.id === profileData.id) {
                    navigate("/creations");
                  } else {
                    navigate(`/creations/${userData.id}`);
                  }
                }}
              >
                3D Creations
              </Button>
            )}
            {userData.id !== profileData.id && (
              <Button
                bgColor={themePrimaryColor}
                rounded={"5rem"}
                color={"white"}
                p={["0.4rem 0.5rem", "0.6rem", "0.68rem", "0.68rem"]}
                fontSize={["0.8rem", "0.9rem", "1rem", "1rem"]}
                h={"auto"}
                onClick={handleFollowButton}
              >
                {isFollowing ? "UnFollow" : "Follow"}
              </Button>
            )}
            {userData.id !== profileData.id && (
              <Button
                bgColor={themePrimaryColor}
                rounded={"5rem"}
                color={"white"}
                p={[
                  "0.4rem 0.5rem",
                  "0.6rem",
                  "0.68rem 0.8rem",
                  "0.68rem 0.8rem",
                ]}
                fontSize={["0.8rem", "0.9rem", "1rem", "1rem"]}
                h={"auto"}
                onClick={() => {
                  setCurrentChatUser(userData.email);
                  navigate(`/message`);
                }}
              >
                Chat
              </Button>
            )}
          </HStack>
        </VStack>
      <HStack
        w={["95%", "95%", "90%", "90%"]}
        fontWeight={600}
        fontSize={["0.8rem", "0.9rem", "1rem", "1rem"]}
        justify={"center"}
        gap={'8rem'}
      >
        {/* <Box
          onClick={() => handleOpen("Followers")}
          cursor={"pointer"}
          color={themePrimaryColor}
        >
          Followers {userData?.followerCount}
        </Box>
        <Box
          onClick={() => handleOpen("Following")}
          cursor={"pointer"}
          color={themePrimaryColor}
        >
          Following {userData?.followingCount}
        </Box> */}
        <Box
          cursor={"pointer"}
          onClick={() => {
            if (userData.id === profileData.id) {
              navigate("/photos");
            } else {
              setShowCreationVideo("creation");
            }
          }}
          color={themePrimaryColor}
        >
          Photos
        </Box>
        <Box
          cursor={"pointer"}
          onClick={() => {
            if (userData.id === profileData.id) {
              navigate("/shortvideos");
            } else {
              setShowCreationVideo("shortvideos");
            }
          }}
          color={themePrimaryColor}
        >
          ShortVideo
        </Box>
      </HStack>
      </VStack>
      {showCreationVideo === "creation" && (
        <>
          {!get_posts_by_another_user_loading &&
            userPosts?.filter((el) => {
              if (el.imageUrls?.filter((imageEl) => imageEl.length).length) {
                return true;
              } else if (el.videoUrl?.length) {
                return false;
              }
            }).length === 0 ? (
            <Empty title="No Photos Yet" />
          ) : (
            <CreationGrid
              creationData={userPosts?.filter((el) => {
                if (el.imageUrls?.filter((imageEl) => imageEl.length).length) {
                  return true;
                } else if (el.videoUrl?.length) {
                  return false;
                }
              })}
            />
          )}
        </>
      )}
      {showCreationVideo === "shortvideos" && (
        <>
          {!get_posts_by_another_user_loading &&
            userPosts.filter((el) => el.videoUrl?.length).length === 0 ? (
            <Empty title="No ShortVideo Yet" />
          ) : (
            <VideoGrid
              shortVideoData={userPosts?.filter((el) => el.videoUrl?.length)}
            />
          )}
        </>
      )}

      <VStack w={"100%"} mt={"2rem"}>
        {userData.id !== profileData.id &&
          userPosts?.map((post) => (
            <Post
              key={v4()}
              videoUrl={post.videoUrl}
              isLikedByMe={post.isLikedByMe}
              caption={post.caption}
              userData={post.userData}
              postId={post.id}
              imageUrls={post.imageUrls?.filter((el) => el.length)}
              likesCount={post.likesCount ?? 0}
              sharesCount={post.sharesCount}
              commentsCount={post.commentsCount}
              postTime={post.createdAt}
            />
          ))}
      </VStack>
      <Modal onClose={onClose} isOpen={isOpen} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader
            textAlign={"center"}
            borderBottom={"0.2px solid #f5f1f1"}
          >
            {followerOrFollowing}
          </ModalHeader>
          <ModalCloseButton background={"white !important"} />
          <ModalBody p={["0.5rem", "1rem", "1rem", "1.5rem"]}>
            <InputGroup size="md">
              <Input placeholder="Search" onChange={handleSearch} />
              <InputRightElement width="3rem">
                <FaSearch />
              </InputRightElement>
            </InputGroup>
            <VStack
              gap={"1.2rem"}
              p={["1rem 0.5rem", "1rem 0.2rem", "1rem", "1rem"]}
              height={"15rem"}
              overflowY={"auto"}
            >
              {!searchResults.length && searchTerm.trim() !== "" && (
                <VStack align={"center"} justify={"center"} height={"6rem"}>
                  <Text w={"100%"} textAlign={"center"}>
                    No user found
                  </Text>
                </VStack>
              )}
              {followerOrFollowing == "Followers"
                ? searchResults?.map((el) => (
                  <FollowCard
                    isMe={false}
                    id={el.id}
                    email={el.email}
                    role={el.role}
                    followerOrFollowing={followerOrFollowing}
                    profilePicture={el.profilePicture}
                    fullName={el.fullName}
                    onClose={onClose}
                  />
                ))
                : searchResults?.map((el) => (
                  <FollowCard
                    isMe={isMe}
                    id={el.id}
                    email={el.email}
                    role={el.role}
                    followerOrFollowing={followerOrFollowing}
                    profilePicture={el.profilePicture}
                    fullName={el.fullName}
                    onClose={onClose}
                  />
                ))}
            </VStack>
          </ModalBody>
        </ModalContent>
      </Modal>
      <PhotoFullView
        setOpenPhotoModal={setOpenPhotoModal}
        openPhotoModal={openPhotoModal}
        displayFullViewImage={displayFullViewImage}
      />
    </VStack>
  );
};

export default UserProfileCard;
