// App.js
import React, { useEffect } from "react";
import firebase from "firebase/compat/app";
import "firebase/compat/database";
import "firebase/compat/firestore";
import AOS from 'aos';
import 'aos/dist/aos.css'; // Import AOS styles


import "./App.css";
import AllPages from "./pages/AllPages";
const firebaseConfig ={
  apiKey: "AIzaSyA78i6IZ3GtGua-PhVUAOeU4R2tlJJzjQE",
  authDomain: "drodel-chat.firebaseapp.com",
  projectId: "drodel-chat",
  storageBucket: "drodel-chat.appspot.com",
  messagingSenderId: "554229580899",
  appId: "1:554229580899:web:9824f5dcb4200fb6132456"
}
firebase.initializeApp(firebaseConfig);
function App() {
  
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <div>
      <AllPages />
      {/* <Footer/> */}
    </div>
  );
}

export default App;

export const database = firebase.database(); // For Real-time Database
// or
export const firestore = firebase.firestore(); // For F
