import {
  HStack,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Image as ChakraImage,
  VStack,
  Modal,
  Input,
  Box,
  Button,
  theme,
  ModalHeader
} from "@chakra-ui/react";
import React, { useRef, useState } from "react";
import { themePrimaryColor } from "../utils/theme";
import { AiOutlineCloudUpload } from "react-icons/ai";
import { IoClose } from "react-icons/io5";
import { canvasPreview } from "../utils/canvasPreview";
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import { MdOutlineEdit } from "react-icons/md";
import { FaEdit } from 'react-icons/fa'
const blobToFile = (blob, fileName) => {
  return new File([blob], fileName, { type: blob.type });
};
const UploadImageModal = ({
  setOpenModal,
  isOpen,
  setFiles,
  isUploadSingle = false,
  imagesPreview = {},
  setImagesPreview,
}) => {
  const inputRef = useRef();
  const [currentImage, setCurrentImage] = useState(0);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [crop, setCrop] = useState();
  const [completedCrop, setCompletedCrop] = useState();
  const [cropCurrentImage, setCropCurrentImage] = useState(false);
  const [cropCurrentImageFile, setCropCurrentImageFile] = useState(null);
  const ClosePostModal = () => {
    setOpenModal(false);
  };
  const imageRef = useRef();
  const previewCanvasRef = useRef(null);
  const handleChangeImage = (e) => {
    let files = [...e.target.files];
    const imagesPreviewURL = files.map((file) => {
      return {url:URL.createObjectURL(file),file};
    });
    setSelectedFiles(files);
    setImagesPreview?.(imagesPreviewURL);
  };
  const handleCrop = async () => {
    console.log('hello world', imageRef, previewCanvasRef);
    if (!imageRef.current || !previewCanvasRef.current || !completedCrop) {
      return;
    };
    const croppedImage = await canvasPreview(
      imageRef.current,
      previewCanvasRef.current,
      completedCrop
    );
    const blob = await base64ToBlob(croppedImage.base64);
    setImagesPreview((pre)=>{
      pre = pre.map((el,current)=>{
        if(current===currentImage){
          return {...el,url:croppedImage.url};
        };
        return el;
      });
      return pre;
    });
    let updatedFile = blobToFile(blob,cropCurrentImageFile?.name??"abc");
    setSelectedFiles((pre)=>{
      pre = pre.map((singleFile)=>{
        console.log(singleFile,cropCurrentImageFile,'ram ram here');
        if(singleFile.name===cropCurrentImageFile?.name){
          return updatedFile;
        };
        console.log(URL.createObjectURL(singleFile),'url here');
        return singleFile;
      })
      return pre;
    });
    setCropCurrentImage(false);
    setCompletedCrop(null);
    setCrop(null);
    console.log(blob, 'cropeedImage here');
  };
  const handleUploadImage = () => {
    setFiles(selectedFiles);
    ClosePostModal();
  };
  const base64ToBlob = async (base64) => {
    const response = await fetch(base64);
    return await response.blob();
  };
  const RemoveImage = (index) => {
    if (selectedFiles.length === 1) {
      inputRef.current.value = null;
    };
    setCropCurrentImage(false);
    const updatedImagesPreviewURL = imagesPreview.filter((_, i) => i !== index);
    // Remove file from inputRef.current.files array
    const updatedFiles = selectedFiles.filter((_, i) => i !== index);
    // Update state with updated arrays
    setImagesPreview(updatedImagesPreviewURL);
    setSelectedFiles(updatedFiles);
  };
  return (
    <Modal onClose={ClosePostModal} isOpen={isOpen} isCentered>
      <ModalOverlay />
      <ModalContent h={"75vh"} minW={"50% !important"}>
        <ModalHeader
          textAlign={"center"}
          borderBottom={"0.2px solid #f5f1f1"}
        ></ModalHeader>
        <ModalCloseButton
          onClick={() => setOpenModal(false)}
          background={"white !important"}
          zIndex={100}
        />
        <ModalBody h={"100%"}>
          <VStack
            h={"70vh"}
            pb={"1.5rem"}
            justify={"space-between"}
            position={"relative"}
            align={"center"}
          >
            {!imagesPreview?.length && (
              <HStack w={'100%'} justify={'center '} top={"35%"}
                position={"absolute"}
              >
                <Box
                  w={["80%", "70%", "55%", "50%"]}
                  h={"6rem"}
                  cursor={"pointer"}
                  rounded={"0.2rem"}
                  border={`1px solid ${themePrimaryColor}`}
                  display={"flex"}
                  flexDirection={"column"}
                  alignItems={"center"}
                  as={"label"}
                  htmlFor="my_video"
                >
                  <Box as={AiOutlineCloudUpload}
                    color={themePrimaryColor}
                    fontSize={['2.8rem', '2.8rem', '3rem', '3.5rem']}
                  />
                  Click here to upload image
                </Box>
              </HStack>
            )}
            <Input
              ref={inputRef}
              accept="image/*"
              display={"none"}
              id="my_video"
              onChange={handleChangeImage}
              type="file"
              multiple={!isUploadSingle}
            />
            <Box h={['35vh', '38vh', '50vh', "50vh"]} position={'relative'}>
              {cropCurrentImage ? <>

                <HStack position={'absolute'} bottom={'0'} zIndex={10} right={0}>
                  <Button
                    bg={themePrimaryColor}
                    onClick={handleCrop}
                    color={"white"}
                  >
                    Crop
                  </Button>
                  <Button
                    bg={themePrimaryColor}
                    color={"white"}
                  >
                    Cancel
                  </Button>
                </HStack>
                <ReactCrop
                  crop={crop}
                  onChange={(c) => setCrop(c)}
                  onComplete={(c) => setCompletedCrop(c)}
                  aspect={undefined}
                >
                  <ChakraImage ref={imageRef} h={['30vh', '30vh', '40vh', "40vh"]}src={imagesPreview[currentImage].url} />
                </ReactCrop>
              </> : <ChakraImage h={"100%"} src={imagesPreview[currentImage]?.url} />
              }
              <canvas
                className="hidden"
                ref={previewCanvasRef}
                style={{
                  display: 'none'
                }}
              />
            </Box>
            <HStack flexWrap={'wrap'}>
              {!isUploadSingle &&
                imagesPreview?.map((el, i) => (
                  <HStack
                    key={el.url}
                    border={"1px solid #f1f1f1"}
                    position={"relative"}
                  >
                    <Box
                      as={IoClose}
                      onClick={() => RemoveImage(i)}
                      color={"black"}
                      fontSize={["1.2rem"]}
                      top={"-0.45rem"}
                      cursor={'pointer'}
                      zIndex={1000}
                      right={"-0.5rem"}
                      position={"absolute"}
                    />
                    <Box
                      as={FaEdit}
                      color={"black"}
                      onClick={() => {
                        setCropCurrentImage(true);
                        setCropCurrentImageFile(el.file);
                      }}
                      fontSize={["1rem"]}
                      top={"-0.45rem"}
                      cursor={'pointer'}
                      zIndex={1000}
                      left={"-0.3rem"}
                      position={"absolute"}
                    />
                    <HStack
                      cursor={"pointer"}
                      p={"0.5rem"}
                      justify={"center"}
                      align={"center"}
                      w={['3.5rem', '3.8rem', '4.5rem', "4.5rem"]}
                      h={['3.5rem', '3.8rem', '4.5rem', "4.5rem"]}
                      onClick={() => setCurrentImage(i)}
                      border={
                        i == currentImage
                          ? `2.5px solid ${themePrimaryColor}`
                          : "2.5px solid transparent"
                      }
                    >
                      <ChakraImage w={"80%"} src={el.url} />
                    </HStack>
                  </HStack>
                ))}
            </HStack>
            <HStack>
              <Button
                bg={themePrimaryColor}
                onClick={handleUploadImage}
                color={"white"}
              >
                Upload
              </Button>
              <Button
                bg={themePrimaryColor}
                onClick={() => {
                  if (setImagesPreview) setImagesPreview([]);
                  setFiles([]);
                  ClosePostModal();
                }}
                color={"white"}
              >
                Cancel
              </Button>
            </HStack>
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default UploadImageModal;
