import { Box, Spinner, VStack } from "@chakra-ui/react";
import React from "react";
import Post from "../../components/Post";
import Empty from "../../components/Empty";
import { useSelector } from "react-redux";
import { themePrimaryColor } from "../../utils/theme";
import SuggestionsUser from "../../components/SuggestionsUser";
import TrialPost from "../../components/TrialPost";
import { v4 } from "uuid";

const Trending = () => {
  const {
    trendingPosts,
    get_trending_posts_error,
    get_trending_posts_loading,
    getUserPosts,
  } = useSelector((store) => store.postData);

  return (
    <VStack w={["100%", "100%", "87%", "78%"]} gap={'2.3rem'} pb={"1rem"}>
      <Box w={["100%", "94%", "75%", "60%"]}>
        <SuggestionsUser />
      </Box>
      {get_trending_posts_loading ? (
        <VStack minH={"65vh"} align={"center"} justify={"center"}>
          <Spinner color={themePrimaryColor} thickness="4px" size={"xl"} />
        </VStack>
      ) : trendingPosts.length ? (
        trendingPosts?.map((post,index) => (
          post.isTrial?<TrialPost key={v4()} postTime={post.createdAt} title={post.title} isTrial={post.isTrial} isLikedByMe={post.isLikedByMe} caption={post.caption} userData={post.userData} postId={post.id} imageUrls={post.imageUrls?.filter((el)=>el.length)} likesCount={post.likesCount??0} sharesCount={post.sharesCount} commentsCount={post.commentsCount} />:
          <Post
            key={post.id}
            videoUrl={post.videoUrl}
            isLikedByMe={post.isLikedByMe}
            caption={post.caption}
            userData={post.userData}
            postId={post.id}
            imageUrls={post.imageUrls?.filter((el) => el.length)}
            likesCount={post.likesCount ?? 0}
            sharesCount={post.sharesCount}
            commentsCount={post.commentsCount}
            postTime={post.createdAt}
            leftOrRight={index%2==0}
          />

        ))
      ) : (
        <Empty title="Trendings Not Available" />
      )}
    </VStack>
  );
};

export default Trending;
