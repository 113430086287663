import { Spinner, VStack } from "@chakra-ui/react";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getSinglePost } from "../../store/post/post.action";
import Post from "../../components/Post";
import { themePrimaryColor } from "../../utils/theme";
import Empty from "../../components/Empty";
import TrialPost from "../../components/TrialPost";

const SinglePost = () => {
  const { postId } = useParams();
  const { get_single_post_loading, get_single_post_error, singlePost } =
    useSelector((store) => store.postData);
    const {  Token } = useSelector((store) => store.authData);
    const dispatch = useDispatch();
  useEffect(() => {
    console.log(postId,'singlePost here');
    dispatch(getSinglePost(Token,postId));
  }, [postId]);
  useEffect(() => {
    console.log(singlePost,'singlejPost here');
  }, [singlePost]);

  return (
    <VStack w={["100%", "100%", "87%", "78%"]} pb={"1rem"}>
      {get_single_post_loading ? (
        <VStack minH={"65vh"} align={"center"} justify={"center"}>
          <Spinner color={themePrimaryColor} thickness="4px" size={"xl"} />
        </VStack>
      ) : (singlePost?.userData&&singlePost.isTrial) ? (
          <TrialPost  title={singlePost.title} isTrial={singlePost.isTrial} isLikedByMe={singlePost.isLikedByMe} caption={singlePost.caption} userData={singlePost.userData} singlePostId={singlePost.id} imageUrls={singlePost.imageUrls?.filter((el)=>el.length)} likesCount={singlePost.likesCount??0} sharesCount={singlePost.sharesCount} commentsCount={singlePost.commentsCount} />
      ) : singlePost?.userData?<Post
      videoUrl={singlePost.videoUrl}
      isLikedByMe={singlePost.isLikedByMe}
      caption={singlePost.caption}
      userData={singlePost.userData}
      postTime={singlePost.createdAt}
      postId={singlePost.id}
      imageUrls={singlePost.imageUrls?.filter((el) => el.length)}
      likesCount={singlePost.likesCount ?? 0}
      sharesCount={singlePost.sharesCount}
      commentsCount={singlePost.commentsCount}
    />:(
        <Empty title="Post Not Available" />
      )}
    </VStack>
  );
};

export default SinglePost;
