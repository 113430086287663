import {
  Avatar,
  Box,
  Button,
  HStack,
  Image,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Text,
  VStack,
  useMediaQuery,
} from "@chakra-ui/react";
import React, { useContext, useEffect, useRef, useState } from "react";
import { RoleName } from "../../utils/constant";
import { themePrimaryColor } from "../../utils/theme";
import { GoSmiley } from "react-icons/go";
import ChatAllUsers from "../../components/ChatAllUsers";
import moment from "moment";
import {
  collection,
  query,
  onSnapshot,
  Timestamp,
  addDoc,
  orderBy,
  updateDoc,
  doc,
  getDoc,
  setDoc,
  deleteDoc,
} from "firebase/firestore";
import { db } from "../../firebaseFile";
import { AppContext } from "../../context/AppContext";
import EmojiPicker from "emoji-picker-react";
import { BiMessageDetail } from "react-icons/bi";
import { IoMdArrowRoundBack } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { BsThreeDots } from "react-icons/bs";
import { MdDelete } from "react-icons/md";
import shareIcon from '../../assets/share.svg';

const Message = () => {
  const [roomId, setRoomId] = useState("");
  const { firebaseUserId: user, setShowMenuHamburger } = useContext(AppContext);
  const [selectedPerson, setSelectedPerson] = useState([]);
  const [messages, setMessages] = useState([]);
  const [isLargerThan769] = useMediaQuery("(min-width: 769px)");
  const [isLargerThan450] = useMediaQuery("(min-width: 450px)");
  const [deleteMessageModal,setDeleteMessageModal] = useState(false)
  const [message, setMessage] = useState("");
  const [deleteMessageId,setDeleteMessageId] = useState("");
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const inputRef = useRef(null);
  const navigate = useNavigate();
  const [showChat, setShowChat] = useState(false);
  const scrollToBottom = () => {
    let objDiv = document.getElementById("boxData");
    if (objDiv) {
      objDiv.scrollTop = objDiv?.scrollHeight;
    }
  };
  const getMessages = (roomId) => {
    return onSnapshot(
      query(
        collection(db, "chats", roomId, "messages"),
        orderBy("time", "asc")
      ),
      (querySnapshot) => {
        const messages = querySnapshot.docs.map((doc) => {
          return {
            id: doc.id,
            ...doc.data(),
          };
        });
        setMessages(messages);
      }
    );
  };
  const handlePersonChat = async (person) => {
    let roomid = [user.uid, person.data.uid].sort();
    roomid = roomid[0] + roomid[1];
    setRoomId(roomid);
    setSelectedPerson(person);
    getMessages(roomid);
    const chats_ref = doc(db, "chats", roomid);
    const myId = user.uid;
    const docSnap = await getDoc(chats_ref);

    if (docSnap.exists()) {
      updateDoc(chats_ref, {
        [myId]: {
          unread_count: 0,
        },
      });
    } else {
      await setDoc(chats_ref, {
        [myId]: {
          unread_count: 0,
        },
      });
    }
    setTimeout(() => {
      scrollToBottom();
    }, 500);
  };
  const updateUnreadCount = async () => {
    const chats_ref = doc(db, "chats", roomId);
    const partnerId = selectedPerson.data.uid;
    const docSnap = await getDoc(chats_ref);

    if (docSnap.exists()) {
      let roomDetail = docSnap.data();
      let partnerUnreadCount =
        roomDetail &&
        roomDetail[partnerId] &&
        roomDetail[partnerId].unread_count;
      updateDoc(chats_ref, {
        [partnerId]: {
          unread_count: partnerUnreadCount ? partnerUnreadCount + 1 : 1,
        },
      }).then(() => {
        // console.log('unread count added');
      });
    } else {
      await setDoc(chats_ref, {
        [partnerId]: {
          unread_count: 1,
        },
      }).then(() => {
        console.log("unread count added");
      });
    }
  };
  const deleteMessage = async () => {
    try {
      await deleteDoc(doc(db, "chats", roomId, "messages", deleteMessageId));
      setDeleteMessageModal(false);
    } catch (error) {
      console.error("Error deleting message: ", error);
    }
  };


  const sendMsg = async (e) => {
    e.preventDefault();
    const msg = message.trim();
    // setUsers();
    if (msg) {
      const msgObj = {
        time: Timestamp.now(),
        message: msg,
        sender: user.uid,
        receiver: selectedPerson.data.uid,
      };
      setMessages((oldArray) => [...oldArray, msgObj]);
      updateUnreadCount();
      setTimeout(() => {
        scrollToBottom();
      }, 100);
      try {
        await addDoc(collection(db, "chats", roomId, "messages"), msgObj);
      } catch (error) {
        console.error(error);
      }
      setMessage("");
      setShowEmojiPicker(false);
    } else {
      setMessage("");
    }
  };
  const handleEmojiClick = (event) => {
    // Insert the clicked emoji at the cursor position
    const cursorPosition = inputRef.current.selectionStart;
    const newValue =
      message.slice(0, cursorPosition) +
      event.emoji +
      message.slice(cursorPosition);

    setMessage(newValue);
  };
  return (
    <HStack
      w={["100%", "100%", "87%", "78%"]}
      align={"start"}
      pl={['0rem','0rem','1rem',"1rem"]}
      gap={"0.3rem"}
      pt={"0"}
      h={"100vh"}
      maxH={"100vh"}
      pb={"1rem"}
      bgColor={'white'}
    >
      {(!showChat || isLargerThan769) && (
        <ChatAllUsers
          setShowChat={setShowChat}
          handlePersonChat={handlePersonChat}
        />
      )}
      {!selectedPerson?.data && isLargerThan769 ? (
        <VStack
          align={"center"}
          justify={"center"}
          h={"95vh"}
          borderLeft={`1px solid rgb(168, 168, 168)`}
          w={"74.5%"}
        >
          <Box
            as={BiMessageDetail}
            fontSize={"3rem"}
            color={themePrimaryColor}
          />
          <Text fontSize={"1.5rem"} color={themePrimaryColor}>
            Your Messages
          </Text>
        </VStack>
      ) : showChat || isLargerThan769 ? (
        <VStack
        id="aman"
          align={"start"}
          h={["97vh", "97vh", "95vh", "95vh"]}
          borderLeft={`1px solid rgb(168, 168, 168)`}
          borderTop={`1px solid rgb(168, 168, 168)`}
          borderRight={
            !isLargerThan769
              ? "1px solid rgb(168, 168, 168)"
              : "1px solid transparent"
          }
          w={["100%", "100%", "63%", "74.5%"]}

        >
          <HStack
            // _hover={{ bgColor: "#f1ecec4f" }}
            p={['0.3rem','0.3rem','0.2rem',"0.2rem"]}
            w={"100%"}
            borderBottom={`1px solid rgb(168, 168, 168)`}
            justify={"start"}
            justifyContent={"space-between"}
            bgColor={themePrimaryColor}
          >
            <HStack gap={"0"}>
              {!isLargerThan769 && (
                <Box
                  as={IoMdArrowRoundBack}
                  onClick={() => {
                    setShowChat(false);
                    setShowMenuHamburger(true);
                  }}
                  fontSize={"1.5rem"}
                  cursor={"pointer"}
                  color={'white'}
                  mr={'1rem'}
                />
              )}{" "}
              <Avatar
                src={selectedPerson?.data?.profile_pictures}
                size={["md", "md", "lg", "lg"]}
                mr={"0.5rem"}
                cursor={"pointer"}
                onClick={() => navigate(`/${selectedPerson?.data?.userId}`)}
              />
              <VStack
                align={"start"}
                cursor={"pointer"}
                onClick={() => navigate(`/${selectedPerson?.data?.userId}`)}
              >
                <Text fontWeight={600} color={'white'}>
                  {selectedPerson?.data?.displayName}
                </Text>
                <Text
                  fontWeight={500}
                  fontSize={"0.8rem"}
                  color={"white"}
                  className="eb-garamond-font"
                >
                  {RoleName(selectedPerson?.data?.role)}
                </Text>
              </VStack>
            </HStack>
          </HStack>
          <Box
            p={"0.5rem"}
            overflowY={"auto"}
            overflowX={"hidden"}
            w={"100%"}
            h={["94.8vh", "94.8vh", "90vh", "90vh"]}
            bgColor={'#E6EEFA'}
            id="boxData"
          >
            {messages?.map((msg, i) => {
              return (
                <VStack
                  key={i}
                  gap={"0"}
                  ml={msg.sender === user.uid ? "auto" : ""}
                  mr={msg.sender !== user.uid ? "auto" : ""}
                  w={["65%", "60%", "40%", "40%"]}
                  align={msg.sender === user.uid ? "end" : "start"}
                  border={"1px solid transparent"}
                  className={
                    msg.sender === user.uid ? "myMessage" : "notMyMessage"
                  }
                >
                  <HStack>
                    <Text fontSize={["0.8rem", "0.8rem", "0.9rem", "0.9rem"]}>
                      {msg.sender === user.uid
                        ? "You"
                        : selectedPerson?.data?.displayName}
                    </Text>
                  </HStack>
                  <VStack
                    bgColor={msg.sender === user.uid ? themePrimaryColor:'white'}
                    color={msg.sender === user.uid ? "white" : "black"}
                    p={["0.5rem 0.8rem 0.3rem 0.8rem"]}
                    borderTopRadius={'1.8rem'}
                    borderBottomRightRadius={msg.sender === user.uid?'0':'1.8rem'}
                    borderBottomLeftRadius={msg.sender === user.uid?'1.8rem':'0'}
                    align={"end"}
                    gap={"0"}
                    position={"relative"}
                  >
                    <Text
                      mt={'0.2rem'}
                      lineHeight={"1rem"}
                      fontSize={["0.95rem", "0.95rem", "1rem", "1rem"]}
                      w={"100%"}
                      className="bree-serif-regular"
                    >
                      {msg.message}
                    </Text>
                    <Box
                      fontSize={["0.45rem", "0.5rem", "0.7rem", "0.7rem"]}
                      as="span"
                    >
                      {moment(msg.time.toDate().toString()).format(
                        "D-MMM-YY, h:mm a"
                      )}
                    </Box>
                 {msg.sender===user.uid&& <Box
                      as={BsThreeDots}
                      onClick={()=>{
                          setDeleteMessageModal(true);
                          setDeleteMessageId(msg.id);
                      }}
                      color={"white"}
                      top={"0.1rem"}
                      right={"0.5rem"}
                      cursor={"pointer"}
                      position={"absolute"}
                      fontSize={"1.1rem"}
                    />}
                  </VStack>
                </VStack>
              );
            })}
          </Box>
          <form style={{ width: "100%" }} onSubmit={sendMsg}>
            <HStack
              gap={"0.1rem"}
              rounded={"5rem"}
              border={"0.2px solid grey"}
              w={"97%"}
              position={"relative"}
            >
              {showEmojiPicker && (
                <VStack
                  position={"absolute"}
                  bottom={isLargerThan450 ? "3rem" : "3.5rem"}
                >
                  <EmojiPicker
                    skinTonesDisabled={true}
                    onEmojiClick={handleEmojiClick}
                    height={isLargerThan450?'350px':"290px"}
                    width={isLargerThan450 ? "350px" : "80vw"}
                    searchDisabled={true}
                  />
                </VStack>
              )}{" "}
              <Button
                background={"transparent !important"}
                p={"0"}
                h={"auto"}
                fontSize={"1.5rem"}
                onClick={() => setShowEmojiPicker(!showEmojiPicker)}
              >
                <GoSmiley />
              </Button>
              <Input
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                type="text"
                border={"none !important"}
                outline={"none !important"}
                placeholder="Message..."
                ref={inputRef}
                boxShadow={"none !important"}
                onClick={() => setShowEmojiPicker(false)}
              />
              <Button
                type="submit"
                w={'2rem'}
                h={'2.5rem'}
                p={'0 !important'}
                color={themePrimaryColor}
                background={`${themePrimaryColor} !important`}
                rounded={'50%'}
              >
                <Image src={shareIcon}/>
              </Button>
            </HStack>
          </form>
          <Modal size={"md"} isOpen={deleteMessageModal} isCentered>
            <ModalOverlay />
            <ModalContent>
              <ModalBody w={"100%"}>
                <VStack gap={"0.5rem"}>
                  <Button
                    w={"100%"}
                    loadingText="Deleting"
                    colorScheme="red"
                    onClick={deleteMessage}
                  >
                    Delete
                  </Button>
                  <Button
                    w={"100%"}
                    colorScheme={"twitter"}
                    onClick={() => setDeleteMessageModal(false)}
                  >
                    Cancel
                  </Button>
                </VStack>
              </ModalBody>
            </ModalContent>
          </Modal>
        </VStack>
      ) : (
        <></>
      )}
    </HStack>
  );
};

export default Message;
