import {
  Box,
  HStack,
  Image,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Text,
  VStack,
  useDisclosure,
  useMediaQuery,
  useToast,
} from "@chakra-ui/react";
import React, { useContext, useEffect, useRef, useState } from "react";
import { AiFillMessage } from "react-icons/ai";
import { FaHeart, FaRegCommentAlt } from "react-icons/fa";
import { GrLike } from "react-icons/gr";
import { LuShare2 } from "react-icons/lu";
import { useDispatch, useSelector } from "react-redux";
import { doLikeApi, doUnlikeApi } from "../store/post/post.action";
import { LIKE_POST_ERROR, UNLIKE_POST_ERROR } from "../store/post/post.types";
import { getCommentsByUser } from "../store/comment/comment.action";
import { MdOpenInNew } from "react-icons/md";
import { GoHeart ,GoHeartFill} from "react-icons/go";
import { motion } from "framer-motion";
import {
  FacebookShareButton,
  WhatsappShareButton,
  WhatsappIcon,
  FacebookIcon,
} from "react-share";
import shareIcon from '../assets/share.svg';
const MotionBox = motion(Box);

const PostFooter = ({
  likesCount,
  commentsCount,
  sharesCount,
  postId,
  handlePostModal,
  h = "auto",
  isLikedByMe = false,
  setCommentModalOpen,
  caption = "",
  isFullView,
  isVertical=false,
  isTrial
}) => {
  const { Token, isAdmin, AdminToken } = useSelector((store) => store.authData);
  const { profileData } = useSelector((store) => store.userData);
  const { like_loading } = useSelector((store) => store.postData);
  const MY_URL = window.location.href.split('/').slice(0, 3).join('/')
  const { onOpen, onClose, isOpen } = useDisclosure();
  const [isCopied, setIsCopied] = useState(false);
  const [isLiked, setIsLiked] = useState(isLikedByMe);
  const [likeCount, setLikeCount] = useState(likesCount);
  const dispatch = useDispatch();
  const [sharePopup, setSharePop] = useState(false);
  const containerRef = useRef(null);
  const toast = useToast();
  const [isLargerThan766] = useMediaQuery("(min-width: 766px)");
  const [showAnimation, setShowAnimation] = useState(false);
  const [animationKey, setAnimationKey] = useState(0);
  const [showMore,setShowMore] = useState(false)
  const handleLikePost = () => {
    if (!isLiked) {
      setShowAnimation(true);
      setIsLiked(true);
      setLikeCount((pre) => Number(pre) + 1);
      setAnimationKey(prev => prev + 1);
      dispatch(doLikeApi(Token, { userId: profileData.id, postId })).then(
        (res) => {
          if (res.type == LIKE_POST_ERROR) {
            setIsLiked(false);
            setLikeCount((pre) => Number(pre) - 1);
            setTimeout(() => {
              setShowAnimation(false);
            }, 1000);        
          }
        }
      );
    } else {
      setIsLiked(false);
      setLikeCount((pre) => Number(pre) - 1);
      dispatch(doUnlikeApi(Token, { userId: profileData.id, postId })).then(
        (res) => {
          if (res.type == UNLIKE_POST_ERROR) {
            setIsLiked(true);
            setLikeCount((pre) => Number(pre) + 1);
          }
        }
      );
    }
  };
  const shareUrl = `${MY_URL}/post/${postId}`;
  const CopyToClipBoard = () => {
    console.log("ram here");
    navigator.clipboard
      .writeText(shareUrl)
      .then(() => {
        setIsCopied(true);
        setTimeout(() => {
          setIsCopied(false);
        }, 1200);
      })
      .catch((error) => {
        console.error("Failed to copy text: ", error);
        toast({
          status: "error",
          title: `error in copying url`,
          isClosable: true,
          duration: 1000,
        });
      });
  };
  const handleClickOutside = (event) => {
    if (containerRef.current && !containerRef.current.contains(event.target)) {
      setSharePop(false);
    }
  };
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
  return (
    <VStack w={!isVertical?'100%':'auto'} position={!isVertical?'relative':''}>
        <Box borderRadius={['3.5rem','4rem','4rem','5rem']} h={isVertical?'10rem':''} id="postFooter" top={isVertical?"38%":['-3.5rem','-3.5rem','-3.7rem',"-3.7rem"]}   style={
          isFullView?{}:
          {
            backdropFilter:'blur(10px)',
            WebkitBackdropFilter:"blur(10px)"
          }
        } zIndex={1} bg={isVertical?"":isFullView?'#E6EEFA':'rgba(255, 255, 255, 0.1)'} right={'0'} position={isVertical?'absolute':isFullView?'':'absolute'}  left={['0.8rem','1rem','1.8rem','2rem']} w={isVertical?'auto':['90%','90%','92%','92%']} >
        <HStack
          flexDirection={isVertical?"column":"row"}
          p={['0.35rem 0.6rem','0.4rem 0.6rem','0.5rem 0.8rem',"0.7rem 0.9rem"]}
          w={"100%"}
          h={h}
          align={isVertical?"end":""}
          justify={"space-between"}
          gap={isVertical?'0.5rem':"3rem"}
          position={"relative"}
        >
        <HStack position={'relative'} flexDirection={isVertical?'column-reverse':'row'}>
          <HStack flexDirection={isVertical?'column':'row'} > 
            <Box
              as={AiFillMessage}
              color={'white'}
              fontSize={["1.1rem", "1.2rem", "1.3rem", "1.5rem"]}
              cursor={"pointer"}
              onClick={() => {
                if (!isLargerThan766) {
                  dispatch(getCommentsByUser(isAdmin ? AdminToken : Token, postId));
                  console.log('clicked');
                  setCommentModalOpen?.(true)
                };
                console.log(handlePostModal, 'checking ahdnlePost')
                if (handlePostModal) handlePostModal(!isLargerThan766);
              }}
            />
            <Box as="span" color={'white'}  fontWeight={600} className="poppins" fontSize={"1.3rem"}>
              {commentsCount}
            </Box>
          </HStack>
          <HStack flexDirection={isVertical?'column':'row'}>
          {showAnimation && (
            <MotionBox
            key={animationKey}
              animate={{
                y: ["0rem", "-0.5rem", "-1rem", "-1.5rem", "-2rem"],
                x: ["0rem", "0.5rem", "-0.5rem", "0.5rem", "-0.5rem"],
                opacity: [1, 0.8, 0.6, 0.4, 0]
              }}
              initial={{opacity:'1'}}
              transition={{ duration: 0.8 }}
              position={'absolute'}
              bottom={"2rem"}
              left={"0"}
            >
              <Box color={'#df1212e0'}   fontSize={["1.1rem", "1.2rem", "1.3rem", "1.5rem"]} as={GoHeartFill}/>
            </MotionBox>
          )}

            <Box
              as={isLiked?GoHeartFill:GoHeart}
              cursor={like_loading ? "cell" : "pointer"}
              fontSize={["1.1rem", "1.2rem", "1.3rem", "1.5rem"]}
              // color={isLiked ? "white" : "white"}
              color={'#df1212e0'}  
              onClick={() => {
                if (!like_loading) {
                  handleLikePost();
                }
              }}
            />
            {/* <GrLike onClick={()=>{
                if(!like_loading){
                    handleLikePost();
                }
            }}  style={{cursor:like_loading?"cell":'pointer',fontSize:'1.5rem',color:isLiked?'#3879c6':'black'}}/> */}
            <Box as="span" color={'white'}  fontWeight={600} className="poppins" fontSize={"1.3rem"}>
            {likeCount}
            </Box>
          </HStack>
        </HStack>

          <VStack>

            <Box
              as={Image}
              src={shareIcon}
              cursor={"pointer"}
              onClick={() => {
                setSharePop(true);
              }}
              fontSize={["1.1rem", "1.2rem", "1.3rem", "1.5rem"]}
            />
            {/* <LuShare2 style={{cursor:'pointer',fontSize:'1.5rem'}}/> */}
          </VStack>
          {sharePopup && (
            
            <MotionBox
              animate={{
                y: sharePopup ? "-1rem" : "0rem",
              }}
              transition={{ duration: 0.2 }}
              position={'absolute'}
              bottom={"1rem"}
              right={"0"}
            >
              <HStack
                rounded={"0.5rem"}
                background={"white"}
                bgColor={'#E6EEFA'}
                // position={"absolute"}
                ref={containerRef}
                p={"0.5rem"}
                border={"1px solid rgb(168, 168, 168)"}
                zIndex={1}
              >
                <Box as={MdOpenInNew} cursor={'pointer'} fontSize={'1.8rem'} onClick={CopyToClipBoard} />
                <HStack>
                  <WhatsappShareButton url={shareUrl} title={caption}>
                    <WhatsappIcon size={30} round={true} />
                  </WhatsappShareButton>
                  <FacebookShareButton url={shareUrl} title={caption}>
                    <Box as={FacebookIcon} size={30} rounded={'50%'} fontSize={'1rem'} />
                  </FacebookShareButton>
                  {/* <Box
                  onClick={CopyToClipBoard}
                  cursor={"pointer"}
                  color={isCopied ? "green" : themePrimaryColor}
                  as={isCopied ? FaCheck : FaCopy}
                />
                <Box
                  cursor={"pointer"}
                  as={IoClose}
                  onClick={() => setSharePop(false)}
                /> */}
                </HStack>
              </HStack>
            </MotionBox>
          )}
        </HStack>
        </Box>
     {(!isTrial&&!isFullView&&!isVertical)&&<Text onClick={()=>{setShowMore(true)}} lineHeight={'1.1rem'} cursor={"pointer"} noOfLines={showMore?null:1} w={'95%'} fontSize={'0.9rem'}>{caption} </Text>}   
      </VStack>
  );
};


export default PostFooter;
