import {
  USER_PROFILE_ERROR,
  USER_PROFILE_SUCCESS,
  USER_PROFILE_LOADING,
  UPDATE_USER_PROFILE_ERROR,
  UPDATE_USER_PROFILE_SUCCESS,
  UPDATE_USER_PROFILE_LOADING,
  GET_ALL_USER_PROFILE_LOADING,
  GET_ALL_USER_PROFILE_SUCCESS,
  GET_ALL_USER_PROFILE_ERROR,
  GET_ANOTHER_USER_PROFILE_LOADING,
  GET_ANOTHER_USER_PROFILE_SUCCESS,
  GET_ANOTHER_USER_PROFILE_ERROR,
  UPDATE_USER_FOLLOWING_LOADING,
  UPDATE_USER_FOLLOWING_SUCCESS,
  UPDATE_USER_FOLLOWER_ERROR,
  UPDATE_USER_FOLLOWER_LOADING,
  UPDATE_USER_FOLLOWER_SUCCESS,
  UPDATE_USER_FOLLOWING_ERROR,
  GET_MY_FOLLOWING_LOADING,
  GET_MY_FOLLOWING_SUCCESS,
  GET_MY_FOLLOWING_ERROR,
  GET_MY_FOLLOWER_LOADING,
  GET_MY_FOLLOWER_SUCCESS,
  GET_MY_FOLLOWER_ERROR,
  GET_USER_FOLLOWING_SUCCESS,
  GET_USER_FOLLOWING_ERROR,
  GET_USER_FOLLOWER_LOADING,
  GET_USER_FOLLOWER_SUCCESS,
  GET_USER_FOLLOWER_ERROR,
  GET_USER_FOLLOWING_LOADING,
  DELETE_USER_PROFILE_LOADING,
  DELETE_USER_PROFILE_SUCCESS,
  DELETE_USER_PROFILE_ERROR
} from "./user.types";

const initialUserData ={
  role: '',
  id: 0,
  userName: '',
  fullName: '',
  email: "",
  gender: '',
  isPrivate: false,
  blueTick: false,
  profilePicture: '',
  backGroundPic: ''
}
const initialState = {
  profileData: initialUserData,
  allUsers:[],
  searchedUser:initialUserData,
  message:'',
  loading: false,
  error: '',
  searched_user_loading: false,
  searched_user_error: '',
  myFollower:[],
  myFollowing:[],
  userFollower:[],
  userFollowing:[],
  user_following_loading:false
};
export const userReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case USER_PROFILE_LOADING: {
      return {
        ...state,
        error: '',
        loading: true,
      };
    }
    case USER_PROFILE_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: '',
        profileData: payload
      };
    }
    case USER_PROFILE_ERROR: {
      console.log(payload, "error in login reducer");
      return {
        ...state,
        loading: false,
        error: payload
      };
    }
    case GET_ANOTHER_USER_PROFILE_LOADING: {
      return {
        ...state,
        searched_user_error: '',
        searched_user_loading: true,
        searchedUser:{},
      };
    }
    case GET_ANOTHER_USER_PROFILE_SUCCESS: {
      return {
        ...state,
        searched_user_loading: false,
        searched_user_error: '',
        searchedUser: payload
      };
    }
    case GET_ANOTHER_USER_PROFILE_ERROR: {
      console.log(payload, "error in login reducer");
      return {
        ...state,
        searched_user_loading: false,
        searched_user_error: payload
      };
    }
    case UPDATE_USER_PROFILE_LOADING: {
      return {
        ...state,
        error: '',
        message:'',
        loading: true,
      };
    }
    case UPDATE_USER_PROFILE_ERROR: {
      return {
        ...state,
        loading: false,
        message:'',
        error: payload
      };
    }
    case UPDATE_USER_PROFILE_SUCCESS: {
      return {
        ...state,
        loading: false,
        error:'',
        message: payload
      };
    }
    case UPDATE_USER_FOLLOWING_LOADING: {
      return {
        ...state,
        error: '',
        message:'',
        loading: true,
      };
    }
    case UPDATE_USER_FOLLOWING_SUCCESS: {
      let updateFollowing = state.myFollowing
      if(payload.status==='remove'){
        updateFollowing = updateFollowing.filter((following)=>following.id!==payload.followingId);
      };
      return {
        ...state,
        loading: false,
        message:payload,
        error: '',
        myFollowing:updateFollowing
      };
    }
    case UPDATE_USER_FOLLOWER_ERROR: {
      return {
        ...state,
        loading: false,
        error:payload,
      };
    }
    case UPDATE_USER_FOLLOWER_LOADING: {
      return {
        ...state,
        error: '',
        message:'',
        loading: true,
      };
    }
    case UPDATE_USER_FOLLOWER_SUCCESS: {
      let updateFollower = state.myFollower
      if(payload.status==='remove'){
        updateFollower = updateFollower.filter((follower)=>follower.id!==payload.followerId);
      };
      return {
        ...state,
        loading: false,
        message:'',
        error: '',
        myFollower:updateFollower
      };
    }
    case UPDATE_USER_FOLLOWING_ERROR: {
      return {
        ...state,
        loading: false,
        error:payload,
      };
    }
    case GET_ALL_USER_PROFILE_LOADING: {
      return {
        ...state,
        error: '',
        message:'',
        loading:true
      };
    }
    case GET_ALL_USER_PROFILE_SUCCESS: {
      return {
        ...state,
        message:'',
        loading:false,
        allUsers: payload
      };
    }
    case GET_ALL_USER_PROFILE_ERROR: {
      return {
        ...state,
        message:'',
        error: payload,
        loading:false,
      };
    }
    case GET_MY_FOLLOWING_LOADING: {
      return {
        ...state,
        error: '',
        message:'',
      };
    }
    case GET_MY_FOLLOWING_SUCCESS: {
      return {
        ...state,
        message:'',
        myFollowing: payload
      };
    }
    case GET_MY_FOLLOWING_ERROR: {
      return {
        ...state,
        message:'',
        error: payload
      };
    }
    case GET_MY_FOLLOWER_LOADING: {
      return {
        ...state,
        error: '',
        message:'',
      };
    }
    case GET_MY_FOLLOWER_SUCCESS: {
      return {
        ...state,
        message:'',
        myFollower: payload
      };
    }
    case GET_MY_FOLLOWER_ERROR: {
      return {
        ...state,
        message:'',
        error: payload
      };
    }
    case GET_USER_FOLLOWING_LOADING: {
      return {
        ...state,
        error: '',
        message:'',
      };
    }
    case GET_USER_FOLLOWING_SUCCESS: {
      return {
        ...state,
        message:'',
        userFollowing: payload
      };
    }
    case GET_USER_FOLLOWING_ERROR: {
      return {
        ...state,
        message:'',
        error: payload
      };
    }
    case GET_USER_FOLLOWER_LOADING: {
      return {
        ...state,
        error: '',
        message:'',
      };
    }
    case GET_USER_FOLLOWER_SUCCESS: {
      return {
        ...state,
        message:'',
        userFollower: payload
      };
    }
    case GET_USER_FOLLOWER_ERROR: {
      return {
        ...state,
        message:'',
        error: payload
      };
    }
    case DELETE_USER_PROFILE_LOADING: {
      return {
        ...state,
        error: '',
        message:'',
      };
    }
    case DELETE_USER_PROFILE_SUCCESS: {
      const filteredGetAllUsers = state.allUsers.filter(
        (user) => user.id != payload
      );
      return {
        ...state,
        message:'',
        allUsers: [...filteredGetAllUsers]
      };
    }
    case DELETE_USER_PROFILE_ERROR: {
      return {
        ...state,
        message:'',
        error: payload
      };
    }
    default: {
      return state;
    }
  }
};
