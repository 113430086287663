import { Avatar, Button, Td, Text, Tr } from "@chakra-ui/react";
import React from "react";
import { useNavigate } from "react-router-dom";
import { themePrimaryColor } from "../../../utils/theme";

const UserCard = ({
  id,
  email,
  setDeleteModal,
  fullName,
  profilePicture,
  setDeleteUserID
}) =>         {            
    
  const navigate = useNavigate();
  // const { isOpen, onOpen,  NewClose=onClose } = useDisclosure();
  return (
    <>
      <Tr>
        <Td
          pt={"0.7rem !important"}
          pb={"0.7rem !important"}
          borderBottom={"1px solid #135638f0"}
        >
         <Avatar src={profilePicture} size={'lg'}/>
        </Td>
        <Td
          pt={"0.7rem !important"}
          pb={"0.7rem !important"}
          borderBottom={"1px solid #135638f0"}
        >
          {email}
        </Td>
        <Td
          pt={"0.7rem !important"}
          pb={"0.7rem !important"}
          borderBottom={"1px solid #135638f0"}
        >
          {fullName}
        </Td>
        <Td
          pt={"0.7rem !important"}
          pb={"0.7rem !important"}
          borderBottom={"1px solid #135638f0"}
        >
          <Button  bgColor={themePrimaryColor}  color={'white'} onClick={()=>{
            setDeleteModal(true);
            setDeleteUserID(id);
            }}>Delete</Button>
        </Td>
      </Tr>
    </>
  );
};

export default UserCard;
