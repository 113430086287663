import { Box, HStack, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, Spinner, VStack } from "@chakra-ui/react";
import React, { useContext, useEffect } from "react";
import PostHeader from "./PostHeader";
import IndividualComment from "./IndividualComment";
import { AppContext } from "../context/AppContext";
import CommentInput from "./CommentInput";
import { useSelector } from "react-redux";
import { themePrimaryColor } from "../utils/theme";
import { FaArrowLeftLong } from "react-icons/fa6";

const CommentsModal = () => {
    const {setPostModalOpen:setHandlePostModal,postModalData,commentModalOpen, setCommentModalOpen} = useContext(AppContext);
    const { caption='',userData={},postId=0 }=postModalData;
    const {get_all_comments_loading:commentLoading,allComments} = useSelector(store=>store.commentData);

    return (  
    <>
      <Modal isOpen={commentModalOpen} onClose={()=>setCommentModalOpen(false)}>
      <ModalOverlay
      bg='blackAlpha.300'
      backdropFilter='blur(10px) hue-rotate(90deg)'
    />
      <ModalContent position={'relative'} top={'-2rem !important'} h={'80vh'} minW={['80%','80%','80%','70% !important']} >
                <ModalHeader
                    textAlign={"center"}
                    borderBottom={"0.2px solid #f5f1f1"}
                >
                </ModalHeader>
                <Box onClick={()=>setCommentModalOpen(false)} as={FaArrowLeftLong}  fontSize={'1.2rem'} position={'absolute'} top={'0.5rem'} right={'1rem'} />
                <ModalBody>
                <HStack align={"start"} h={"70vh"} w={"100%"}>
                    <VStack justify={"space-between"} pt={"1rem"} w={["100%"]} h={"100%"}>
                    <VStack
                        position={"relative"}
                        overflowY={"auto"}
                        borderY={"0.1px solid grey"}
                        w={"100%"}
                        height={"70vh"}
                    >
                        <IndividualComment
                        isContent={true}
                        userName={userData.email}
                        comment={caption}
                        profilePicture={userData.profilePicture}
                        />
                        {commentLoading && (
                        <Spinner
                            position={"absolute"}
                            top={"50%"}
                            size={"lg"}
                            thickness="3px"
                            color={themePrimaryColor}
                        />
                        )}
                        {allComments?.map((comment) => (
                        <IndividualComment
                            postId={postId}
                            commentId={comment.id}
                            userName={comment.userData.email}
                            comment={comment.content}
                            profilePicture={comment.userData.profilePicture}
                            fullName={comment.userData.fullName}
                        />
                        ))}
                    </VStack>
                    <CommentInput
                        h={"10vh"}
                        setHandlePostModal={setHandlePostModal}
                        postId={postId}
                    />
                    </VStack>
                </HStack>
                </ModalBody>
                </ModalContent>
      </Modal>
    </>
  );
};

export default CommentsModal;
