// route.js
import React, { useState, useEffect } from "react";

import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { jwtDecode } from "jwt-decode";
// import { getAllRole, getUserByToken } from "../redux/features/auth";
import { Routes, Route } from "react-router-dom";
import Login from "./Login";
import Signup from "./Signup";
import Profile from "./Profile/Profile";
import Home from "./Home/Home";
import PrivateRoute from "../components/PrivateRoute";
import Creations from "./Creations";
import OAuth from "./OAuth/OAuth";
import Search from "./Search";
import UserProfile from "./UserProfile";
import Message from "./Message";
import AboutUs from "./About/About";
import Terms from "./Terms";
import ShortVideo from "./ShortVideo";
import Saved from "./Saved";
import Privacy from "./Privacy/Privacy";
import SelectRole from "./SelectRole";
import TrialRoom from "./TrialRoom";
import Contact from "./Contact";
import ForgotPassword from "./ForgotPassword/ForgotPassword";
import TrialRoomAnother from "./TrialRoomAnother";
import ResetPassword from "./ResetPassword";
import Setting from "./Setting";
import Trending from "./Trending";
import Notifications from "./Notifications";
import SinglePost from "./SinglePost";
import AdminAuth from "../components/AdminAuth";
import Admin from "./Admin/Admin";
import Users from "./Admin/Users/Users";
import Posts from "./Admin/Posts";

const AllPages = () => {
    return (
        <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/signup" element={<Signup />} />
            <Route element={<OAuth />} path="/account/login-success" />
            <Route element={<AboutUs />} path="/aboutus" />
            <Route element={<Terms />} path="/terms-conditions" />
            <Route element={<Privacy />} path="/privacy" />
            <Route element={<Contact />} path="/contact" />
            <Route element={<SelectRole/>} path="/select-role" />
            <Route element={<ForgotPassword/>} path="/forget-password" />
            <Route element={<ResetPassword/>} path="/resetpassword" />
            <Route path="/" element={
                <PrivateRoute>
                    <Home />
                </PrivateRoute>
            }>
                <Route path="/setting" element={<Setting />} />
                <Route path="/profile" element={<Profile />} />
                <Route path="/search" element={<Search />} />
                <Route path="/message" element={<Message />} />
                <Route path="/photos" element={<Creations/>} />
                <Route path="/:userId" element={<UserProfile/>} />
                <Route path="/shortvideos" element={<ShortVideo/>} />
                <Route path="/trending" element={<Trending/>} />
                <Route path="/notification" element={<Notifications/>} />
                <Route path="/post/:postId" element={<SinglePost/>} />
                <Route path="/collection" element={<Saved/>} />
                <Route path="/creations" element={<TrialRoom/>} />
                <Route path="/creations/:userId" element={<TrialRoomAnother/>} />
            </Route>
            <Route path="/admin" element={
                <AdminAuth>
                    <Admin/>
                </AdminAuth> 
            }>
                <Route path="users" element={<Users/>}/>
                <Route path="posts" element={<Posts/>}/>
            </Route>
        </Routes >

    );
};

export default AllPages;
