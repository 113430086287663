import React, { useEffect } from 'react'
import CreationGrid from '../../components/CreationGrid'
import { Heading, Spinner, VStack } from '@chakra-ui/react'
import { useSelector } from 'react-redux';
import { themePrimaryColor } from '../../utils/theme';
import { DESIGNER_ROLE } from '../../utils/constant';
import Empty from '../../components/Empty';

const Creations = () => {
  const { getUserPosts,get_all_posts_error,
    get_posts_by_user_loading } = useSelector((store) => store.postData);
    const { profileData } = useSelector((store) => store.userData);
  return (
    <VStack w={['100%','100%','87%',"78%"]} pb={'1rem'}>
      <Heading color={themePrimaryColor} pt={'1rem'} fontSize={"2rem"} fontWeight={100} fontFamily={" Lora, serif"}>
         Photos
        </Heading>
       {get_posts_by_user_loading&&<Spinner position={'absolute'} top={'40%'} color={themePrimaryColor} thickness='4px' size={'xl'}/>}
        {(!get_posts_by_user_loading&&getUserPosts?.filter((el) => {
          if (el.imageUrls?.filter((imageEl) => imageEl.length).length) {
            return true;
          } else if (el.videoUrl?.length) {
            return false;
          }
        }).length===0)?<Empty title='No Profile Yet'/>:<CreationGrid creationData={getUserPosts?.filter((el) => {
          if (el.imageUrls?.filter((imageEl) => imageEl.length).length) {
            return true;
          } else if (el.videoUrl?.length) {
            return false;
          }
        })}/>}
    </VStack>          
    
  )
}

export default Creations
