import { Text, VStack, useMediaQuery } from "@chakra-ui/react";
import React from "react";
import Lottie from "lottie-react";
import animationData from "../assets/empty.json";
import { themePrimaryColor } from "../utils/theme";
const Empty = ({title='Nothing Available'}) => {
  const [isLargerThan450] = useMediaQuery("(min-width: 450px)");
  return (
    <VStack w={"100%"}>
      <Lottie style={isLargerThan450?{width:400,height:380}:{width:250,height:250}} animationData={animationData} loop={true} />
      <Text color={'#7C0AB6'} textAlign={'center'} fontSize={['1.37rem','1.7rem','2rem',"2rem"]} fontWeight={700}>
    {title}
      </Text>
    </VStack>
  );
};

export default Empty;
