import { Avatar, Button, HStack, Text, VStack } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { themePrimaryColor } from "../utils/theme";
import { useDispatch, useSelector } from "react-redux";
import { addFollowing, getMyFollowing, removeFollowing } from "../store/user/user.action";
import { useNavigate } from "react-router-dom";
import { UPDATE_USER_FOLLOWING_SUCCESS } from "../store/user/user.types";
import { RoleName } from "../utils/constant";

const FollowCard = ({profilePicture,fullName,email,id,isMe,onClose,role}) => {
    const { Token } = useSelector((store) => store.authData);
    const dispatch = useDispatch();
    const { profileData,myFollowing,myFollower } = useSelector((store) => store.userData);
    const [isFollowing, setIsFollowing] = useState(isMe?true:false);  
    const navigate = useNavigate();
    const handleFollowButton = () => {
        if (isFollowing) {
          dispatch(
            removeFollowing(Token, {
              userId: profileData.id,
              followingId: id,
            })
          );
          setIsFollowing(false);
        } else {
          dispatch(
            addFollowing(Token, {
              userId: profileData.id,
              followingId: id,
            })
          ).then((res)=>{
            if(res.type===UPDATE_USER_FOLLOWING_SUCCESS){
              dispatch(getMyFollowing(Token,profileData.id));
            }
          })
          setIsFollowing(true);
        }
      };
      useEffect(() => {
        if(!isMe){
            myFollowing?.forEach((following) => {
              if (following.id == id) {
                setIsFollowing(true);
              }
            });
        }
      }, [myFollowing]);
      const handleRedirectAnotherProfile = ()=>{
        navigate(`/${id}`);
        onClose();
      }
  return (
    <HStack w={"100%"}  justify={"space-between"}>
      <HStack alignItems={'start'}>
        <Avatar onClick={handleRedirectAnotherProfile} cursor={'pointer'} src={profilePicture} size="sm" />
        <VStack align={'start'} justify={'start'} gap={'0'}>
        <Text cursor={'pointer'} onClick={handleRedirectAnotherProfile} fontSize={"0.9rem"} fontWeight={600}>
          {fullName}
        </Text>
        <Text fontSize={"0.85rem"} fontWeight={500}>
          {/* {RoleName(role)} */}
        </Text>
        </VStack>
      </HStack>
      {
        id!=profileData.id&&
      <Button
        bgColor={themePrimaryColor}
        h={"auto"}
        color={"white"}
        p={['0.4rem 0.5rem','0.6rem','0.68rem','0.68rem']}
        fontSize={['0.8rem','0.9rem','1rem','1rem']}
        onClick={handleFollowButton}
      >
        {" "}
        {isFollowing?"UnFollow":"Follow"}
      </Button>
      }
    </HStack>
  );
};

export default FollowCard;
