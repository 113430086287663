import {
  Avatar,
  Box,
  HStack,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  VStack,
  useToast,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { BsThreeDots } from "react-icons/bs";
import { RoleName } from "../utils/constant";
import DeletePostModal from "./DeletePostModal";
import { useDispatch, useSelector } from "react-redux";
import { addSavedPost, updateSavedPosts } from "../store/saved/saved.action";
import { ADD_SAVED_POST_SUCCESS } from "../store/saved/saved.types";
import { useNavigate } from "react-router-dom";
import moment from "moment";

const PostHeader = ({
  email,
  profilePicture,
  role,
  postId,
  h = "auto",
  fullName,
  isLikedByMe,
  isTrial,
  userId,
  postTime,
}) => {
  const [deleteModal, setDeleteModal] = useState(false);
  const { profileData } = useSelector((store) => store.userData);
  const { Token } = useSelector((store) => store.authData);
  const toast = useToast();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleAddToCollection = () => {
    dispatch(addSavedPost(Token, { userId: profileData.id, postId })).then(
      (res) => {
        if (res.type === ADD_SAVED_POST_SUCCESS) {
          toast({
            description: "Post has been saved",
            isClosable: true,
            duration: 500,
            status: "success",
          });
          console.log("successfully", res.payload);
          dispatch(
            updateSavedPosts(Token, {
              postId,
              userData: { email, fullName, role, profilePicture },
              isLikedByMe,
            })
          );
        }
      }
    );
  };
  return (
    <HStack h={h} w={"100%"} align={"center"} position={'relative'} justify={"space-between"}>
      <HStack>
        <Avatar
          cursor={"pointer"}
          onClick={() => {
            if (userId == profileData.id) {
              navigate("/profile");
            } else {
              navigate(`/${userId}`);
            }
          }}
          src={profilePicture}
          width={"2.7rem"}
          h={"2.7rem"}
        />
        <VStack gap={"0"} align={"start"} justify={"start"}>
          <Text fontSize={"1.2rem"} className="poppins" fontWeight={600}>
            {fullName}
          </Text>
          <Text fontSize={"0.9rem"} fontWeight={600}>
            {RoleName(role)} <Box  ml={'0.3rem'} right={'1.8rem'} fontSize={'0.7rem'} fontWeight={400} as="span">{moment(postTime).format("D-MMM, h:mm")}</Box>
          </Text>
        </VStack>
      </HStack>
      <Menu>
        <MenuButton cursor={"pointer"}>
          <BsThreeDots style={{ fontSize: "1.5rem", cursor: "pointer" }} />
        </MenuButton>
        <MenuList>
          {/* {profileData.email === email && <MenuItem>Visiblity</MenuItem>} */}
          <MenuItem onClick={handleAddToCollection}>Add to collection</MenuItem>
          {(profileData.id === userId||profileData.email==process.env.REACT_APP_ADMIN_ID) && (
            <MenuItem color={"red"} onClick={() => setDeleteModal(true)}>
              Delete {isTrial ? "3D Creation post" : "post"}
            </MenuItem>
          )}
          <MenuItem>Cancel</MenuItem>
        </MenuList>
      </Menu>
      <DeletePostModal
        deleteModal={deleteModal}
        postId={postId}
        setDeleteModal={setDeleteModal}
      />
    </HStack>
  );
};

export default PostHeader;
