import {
  Center,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  VStack,
  Button,
  Box,
  Image,
  useToast,
  Alert,
  AlertIcon,
  Image as ChakraImage,
  HStack,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { Formik, Field } from "formik";
import { BiHide, BiShow } from "react-icons/bi";
import Logo from "../../images/Logo.png";
import { authLoginApi } from "../../store/auth/auth.action";
import { useDispatch, useSelector } from "react-redux";
import Footer from "../../components/Footer";
import { AUTH_LOGIN_ERROR } from "../../store/auth/auth.types";
import { baseurl } from "../../utils/api";
import axios from "axios";
import Begin from "../../components/Begin";
import HomeTrending from "../../components/HomeTrending";
import FollowUS from "../../components/FollowUS";
import { themePrimaryColor } from "../../utils/theme";
const Login = () => {
  const [error, setError] = useState("");
  let navigate = useNavigate();
  const toast = useToast();
  const dispatch = useDispatch();
  const { isAuth, signupMessage,isAdmin } = useSelector((store) => store.authData);
  const [show, setShow] = useState(false);
  if (isAuth) {
    return <Navigate to={"/trending"} />;
  }
  if (isAdmin) {
    return <Navigate to={"/admin"} />;
  }
  const googleLogin = async () => {
    const response = await axios.get(`${baseurl}`);
    if (response.status == 200) {
      window.location.href = `${baseurl}/google`;
    }
  };
  return (
    <VStack w={"100%"} p={["0.5rem", "1rem", "1rem", "1rem"]} gap={"2rem"}>
      <VStack
        w={["90%", "70%", "48%", "28%"]}
        border={"1px solid rgb(219, 219, 219)"}
        py={"2rem"}
      >
        <ChakraImage
          w={"10rem"}
          // style={{
          //   backgroundImage:
          //     "linear-gradient(to right, rgb(37 151 217) 0%, rgb(163 73 191) 51%, rgb(37 151 217) 100%)",
          //   transition: "0.5s",
          //   backgroundSize: "200% auto",
          //   color: "white",
          //   boxShadow: "0 0 20px #eee",
          //   display: "block",
          // }}
          // _hover={{ backgroundPosition: "right center" }}
          // border={`1px solid ${themePrimaryColor}`}
          src={Logo}
        />
        <Heading
          fontSize={["1.7rem", "1.85rem", "1.9rem", "2rem"]}
          fontWeight={100}
          fontFamily={" Lora, serif"}
        >
          LOGIN
        </Heading>
        <VStack mt={"1rem"} width={["93%", "93%", "90%", "90%"]}>
          <Formik
            initialValues={{
              userName: "",
              password: "",
            }}
            onSubmit={(values) => {
              let { userName, password } = values;
              dispatch(authLoginApi({ userName, password })).then((res) => {
                if (res?.type === AUTH_LOGIN_ERROR) {
                  toast({
                    status: "error",
                    title: res.payload,
                    isClosable: true,
                    duration: 1800,
                  });
                } else {
                  toast({
                    status: "success",
                    title: res.payload.message,
                    isClosable: true,
                    duration: 1800,
                  });
                }
              });
            }}
          >
            {({ handleSubmit, errors, touched }) => (
              <form style={{ width: "100%" }} onSubmit={handleSubmit}>
                <VStack
                  gap={["1.1rem", "1.3rem", "1.4rem", "1.4rem"]}
                  align="flex-start"
                >
                  {error?.length && (
                    <Alert mt={"1rem"} status="error">
                      <AlertIcon />
                      {error}
                    </Alert>
                  )}
                  <FormControl
                    position={"relative"}
                    isInvalid={!!errors.userName && touched.userName}
                  >
                    <FormLabel m={"0"} htmlFor="userName">
                      Username or email
                    </FormLabel>
                    <Field
                      as={Input}
                      id="userName"
                      width={"100%"}
                      name="userName"
                      type="text"
                      variant="outline"
                      borderRadius={"0"}
                      border="1.5px solid black"
                      _hover={{ background: "#ffffff", color: "black" }}
                      validate={(value) => {
                        let error;
                        if (!value) {
                          error = "Required";
                        }
                        return error;
                      }}
                    />
                    <FormErrorMessage position={"absolute"} mt={"0.2rem"}>
                      {errors.userName}
                    </FormErrorMessage>
                  </FormControl>
                  <FormControl
                    isInvalid={!!errors.password && touched.password}
                  >
                    <FormLabel m={"0"} htmlFor="password">
                      Password
                    </FormLabel>
                    <InputGroup position={"relative"}>
                      <Field
                        as={Input}
                        id="password"
                        name="password"
                        borderRadius={"0"}
                        border="1.5px solid black"
                        type={show ? "text" : "password"}
                        _hover={{ background: "#ffffff", color: "black" }}
                        validate={(value) => {
                          let error;

                          if (value.length < 8) {
                            error =
                              "Password must contain at least 8 characters";
                          }

                          return error;
                        }}
                      />
                      <InputRightElement width="3rem">
                        <Center
                          fontSize="30px"
                          pt={"0.2rem"}
                          bg="none"
                          onClick={() => setShow(!show)}
                        >
                          {show ? (
                            <BiHide style={{ cursor: "pointer" }} />
                          ) : (
                            <BiShow style={{ cursor: "pointer" }} />
                          )}
                        </Center>
                      </InputRightElement>
                    </InputGroup>
                    <HStack justify={"end "} m={0}>
                      <Link
                        id="signup"
                        style={{ color: "#4285F4" }}
                        to="/resetpassword"
                      >
                        Forget Password?
                      </Link>
                    </HStack>
                    <FormErrorMessage mt={"0.2rem"} position={"absolute"}>
                      {" "}
                      {errors.password}
                    </FormErrorMessage>
                  </FormControl>
                  <Button
                    _hover={{
                      color: "color",
                      background: "#0064E0",
                    }}
                    type="submit"
                    bg="black"
                    color={"white"}
                    width="full"
                    outline={"none"}
                    border={"none"}
                    cursor={"pointer"}
                    rounded={"0rem"}
                    // h="48px"
                    mt={"1rem"}
                    letterSpacing={"3px"}
                  >
                    LOGIN
                  </Button>
                </VStack>
              </form>
            )}
          </Formik>
          <Text textAlign={"center"} mt={"1.5rem"}>
            Don't have an account?{" "}
            <Link id="signup" style={{ color: "#4285F4" }} to="/signup">
              Create account
            </Link>{" "}
          </Text>
          <Flex width={"100%"} align="center" justify="space-around">
            <Box borderBottom="2px solid gray" w="42%"></Box>
            <Text fontWeight="bold">OR</Text>
            <Box borderBottom="2px solid gray" w="42%"></Box>
          </Flex>
          <Flex
            align="center"
            bg="white"
            color="rgb(66,133,244)"
            cursor={"pointer"}
            rounded={"0.5rem"}
            w={"90%"}
            p={["0.1rem", 2, 2, 2]}
            gap={"0.5rem"}
            height={"42px"}
            justify={"center"}
            // onClick={signInWithGoogle}
            border={"1.5px solid #4285F4"}
            // _hover={{ background: "#ffebd7" }}
          >
            <Image
              w={["16px", "20px", "25px", "28px"]}
              src={"https://img.icons8.com/color/452/google-logo.png"}
            />
            <Text
              ml={["2px", "5px", "7px", "10px"]}
              letterSpacing={["1px", "2px", "2px", "2px"]}
              fontSize={["0.8rem", "0.97rem", "0.97rem", "0.97rem"]}
              onClick={googleLogin}
              fontWeight="bold"
              textAlign={"center"}
            >
              SIGN WITH THE GOOGLE
            </Text>
          </Flex>
        </VStack>
      </VStack>
      <Begin />
      <HomeTrending />
      <FollowUS />
      <Footer />
    </VStack>
  );
};

export default Login;
