import {
  Box,
  Button,
  FormControl,
  FormLabel,
  HStack,
  Heading,
  Image,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Text,
  Textarea,
  Toast,
  VStack,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import { ReactImageTurntable } from "react-image-turntable";
import TrialRoomGrid from "../../components/TrialRoomGrid";
import { v4 } from "uuid";
import { IoMdArrowRoundBack } from "react-icons/io";
import { themePrimaryColor } from "../../utils/theme";
import Swal from "sweetalert2";
import { AiOutlineCloudUpload } from "react-icons/ai";
import { FaCheckDouble } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { getTrialPosts } from "../../store/post/post.action";
import { useParams } from "react-router-dom";
import Empty from "../../components/Empty";
import TrialPost from "../../components/TrialPost";

export const importAllImages = (context) => {
  let images = {};
  context.keys().forEach((item, index) => {
    images[item.replace("./", "")] = context(item);
  });
  return images;
};
const jackedImages = importAllImages(
  require.context("../../images/jacket-images", false, /\.(jpg)$/)
);
const trialRoomData = [
  {
    "caption": "The Nike Chain Black Jacket epitomizes sleek versatility and urban sophistication. Crafted from a blend of high-quality materials, typically polyester and nylon, this jacket exudes durability and comfort",
    "likesCount": 0,
    "sharesCount": 0,
    "commentsCount": 0,
    "title":"Nike Jacket",
    "postId": 33,
    "imageUrls": [
        "https://firebasestorage.googleapis.com/v0/b/chat-app-aceb0.appspot.com/o/files%2Fjacket-image1.jpg?alt=media&token=e419ee1b-8d52-4855-8465-bd0630a6cbac",
        "https://firebasestorage.googleapis.com/v0/b/chat-app-aceb0.appspot.com/o/files%2Fjacket-image2.jpg?alt=media&token=e419ee1b-8d52-4855-8465-bd0630a6cbac",
        "https://firebasestorage.googleapis.com/v0/b/chat-app-aceb0.appspot.com/o/files%2Fjacket-image3.jpg?alt=media&token=e419ee1b-8d52-4855-8465-bd0630a6cbac",
        "https://firebasestorage.googleapis.com/v0/b/chat-app-aceb0.appspot.com/o/files%2Fjacket-image4.jpg?alt=media&token=e419ee1b-8d52-4855-8465-bd0630a6cbac",
        "https://firebasestorage.googleapis.com/v0/b/chat-app-aceb0.appspot.com/o/files%2Fjacket-image5.jpg?alt=media&token=e419ee1b-8d52-4855-8465-bd0630a6cbac",
        "https://firebasestorage.googleapis.com/v0/b/chat-app-aceb0.appspot.com/o/files%2Fjacket-image6.jpg?alt=media&token=e419ee1b-8d52-4855-8465-bd0630a6cbac",
        "https://firebasestorage.googleapis.com/v0/b/chat-app-aceb0.appspot.com/o/files%2Fjacket-image7.jpg?alt=media&token=e419ee1b-8d52-4855-8465-bd0630a6cbac",
        "https://firebasestorage.googleapis.com/v0/b/chat-app-aceb0.appspot.com/o/files%2Fjacket-image8.jpg?alt=media&token=e419ee1b-8d52-4855-8465-bd0630a6cbac",
        "https://firebasestorage.googleapis.com/v0/b/chat-app-aceb0.appspot.com/o/files%2Fjacket-image9.jpg?alt=media&token=e419ee1b-8d52-4855-8465-bd0630a6cbac",
        "https://firebasestorage.googleapis.com/v0/b/chat-app-aceb0.appspot.com/o/files%2Fjacket-image10.jpg?alt=media&token=e419ee1b-8d52-4855-8465-bd0630a6cbac",
        "https://firebasestorage.googleapis.com/v0/b/chat-app-aceb0.appspot.com/o/files%2Fjacket-image11.jpg?alt=media&token=e419ee1b-8d52-4855-8465-bd0630a6cbac",
        "https://firebasestorage.googleapis.com/v0/b/chat-app-aceb0.appspot.com/o/files%2Fjacket-image12.jpg?alt=media&token=e419ee1b-8d52-4855-8465-bd0630a6cbac",
        "https://firebasestorage.googleapis.com/v0/b/chat-app-aceb0.appspot.com/o/files%2Fjacket-image13.jpg?alt=media&token=e419ee1b-8d52-4855-8465-bd0630a6cbac",
        "https://firebasestorage.googleapis.com/v0/b/chat-app-aceb0.appspot.com/o/files%2Fjacket-image14.jpg?alt=media&token=e419ee1b-8d52-4855-8465-bd0630a6cbac",
        "https://firebasestorage.googleapis.com/v0/b/chat-app-aceb0.appspot.com/o/files%2Fjacket-image15.jpg?alt=media&token=e419ee1b-8d52-4855-8465-bd0630a6cbac",
        "https://firebasestorage.googleapis.com/v0/b/chat-app-aceb0.appspot.com/o/files%2Fjacket-image16.jpg?alt=media&token=e419ee1b-8d52-4855-8465-bd0630a6cbac",
        "https://firebasestorage.googleapis.com/v0/b/chat-app-aceb0.appspot.com/o/files%2Fjacket-image17.jpg?alt=media&token=e419ee1b-8d52-4855-8465-bd0630a6cbac",
        "https://firebasestorage.googleapis.com/v0/b/chat-app-aceb0.appspot.com/o/files%2Fjacket-image18.jpg?alt=media&token=e419ee1b-8d52-4855-8465-bd0630a6cbac",
        "https://firebasestorage.googleapis.com/v0/b/chat-app-aceb0.appspot.com/o/files%2Fjacket-image19.jpg?alt=media&token=e419ee1b-8d52-4855-8465-bd0630a6cbac",
        "https://firebasestorage.googleapis.com/v0/b/chat-app-aceb0.appspot.com/o/files%2Fjacket-image20.jpg?alt=media&token=e419ee1b-8d52-4855-8465-bd0630a6cbac",
        "https://firebasestorage.googleapis.com/v0/b/chat-app-aceb0.appspot.com/o/files%2Fjacket-image21.jpg?alt=media&token=e419ee1b-8d52-4855-8465-bd0630a6cbac",
        "https://firebasestorage.googleapis.com/v0/b/chat-app-aceb0.appspot.com/o/files%2Fjacket-image22.jpg?alt=media&token=e419ee1b-8d52-4855-8465-bd0630a6cbac",
        "https://firebasestorage.googleapis.com/v0/b/chat-app-aceb0.appspot.com/o/files%2Fjacket-image23.jpg?alt=media&token=e419ee1b-8d52-4855-8465-bd0630a6cbac",
        "https://firebasestorage.googleapis.com/v0/b/chat-app-aceb0.appspot.com/o/files%2Fjacket-image24.jpg?alt=media&token=e419ee1b-8d52-4855-8465-bd0630a6cbac",
        "https://firebasestorage.googleapis.com/v0/b/chat-app-aceb0.appspot.com/o/files%2Fjacket-image25.jpg?alt=media&token=e419ee1b-8d52-4855-8465-bd0630a6cbac",
        "https://firebasestorage.googleapis.com/v0/b/chat-app-aceb0.appspot.com/o/files%2Fjacket-image26.jpg?alt=media&token=e419ee1b-8d52-4855-8465-bd0630a6cbac",
        "https://firebasestorage.googleapis.com/v0/b/chat-app-aceb0.appspot.com/o/files%2Fjacket-image27.jpg?alt=media&token=e419ee1b-8d52-4855-8465-bd0630a6cbac",
        "https://firebasestorage.googleapis.com/v0/b/chat-app-aceb0.appspot.com/o/files%2Fjacket-image28.jpg?alt=media&token=e419ee1b-8d52-4855-8465-bd0630a6cbac",
        "https://firebasestorage.googleapis.com/v0/b/chat-app-aceb0.appspot.com/o/files%2Fjacket-image29.jpg?alt=media&token=e419ee1b-8d52-4855-8465-bd0630a6cbac",
        "https://firebasestorage.googleapis.com/v0/b/chat-app-aceb0.appspot.com/o/files%2Fjacket-image30.jpg?alt=media&token=e419ee1b-8d52-4855-8465-bd0630a6cbac",
        "https://firebasestorage.googleapis.com/v0/b/chat-app-aceb0.appspot.com/o/files%2Fjacket-image31.jpg?alt=media&token=e419ee1b-8d52-4855-8465-bd0630a6cbac",
        "https://firebasestorage.googleapis.com/v0/b/chat-app-aceb0.appspot.com/o/files%2Fjacket-image32.jpg?alt=media&token=e419ee1b-8d52-4855-8465-bd0630a6cbac",
        "https://firebasestorage.googleapis.com/v0/b/chat-app-aceb0.appspot.com/o/files%2Fjacket-image33.jpg?alt=media&token=e419ee1b-8d52-4855-8465-bd0630a6cbac",
        "https://firebasestorage.googleapis.com/v0/b/chat-app-aceb0.appspot.com/o/files%2Fjacket-image34.jpg?alt=media&token=e419ee1b-8d52-4855-8465-bd0630a6cbac",
        "https://firebasestorage.googleapis.com/v0/b/chat-app-aceb0.appspot.com/o/files%2Fjacket-image35.jpg?alt=media&token=e419ee1b-8d52-4855-8465-bd0630a6cbac",
        "https://firebasestorage.googleapis.com/v0/b/chat-app-aceb0.appspot.com/o/files%2Fjacket-image36.jpg?alt=media&token=e419ee1b-8d52-4855-8465-bd0630a6cbac"
    ],
    "userData": {
        "email": "aman@gmail.com",
        "profilePicture": "https://firebasestorage.googleapis.com/v0/b/chat-app-aceb0.appspot.com/o/files%2F1000125200.jpg?alt=media&token=b1ea0e2b-a290-4bc3-88fd-40ac6a9520ab",
        "role": "designer",
        "fullName": "aman yadav"
    },
    isTrial:true
}
  // {
  //   name: "Car",
  //   images: new Array(36)
  //     .fill(0)
  //     .map(
  //       (_, i) =>
  //         `https://static2.zerolight.com/web/df3a45687480167bb4451d79c02f67bd/img/2d-explorer/bmw/P0C1G__S022B__FX3A9/P0C1G__S022B__FX3A9__Spin_${(
  //           "0" +
  //           (i + 1)
  //         ).slice(-2)}.jpg?v=1`
  //     ),
  //   primaryImage:
  //     "https://static2.zerolight.com/web/df3a45687480167bb4451d79c02f67bd/img/2d-explorer/bmw/P0C1G__S022B__FX3A9/P0C1G__S022B__FX3A9__Spin_01.jpg?v=1",
  // },
  // {
  //   name: "Chair",
  //   images: new Array(120)
  //     .fill(0)
  //     .map(
  //       (_, i) =>
  //         `https://s3-us-west-2.amazonaws.com/s.cdpn.io/68939/360-${(
  //           "00" +
  //           (120 - i)
  //         ).slice(-3)}.jpg`
  //     ),
  //   primaryImage:
  //     "https://s3-us-west-2.amazonaws.com/s.cdpn.io/68939/360-120.jpg",
  // },
  // {
  //   title: "Jacket",
  //   description:"This is a new jacket, color:black, size:lg",
  //   images: Object.keys(jackedImages)
  //     .map((imageName) => jackedImages[imageName])
  //     .reverse(),
  //   primaryImage: jackedImages["jacket-image1.jpg"],
  // },
];
const TrialRoomAnother = () => {
  const toast = useToast();
  const [imagesFiles, setImagesFiles] = useState([]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { trialRoomPosts,get_trial_posts_loading,
    get_trial_posts_error } = useSelector((store) => store.postData);
  const { Token } = useSelector((store) => store.authData);
  const {userId} = useParams();
  const {
    profileData
  } = useSelector((store) => store.userData);
  const dispatch = useDispatch();

  useEffect(() => {
    if (Token && profileData.email.length) {
      dispatch(getTrialPosts(Token, userId));
    };
  }, [userId]);
  useEffect(()=>{
    console.log(get_trial_posts_loading,'loading  ')
  },[get_trial_posts_loading])
  return (
    <VStack
      w={["100%", "100%", "87%", "78%"]}
      pb={"1rem"}
      position={"relative"}
    >
      <Heading
        color={themePrimaryColor}
        pt={"1rem"}
        fontSize={"2rem"}
        fontWeight={100}
        fontFamily={" Lora, serif"}
      >
        3D Creations
      </Heading>
      {
        get_trial_posts_loading&&<VStack minH={"65vh"} align={"center"} justify={"center"}>
         <Spinner color={themePrimaryColor} thickness="4px" size={"xl"} />
       </VStack>
      }
      
      {
      (!get_trial_posts_loading&&trialRoomPosts.length)?trialRoomPosts?.map((post)=>(
        <TrialPost key={v4()} title={post.title} isTrial={post.isTrial} isLikedByMe={post.isLikedByMe} caption={post.caption} userData={post.userData} postId={post.id} imageUrls={post.imageUrls?.filter((el)=>el.length)} likesCount={post.likesCount??0} sharesCount={post.sharesCount} commentsCount={post.commentsCount} />
      )): get_trial_posts_loading?<></>:<Empty title='No 3D Creations Yet'/>
      }
   
    </VStack>
  );
};

export default TrialRoomAnother;
