import {
  Center,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  VStack,
  Button,
  Box,
  Image,
  useToast,
  Alert,
  AlertIcon,
  Image as ChakraImage,
  HStack,
  Checkbox,
} from "@chakra-ui/react";
import { Formik, Field } from "formik";
import React, { useContext, useEffect, useState } from "react";
import { BiHide, BiShow } from "react-icons/bi";
import Logo from "../../images/Logo.png";
import { Link, Navigate, useNavigate, useSearchParams } from "react-router-dom";
import { BsFacebook } from "react-icons/bs";
//   import { AppContext } from "../../context/AppContext";
import { useDispatch, useSelector } from "react-redux";
import Footer from "../../components/Footer";
import Begin from "../../components/Begin";
import HomeTrending from "../../components/HomeTrending";
import FollowUS from "../../components/FollowUS";
import { authSignupApi } from "../../store/auth/auth.action";
import {
  AUTH_LOGIN_SUCCESS,
  AUTH_SIGNUP_ERROR,
} from "../../store/auth/auth.types";
import axios from "axios";
import { GET, UPDATE, apiRequest, baseurl } from "../../utils/api";
import { themePrimaryColor } from "../../utils/theme";
//   import {
//     AUTH_SIGNUP_ERROR,
//     AUTH_SIGNUP_SUCCESS,
//   } from "../../store/auth/auth.types";
//   import { authSignupApi } from "../../store/auth/auth.action";
const Roles = [
  { name: "Fashion Designer", id: "designer" },
  { name: "Model", id: "model" },
  { name: "User", id: "user" },
];
const Signup = () => {
  const [show, setShow] = useState(false);
  const [role, setRole] = useState();
  const [searchParams] = useSearchParams();
  const toast = useToast();
  const { isAuth } = useSelector((store) => store.authData);
  const dispatch = useDispatch();
  const [error, setError] = useState("");
  let navigate = useNavigate();
  let token = searchParams.get("token");
  useEffect(() => {
    if (!token) {
      navigate("/login");
    }
  }, []);
  const handleRole = () => {
    apiRequest({ type: GET, endpoint: "auth/get-user-by-token", token })
      .then((res) => {
        apiRequest({
          type: UPDATE,
          endpoint: "users/roleSelect",
          token,
          data: { userId: res.data.id, role },
        })
          .then(() => {
            dispatch({
              type: AUTH_LOGIN_SUCCESS,
              payload: { data: { message: "Succesfully signin", token } },
            });
            setTimeout(() => {
              navigate("/profile");
            }, 800);
          })
          .catch((err) => {
            err = err.response?.data?.message
              ? err.response?.data?.message
              : err.message;
            toast({
              status: "error",
              title: err,
              isClosable: true,
              duration: 1200,
            });
            navigate("/login");
          });
      })
      .catch((err) => {
        err = err.response?.data?.message
          ? err.response?.data?.message
          : err.message;
        toast({
          status: "error",
          title: err,
          isClosable: true,
          duration: 1200,
        });
        navigate("/login");
      });
  };
  if (isAuth) {
    return <Navigate to={"/"} />;
  }
  return (
    <VStack w={"100%"} p={["0.5rem", "1rem", "1rem", "1rem"]} gap={"3rem"}>
      <VStack
        w={["90%", "70%", "48%", "28%"]}
        gap={"1.5rem"}
        border={"1px solid rgb(219, 219, 219)"}
        py={"2rem"}
      >
        <ChakraImage
          w={"10rem"}
          cursor={"pointer"}
          onClick={() => navigate("/")}
          // style={{
          //   backgroundImage:
          //     "linear-gradient(to right, rgb(37 151 217) 0%, rgb(163 73 191) 51%, rgb(37 151 217) 100%)",
          //   transition: "0.5s",
          //   backgroundSize: "200% auto",
          //   color: "white",
          //   boxShadow: "0 0 20px #eee",
          //   display: "block",
          // }}
          // _hover={{ backgroundPosition: "right center" }}
          // border={`1px solid ${themePrimaryColor}`}
          src={Logo}
        />
        <Heading
          fontSize={["1.7rem", "1.85rem", "1.9rem", "2rem"]}
          textAlign={"center"}
          fontWeight={100}
          fontFamily={" Lora, serif"}
        >
          Choose Your Role
        </Heading>
        <VStack mt={"1rem"} width={["93%", "93%", "90%", "90%"]}>
          <Formik
            initialValues={{}}
            onSubmit={(values) => {
              if (!role) {
                toast({
                  status: "warning",
                  title: "Please select role",
                  isClosable: true,
                  duration: 1800,
                });
                return;
              }
              handleRole();
              // dispatch(authSignupApi({ fullName, role: role, email, password, userName: email })).then((res) => {
              //   if (res?.type == AUTH_SIGNUP_ERROR) {
              //     toast({ status: 'error', title: res.payload, isClosable: true, duration: 1800 });
              //   };
              // })
            }}
          >
            {({ handleSubmit, errors, touched }) => (
              <form style={{ width: "100%" }} onSubmit={handleSubmit}>
                <VStack
                  minH={"27vh"}
                  gap={["1.1rem", "1.3rem", "1.4rem", "2rem"]}
                  align="flex-start"
                  mt={"1.5rem"}
                >
                  {error?.length && (
                    <Alert mt={"1rem"} status="error">
                      <AlertIcon />
                      {error}
                    </Alert>
                  )}
                  <FormControl
                    position={"relative"}
                    isInvalid={!!errors.role && touched.role}
                  >
                    <HStack gap={"0.2rem"} justify={"space-between"}>
                      {Roles?.map((el) => (
                        <Checkbox
                          onChange={(e) => {
                            if (e.target.checked) {
                              setRole(el.id);
                            } else {
                              setRole("");
                            }
                          }}
                          size={["sm", "md", "md", "lg"]}
                          colorScheme="orange"
                          isChecked={role === el.id}
                        >
                          {el.name}
                        </Checkbox>
                      ))}
                    </HStack>
                  </FormControl>
                  <Button
                    _hover={{
                      color: "color",
                      background: "#0064E0",
                    }}
                    type="submit"
                    bg="black"
                    color={"white"}
                    width="full"
                    outline={"none"}
                    border={"none"}
                    cursor={"pointer"}
                    rounded={"0rem"}
                    // h="48px"
                    mt={"1rem"}
                    letterSpacing={"3px"}
                  >
                    Next
                  </Button>
                </VStack>
              </form>
            )}
          </Formik>
        </VStack>
      </VStack>
      <Footer />
    </VStack>
    // D33C99
  );
};

export default Signup;
