import { HStack, Image, Text, VStack } from "@chakra-ui/react";
import React from "react";
import { Link } from "react-router-dom";
import "@splidejs/react-splide/css";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import { v4 } from "uuid";

const ImageSlider = ({
  perPage = 1,
  perMove = 1,
  bannerData,
  gap = "1rem",
  imagesHeight='auto'
}) => {
  return (
    <HStack w={"100%"}>
      <Splide
        options={{
          rewind: true,
          width: "100%",
          gap: gap,
          type: "loop",
          autoplay: true,
          arrows: false,
          pagination: true,
          snap: true,
          speed: 1200,
          perPage: perPage,
          perMove: perMove,
          breakpoints: {
            640: {
              perPage: perPage,
            },
          },
        }}
      >
        {bannerData?.map((el, i) => (
          <SplideSlide w={"100%"} key={v4()} >
            <VStack h={imagesHeight}>
            <Image
              border={"0.1px solid rgb(212,212,212)"}
              rounded={["1.1rem", "1.3rem", "2rem", "1rem"]}
              h={"100%"}
              src={el.img}
              w={"100%"}
            />

            </VStack>
          </SplideSlide>
        ))}
      </Splide>
    </HStack>
  );
};

export default ImageSlider;
