import {
  AUTH_LOGIN_ERROR,
  AUTH_LOGIN_SUCCESS,
  AUTH_LOGOUT_SUCCESS,
  AUTH_SIGNUP_ERROR,
  AUTH_SIGNUP_SUCCESS,
} from "./auth.types";

let token = localStorage.getItem("accessToken") || null;
let adminToken = localStorage.getItem("adminToken") || null;
const initialState = {
  Token: token,
  AdminToken: adminToken,
  loginError: "",
  signupError: "",
  isAuth: token ? true : false,
  isAdmin: adminToken ? true : false,
  signupMessage: "",
  loginMessage: "",
};
export const authReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case AUTH_SIGNUP_ERROR: {
      console.log(payload, "error signup in reducer");
      return {
        ...state,
        signupError: payload,
        signupMessage: "",
      };
    }
    case AUTH_SIGNUP_SUCCESS: {
      localStorage.setItem("accessToken", payload.data.token);
      return {
        ...state,
        signupError: "",
        Token: payload.data.token,
        isAuth: true,
        signupMessage: payload.message,
      };
    }
    case AUTH_LOGIN_ERROR: {
      console.log(payload, "error in login reducer");
      return {
        ...state,
        loginError: payload,
        loginMessage: "",
      };
    }
    case AUTH_LOGIN_SUCCESS: {
      if(payload.data.email==process.env.REACT_APP_ADMIN_ID){
        localStorage.setItem("adminToken", payload.data.token);
        localStorage.removeItem("accessToken");
      }else{
        localStorage.setItem("accessToken", payload.data.token);
        localStorage.removeItem("adminToken");
      }
      return {
        ...state,
        loginError: "",
        Token: payload.data.email==process.env.REACT_APP_ADMIN_ID?null:payload.data.token,
        isAuth: payload.data.email!==process.env.REACT_APP_ADMIN_ID,
        isAdmin: payload.data.email===process.env.REACT_APP_ADMIN_ID,
        AdminToken:payload.data.email==process.env.REACT_APP_ADMIN_ID?payload.data.token:null,
        loginMessage: payload.message
      };
    }
    case AUTH_LOGOUT_SUCCESS: {
      localStorage.removeItem("accessToken");
      localStorage.removeItem("adminToken");
      return {
        ...state,
        signupMessage: "",
        loginMessage: "",
        loginError: "",
        signupError:"",
        signupMessage:"",
        isAuth: false,
        isAdmin: false,
        Token: null,
        AdminToken: null,
      };
    }

    default: {
      return state;
    }
  }
};
