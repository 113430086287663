import {
  Button,
  HStack,
  Heading,
  VStack,
  Input,
  Text,
  RadioGroup,
  Radio,
  NumberInputField,
  NumberInput,
  InputGroup,
  InputLeftAddon,
  Select,
  Avatar,
  useToast,
  Box,
  Textarea,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { FaEdit } from "react-icons/fa";
import { FiLogOut } from "react-icons/fi";
import { themePrimaryColor } from "../../utils/theme";
import { cities, province } from "../../utils/locations";
import { useDispatch, useSelector } from "react-redux";
import { authLogoutApi } from "../../store/auth/auth.action";
import {
  getUserProfile,
  updateUserProfile,
} from "../../store/user/user.action";
import {
  UPDATE_USER_PROFILE_ERROR,
  UPDATE_USER_PROFILE_SUCCESS,
} from "../../store/user/user.types";
import UploadImageModal from "../../components/UploadImageModal";
import { uploadFileOnFireBase } from "../../utils/utils";
import { DESIGNER_ROLE } from "../../utils/constant";
import { PATCH, apiRequest } from "../../utils/api";

const Setting = () => {
  const {
    loading: ProfileLoading,
    error: ProfileError,
    profileData,
    message,
  } = useSelector((store) => store.userData);
  const { Token } = useSelector((store) => store.authData);
  const toast = useToast();
  const [edit, setEdit] = useState(false);
  const [userData, setUserData] = useState({});
  const [uploadProfileImage, setUploadProfileImage] = useState(false);
  const [uploadImage, setUploadImage] = useState([]);
  const [tempUrl, setTempUrl] = useState("");
  const [password, setPassword] = useState({
    oldPassword: "",
    newPassword: "",
  });
  const [imagesPreview, setImagesPreview] = useState([]);
  const dispatch = useDispatch();
  const handleChangePassword = () => {
    if (!password.oldPassword.length && !password.newPassword.length) {
      toast({
        status: "warning",
        title: "Please enter password first",
        isClosable: true,
        duration: 1200,
      });
      return;
    } else if (password.newPassword.length < 8) {
      toast({
        status: "warning",
        title: "Password must be at least 8 character",
        isClosable: true,
        duration: 1200,
      });
      return;
    }
    apiRequest({
      type: PATCH,
      endpoint: "users/changePassword",
      token: Token,
      data: { ...password, userId: profileData.id },
    })
      .then((res) => {
        console.log(res.data.data);
        toast({ status: "success", title: "Password change successfully" });
      })
      .catch((err) => {
        toast({
          status: "error",
          title: err.response?.data?.message,
          isClosable: true,
          duration: 1000,
        });
        console.log(err.response?.data?.message, "checking error here");
      });
  };
  const handleChange = (event) => {
    if (typeof event === "string") {
      setUserData({
        ...userData,
        gender: event,
      });
    } else {
      const { name, value } = event.target;
      setUserData({
        ...userData,
        [name]: value,
      });
    }
  };
  useEffect(() => {
    if (uploadImage?.length) {
      handleUpdateProfile();
    }
  }, [uploadImage]);
  useEffect(() => {
    setUserData({ ...profileData });
  }, [profileData]);
  const handleLogout = () => {
    dispatch(authLogoutApi(Token));
  };
  const handleUpdateProfile = async () => {
    let profileImageUrl = await uploadFileOnFireBase(uploadImage);
    setTempUrl(profileImageUrl[0]);
    setUserData({ ...userData, profilePicture: profileImageUrl[0] });
    handleSubmit(profileImageUrl[0]);
  };
  const handleSubmit = (profilePicture) => {
    let deleteUnWantedKeys = [
      "is_active",
      "updated_at",
      "updated_by",
      "created_at",
      "password",
      "created_by",
      "role",
      "id",
      'followerCount',
      'followingCount'
    ];
    deleteUnWantedKeys.forEach((el) => {
      delete userData[el];
    });
    if (typeof profilePicture === "string") {
      userData.profilePicture = profilePicture;
    }
    dispatch(
      updateUserProfile(Token, { ...userData, userId: profileData.id })
    ).then((res) => {
      if (res?.type === UPDATE_USER_PROFILE_ERROR) {
        toast({
          status: "error",
          title: res?.payload ?? "Error in updating user",
          isClosable: true,
          duration: 1800,
        });
      } else if (res?.type === UPDATE_USER_PROFILE_SUCCESS) {
        toast({
          status: "success",
          title: res?.payload ?? "User updated",
          isClosable: true,
          duration: 1800,
        });
        dispatch(getUserProfile(Token));
        setImagesPreview([]);
      }
    });
    setEdit(false);
  };
  useEffect(()=>{
    console.log(userData,'user contact');
  },[userData])
  return (
    <HStack
      justify={"center"}
      flexDirection={["column", "column", "row", "row"]}
      w={["100%", "100%", "87%", "78%"]}
      align={["center", "center", "start", "start"]}
      p={"2rem"}
      gap={['2','2.5rem','5rem',"5rem"]}
    >
      <Box border={"1px solid transparent"} position={"relative"}>
        <Box
          cursor={"pointer"}
          as="span"
          onClick={() => setUploadProfileImage(true)}
          position={"absolute"}
          bottom={"0"}
          fontSize={"1.2rem"}
          zIndex={1000}
          right={"0.5rem"}
          color={themePrimaryColor}
        >
          <FaEdit />
        </Box>
        <Avatar
          size={"2xl"}
          src={tempUrl?.length ? tempUrl : profileData.profilePicture}
        />
      </Box>
      <VStack
        align={"start"}
        gap={"1.5rem"}
        w={["99%", "80%", "65%", "65%"]}
        padding={["1rem"]}
        bgColor={'white'}
        // boxShadow={"rgba(0, 0, 0, 0.24) 0px 3px 8px"}
      >
        <Heading fontSize={["1.5rem","1.7rem","2rem","2rem"]} fontWeight={100} fontFamily={" Lora, serif"}>
          Edit Profile
        </Heading>
        <HStack justify={"space-between"} w={"98%"}>
          <VStack w={"100%"} align={"start"} gap={"0.1rem"}>
            <Text fontSize={"0.95rem"} fontWeight={600}>
              Full Name
            </Text>
            <Input
              height={"auto"}
              p={"0.5rem 0.5rem"}
              onChange={handleChange}
              rounded={"3px"}
              type={"text"}
              isDisabled={!edit}
              defaultValue={userData?.fullName}
              name="fullName"
            />
          </VStack>
          {/* <VStack w={"46%"} align={"start"} gap={"0.1rem"}>
            <Text fontSize={"0.95rem"} fontWeight={600}>
              Last Name
            </Text>
            <Input
              height={"auto"}
              p={"0.5rem 0.5rem"}
              onChange={handleChange}
              rounded={"3px"}
              type={"text"}
              isDisabled={!edit}
              defaultValue={userData?.fullName?.split(" ")[1] ?? ''}
              name="lastName"
            />
          </VStack> */}
        </HStack>
        <HStack w={"98%"} justify={"space-between"}>
          <VStack w={"100%"} align={"start"} gap={"0.1rem"}>
            <Text fontSize={"0.95rem"} fontWeight={600}>
              Email
            </Text>
            <Input
              height={"auto"}
              p={"0.5rem 0.5rem"}
              rounded={"3px"}
              type={"email"}
              isDisabled={true}
              defaultValue={userData.email}
              name="email"
            />
          </VStack>
        </HStack>
        <HStack w={"98%"} justify={"space-between"}>
          <VStack w={"100%"} align={"start"} gap={"0.1rem"}>
            <Text fontSize={"0.95rem"} fontWeight={600}>
              Address
            </Text>
            <Input
              height={"auto"}
              p={"0.5rem 0.5rem"}
              rounded={"3px"}
              type={"text"}
              name="location"
              defaultValue={profileData.location}
              isDisabled={!edit}
              onChange={handleChange}
            />
          </VStack>
        </HStack>
        <HStack w={"98%"} justify={"space-between"}>
          <VStack w={"100%"} align={"start"} gap={"0.1rem"}>
            <Text fontSize={"0.95rem"} fontWeight={600}>
              Contact Number
            </Text>
            <InputGroup size="sm">
              <NumberInput
                value={userData?.contact??''}
                isDisabled={!edit}
                w={"100%"}
              >
                <NumberInputField
                  name="contact"
                  height={"auto"}
                  onChange={handleChange}
                  p={"0.5rem 0.5rem"}
                  rounded={"3px"}
                />
              </NumberInput>
            </InputGroup>
          </VStack>
        </HStack>
        <VStack align={"start"} gap={"0.1rem"}>
          <Text fontSize={"0.95rem"} fontWeight={600}>
            Gender
          </Text>
          <RadioGroup
            isDisabled={!edit}
            onChange={handleChange}
            value={userData.gender}
            name="gender"
          >
            <HStack gap={['0.4rem','0.5rem','2rem',"2rem"]}>
              <Radio value="male">Male</Radio>
              <Radio value="female">Female</Radio>
              <Radio value="other">Other</Radio>
            </HStack>
          </RadioGroup>
        </VStack>
        <HStack justify={"space-between"} w={"98%"}>
          <VStack w={"100%"} align={"start"} gap={"0.1rem"}>
            <Text fontSize={"0.95rem"} fontWeight={600}>
              Country
            </Text>
            <Select
              onChange={handleChange}
              name="country"
              placeholder="India"
              isDisabled={!edit}
              h={"2.7rem"}
              rounded={"3px"}
            ></Select>
          </VStack>
        </HStack>
        <HStack
          justify={"space-between"}
          w={"98%"}
          flexDirection={["column", "column", "row", "row"]}
        >
          <VStack
            w={["100%", "100%", "46%", "46%"]}
            align={"start"}
            gap={"0.1rem"}
          >
            <Text fontSize={"0.95rem"} fontWeight={600}>
              City
            </Text>
            <Select
              onChange={handleChange}
              name="city"
              h={"2.7rem"}
              value={userData?.city}
              isDisabled={!edit}
              rounded={"3px"}
            >
              {cities?.map((el, i) => (
                <option key={el + i} value={el}>
                  {el}
                </option>
              ))}
            </Select>
          </VStack>
          <VStack
            w={["100%", "100%", "46%", "46%"]}
            align={"start"}
            gap={"0.1rem"}
          >
            <Text fontSize={"0.95rem"} fontWeight={600}>
              Province
            </Text>
            <Select
              onChange={handleChange}
              name="province"
              h={"2.7rem"}
              value={userData?.province}
              rounded={"3px"}
              isDisabled={!edit}
            >
              {province?.map((el) => (
                <option key={el.label} value={el.value}>
                  {el.label}
                </option>
              ))}
            </Select>
          </VStack>
        </HStack>
        <HStack w={"98%"} justify={"space-between"}>
          <VStack w={"100%"} align={"start"} gap={"0.1rem"}>
            <Text fontSize={"0.95rem"} fontWeight={600}>
              Bio
            </Text>
            <Textarea
              minH={"2rem"}
              p={"0.5rem 0.5rem"}
              rounded={"3px"}
              type={"text"}
              name="bio"
              defaultValue={userData.bio}
              isDisabled={!edit}
              onChange={handleChange}
            />
          </VStack>
        </HStack>
        <HStack w={"98%"} justify={"space-between"}>
          {profileData.role === DESIGNER_ROLE && (
            <VStack w={"100%"} align={"start"} gap={"0.1rem"}>
              <Text fontSize={"0.95rem"} fontWeight={600}>
                Brand Name
              </Text>
              <Input
                height={"auto"}
                p={"0.5rem 0.5rem"}
                rounded={"3px"}
                type={"text"}
                name="brandName"
                defaultValue={profileData.brandName}
                isDisabled={!edit}
                onChange={handleChange}
              />
            </VStack>
          )}
        </HStack>
        <HStack w={"98%"} justify={"space-between"}>
          <VStack w={"100%"} align={"start"} gap={"0.1rem"}>
            <Text fontSize={"0.95rem"} fontWeight={600}>
              Educational Qualification
            </Text>
            <Input
              height={"auto"}
              p={"0.5rem 0.5rem"}
              rounded={"3px"}
              type={"text"}
              name="educationalQualification"
              defaultValue={userData.educationalQualification}
              isDisabled={!edit}
              onChange={handleChange}
            />
          </VStack>
        </HStack>
        <HStack w={"98%"} justify={"space-between"}>
          <VStack w={"100%"} align={"start"} gap={"0.1rem"}>
            <Text fontSize={"0.95rem"} fontWeight={600}>
              Hobbies
            </Text>
            <Input
              height={"auto"}
              p={"0.5rem 0.5rem"}
              rounded={"3px"}
              type={"text"}
              name="hobbies"
              defaultValue={userData.hobbies}
              isDisabled={!edit}
              onChange={handleChange}
            />
          </VStack>
        </HStack>
        <HStack gap={"1.5rem"}>
          {!edit && (
            <Button
              rounded={"0"}
              bg={themePrimaryColor}
              color={"white"}
              w={['7rem','10rem','10rem',"10rem"]}
              m={"auto"}
              mt={"1rem"}
              onClick={() => setEdit(!edit)}
            >
              Edit
            </Button>
          )}
          {edit && (
            <>
              <Button
                // bg={themePrimaryColor}
                border={`1.5px solid ${themePrimaryColor}`}
                w={['5rem','10rem','10rem',"10rem"]}
                m={"auto"}
                color={themePrimaryColor}
                rounded={"3px"}
                mt={"1rem"}
                variant="outline"
                onClick={() => setEdit(!edit)}
              >
                Cancel
              </Button>
              <Button
                bg={themePrimaryColor}
                color={"white"}
                w={['5rem','10rem','10rem',"10rem"]}
                m={"auto"}
                rounded={"3px"}
                mt={"1rem"}
                type="submit"
                onClick={handleSubmit}
              >
                Save
              </Button>
            </>
          )}
        </HStack>
        {/* <Heading
          mt={"1.5rem"}
          fontSize={["1.5rem","1.7rem","2rem","2rem"]}
          fontWeight={100}
          fontFamily={" Lora, serif"}
        >
          Privacy Settings
        </Heading>
        <HStack justify={"space-between"} w={"98%"}>
          <VStack w={"46%"} align={"start"} gap={"0.1rem"}>
            <Text fontSize={"0.95rem"} fontWeight={600}>
              Visible to
            </Text>
            <Select
              onChange={handleChange}
              name="visible"
              h={"2.7rem"}
              rounded={"3px"}
            >
              <option value="true">Public</option>
              <option value="false">Only Friends</option>
            </Select>
          </VStack>
        </HStack> */}
        <Heading
          mt={"1.5rem"}
          fontSize={["1.5rem","1.7rem","2rem","2rem"]}
          fontWeight={100}
          fontFamily={" Lora, serif"}
        >
          Security Settings
        </Heading>
        <HStack justify={"space-between"} w={"98%"}>
          <VStack
            w={["100%", "100%", "46%", "46%"]}
            align={"start"}
            gap={"0.1rem"}
          >
            <Text fontSize={"0.95rem"} fontWeight={600}>
              Old Password
            </Text>
            <Input
              height={"auto"}
              p={"0.5rem 0.5rem"}
              rounded={"3px"}
              type={"text"}
              name="oldPassword"
              onChange={(e) =>
                setPassword({ ...password, oldPassword: e.target.value })
              }
            />
          </VStack>
        </HStack>
        <HStack
          justify={"space-between"}
          alignItems={'start'}
          flexDirection={["column", "column", "row", "row"]}
          w={"98%"}
        >
          <VStack
            w={["100%", "100%", "46%", "46%"]}
            align={"start"}
            gap={"0.1rem"}
          >
            <Text fontSize={"0.95rem"} fontWeight={600}>
              New Password
            </Text>
            <Input
              height={"auto"}
              p={"0.5rem 0.5rem"}
              rounded={"3px"}
              type={"text"}
              name="newPassword"
              onChange={(e) =>
                setPassword({ ...password, newPassword: e.target.value })
              }
            />
          </VStack>
          <Button
            bg={themePrimaryColor}
            color={"white"}
            w={['auto','10rem','10rem',"10rem"]}
            rounded={"3px"}
            mb={['1rem','1rem','auto','auto']}
            mt={"1rem"}
            type="submit"
            onClick={handleChangePassword}
          >
            Change Password
          </Button>
        </HStack>
        <Button
          onClick={handleLogout}
          bgColor={themePrimaryColor}
          color={"white"}
          rightIcon={<FiLogOut />}
        >
          Logout
        </Button>
      </VStack>
      <UploadImageModal
        imagesPreview={imagesPreview}
        setImagesPreview={setImagesPreview}
        isUploadSingle={true}
        setFiles={setUploadImage}
        isOpen={uploadProfileImage}
        setOpenModal={setUploadProfileImage}
      />
    </HStack>
  );
};

export default Setting;
