import {
    HStack,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalOverlay,
    Image as ChakraImage,
    VStack,
    Modal,
    Input,
    Box,
    Button,  
    ModalHeader  
  } from "@chakra-ui/react";
  import React, { useRef, useState } from "react";
  import { themePrimaryColor } from "../utils/theme";
  import { AiOutlineCloudUpload } from "react-icons/ai";
  import { IoClose } from "react-icons/io5";
  const UploadVideoModal = ({
    setOpenModal,
    isOpen,
    setFiles,
    isUploadSingle = false,
    videoPreview,
    setVideoPreview,
  }) => {
    const inputRef = useRef();
    const [selectedFiles,setSelectedFiles] = useState([]);
    const ClosePostModal = () => {
      setOpenModal(false);
      setVideoPreview('');
      setFiles([]);
    };
    const handleChangeImage = (e) => {
      let files = [...e.target.files];
      const videoPreviewURL = files.map((file) => {
        return URL.createObjectURL(file);
      });
      setSelectedFiles(files);
      setVideoPreview?.(videoPreviewURL);
    };
    const handleUploadImage = () => {
      setFiles(selectedFiles);
      setOpenModal(false);
    };
      const RemoveVideo = () => {
          if(selectedFiles.length===1){
              setVideoPreview('');
              inputRef.current.value=null;
          }
      };
    return (
      <Modal onClose={ClosePostModal} isOpen={isOpen} isCentered>
        <ModalOverlay />
        <ModalContent h={['70vh','70vh','75vh',"85vh"]} minW={"50% !important"}>
          <ModalHeader
            textAlign={"center"}
            borderBottom={"0.2px solid #f5f1f1"}
          ></ModalHeader>
          <ModalCloseButton
            onClick={() => setOpenModal(false)}
            background={"white !important"}
            zIndex={100}
          />
          <ModalBody h={"100%"}>
            <VStack
              h={"70vh"}
              pb={"1.5rem"}
              justify={"space-between"}
              position={"relative"}
              align={"center"}
            >
              {!videoPreview?.length && (
                <HStack        w={'100%'} justify={'center '}  top={"35%"}
                position={"absolute"}
                >
                <Box
                  w={["80%", "70%", "55%", "50%"]}
                  h={"6rem"}
                  cursor={"pointer"}
                  rounded={"0.2rem"}
                  border={`1px solid ${themePrimaryColor}`}
                  display={"flex"}
                  flexDirection={"column"}
                  alignItems={"center"}
                  as={"label"}
                  htmlFor="my_video"
                  >
                  <Box as={AiOutlineCloudUpload}
                  color={themePrimaryColor}
                  fontSize={['2.8rem','2.8rem','3rem','3.5rem']}
                    />
                  Click here to upload video
                </Box>
            </HStack>
              )}
              <Input
                ref={inputRef}
                accept="video/*"
                display={"none"}
                id="my_video"
                onChange={handleChangeImage}
                type="file"
                multiple={false}
              />
              <Box h={['45vh','45vh','50vh',"60vh"]}>
              {videoPreview&&<ChakraImage h={"100%"} controls src={videoPreview} as={'video'} />}  
              </Box>
              <HStack flexWrap={'wrap'}>
                {
                  videoPreview?.length&& (
                    <HStack
                      border={"1px solid #f1f1f1"}
                      position={"relative"}

                    >
                      <Box
                        as={IoClose}
                        onClick={()=>RemoveVideo()}
                        color={"black"}
                        fontSize={["1.2rem"]}
                        top={"-0.45rem"}
                        cursor={'pointer'}
                        zIndex={1000}
                        right={"-0.5rem"}
                        position={"absolute"}
                      />
                      <HStack
                        cursor={"pointer"}
                        p={"0.5rem"}
                        justify={"center"}
                        align={"center"}
                        w={['3.5rem','3.8rem','4.5rem',"4.5rem"]}
                        h={['3.5rem','3.8rem','4.5rem',"4.5rem"]} 
                        border={ `2.5px solid ${themePrimaryColor}`}
                      >
                        <ChakraImage w={"70%"} as={'video'} src={videoPreview} />
                      </HStack>
                    </HStack>
                  )}
              </HStack>
              <HStack>
                <Button
                  bg={themePrimaryColor}
                  onClick={handleUploadImage}
                  color={"white"}
                >
                  Upload
                </Button>
                <Button
                  bg={themePrimaryColor}
                  onClick={() => {
                    if (setVideoPreview) setVideoPreview([]);
                    setFiles([]);
                    ClosePostModal();
                  }}
                  color={"white"}
                >
                  Cancel
                </Button>
              </HStack>
            </VStack>
          </ModalBody>
        </ModalContent>
      </Modal>
    );
  };
  
  export default UploadVideoModal;
  