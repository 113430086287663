import axios from "axios";
import { DELETE, GET, POST, UPDATE, apiRequest, baseurl } from "../../utils/api";
import { unAuthorisedApi } from "../auth/auth.action";
import { CREATE_COMMENT_ERROR, CREATE_COMMENT_LOADING, CREATE_COMMENT_SUCCESS, DELETE_COMMENT_ERROR, DELETE_COMMENT_LOADING, DELETE_COMMENT_SUCCESS, GET_COMMENT_BY_POST_ERROR, GET_COMMENT_BY_POST_LOADING, GET_COMMENT_BY_POST_SUCCESS } from "./comment.types";
import { getAllUsersBehalfId, getObjectsWithUniqueUserIds } from "../../utils/utils";
import { UPDATE_POST_COMMENT_COUNT_SUCCESS } from "../post/post.types";
// export const getUserProfile = (token) => async (dispatch) => {
//   dispatch({ type: USER_PROFILE_LOADING });
//   try {
//     const res = await apiRequest({ type: GET, endpoint: 'auth/get-user-by-token', token });
//     return dispatch({ type: USER_PROFILE_SUCCESS, payload: res.data });
//   } catch (err) {
//     err = err.response?.data?.message
//       ? err.response?.data?.message
//       : err.message;
//     dispatch(unAuthorisedApi(err));
//     return dispatch({
//       type: USER_PROFILE_ERROR,
//       payload: err
//     });
//   }
// };



export const getCommentsByUser = (token,postId) => async (dispatch) => {
  try {
    dispatch({ type: GET_COMMENT_BY_POST_LOADING});
    const res = await apiRequest({ type: GET, endpoint: `comments/commentsByPost/${postId}`, token });
    const uniqueUserIds = getObjectsWithUniqueUserIds(res.data.data);
    const uniqueUsers = await getAllUsersBehalfId(uniqueUserIds,token);
    const uniqueUserDataMap = {};
    uniqueUsers.forEach(user => {
      uniqueUserDataMap[user.id] = {
            email: user.email,
            profilePicture: user.profilePicture,
            role: user.role,
            fullName:user.fullName
        };
    });
    const usersInfoAddedInCommentsData = res.data.data.map(item => {
      const userData = uniqueUserDataMap[item.userId];
      return {
          ...item,
          userData: userData
      };
    });
    return dispatch({ type: GET_COMMENT_BY_POST_SUCCESS, payload: usersInfoAddedInCommentsData});
  } catch (err) {
    err = err.response?.data?.message
      ? err.response?.data?.message
      : err.message;
    dispatch(unAuthorisedApi(err));
    return dispatch({
      type: GET_COMMENT_BY_POST_ERROR,
      payload: err
    });
  }
};
export const createCommentApi = (token,data,userData) => async (dispatch) => {
  try {
    dispatch({ type: CREATE_COMMENT_LOADING});
    const res = await apiRequest({ type: POST, endpoint: 'comments/addCommentToPost', token,data });
    res.data.data.userData = userData;
    dispatch({type:UPDATE_POST_COMMENT_COUNT_SUCCESS,payload:{postId:data.postId,value:1}});
    return dispatch({ type: CREATE_COMMENT_SUCCESS, payload: res.data.data});
  } catch (err) {
    err = err.response?.data?.message
      ? err.response?.data?.message
      : err.message;
    dispatch(unAuthorisedApi(err));
    return dispatch({
      type: CREATE_COMMENT_ERROR,
      payload: err
    });
  }
};
export const deleteCommentApi = (token,id,postId) => async (dispatch) => {
  try {
    dispatch({ type: DELETE_COMMENT_LOADING});
    const res = await apiRequest({ type: DELETE, endpoint: `comments/deleteComment/${id}`, token });
    dispatch({type:UPDATE_POST_COMMENT_COUNT_SUCCESS,payload:{postId,value:-1}});
    return dispatch({ type: DELETE_COMMENT_SUCCESS,payload:id});
  } catch (err) {
    err = err.response?.data?.message
      ? err.response?.data?.message
      : err.message;
    console.log(err,'error in delete comment api');
    dispatch(unAuthorisedApi(err));
    return dispatch({
      type: DELETE_COMMENT_ERROR,
      payload: err
    });
  }
};
// export const doLikeApi = (token,data) => async (dispatch) => {
//   try {
//     dispatch({ type: LIKE_POST_LOADING});
//     const res = await apiRequest({ type: POST, endpoint: 'posts/likePost', token,data });
//     return dispatch({ type: LIKE_POST_SUCCESS, payload: res.data.message});
//   } catch (err) {
//     err = err.response?.data?.message
//       ? err.response?.data?.message
//       : err.message;
//     dispatch(unAuthorisedApi(err));
//     return dispatch({
//       type: LIKE_POST_ERROR,
//       payload: err
//     });
//   }
// };
// export const updateUserProfile = (token,data) => async (dispatch) => {

//   try {
//     dispatch({ type: UPDATE_USER_PROFILE_LOADING});
//     console.log(data,'chekcing user data here');
//     const res = await apiRequest({ type: UPDATE, endpoint: 'users/updateUser', token,data });
//     return dispatch({ type: UPDATE_USER_PROFILE_SUCCESS, payload: res.data.message});
//   } catch (err) {
//     err = err.response?.data?.message
//       ? err.response?.data?.message
//       : err.message;
//     dispatch(unAuthorisedApi(err));
//     return dispatch({
//       type: UPDATE_USER_PROFILE_ERROR,
//       payload: err
//     });
//   }
// };