import { Box, HStack, VStack } from '@chakra-ui/react';
import React, { useContext, useRef, useState } from 'react';
import ReactPlayer from 'react-player';
import { AppContext } from '../context/AppContext';
import { MdZoomInMap, MdZoomOutMap } from 'react-icons/md';
import { AiOutlineLike } from 'react-icons/ai';
function formatTime(seconds) {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds}`;
  }
  
const VideoPlayer = ({url,isGeneral=false,Component}) => {
    const playerRef = useRef(null);
    const [playing, setPlaying] = useState(false);
    const [played, setPlayed] = useState(0);
    const [currentTime,setCurrentTime] = useState(0);
    const {handlePlay,setFullScreen,fullScreen,FooterComponent,setFooterComponent} = useContext(AppContext);
    const [totalTime,setTotalTime] = useState(0);
    const handlePlayPause = () => {
      setPlaying(!playing);
    };
    const handleProgress = (state) => {
        setPlayed(state.played);
        setCurrentTime(state.playedSeconds);
        setTotalTime(state.loadedSeconds);
      };
    
      const handleSeekChange = (event) => {
        console.log('ram ram here');
        setPlayed(parseFloat(event.target.value));
        playerRef.current.seekTo(parseFloat(event.target.value));
      };
  return (
    <VStack h={fullScreen.length?'100vh':'65vh'} w={'100%'}  bgColor={'black'} align={'center'} minH={'40vh'} rounded={'0.2rem'}  >
      <HStack justify={'center'} zIndex={1000} position={'relative'} height={'100%'} w={fullScreen.length?['100%']:['100%','90%','50%','50%']} onClick={handlePlayPause}>
      <ReactPlayer
        ref={playerRef}
        url={url}
        controls={false} // Disable default controls
        width="100%"
        height="100%"
        className="video-player"
        onPlay={()=>{
          handlePlay?.(playerRef);
         }}
        playing={playing}
        volume={1} // Set volume to 100%
        onProgress={handleProgress}
      />
      <VStack gap={0} position={'absolute'} bottom={fullScreen.length?'0px':'10px'} w={fullScreen.length?['96%']:['95%','90%','80%','80%']} align={'start'} p={'0rem 1rem'} className="custom-controls">
        <Box as='span'color={'white'} bgColor={'rgba(95, 94, 92, 0.055)'} >{formatTime(Math.round(currentTime))}/{formatTime(Math.round(totalTime))}</Box>
        <input
          type="range"
          min="0"
          max="1"
          style={{width:'100%'}}
          step="0.01"
          value={played}
          onChange={handleSeekChange}
        />
      </VStack>
     {isGeneral&&<Box onClick={()=>{  
      setFullScreen(fullScreen.length?"":url)
      if(Component){
        setFooterComponent(Component);
      }
      setFooterComponent(fullScreen.length?null:Component);
      }} as={fullScreen.length?MdZoomOutMap:MdZoomInMap} position={'absolute'} bottom={'1.6rem'} right={['1rem','1rem','2.5rem','2.8rem']} cursor={'pointer'} color={'white'} fontSize={'1.5rem'}/>} 
      {fullScreen.length&& <VStack position={'absolute'} right={0} gap={'2rem'} top={'30%'} >
        <AiOutlineLike/>
      </VStack>}
      {FooterComponent}

      </HStack>
    </VStack>
  )
}
export default VideoPlayer
