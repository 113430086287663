import {
  SimpleGrid,
} from "@chakra-ui/react";
import React, { useEffect } from "react";

import CreationCard from "./CreationCard";
import { v4 } from "uuid";
const CreationGrid = ({ creationData,isCollection=false }) => {

  return (
    <SimpleGrid my={"2rem"} gap={['0.5rem','0.6rem','1rem',"1rem"]} columns={[3]}>
      {creationData.map((el) => (
          <CreationCard key={v4()} isCollection={isCollection} data={el} />
        ))}
    </SimpleGrid>
  );
};

export default CreationGrid;
