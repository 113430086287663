import React from 'react'
import { useSelector } from 'react-redux'
import { Navigate } from 'react-router-dom';

const AdminAuth = ({ children }) => {
  const { isAdmin } = useSelector((store) => store.authData);
  if (!isAdmin) {
    return <Navigate to={'/login'} />
  }
  return children
}

export default AdminAuth