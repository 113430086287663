import React, { useEffect } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { AUTH_LOGIN_SUCCESS } from '../../store/auth/auth.types';

const OAuth = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    let token = searchParams.get("token");
    if (token) {
      console.log('token,checking token here',token);
      dispatch({ type: AUTH_LOGIN_SUCCESS, payload: { data:{message:"Succesfully signin",token} } });
      navigate("/trending");
    } else {
      navigate("/");
    }
  }, [])
  return (
    <></>
  );
}

export default OAuth