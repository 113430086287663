import { storage } from "../firebaseFile";
import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";
import { useSelector } from "react-redux";
import { GET, apiRequest } from "./api";

export const uploadFileOnFireBase = async (files) => {
  const imagesStoredUrls = await Promise.all(
    files?.map(async (file, index) => {
      const storageRef = ref(storage, `files/${file.name}`);
      const uploadTask = uploadBytesResumable(storageRef, file);
      let downloadUrl = await new Promise((resolve, reject) => {
        uploadTask.on(
          "state_changed",
          (snapshot) => {
            const progress = Math.round(
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100
            );
            console.log(progress, file.name, "image number", index + 1);
          },
          (error) => {
            console.log(error);
            reject(error); // Reject the promise in case of an error
          },
          async () => {
            const url = await getDownloadURL(uploadTask.snapshot.ref);
            resolve(url); // Resolve the promise with the download URL
          }
        );
      });
      return downloadUrl;
    })
  );
  return imagesStoredUrls;
};

export function getObjectsWithUniqueUserIds(data,isFollowers) {
  const uniqueObjects = {};
  data = data.filter((item)=>{
    let userId = isFollowers?item.followerId:item.userId
    return !isNaN(userId);
  });
  data.forEach((item) => {
    let userId = isFollowers?item.followerId:item.userId
    if (!uniqueObjects[userId]) {
      uniqueObjects[userId] = userId;
    }
  });
  return Object.values(uniqueObjects);
}
// Get all user Behalf Id
export function getAllUsersBehalfId(uniqueUserIds, token) {
  uniqueUserIds = uniqueUserIds.filter((item)=>{
    return (!isNaN(item)&&item!=null);
  });
  return Promise.all(
    uniqueUserIds.map(async (userId) => {
      const userData = await apiRequest({
        type: GET,
        endpoint: `users/getUserById/${userId}`,
        token,
      });
      return userData.data.data;
    })
  );
}
export const useApiToken = async () => {
  const { signInData } = useSelector((state) => ({
    ...state.auth,
  }));

  return signInData?.Table?.token || localStorage.getItem("token");
};

export const apiUrl =
  process.env.REACT_APP_NODE_ENV === "production"
    ? process.env.REACT_APP_PROD_URL
    : process.env.REACT_APP_DEV_URL;
