//Get post types
export const GET_SAVED_POSTS_LOADING = "get/saved-posts/loading";
export const GET_SAVED_POSTS_SUCCESS= "get/saved-posts/success";
export const GET_SAVED_POSTS_ERROR = "get/saved-posts/error";

//add saved post types
export const ADD_SAVED_POST_LOADING = "create/saved-post/loading";
export const ADD_SAVED_POST_SUCCESS= "create/saved-post/success";
export const ADD_SAVED_POST_ERROR = "create/saved-post/error";

//Update saved post types
export const UPDATE_SAVED_POST_LOADING = "update/saved-post/loading";
export const UPDATE_SAVED_POST_SUCCESS= "update/saved-post/success";
export const UPDATE_SAVED_POST_ERROR = "update/saved-post/error";