export  const province = [
    { label: "Andaman and Nicobar Islands", value: "AN" },
    { label: "Andhra Pradesh", value: "AP" },
    { label: "Arunachal Pradesh", value: "AR" },
    { label: "Assam", value: "AS" },
    { label: "Bihar", value: "BR" },
    { label: "Chandigarh", value: "CG" },
    { label: "Chhattisgarh", value: "CH" },
    { label: "Dadra and Nagar Haveli", value: "DN" },
    { label: "Daman and Diu", value: "DD" },
    { label: "Delhi", value: "DL" },
    { label: "Goa", value: "GA" },
    { label: "Gujarat", value: "GJ" },
    { label: "Haryana", value: "HR" },
    { label: "Himachal Pradesh", value: "HP" },
    { label: "Jammu and Kashmir", value: "JK" },
    { label: "Jharkhand", value: "JH" },
    { label: "Karnataka", value: "KA" },
    { label: "Kerala", value: "KL" },
    { label: "Ladakh", value: "LA" },
    { label: "Lakshadweep", value: "LD" },
    { label: "Madhya Pradesh", value: "MP" },
    { label: "Maharashtra", value: "MH" },
    { label: "Manipur", value: "MN" },
    { label: "Meghalaya", value: "ML" },
    { label: "Mizoram", value: "MZ" },
    { label: "Nagaland", value: "NL" },
    { label: "Odisha", value: "OR" },
    { label: "Puducherry", value: "PY" },
    { label: "Punjab", value: "PB" },
    { label: "Rajasthan", value: "RJ" },
    { label: "Sikkim", value: "SK" },
    { label: "Tamil Nadu", value: "TN" },
    { label: "Telangana", value: "TS" },
    { label: "Tripura", value: "TR" },
    { label: "Uttar Pradesh", value: "UP" },
    { label: "Uttarakhand", value: "UK" },
    { label: "West Bengal", value: "WB" },
  ];
  export const cities = ["SGM",
    "STR",
    "A.Thirumuruganpoondi",
    "A.Vellalapatti",
    "Aadityana",
    "Aambaliyasan",
    "Aantaliya",
    "Aarambhada",
    "Abhayapuri",
    "Abiramam",
    "Abohar",
    "Abrama",
    "Abu Road",
    "Achabal",
    "Achalpur",
    "Achampudur",
    "Acharapakkam",
    "Acharipallam",
    "Achhalda",
    "Achhnera",
    "Achipatti",
    "Adalaj",
    "Adampur",
    "Adari",
    "Adikaratti",
    "Adilabad",
    "Adiramapattinam",
    "Adityapatna",
    "Adityapur",
    "Adoni",
    "Adoor",
    "Adra",
    "Aduthurai alias Maruthuvakudi",
    "Adyar",
    "Afzalgarh",
    "Afzalpur",
    "Agar",
    "Agaram",
    "Agartala",
    "Agarwal Mandi",
    "Agastheeswaram",
    "Agra",
    "Agra",
    "Ahiwara",
    "Ahmadnagar",
    "Ahmadnagar",
    "Ahmadpur",
    "Ahmadpur",
    "Ahmedabad",
    "Ahmedabad Cantonment",
    "Ahmedgarh",
    "Ahraura",
    "Aiho",
    "Ailum",
    "Air Force Area",
    "Aistala",
    "Aizawl",
    "Ajaigarh",
    "Ajhuwa",
    "Ajmer",
    "Ajnala",
    "Ajra",
    "Akalgarh",
    "Akaltara",
    "Akathiyoor",
    "Akbarpur",
    "Akbarpur",
    "Akhnoor",
    "Akkalkot",
    "Akkarampalle",
    "Akkayapalle",
    "Aklera",
    "Akoda",
    "Akodia",
    "Akola",
    "Akot",
    "Alagappapuram",
    "Alampalayam",
    "Alampur",
    "Aland",
    "Alandi",
    "Alandur",
    "Alang",
    "Alanganallur",
    "Alangayam",
    "Alangudi",
    "Alangulam",
    "Alangulam",
    "Alanthurai",
    "Alapakkam",
    "Alappuzha",
    "Alawalpur",
    "Aldona",
    "Ali Pur",
    "Alibag",
    "Aliganj",
    "Aligarh",
    "Alipurduar",
    "Alipurduar Rly.Jnc.",
    "Alirajpur",
    "Allahabad",
    "Allahabad",
    "Allahganj",
    "Allapur",
    "Allapuram",
    "Almora",
    "Almora",
    "Alnavar",
    "Along",
    "Alot",
    "Alpur",
    "Alur",
    "Alur",
    "Aluva",
    "Alwal",
    "Alwar",
    "Alwarkurichi",
    "Alwarthirunagiri",
    "Amadalavalasa",
    "Amalapuram",
    "Amalner",
    "Amanganj",
    "Amanpur",
    "Amarkantak",
    "Amarpatan",
    "Amarpur",
    "Amarpur",
    "Amarwara",
    "Ambad",
    "Ambada",
    "Ambagarh Chowki",
    "Ambah",
    "Ambaji",
    "Ambala",
    "Ambala Cantt.",
    "Ambala Sadar",
    "Ambarnath",
    "Ambasamudram",
    "Ambassa",
    "Ambattur",
    "Ambehta",
    "Ambejogai",
    "Ambikanagara",
    "Ambikapur",
    "Ambikapur Part-X",
    "Ambivali Tarf Wankhal",
    "Ambur",
    "Amet",
    "Amethi",
    "Amethi",
    "Amguri",
    "Amila",
    "Amilo",
    "Aminagar Sarai",
    "Aminagar Urf Bhurbaral",
    "Amini",
    "Amkula",
    "Amla",
    "Amlabad",
    "Amlai",
    "Amli",
    "Amloh",
    "Ammainaickanur",
    "Ammapettai",
    "Ammapettai",
    "Ammavarikuppam",
    "Ammoor",
    "Amodghata",
    "Amraudha",
    "Amravati",
    "Amreli",
    "Amritsar",
    "Amritsar Cantt.",
    "Amroha",
    "Amtala",
    "Anaimalai",
    "Anaiyur",
    "Anaiyur",
    "Anakapalle",
    "Anakaputhur",
    "Anand",
    "Anand Nagar",
    "Anandapur",
    "Anandnagar",
    "Anandpur Sahib",
    "Anantapur",
    "Ananthapuram",
    "Anantnag",
    "Ancharakandy",
    "Andada",
    "Andipalayam",
    "Andipatti Jakkampatti",
    "Andro",
    "Andul",
    "Anekal",
    "Angamaly",
    "Angarpathar",
    "Anjad",
    "Anjangaon",
    "Anjar",
    "Anjugramam",
    "Anklav",
    "Anklesvar",
    "Anklesvar INA",
    "Ankola",
    "Anksa",
    "Ankurhati",
    "Annamalai Nagar",
    "Annavasal",
    "Annigeri",
    "Annur",
    "Anpara",
    "Antah",
    "Antari",
    "Anthiyur",
    "Antu",
    "Anugul",
    "Anup Nagar",
    "Anupgarh",
    "Anuppur",
    "Anupshahr",
    "Aonla",
    "Appakudal",
    "Aquem",
    "Ara",
    "Arachalur",
    "Arakandanallur",
    "Arakonam",
    "Aralvaimozhi",
    "Arambag",
    "Arang",
    "Arani",
    "Arani",
    "Aranthangi",
    "Araria",
    "Arasiramani",
    "Aravakurichi",
    "Aravankad",
    "Arcot",
    "Areraj",
    "Argari",
    "Arimalam",
    "Ariyalur",
    "Ariyappampalayam",
    "Ariyur",
    "Arkalgud",
    "Arki",
    "Armapur Estate",
    "Arnia",
    "Aron",
    "Arookutty",
    "Aroor",
    "Arra",
    "Arrah",
    "Arsikere",
    "Arumanai",
    "Arumbavur",
    "Arumuganeri",
    "Aruppukkottai",
    "Arvi",
    "Asan Khurd",
    "Asansol",
    "Asarganj",
    "Ashok Nagar",
    "Ashokapuram",
    "Ashoknagar Kalyangarh",
    "Ashrafpur Kichhauchha",
    "Ashta",
    "Ashta",
    "Asifabad",
    "Asika",
    "Asind",
    "Asola",
    "Assandh",
    "Atarra",
    "Atasu",
    "Ateli",
    "Athagad",
    "Athani",
    "Athanur",
    "Athimarapatti",
    "Athipattu",
    "Athmallik",
    "Athni",
    "Athur",
    "Athur",
    "Atrauli",
    "Atraulia",
    "Attayampatti",
    "Attingal",
    "Attur",
    "Atul",
    "Aurad",
    "Auraiya",
    "Aurangabad",
    "Aurangabad",
    "Aurangabad",
    "Aurangabad",
    "Aurangabad",
    "Aurangabad Bangar",
    "Auras",
    "Ausa",
    "Avadattur",
    "Avadi",
    "Avalpoondurai",
    "Avanashi",
    "Avaniapuram",
    "Avinissery",
    "Awagarh",
    "Awantipora",
    "Ayakudi",
    "Aygudi",
    "Ayodhya",
    "Ayothiapattinam",
    "Ayyalur",
    "Ayyampalayam",
    "Ayyampettai",
    "Ayyampettai",
    "Azamgarh",
    "Azhagiapandiapuram",
    "Azhikode North",
    "Azhikode South",
    "Azizpur",
    "Azmatgarh",
    "B. Mallapuram",
    "B. Meenakshipuram",
    "Babai",
    "Babar Pur",
    "Babarpur Ajitmal",
    "Baberu",
    "Babhulgaon",
    "Babina",
    "Babiyal",
    "Bablari Dewanganj",
    "Babrala",
    "Babua Kalan",
    "Babugarh",
    "Bachhraon",
    "Bachhrawan",
    "Bad",
    "Bada Malhera",
    "Badagaon",
    "Badagoan",
    "Badami",
    "Badami Bagh",
    "Badarpur",
    "Badarpur Rly Town",
    "Badarwas",
    "Badawada",
    "Baddi",
    "Bade Bacheli",
    "Badepalle",
    "Badgam",
    "Badhagachhi",
    "Badharghat",
    "Badhni Kalan",
    "Badi",
    "Badkuhi",
    "Badlapur",
    "Badnagar",
    "Badnawar",
    "Badod",
    "Badoda",
    "Badra",
    "Badrinathpuri",
    "Baduria",
    "Bagaha",
    "Bagalkot",
    "Bagasara",
    "Bagbahara",
    "Bagbera",
    "Bagepalli",
    "Bageshwar",
    "Baggar",
    "Bagh",
    "Bagha Purana",
    "Baghmara",
    "Baghpat",
    "Bagli",
    "Bagnan",
    "Bagru",
    "Bah",
    "Bahadurganj",
    "Bahadurganj",
    "Bahadurgarh",
    "Baharampur",
    "Bahbari Gaon",
    "Baheri",
    "Bahirgram",
    "Bahjoi",
    "Bahraich",
    "Bahsuma",
    "Bahula",
    "Bahuwa",
    "Baidyabati",
    "Baihar",
    "Baikunthpur",
    "Baikunthpur",
    "Bail Hongal",
    "Bairabi",
    "Bairatisal",
    "Bairgania",
    "Bajala",
    "Bajna",
    "Bajpe",
    "Bajpur",
    "Bajva",
    "Bakani",
    "Bakewar",
    "Bakhtiarpur",
    "Bakiabad",
    "Bakloh",
    "Balachaur",
    "Balaghat",
    "Balagoda (Bolani)",
    "Balakrishnampatti",
    "Balakrishnapuram",
    "Balangir",
    "Balapallam",
    "Balapur",
    "Balaram Pota",
    "Balarampur",
    "Balarampur",
    "Balasamudram",
    "Balasinor",
    "Baldeo",
    "Baldeogarh",
    "Baleshwar",
    "Bali",
    "Baliari",
    "Balichak",
    "Balimela",
    "Balkundra",
    "Ballarpur",
    "Ballavpur",
    "Ballia",
    "Bally",
    "Bally",
    "Balod",
    "Baloda",
    "Baloda Bazar",
    "Balotra",
    "Balrampur",
    "Balugaon",
    "Balurghat",
    "Bambolim",
    "Bamhani",
    "Bamor",
    "Bamora",
    "Bamun Sualkuchi",
    "Bamunari",
    "Banapur",
    "Banarhat Tea Garden",
    "Banarsi",
    "Banat",
    "Banaur",
    "Banbasa",
    "Banda",
    "Banda",
    "Bandarulanka",
    "Bandhgora",
    "Bandia",
    "Bandikui",
    "Bandipore",
    "Bandora",
    "Banga",
    "Bangalore",
    "Bangaon",
    "Bangarapet",
    "Bangarmau",
    "Bangawan",
    "Bangramanjeshwar",
    "Bangura",
    "Banihal",
    "Banjar",
    "Banka",
    "Bankapura",
    "Banki",
    "Banki",
    "Bankner",
    "Bankra",
    "Bankura",
    "Banmankhi Bazar",
    "Bannur",
    "Bansatar Kheda",
    "Bansberia",
    "Bansdih",
    "Bansgaon",
    "Banshra",
    "Bansi",
    "Banswara",
    "Bantwa",
    "Bantwal",
    "Banupur",
    "Bapatla",
    "Bara Bamonia",
    "Barabazar",
    "Baragaon",
    "Baragaon",
    "Barahiya",
    "Baraily",
    "Barajamda",
    "Baramati",
    "Baramula",
    "Baran",
    "Baranagar",
    "Barapali",
    "Barasat",
    "Barauli",
    "Barauni IOC Township",
    "Baraut",
    "Barbari (AMC Area)",
    "Barbigha",
    "Barbil",
    "Barddhaman",
    "Bardoli",
    "Bareilly",
    "Bareilly",
    "Barela",
    "Baretta",
    "Bargarh",
    "Barghat",
    "Bargur",
    "Barh",
    "Barhalganj",
    "Barhani Bazar",
    "Barhi",
    "Barhi",
    "Bari",
    "Bari Brahmana",
    "Bari Sadri",
    "Barigarh",
    "Barijhati",
    "Baripada",
    "Bariwala",
    "Barjora",
    "Barkakana",
    "Barkhera",
    "Barkot",
    "Barmer",
    "Barnala",
    "Barpathar",
    "Barpeta",
    "Barpeta Road",
    "Barrackpur",
    "Barrackpur Cantonment",
    "Barsana",
    "Barshi",
    "Barua Sagar",
    "Barughutu",
    "Baruihuda",
    "Baruipur",
    "Barwadih",
    "Barwaha",
    "Barwala",
    "Barwani",
    "Barwar",
    "Basar",
    "Basaria",
    "Basavakalyan",
    "Basavana Bagevadi",
    "Bashohli",
    "Basirhat",
    "Baska",
    "Basmath",
    "Basna",
    "Basni Belima",
    "Basoda",
    "Bassi Pathana",
    "Basti",
    "Basudebpur",
    "Basugaon",
    "Basukinath",
    "Batala",
    "Bathinda",
    "Batlagundu",
    "Batote",
    "Baudhgarh",
    "Bavla",
    "Bawal",
    "Bawana",
    "Bawani Khera",
    "Bayana",
    "Beawar",
    "Bedi",
    "Beejoliya Kalan",
    "Beerwah",
    "Begamganj",
    "Begampur",
    "Begowal",
    "Begum Pur",
    "Begumabad Budhana",
    "Begun",
    "Begusarai",
    "Behat",
    "Behea",
    "Behror",
    "Behta Hajipur",
    "Bela Pratapgarh",
    "Belagachhia",
    "Beldanga",
    "Beldubi",
    "Belebathan",
    "Belgaum",
    "Belgaum Cantonment",
    "Beliatore",
    "Bellaguntha",
    "Bellampalle",
    "Bellary",
    "Belonia",
    "Belpahar",
    "Belsand",
    "Beltangadi",
    "Belthara Road",
    "Belur",
    "Belur",
    "Belvata",
    "Bemetra",
    "Benaulim",
    "Beniganj",
    "Beohari",
    "Berasia",
    "Beri",
    "Bermo",
    "Bestavaripeta",
    "Beswan",
    "Bethamcheria",
    "Betma",
    "Bettiah",
    "Betul",
    "Betul-Bazar",
    "Bewar",
    "Beypore",
    "Bhabat",
    "Bhabua",
    "Bhachau",
    "Bhadarsa",
    "Bhadaur",
    "Bhaderwah",
    "Bhadohi",
    "Bhadra",
    "Bhadrachalam",
    "Bhadrak",
    "Bhadravati",
    "Bhadravati",
    "Bhadreswar",
    "Bhagalpur (M.Corp)",
    "Bhagatdih",
    "Bhagur",
    "Bhagwant Nagar",
    "Bhainsa",
    "Bhainsdehi",
    "Bhalariya",
    "Bhalki",
    "Bhalswa Jahangir Pur",
    "Bhamodi",
    "Bhandara",
    "Bhandardaha",
    "Bhander",
    "Bhangar Raghunathpur",
    "Bhangri Pratham Khanda",
    "Bhanjanagar",
    "Bhankharpur",
    "Bhanowara",
    "Bhanpura",
    "Bhanpuri",
    "Bhanvad",
    "Bharat Heavy Electrical Limited Ranipur",
    "Bharatganj",
    "Bharatpur",
    "Bhargain",
    "Bharoli Kalan",
    "Bharthana",
    "Bharuch",
    "Bharuch INA",
    "Bharuhana",
    "Bharveli",
    "Bharwari",
    "Bhatapara",
    "Bhatgaon",
    "Bhati",
    "Bhatkal",
    "Bhatni Bazar",
    "Bhatpar Rani",
    "Bhatpara",
    "Bhaurasa",
    "Bhavani",
    "Bhavanisagar",
    "Bhavnagar",
    "Bhavra",
    "Bhawan Bahadur Nagar",
    "Bhawani Mandi",
    "Bhawanigarh",
    "Bhawanipatna",
    "Bhayavadar",
    "Bhedaghat",
    "Bheemavaram",
    "Bheemunipatnam",
    "Bhikangaon",
    "Bhikhi",
    "Bhikhiwind",
    "Bhilai Charoda",
    "Bhilai Nagar",
    "Bhilakhedi",
    "Bhilwara",
    "Bhimarayanagudi",
    "Bhimtal",
    "Bhind",
    "Bhinder",
    "Bhinga",
    "Bhingar",
    "Bhinmal",
    "Bhisiana",
    "Bhitarwar",
    "Bhiwadi",
    "Bhiwandi",
    "Bhiwani",
    "Bhogadi",
    "Bhogaon",
    "Bhogpur",
    "Bhojpur Dharampur",
    "Bhojudih",
    "Bhokardan",
    "Bhokarhedi",
    "Bholar Dabri",
    "Bhongir",
    "Bhopal",
    "Bhor",
    "Bhota",
    "Bhowali",
    "Bhowrah",
    "Bhuban",
    "Bhubaneswar",
    "Bhucho Mandi",
    "Bhuj",
    "Bhulath",
    "Bhulepur",
    "Bhuli",
    "Bhum",
    "Bhuntar",
    "Bhusawal",
    "Bhusawar",
    "Bhuvanagiri",
    "Biaora",
    "Biate",
    "Bicholim",
    "Bid",
    "Bidar",
    "Bidasar",
    "Bidhan Nagar",
    "Bidhuna",
    "Bighapur",
    "Bihar",
    "Bihpuria",
    "Bijapur",
    "Bijawar",
    "Bijbehara",
    "Bijeypur",
    "Bijni",
    "Bijnor",
    "Bijpur",
    "Bijuri",
    "Bikaner",
    "Bikapur",
    "Bikihakola",
    "Bikketti",
    "Bikramganj",
    "Bilandapur",
    "Bilara",
    "Bilari",
    "Bilariaganj",
    "Bilasipara",
    "Bilaspur",
    "Bilaspur",
    "Bilaspur",
    "Bilaspur",
    "Bilaspur",
    "Bilaua",
    "Bilgi",
    "Bilgram",
    "Bilha",
    "Bilhaur",
    "Bilimora",
    "Billawar",
    "Biloli",
    "Bilpahari",
    "Bilpura",
    "Bilram",
    "Bilsanda",
    "Bilsi",
    "Bina Etawa",
    "Bina Rly Colony",
    "Bindki",
    "Binika",
    "Bipra Noapara",
    "Biramitrapur",
    "Birgaon",
    "Birlapur",
    "Birnagar",
    "Birpur",
    "Birsinghpur",
    "Birur",
    "Birwadi",
    "Bisalpur",
    "Bisanda Buzurg",
    "Bisauli",
    "Bishama Katek",
    "Bisharatganj",
    "Bishna",
    "Bishnupur",
    "Bishnupur",
    "Bishnupur",
    "Bisokhar",
    "Bissau",
    "Biswan",
    "Biswanath Chariali",
    "Bithoor",
    "Bobbili",
    "Boda",
    "Bodeli",
    "Bodh Gaya",
    "Bodhan",
    "Bodinayakkanur",
    "Bodri",
    "Bohari",
    "Boisar",
    "Bokajan",
    "Bokakhat",
    "Bokaro",
    "Bokaro Steel City",
    "Bollaram",
    "Bolpur",
    "Bombooflat",
    "Bomdila",
    "Bommanahalli",
    "Bommasandra",
    "Bongaigaon",
    "Bongaigaon", "Refinery & Petro-chemical Ltd. Township",
    "Boothapandi",
    "Boothipuram",
    "Bopal",
    "Borgolai Grant No.11",
    "Boriavi",
    "Borsad",
    "Botad",
    "Bowali",
    "Brahmakulam",
    "Brahmana Periya-Agraharam",
    "Brahmapur",
    "Brahmapuri",
    "Brajarajnagar",
    "Budaun",
    "Budge Budge",
    "Budha Theh",
    "Budhana",
    "Budhgaon",
    "Budhlada",
    "Budhni",
    "Budhpura",
    "Bugganipalle",
    "Bugrasi",
    "Buguda",
    "Bulandshahr",
    "Buldana",
    "Bundi",
    "Bundu",
    "Burari",
    "Burhanpur",
    "Burhar",
    "Buria",
    "Burla",
    "Buxar",
    "Buxwaha",
    "Byadgi",
    "Byasanagar",
    "Byatarayanapura",
    "Calangute",
    "Calapor",
    "Canacona",
    "Candolim",
    "Carapur",
    "Cart Road",
    "Chabua",
    "Chachanda",
    "Chachaura-Binaganj",
    "Chaibasa",
    "Chail",
    "Chak Bankola",
    "Chak Bansberia",
    "Chak Enayetnagar",
    "Chak Imam Ali",
    "Chak Kashipur",
    "Chakan",
    "Chakapara",
    "Chakdaha",
    "Chakeri",
    "Chakghat",
    "Chakia",
    "Chakia",
    "Chaklasi",
    "Chakradharpur",
    "Chakrata",
    "Chaksu",
    "Chakulia",
    "Chala",
    "Chala",
    "Chalakudy",
    "Chalala",
    "Chalisgaon",
    "Challakere",
    "Chalthan",
    "Chamba",
    "Chamba",
    "Chamoli Gopeshwar",
    "Champa",
    "Champawat",
    "Champdani",
    "Champhai",
    "Champua",
    "Chamrail",
    "Chamrajnagar",
    "Chanasma",
    "Chandameta-Butaria",
    "Chandannagar",
    "Chandapur",
    "Chandauli",
    "Chandaur",
    "Chandausi",
    "Chanderi",
    "Chandia",
    "Chandigarh",
    "Chandil",
    "Chandili",
    "Chandkheda",
    "Chandla",
    "Chandlodiya",
    "Chandpur",
    "Chandpur",
    "Chandrakona",
    "Chandrapur",
    "Chandrapur Bagicha",
    "Chandrapura",
    "Chandur",
    "Chandur",
    "Chandur",
    "Chandurbazar",
    "Changanassery",
    "Changlang",
    "Channagiri",
    "Channapatna",
    "Channarayapattana",
    "Chanod",
    "Chanpatia",
    "Chapar",
    "Chapari",
    "Chapra",
    "Chapui",
    "Char Brahmanagar",
    "Char Maijdia",
    "Charari Sharief",
    "Charibatia",
    "Charka",
    "Charkhari",
    "Charkhi Dadri",
    "Charthaval",
    "Chas",
    "Chata Kalikapur",
    "Chatakonda",
    "Chatra",
    "Chaumuhan",
    "Chaupal",
    "Chaurai Khas",
    "Chavakkad",
    "Chechakhata",
    "Chechat",
    "Cheeka",
    "Cheema",
    "Chekonidhara",
    "Chelad",
    "Chelora",
    "Chemmumiahpet",
    "Chenani",
    "Chendamangalam",
    "Chengalpattu",
    "Chengam",
    "Chengamanad",
    "Chengannur",
    "Chennai",
    "Chennasamudram",
    "Chennimalai",
    "Cheranallur",
    "Cheranmadevi",
    "Cherapunjee",
    "Cherthala",
    "Cheruthazham",
    "Cheruvannur",
    "Chetpet",
    "Chettiarpatti",
    "Chettipalayam",
    "Chettipalayam",
    "Chettithangal",
    "Chevvoor",
    "Chhabra",
    "Chhachhrauli",
    "Chhapar",
    "Chhaprabhatha",
    "Chhaprauli",
    "Chharchha",
    "Chharra Rafatpur",
    "Chhata",
    "Chhatari",
    "Chhatarpur",
    "Chhatatanr",
    "Chhatral INA",
    "Chhatrapur",
    "Chhawla",
    "Chhaya",
    "Chhibramau",
    "Chhindwara",
    "Chhipabarod",
    "Chhora",
    "Chhota Chhindwara (Gotegaon)",
    "Chhota Gobindpur",
    "Chhota Udaipur",
    "Chhotaputki",
    "Chhoti Sadri",
    "Chhuikhadan",
    "Chhutmalpur",
    "Chicalim",
    "Chichli",
    "Chicholi",
    "Chidambaram",
    "Chik Ballapur",
    "Chikhaldara",
    "Chikhli",
    "Chikhli",
    "Chikiti",
    "Chikmagalur",
    "Chiknayakanhalli",
    "Chikodi",
    "Chikrand",
    "Chilakaluripet",
    "Chilkana Sultanpur",
    "Chilla Saroda Bangar",
    "Chiloda(Naroda)",
    "Chimbel",
    "Chinchani",
    "Chinchinim",
    "Chincholi",
    "Chinna Anuppanadi",
    "Chinnachowk",
    "Chinnakkampalayam",
    "Chinnalapatti",
    "Chinnamanur",
    "Chinnampalayam",
    "Chinnasalem",
    "Chinnasekkadu",
    "Chinnavedampatti",
    "Chintalavalasa",
    "Chintamani",
    "Chiplun",
    "Chirakkal",
    "Chirala",
    "Chirawa",
    "Chirgaon",
    "Chiria",
    "Chirkunda",
    "Chirmiri",
    "Chitapur",
    "Chitbara Gaon",
    "Chitgoppa",
    "Chithode",
    "Chitlapakkam",
    "Chitradurga",
    "Chitrakoot",
    "Chitrakoot Dham (Karwi)",
    "Chittaranjan",
    "Chittaurgarh",
    "Chittoor",
    "Chittur-Thathamangalam",
    "Chockli",
    "Chohal",
    "Cholapuram",
    "Chomu",
    "Choornikkara",
    "Chopan",
    "Chopda",
    "Chorvad",
    "Choto Haibor",
    "Choubepur Kalan",
    "Choudwar",
    "Choutuppal",
    "Chowwara",
    "Chuari Khas",
    "Chumukedima",
    "Chunar",
    "Chunchupalle",
    "Churhat",
    "Churi",
    "Churk Ghurma",
    "Churu",
    "Clement Town",
    "Coimbatore",
    "Colgong",
    "Colonelganj",
    "Colvale",
    "Contai",
    "Coonoor",
    "Cooper's Camp",
    "Courtalam",
    "Cuddalore",
    "Cuddapah",
    "Cuncolim",
    "Curchorem Cacora",
    "Curti",
    "Cuttack",
    "Dabhoi",
    "Daboh",
    "Dabra",
    "Dadhapatna",
    "Dadri",
    "Dafahat",
    "Dagshai",
    "Dahanu",
    "Dahegam",
    "Dainhat",
    "Daitari",
    "Dakor",
    "Dakshin Baguan",
    "Dakshin Jhapardaha",
    "Dakshin Rajyadharpur",
    "Dalavaipatti",
    "Dalhousie",
    "Dalhousie",
    "Dalkhola",
    "Dalli-Rajhara",
    "Dallo Pura",
    "Dalmau",
    "Dalsinghsarai",
    "Daltonganj",
    "Dalurband",
    "Daman",
    "Damanjodi",
    "Damnagar",
    "Damoh",
    "Damua",
    "Dandeli",
    "Danguwapasi",
    "Dankaur",
    "Dantewada",
    "Dapoli Camp",
    "Daporijo",
    "Darappur",
    "Darbhanga",
    "Dargajogihalli",
    "Dari",
    "Dariba",
    "Dariyabad",
    "Darjiling",
    "Darlawn",
    "Daroha",
    "Darwha",
    "Daryapur Banosa",
    "Dasarahalli",
    "Dasna",
    "Dasnapur",
    "Dasua",
    "Dataganj",
    "Datia",
    "Dattapur Dhamangaon",
    "Daudnagar",
    "Daulatpur",
    "Daulatpur",
    "Daund",
    "Daurala",
    "Dausa",
    "Davanagere",
    "Davlameti",
    "Davorlim",
    "Dayal Pur",
    "Dayalbagh",
    "Debagarh",
    "Debipur",
    "Deeg",
    "Deesa",
    "Deglur",
    "Dehradun",
    "Dehradun",
    "Dehri",
    "Dehu",
    "Dehu Road",
    "Delhi Cantt.",
    "Delhi Municipal Corporation (U)",
    "Denkanikottai",
    "Deoband",
    "Deodara",
    "Deoghar",
    "Deolali",
    "Deolali Pravara",
    "Deoli",
    "Deoli",
    "Deoli",
    "Deomali",
    "Deoranian",
    "Deori",
    "Deori",
    "Deori",
    "Deoria",
    "Deorikalan",
    "Depalpur",
    "Dera Baba Nanak",
    "Dera Bassi",
    "Dera Gopipur",
    "Deracolliery Township",
    "Dergaon",
    "Desaiganj",
    "Deshnoke",
    "Desur",
    "Deulgaon Raja",
    "Deuli",
    "Dev Prayag",
    "Devadanapatti",
    "Devadurga",
    "Devakottai",
    "Devanangurichi",
    "Devanhalli",
    "Devarakonda",
    "Devarshola",
    "Devendranagar",
    "Devgadbaria",
    "Devgarh",
    "Devhara",
    "Devsar",
    "Dewa",
    "Dewas",
    "Dewhadi",
    "Dhaka",
    "Dhakuria",
    "Dhalavoipuram",
    "Dhali",
    "Dhaliyur",
    "Dhaluwala",
    "Dhamanagar",
    "Dhamdha",
    "Dhamnod",
    "Dhampur",
    "Dhamtari",
    "Dhana",
    "Dhanauha",
    "Dhanaula",
    "Dhanauli",
    "Dhanaura",
    "Dhanbad",
    "Dhandadihi",
    "Dhandera",
    "Dhandhuka",
    "Dhanera",
    "Dhanpuri",
    "Dhanwar",
    "Dhanyakuria",
    "Dhar",
    "Dharamjaigarh",
    "Dharamkot",
    "Dharampur",
    "Dharampuri",
    "Dharangaon",
    "Dharapadavedu",
    "Dharapur",
    "Dharapuram",
    "Dharasuram",
    "Dharchula",
    "Dharchula Dehat",
    "Dhariawad",
    "Dhariwal",
    "Dharmabad",
    "Dharmadom",
    "Dharmanagar",
    "Dharmapur",
    "Dharmapuri",
    "Dharmavaram",
    "Dharmsala",
    "Dharoti Khurd",
    "Dharuhera",
    "Dharur",
    "Dhatau",
    "Dhatrigram",
    "Dhaulpur",
    "Dhaunsar",
    "Dhaura Tanda",
    "Dhaurehra",
    "Dhekiajuli",
    "Dhemaji",
    "Dhenkanal",
    "Dhilwan",
    "Dhing",
    "Dhola",
    "Dholka",
    "Dhoraji",
    "Dhrangadhra",
    "Dhrol",
    "Dhubri",
    "Dhuilya",
    "Dhule",
    "Dhulian",
    "Dhupguri",
    "Dhuri",
    "Dhusaripara",
    "Diamond Harbour",
    "Dibai",
    "Dibiyapur",
    "Dibrugarh",
    "Didihat",
    "Didwana",
    "Digapahandi",
    "Digboi",
    "Digboi Oil Town",
    "Digdoh",
    "Dighawani",
    "Dighwara",
    "Dignala",
    "Digras",
    "Digvijaygram",
    "Diken",
    "Dildarnagar Fatehpur Bazar",
    "Dimapur",
    "Dimaruguri",
    "Dina Nagar",
    "Dinapur Cantt.",
    "Dinapur Nizamat",
    "Dindigul",
    "Dindori",
    "Dineshpur",
    "Dinhata",
    "Diphu",
    "Dipka",
    "Dirba",
    "Diu",
    "Doboka",
    "Dod Ballapur",
    "Doda",
    "Dogadda",
    "Doghat",
    "Dohad",
    "Dohrighat",
    "Doiwala",
    "Dokmoka",
    "Dola",
    "Domjur",
    "Dommara Nandyal",
    "Dondaicha-Warwade",
    "Dongar Parasia",
    "Dongargaon",
    "Dongragarh",
    "Donimalai Township",
    "Donkamokam",
    "Doom Dooma",
    "Dostpur",
    "Dowleswaram",
    "Dubrajpur",
    "Dudhani",
    "Dudhi",
    "Dugda",
    "Dulhipur",
    "Duliajan No.1",
    "Duliajan Oil Town",
    "Dumar Kachhar",
    "Dumarkunda",
    "Dumdum",
    "Dumka",
    "Dumra",
    "Dumraon",
    "Dundahera",
    "Dungamal",
    "Dungargarh",
    "Dungarpur",
    "Dungra",
    "Durg",
    "Durga Nagar Part-V",
    "Durgapur",
    "Durgapur",
    "Durllabhganj",
    "Duru-Verinag",
    "Dusi",
    "Dwarahat",
    "Dwarka",
    "Dyane",
    "Edaganasalai",
    "Edaikodu",
    "Edakalinadu",
    "Edappadi",
    "Edathala",
    "Eddumailaram",
    "Egarkunr",
    "Egra",
    "Ekambara kuppam",
    "Ekdil",
    "Eklahare",
    "Eksara",
    "Elathur",
    "Elayavoor",
    "Elayirampannai",
    "Ellenabad",
    "Eloor",
    "Elumalai",
    "Eluru",
    "English Bazar",
    "Eral",
    "Erandol",
    "Eranholi",
    "Eraniel",
    "Erattupetta",
    "Erich",
    "Eriodu",
    "Erode",
    "Erumaipatti",
    "Eruvadi",
    "Etah",
    "Etawah",
    "Ethapur",
    "Etmadpur",
    "Ettayapuram",
    "Ettimadai",
    "Ezhudesam",
    "Faizabad",
    "Faizabad",
    "Faizganj",
    "Faizpur",
    "Falakata",
    "Farah",
    "Farakhpur",
    "Faridabad",
    "Faridkot",
    "Faridnagar",
    "Faridpur",
    "Faridpur",
    "Fariha",
    "Farooqnagar",
    "Farrakka Barrage Township",
    "Farrukhabad-cum-Fatehgarh",
    "Farrukhnagar",
    "Fatehabad",
    "Fatehabad",
    "Fatehganj Pashchimi",
    "Fatehganj Purvi",
    "Fatehgarh",
    "Fatehgarh Churian",
    "Fatehnagar",
    "Fatehpur",
    "Fatehpur",
    "Fatehpur",
    "Fatehpur Chaurasi",
    "Fatehpur Sikri",
    "Fatellapur",
    "Fatwah",
    "Fazilka",
    "Feroke",
    "Ferozepur Jhirka",
    "Fertilzer Corporation of IndiaTownship",
    "Fiozpur",
    "Firozabad",
    "Firozpur Cantt.",
    "Forbesganj",
    "Freelandgunj",
    "Frezarpur",
    "FRI and College Area",
    "G. Udayagiri",
    "G.C.F Jabalpur",
    "Gabberia",
    "Gadag-Betigeri",
    "Gadarpur",
    "Gadarwara",
    "Gadchiroli",
    "Gaddi annaram",
    "Gadhada",
    "Gadhinglaj",
    "Gadhra",
    "Gadwal",
    "Gagret",
    "Gairatganj",
    "Gairkata",
    "Gajapathinagaram",
    "Gajendragarh",
    "Gajraula",
    "Gajsinghpur",
    "Gajularega",
    "Gajuwaka",
    "Gakulnagar",
    "Galiakot",
    "Ganapathipuram",
    "Ganaur",
    "Gandai",
    "Ganderbal",
    "Gandevi",
    "Gandhi Nagar(Katpadi Ext.)",
    "Gandhidham",
    "Gandhigram",
    "Gandhinagar",
    "Gandhinagar",
    "Ganeshpur",
    "Gangaghat",
    "Gangaikondan",
    "Gangakhed",
    "Ganganagar",
    "Gangapur",
    "Gangapur",
    "Gangapur",
    "Gangapur City",
    "Gangarampur",
    "Gangavalli",
    "Gangawati",
    "Gangoh",
    "Gangotri",
    "Gangtok",
    "Ganguvarpatti",
    "Ganj Dundawara",
    "Ganj Muradabad",
    "Ganjam",
    "Garacharma",
    "Garalgachha",
    "Garautha",
    "Gardhiwala",
    "Garhakota",
    "Garhi Pukhta",
    "Garhi-Malhara",
    "Garhmukteshwar",
    "Garhshanker",
    "Garhwa",
    "Gariadhar",
    "Garoth",
    "Garshyamnagar",
    "Garulia",
    "Gaura Barhaj",
    "Gaurella",
    "Gauri Bazar",
    "Gauribidanur",
    "Gauripur",
    "Gausganj",
    "Gawan",
    "Gaya",
    "Gayespur",
    "Geedam",
    "Georai",
    "Ghagga",
    "Ghanaur",
    "Ghansor",
    "Ghantapada",
    "Gharaunda",
    "Gharghoda",
    "Gharoli",
    "Gharonda Neemka Bangar alias Patpar Ganj",
    "Ghatal",
    "Ghatampur",
    "Ghatanji",
    "Ghatkeser",
    "Ghatlodiya",
    "Ghatshila",
    "Ghaziabad",
    "Ghazipur",
    "Gheora",
    "Ghiraur",
    "Ghitorni",
    "Gho Manhasan",
    "Ghoga",
    "Ghogha",
    "Ghoghardiha",
    "Ghorabandha",
    "Ghorawal",
    "Ghorsala",
    "Ghosi",
    "Ghosia Bazar",
    "Ghoti Budruk",
    "Ghughuli",
    "Ghugus",
    "Ghulewadi",
    "Ghumarwin",
    "Ghuwara",
    "Gidderbaha",
    "Gidi",
    "Gingee",
    "Giridih",
    "Goa Velha",
    "Goaljan",
    "Goalpara",
    "Goasafat",
    "Gobardanga",
    "Gobichettipalayam",
    "Gobindgarh",
    "Gobindpur",
    "Gobranawapara",
    "Gochar",
    "Godda",
    "Godhar",
    "Godhra",
    "Godoli",
    "Gogaon",
    "Gogapur",
    "Gogri Jamalpur",
    "Gohad",
    "Gohana",
    "Gohand",
    "Gohpur",
    "Gokak",
    "Gokak Falls",
    "Gokal Pur",
    "Gokhivare",
    "Gokul",
    "Gola Bazar",
    "Gola Gokarannath",
    "Golaghat",
    "Golokganj",
    "Gomoh",
    "Gonda",
    "Gondal",
    "Gondiya",
    "Goniana",
    "Gonikoppal",
    "Gooty",
    "Gopalasamudram",
    "Gopalganj",
    "Gopalpur",
    "Gopalpur",
    "Gopamau",
    "Gopiganj",
    "Gopinathpur",
    "Gora Bazar",
    "Gorah Salathian",
    "Gorakhpur",
    "Goraya",
    "Goredi Chancha",
    "Goregaon",
    "Gormi",
    "Gosainganj",
    "Gosainganj",
    "Gossaigaon",
    "Gota",
    "Gothra",
    "Gottikere",
    "Goundampalayam",
    "Govardhan",
    "Govindgarh",
    "Govindgarh",
    "Greater Mumbai",
    "GSFC Complex INA",
    "Gua",
    "Gubbi",
    "Gudalur",
    "Gudalur",
    "Gudalur",
    "Gudari",
    "Gudibanda",
    "Gudivada",
    "Gudivada",
    "Gudiyatham",
    "Gudur",
    "Guhagar",
    "Guirim",
    "Gulabpura",
    "Gulaothi",
    "Gularia Bhindara",
    "Gulariya",
    "Gulbarga",
    "Guledgudda",
    "Gulmarg",
    "Guma",
    "Gumia",
    "Gumla",
    "Gummidipoondi",
    "Guna",
    "Gundlupet",
    "Gunnaur",
    "Guntakal",
    "Guntur",
    "Gunupur",
    "Gurdaspur",
    "Gurgaon",
    "Gurgaon Rural",
    "Gurh",
    "Guriahati",
    "Gurmatkal",
    "Gursahaiganj",
    "Gursarai",
    "Guru Har Sahai",
    "Guruvayoor",
    "Guskara",
    "Guwahati",
    "Gwalior",
    "Gyalshing",
    "Gyanpur",
    "Habibpur",
    "Habra",
    "Hadgaon",
    "Hafizpur",
    "Haflong",
    "Haidergarh",
    "Hailakandi",
    "Haileymandi",
    "Hajan",
    "Hajipur",
    "Hajipur",
    "Hajira INA",
    "Haldaur",
    "Haldia",
    "Haldibari",
    "Haldwani-cum-Kathgodam",
    "Halisahar",
    "Haliyal",
    "Halol",
    "Haludbani",
    "Halvad",
    "Hamirpur",
    "Hamirpur",
    "Hamren",
    "Handia",
    "Handiaya",
    "Handwara",
    "Hangal",
    "Hansi",
    "Hanumana",
    "Hanumangarh",
    "Hanumanthampatti",
    "Haora",
    "Hapur",
    "Harda",
    "Hardoi",
    "Harduaganj",
    "Hardwar",
    "Hargaon",
    "Harharia Chak",
    "Hariana",
    "Harihar",
    "Hariharpur",
    "Harij",
    "Haripur",
    "Harishpur",
    "Harpalpur",
    "Harpanahalli",
    "Harrai",
    "Harraiya",
    "Harsud",
    "Harur",
    "Harveypatti",
    "Hasanpur",
    "Hasayan",
    "Hassan",
    "Hassanpur",
    "Hastinapur",
    "Hastsal",
    "Hata",
    "Hatgachha",
    "Hathin",
    "Hathras",
    "Hatibandha",
    "Hatkachora",
    "Hatod",
    "Hatpipalya",
    "Hatsimla",
    "Hatta",
    "Hatti",
    "Hatti Gold Mines",
    "Haveri",
    "Hazaribag",
    "Hebbagodi",
    "Hebbalu",
    "Heggadadevanakote",
    "Herbertpur",
    "Heriok",
    "Herohalli",
    "Hesla",
    "Highways",
    "Hijuli",
    "Hilsa",
    "Himatnagar",
    "Hindalgi",
    "Hindaun",
    "Hindoria",
    "Hindupur",
    "Hindustan Paper Corporation Ltd. Township Area Panchgram",
    "Hindusthan Cables Town",
    "Hinganghat",
    "Hingoli",
    "Hinjilicut",
    "Hirakud",
    "Hiranagar",
    "Hirapur",
    "Hirekerur",
    "Hiriyur",
    "Hisar",
    "Hisua",
    "Hnahthial",
    "Hodal",
    "Hojai",
    "Holalkere",
    "Holenarsipur",
    "Homnabad",
    "Honavar",
    "Honnali",
    "Hoovina Hadagalli",
    "Hosabettu",
    "Hosanagara",
    "Hosdurga",
    "Hoshangabad",
    "Hoshiarpur",
    "Hoskote",
    "Hospet",
    "Hosur",
    "Howli",
    "Howraghat",
    "Hubbathala",
    "Hubli-Dharwad",
    "Hugli-Chinsurah",
    "Hukeri",
    "Huligal",
    "Hunasamaranahalli",
    "Hungund",
    "Hunsur",
    "Hupari",
    "Hussainabad",
    "Hussainpur",
    "Hyderabad",
    "Hyderabad M.Corp",
    "Ibrahimpur",
    "Ichalkaranji",
    "Ichchapuram",
    "Ichchhapor",
    "Ichhapur Defence Estate",
    "Ichhawar",
    "Idar",
    "Idikarai",
    "Idukki Township",
    "Igatpuri",
    "Iglas",
    "Ikauna",
    "Iklehra",
    "Ilampillai",
    "Ilanji",
    "Ilkal",
    "Iltifatganj Bazar",
    "Iluppaiyurani",
    "Iluppur",
    "Imphal",
    "Inam Karur",
    "Indapur",
    "Indergarh",
    "Indi",
    "Indian Telephone Industry", "Mankapur (Sp. Village)",
    "Indore",
    "Indragarh",
    "Indranagar",
    "Indri",
    "Injambakkam",
    "Iringaprom",
    "Irinjalakuda",
    "Iriveri",
    "Irugur",
    "Isagarh",
    "Islamnagar",
    "Islampur",
    "Islampur",
    "Isnapur",
    "Isri",
    "Itanagar",
    "Itarsi",
    "Itaunja",
    "Jabalpur",
    "Jabalpur Cantt.",
    "Jadugora",
    "Jafarabad",
    "Jafarpur",
    "Jaffrabad",
    "Jaffrabad",
    "Jafrabad",
    "Jagadanandapur",
    "Jagadhri",
    "Jagadishpur",
    "Jagalur",
    "Jagathala",
    "Jagatsinghapur",
    "Jagdalpur",
    "Jagdishpur",
    "Jaggayyapet",
    "Jagiroad",
    "Jagner",
    "Jagraon",
    "Jagtaj",
    "Jagtial",
    "Jahanabad",
    "Jahangirabad",
    "Jahangirpur",
    "Jahazpur",
    "Jainagar",
    "Jaipur",
    "Jairampur",
    "Jais",
    "Jaisalmer",
    "Jaisinghnagar",
    "Jaitaran",
    "Jaithara",
    "Jaithari",
    "Jaitu",
    "Jaitwara",
    "Jajapur",
    "Jakhal Mandi",
    "Jala Kendua",
    "Jalakandapuram",
    "Jalalabad",
    "Jalalabad",
    "Jalalabad",
    "Jalalabad",
    "Jalali",
    "Jalalpore",
    "Jalalpur",
    "Jalandhar",
    "Jalandhar Cantt.",
    "Jalaun",
    "Jalda",
    "Jalesar",
    "Jaleswar",
    "Jalgaon",
    "Jalgaon",
    "Jalgaon (Jamod)",
    "Jalladiampet",
    "Jallaram Kamanpur",
    "Jalna",
    "Jalor",
    "Jalpaiguri",
    "Jam Jodhpur",
    "Jamadoba",
    "Jamai",
    "Jamalpur",
    "Jambai",
    "Jambusar",
    "Jamhaur",
    "Jamkhandi",
    "Jamkhed",
    "Jammalamadugu",
    "Jammu",
    "Jammu Cantonment",
    "Jamnagar",
    "Jamshedpur",
    "Jamshila",
    "Jamtara",
    "Jamui",
    "Jamul",
    "Jamuria",
    "Janakpur Road",
    "Jandiala",
    "Jandiala",
    "Jangaon",
    "Jangipur",
    "Jangipur",
    "Jansath",
    "Jaora",
    "Jaridih Bazar",
    "Jarjapupeta",
    "Jarwal",
    "Jasdan",
    "Jashpur nagar",
    "Jasidih",
    "Jaspur",
    "Jasrana",
    "Jaswantnagar",
    "Jata Chhapar",
    "Jatani",
    "Jatara",
    "Jatari",
    "Jaunpur",
    "Jawad",
    "Jawaharnagar (Gujarat Refinery)",
    "Jawai",
    "Jawalamukhi",
    "Jawar",
    "Jawhar",
    "Jayankondam",
    "Jaygaon",
    "Jaynagar Mazilpur",
    "Jaysingpur",
    "Jehanabad",
    "Jejuri",
    "Jemari",
    "Jemari (J.K. Nagar Township)",
    "Jena",
    "Jeron Khalsa",
    "Jetia",
    "Jetpur Navagadh",
    "Jevargi",
    "Jewar",
    "Jeypur",
    "Jhabrera",
    "Jhabua",
    "Jhagrakhand",
    "Jhajha",
    "Jhajjar",
    "Jhalawar",
    "Jhalda",
    "Jhalrapatan",
    "Jhalu",
    "Jhanjharpur",
    "Jhansi",
    "Jhansi",
    "Jhansi Rly. Settlement",
    "Jhargram",
    "Jharia",
    "Jharia Khas",
    "Jharoda Majra Burari",
    "Jharsuguda",
    "Jhinjhak",
    "Jhinjhana",
    "Jhinkpani",
    "Jhorhat",
    "Jhumpura",
    "Jhumri Tilaiya",
    "Jhundpura",
    "Jhunjhunun",
    "Jhusi",
    "Jhusi Kohna",
    "Jiaganj Azimganj",
    "Jind",
    "Jintur",
    "Jiran",
    "Jirapur",
    "Jiribam",
    "Jiwan Pur alias Johri Pur",
    "Jiyanpur",
    "Jobat",
    "Jobner",
    "Joda",
    "Jodhpur",
    "Jodhpur",
    "Jog Falls",
    "Jogbani",
    "Jogendranagar",
    "Jogindarnagar",
    "Jolarpet",
    "Jona Pur",
    "Jonai Bazar",
    "Jorapokhar",
    "Jorethang",
    "Jorhat",
    "Joshimath",
    "Joshipura",
    "Jot Kamal",
    "Joura",
    "Jourian",
    "Joya",
    "Jubbal",
    "Jugial",
    "Jugsalai",
    "Julana",
    "Junagadh",
    "Junagarh",
    "Junnar",
    "Jutogh",
    "Jyoti Khuria",
    "Kabnur",
    "Kabrai",
    "Kachhauna Patseni",
    "Kachhla",
    "Kachhwa",
    "Kachnal Gosain",
    "Kachu Pukur",
    "Kadachira",
    "Kadamakkudy",
    "Kadambur",
    "Kadathur",
    "Kadaura",
    "Kadayal",
    "Kadayampatti",
    "Kadayanallur",
    "Kadi",
    "Kadipur",
    "Kadiri",
    "Kadirur",
    "Kadodara",
    "Kadungalloor",
    "Kadur",
    "Kagal",
    "Kaghaznagar",
    "Kailaras",
    "Kailasahar",
    "Kailashpur",
    "Kailudih",
    "Kaimganj",
    "Kairana",
    "Kaithal",
    "Kaithoon",
    "Kajora",
    "Kakarhati",
    "Kakching",
    "Kakching Khunou",
    "Kakdihi",
    "Kakgaina",
    "Kakinada",
    "Kakod",
    "Kakori",
    "Kakrala",
    "Kaladungi",
    "Kalakkad",
    "Kalamassery",
    "Kalamb",
    "Kalambe Turf Thane",
    "Kalambur",
    "Kalameshwar",
    "Kalamnuri",
    "Kalan Wali",
    "Kalanaur",
    "Kalanaur",
    "Kalapatti",
    "Kalappanaickenpatti",
    "Kalara",
    "Kalavad",
    "Kalavai",
    "Kalayat",
    "Kalghatgi",
    "Kali",
    "Kali Chhapar",
    "Kaliaganj",
    "Kalikapur",
    "Kalimpong",
    "Kalinagar",
    "Kalinjur",
    "Kaliyakkavilai",
    "Kalka",
    "Kalladaikurichi",
    "Kallakkurichi",
    "Kallakudi",
    "Kalliasseri",
    "Kallukuttam",
    "Kallur",
    "Kalna",
    "Kalol",
    "Kalol",
    "Kalol INA",
    "Kalol INA",
    "Kalpetta",
    "Kalpi",
    "Kalugumalai",
    "Kalundre",
    "Kalyan-Dombivali",
    "Kalyandurg",
    "Kalyani",
    "Kamakshyanagar",
    "Kamalapuram",
    "Kamalganj",
    "Kamalpur",
    "Kaman",
    "Kamareddy",
    "Kamarhati",
    "Kamayagoundanpatti",
    "Kambainallur",
    "Kambam",
    "Kampil",
    "Kampli",
    "Kamptee",
    "Kamptee",
    "Kampur Town",
    "Kamuthi",
    "Kanad",
    "Kanadukathan",
    "Kanaipur",
    "Kanakapura",
    "Kanakkampalayam",
    "Kanakpur Part-II",
    "Kanam",
    "Kanapaka",
    "Kanchanpur",
    "Kancheepuram",
    "Kanchrapara",
    "Kandanur",
    "Kandari",
    "Kandhar",
    "Kandhla",
    "Kandi",
    "Kandla",
    "Kandra",
    "Kandri",
    "Kandri",
    "Kandukur",
    "Kandwa",
    "Kangayampalayam",
    "Kangeyam",
    "Kangeyanallur",
    "Kangra",
    "Kangrali (BK)",
    "Kangrali (KH)",
    "Kanhan (Pipri)",
    "Kanhangad",
    "Kanhirode",
    "Kanina",
    "Kaniyur",
    "Kanjhawala",
    "Kanjikkuzhi",
    "Kanjikoil",
    "Kankavli",
    "Kanke",
    "Kanker",
    "Kankuria",
    "Kannad",
    "Kannadendal",
    "Kannadiparamba",
    "Kannamangalam",
    "Kannampalayam",
    "Kannankurichi",
    "Kannapuram",
    "Kannauj",
    "Kannivadi",
    "Kannivadi",
    "Kanniyakumari",
    "Kannod",
    "Kannur",
    "Kannur",
    "Kannur Cantonment",
    "Kanodar",
    "Kanor",
    "Kanpur",
    "Kanpur",
    "Kansepur",
    "Kantabamsuguda",
    "Kantabanji",
    "Kantaphod",
    "Kanth",
    "Kanth",
    "Kanti",
    "Kantilo",
    "Kantlia",
    "Kanuru",
    "Kanyanagar",
    "Kapadvanj",
    "Kapas Hera",
    "Kapasan",
    "Kappiyarai",
    "Kapra",
    "Kaprain",
    "Kaptanganj",
    "Kapurthala",
    "Karachiya",
    "Karad",
    "Karaikal",
    "Karaikkudi",
    "Karamadai",
    "Karambakkam",
    "Karambakkudi",
    "Karamsad",
    "Karanja",
    "Karanje Turf Satara",
    "Karanjia",
    "Karanpur",
    "Karari",
    "Karauli",
    "Karawal Nagar",
    "Kardhan",
    "Kareli",
    "Karera",
    "Kargil",
    "Karhal",
    "Kari",
    "Kariamangalam",
    "Kariapatti",
    "Karimganj",
    "Karimnagar",
    "Karimpur",
    "Karivali",
    "Karjan",
    "Karjat",
    "Karkal",
    "Karmala",
    "Karn Prayag",
    "Karnal",
    "Karnawad",
    "Karnawal",
    "Karoran",
    "Karrapur",
    "Kartarpur",
    "Karugampattur",
    "Karumandi Chellipalayam",
    "Karumathampatti",
    "Karungal",
    "Karunguzhi",
    "Karuppur",
    "Karur",
    "Karuvanthuruthy",
    "Karwar",
    "Kasara Budruk",
    "Kasaragod",
    "Kasauli",
    "Kasba",
    "Kasba",
    "Kasganj",
    "Kashinagara",
    "Kashipur",
    "Kashirampur",
    "Kasim Bazar",
    "Kasipalayam (E)",
    "Kasipalayam (G)",
    "Kasrawad",
    "Katai",
    "Kataiya",
    "Katangi",
    "Katangi",
    "Katariya",
    "Katghar Lalganj",
    "Katghora",
    "Kathera",
    "Kathua",
    "Kathujuganapalli",
    "Katihar",
    "Katkar",
    "Katol",
    "Katpadi",
    "Katpar",
    "Katra",
    "Katra",
    "Katra",
    "Katra Medniganj",
    "Katras",
    "Kattivakkam",
    "Kattumannarkoil",
    "Kattuputhur",
    "Katwa",
    "Kaugachhi",
    "Kauriaganj",
    "Kavali",
    "Kavaratti",
    "Kaveripakkam",
    "Kaveripattinam",
    "Kavisuryanagar",
    "Kawardha",
    "Kayalpattinam",
    "Kayamkulam",
    "Kayatharu",
    "Kedarnath",
    "Kedla",
    "Keelakarai",
    "Keeramangalam",
    "Keeranur",
    "Keeranur",
    "Keeripatti",
    "Keezhapavur",
    "Kegaon",
    "Kekri",
    "Kela Khera",
    "Kelamangalam",
    "Kelhauri(chachai)",
    "Kembainaickenpalayam",
    "Kemri",
    "Kenda",
    "Kendra Khottamdi",
    "Kendrapara",
    "Kendua",
    "Kenduadih",
    "Kendujhar",
    "Kengeri",
    "Kerakat",
    "Kerur",
    "Kesabpur",
    "Keshod",
    "Keshoraipatan",
    "Kesinga",
    "Kesrisinghpur",
    "Kethi",
    "Kevadiya",
    "Khachrod",
    "Khadda",
    "Khadkale",
    "Khaga",
    "Khagaria",
    "Khagaul",
    "Khagrabari",
    "Khailar",
    "Khair",
    "Khairabad",
    "Khairabad",
    "Khairagarh",
    "Khairthal",
    "Khajoori Khas",
    "Khajuraho",
    "Khalia",
    "Khaliapali",
    "Khalikote",
    "Khalilabad",
    "Khalor",
    "Khamanon",
    "Khamaria",
    "Khambhalia",
    "Khambhat",
    "Khamgaon",
    "Khamhria",
    "Khammam",
    "Khan Sahib",
    "Khanapur",
    "Khanauri",
    "Khand(Bansagar)",
    "Khandapada",
    "Khandela",
    "Khandra",
    "Khandwa",
    "Khaniyadhana",
    "Khanna",
    "Khanpur",
    "Khantora",
    "Khapa",
    "Kharaghoda",
    "Kharagpur",
    "Kharagpur",
    "Kharagpur Rly. Settlement",
    "Kharar",
    "Kharar",
    "Khardaha",
    "Kharela",
    "Khargapur",
    "Khargone",
    "Khargupur",
    "Khariar",
    "Khariar Road",
    "Kharijapikon",
    "Kharimala Khagrabari",
    "Khariya",
    "Kharkhari",
    "Kharkhoda",
    "Kharkhoda",
    "Kharod",
    "Kharsarai",
    "Kharsawan",
    "Kharsia",
    "Kharupatia",
    "Khatauli",
    "Khatauli Rural",
    "Khategaon",
    "Khatiguda",
    "Khatima",
    "Khawhai",
    "Khawzawl",
    "Khed",
    "Khed",
    "Kheda",
    "Khedbrahma",
    "Khekada",
    "Khelari",
    "Khem Karan",
    "Khera Khurd",
    "Kheragarh",
    "Kheralu",
    "Kherdi",
    "Kheri",
    "Kherli",
    "Kherliganj",
    "Kherwara Chhaoni",
    "Kheta Sarai",
    "Khetia",
    "Khetri",
    "Khilchipur",
    "Khirkiya",
    "Khodarampur",
    "Khongapani",
    "Khongman",
    "Khoni",
    "Khonsa",
    "Khopoli",
    "Khordha",
    "Khour",
    "Khowai",
    "Khrew",
    "Khudaganj",
    "Khujner",
    "Khuldabad",
    "Khunti",
    "Khurai",
    "Khurja",
    "Khusrupur",
    "Khutar",
    "Kichha",
    "Kilampadi",
    "Kilkulam",
    "Kilkunda",
    "Killiyur",
    "Killlai",
    "Kilpennathur",
    "Kilvelur",
    "Kinathukadavu",
    "Kinwat",
    "Kirandul",
    "Kiranipura",
    "Kiraoli",
    "Kirari Suleman Nagar",
    "Kiratpur",
    "Kiriburu",
    "Kirkee",
    "Kirtinagar",
    "Kishanganj",
    "Kishangarh",
    "Kishangarh",
    "Kishangarh Renwal",
    "Kishni",
    "Kishtwar",
    "Kishunpur",
    "Kithaur",
    "Koath",
    "Koch Bihar",
    "Kochi",
    "Kochinda",
    "Kochpara",
    "Kodaikanal",
    "Kodala",
    "Kodalia",
    "Kodarma",
    "Kodavasal",
    "Kodigenahalli",
    "Kodinar",
    "Kodiyal",
    "Kodoli",
    "Kodumudi",
    "Kodungallur",
    "Koeripur",
    "Kohima",
    "Koilwar",
    "Kokkothamangalam",
    "Kokrajhar",
    "Kolachal",
    "Kolaghat",
    "Kolappalur",
    "Kolar",
    "Kolaras",
    "Kolasib",
    "Kolathupalayam",
    "Kolathur",
    "Kolazhy",
    "Kolhapur",
    "Kolkata",
    "Kollam",
    "Kollankodu",
    "Kollankoil",
    "Kollegal",
    "Kolvi Mandi Rajendra pura",
    "Komalapuram",
    "Komaralingam",
    "Kombai",
    "Kon",
    "Konanakunte",
    "Konardihi",
    "Konark",
    "Konavattam",
    "Konch",
    "Kondagaon",
    "Kondalampatti",
    "Kondli",
    "Kondumal",
    "Konganapuram",
    "Konnagar",
    "Konnur",
    "Kooraikundu",
    "Koothappar",
    "Koothuparamba",
    "Kopaganj",
    "Kopargaon",
    "Kopharad",
    "Koppa",
    "Koppal",
    "Kora Jahanabad",
    "Koradacheri",
    "Koraon",
    "Koraput",
    "Koratagere",
    "Koratla",
    "Koratty",
    "Korba",
    "Korochi",
    "Korwa",
    "Kosamba",
    "Kosi Kalan",
    "Kot Fatta",
    "Kot Kapura",
    "Kota",
    "Kota",
    "Kota",
    "Kotagiri",
    "Kotar",
    "Kotdwara",
    "Kotekara",
    "Kothagudem",
    "Kothamangalam",
    "Kothavalasa",
    "Kothi",
    "Kothinallur",
    "Kothnur",
    "Kotkhai",
    "Kotma",
    "Kotpad",
    "Kotputli",
    "Kotra",
    "Kottaiyur",
    "Kottakuppam",
    "Kottaram",
    "Kottayam",
    "Kottayam-Malabar",
    "Kottivakkam",
    "Kottur",
    "Kotturu",
    "Kottuvally",
    "Kotwa",
    "Kouthanallur",
    "Kovilpatti",
    "Kovurpalle",
    "Kovvur",
    "Kozhikode",
    "Krishnagiri",
    "Krishnanagar",
    "Krishnapur",
    "Krishnarajanagar",
    "Krishnarajapura",
    "Krishnarajasagara",
    "Krishnarajpet",
    "Krishnarayapuram",
    "Krishnasamudram",
    "Kshidirpur",
    "Kshirpai",
    "Kuchaman City",
    "Kuchanur",
    "Kuchera",
    "Kud",
    "Kudal",
    "Kudchi",
    "Kudligi",
    "Kudlu",
    "Kudremukh",
    "Kuhalur",
    "Kuju",
    "Kukatpalle",
    "Kukernag",
    "Kukshi",
    "Kul Pahar",
    "Kulasekarapuram",
    "Kulgam",
    "Kulihanda",
    "Kulithalai",
    "Kullu",
    "Kulti",
    "Kumar Kaibarta Gaon",
    "Kumarapalayam",
    "Kumarapuram",
    "Kumarghat",
    "Kumbakonam",
    "Kumbhkot",
    "Kumbhraj",
    "Kumbi",
    "Kumhari",
    "Kumher",
    "Kumta",
    "Kunda",
    "Kundalwadi",
    "Kundapura",
    "Kundarki",
    "Kundgol",
    "Kundrathur",
    "Kunigal",
    "Kuniyamuthur",
    "Kunjaban",
    "Kunnamkulam",
    "Kunnathur",
    "Kunustara",
    "Kunwargaon",
    "Kunzer",
    "Kuppam",
    "Kupwara",
    "Kurali",
    "Kuraoli",
    "Kurara",
    "Kurduvadi",
    "Kureekkad",
    "Kurgunta",
    "Kurichi",
    "Kurinjipadi",
    "Kurnool",
    "Kurpania",
    "Kursath",
    "Kursath",
    "Kurseong",
    "Kurthi Jafarpur",
    "Kurud",
    "Kurudampalayam",
    "Kurumbalur",
    "Kurumbapet",
    "Kurundvad",
    "Kurwai",
    "Kusgaon Budruk",
    "Kushalgarh",
    "Kushalnagar",
    "Kushinagar",
    "Kushtagi",
    "Kusmara",
    "Kustai",
    "Kuthalam",
    "Kutiyana",
    "Kuzhithurai",
    "Kwakta",
    "Kyathampalle",
    "Kymore",
    "L.B. Nagar",
    "Labbaikudikadu",
    "Lachhmangarh",
    "Ladnu",
    "Ladrawan",
    "Ladwa",
    "Lahar",
    "Laharpur",
    "Lakarka",
    "Lakhenpur",
    "Lakheri",
    "Lakhimpur",
    "Lakhipur",
    "Lakhipur",
    "Lakhisarai",
    "Lakhna",
    "Lakhnadon",
    "Lakkampatti",
    "Laksar",
    "Lakshmeshwar",
    "Lal Gopalganj Nindaura",
    "Lala",
    "Lalganj",
    "Lalganj",
    "Lalgudi",
    "Lalitpur",
    "Lalkuan",
    "Lalpet",
    "Lalsot",
    "Lamai",
    "Lambha",
    "Lamjaotongba",
    "Lamshang",
    "Landaura",
    "Landour",
    "Lanja",
    "Lanka",
    "Lansdowne",
    "Lapanga",
    "Lar",
    "Lasalgaon",
    "Latehar",
    "Lateri",
    "Lathi",
    "Lattikata",
    "Latur",
    "Laundi",
    "Lauthaha",
    "Lawar NP",
    "Ledwa Mahua",
    "Leh",
    "Lehragaga",
    "Lengpui",
    "Libas Pur",
    "Lidhorakhas",
    "Lido Tikok",
    "Lido Town",
    "Lilong (Imphal West)",
    "Lilong (Thoubal)",
    "Limbdi",
    "Limla",
    "Lingiyadih",
    "Lingsugur",
    "Llayangudi",
    "Lodhian Khas",
    "Lodhikheda",
    "Loha",
    "Lohaghat",
    "Loharda",
    "Lohardaga",
    "Loharu",
    "Lohta",
    "Lonar",
    "Lonavala",
    "Londa",
    "Longowal",
    "Loni",
    "Lormi",
    "Losal",
    "Loyabad",
    "Lucknow",
    "Lucknow",
    "Ludhiana",
    "Lumding",
    "Lumding Rly Colony",
    "Lunawada",
    "Lunglei",
    "Machalpur",
    "Macherla",
    "Machhiwara",
    "Machhlishahr",
    "Machilipatnam",
    "Madambakkam",
    "Madanapalle",
    "Madanpur",
    "Madanrting",
    "Madaram",
    "Madathukulam",
    "Madavaram",
    "Maddur",
    "Madhavnagar",
    "Madhepura",
    "Madhoganj",
    "Madhogarh",
    "Madhubani",
    "Madhugiri",
    "Madhupur",
    "Madhusudanpur",
    "Madhyamgram",
    "Madikeri",
    "Madippakkam",
    "Madukkarai",
    "Madukkur",
    "Madurai",
    "Maduranthakam",
    "Maduravoyal",
    "Magadi",
    "Magam",
    "Maghar",
    "Mahabaleshwar",
    "Mahaban",
    "Mahad",
    "Mahadevapura",
    "Mahadula",
    "Mahalingpur",
    "Maham",
    "Maharajganj",
    "Maharajpur",
    "Mahasamund",
    "Mahbubnagar",
    "Mahe",
    "Mahemdavad",
    "Mahendragarh",
    "Mahesana",
    "Maheshtala",
    "Maheshwar",
    "Mahiari",
    "Mahidpur",
    "Mahilpur",
    "Mahira",
    "Mahmudabad",
    "Mahnar Bazar",
    "Mahoba",
    "Maholi",
    "Mahona",
    "Mahrajganj",
    "Mahrajganj",
    "Mahrajganj",
    "Mahroni",
    "Mahu Kalan",
    "Mahua Dabra Haripura",
    "Mahua Kheraganj",
    "Mahudha",
    "Mahur",
    "Mahuva",
    "Mahuvar",
    "Mahwa",
    "Maibong",
    "Maihar",
    "Mailani",
    "Mainaguri",
    "Maindargi",
    "Mainpuri",
    "Mairang",
    "Mairwa",
    "Maithon",
    "Majgaon",
    "Majhara Pipar Ehatmali",
    "Majhauli Raj",
    "Majholi",
    "Majitha",
    "Makarba",
    "Makardaha",
    "Makhdumpur",
    "Makhu",
    "Makrana",
    "Makronia",
    "Maksi",
    "Maktampur",
    "Makum",
    "Makundapur",
    "Mal",
    "Malaj Khand",
    "Malappuram",
    "Malavalli",
    "Malegaon",
    "Malerkotla",
    "Malhargarh",
    "Malihabad",
    "Malkajgiri",
    "Malkangiri",
    "Malkapur",
    "Malkapur",
    "Malkapur",
    "Malkera",
    "Mallamooppampatti",
    "Mallankinaru",
    "Mallar",
    "Mallasamudram",
    "Mallawan",
    "Mallur",
    "Maloud",
    "Malout",
    "Malpur",
    "Malpura",
    "Malur",
    "Malwan",
    "Mamallapuram",
    "Mamit",
    "Mamsapuram",
    "Manachanallur",
    "Manadur",
    "Manali",
    "Manali",
    "Manalmedu",
    "Manalurpet",
    "Manamadurai",
    "Manapakkam",
    "Manapparai",
    "Manasa",
    "Manavadar",
    "Manavalakurichi",
    "Manawar",
    "Manchar",
    "Mancherial",
    "Mandaikadu",
    "Mandalgarh",
    "Mandamarri",
    "Mandapam",
    "Mandapeta",
    "Mandarbani",
    "Mandav",
    "Mandawa",
    "Mandawar",
    "Mandawar",
    "Mandi",
    "Mandi Dabwali",
    "Mandideep",
    "Mandla",
    "Mandleshwar",
    "Mandsaur",
    "Mandvi",
    "Mandya",
    "Manegaon",
    "Manendragarh",
    "Maner",
    "Mangadu",
    "Mangalagiri",
    "Mangalam",
    "Mangalampet",
    "Mangaldoi",
    "Mangalore",
    "Mangalvedhe",
    "Mangan",
    "Mangawan",
    "Manglaur",
    "Manglaya Sadak",
    "Mango",
    "Mangrol",
    "Mangrol",
    "Mangrulpir",
    "Manihari",
    "Manikpur",
    "Manikpur",
    "Manikpur Sarhat",
    "Manimutharu",
    "Maniyar",
    "Manjeri",
    "Manjeshwar",
    "Manjhanpur",
    "Manjlegaon",
    "Mankachar",
    "Mankapur",
    "Manmad",
    "Mannargudi",
    "Manohar Thana",
    "Manor",
    "Manpur",
    "Mansa",
    "Mansa",
    "Mansar",
    "Mansinhapur",
    "Mant Khas",
    "Manugur",
    "Manvi",
    "Manwath",
    "Mappilaiurani",
    "Mapusa",
    "Maradu",
    "Maraimalainagar",
    "Marakkanam",
    "Maramangalathupatti",
    "Marandahalli",
    "Marathakkara",
    "Marehra",
    "Margao",
    "Margherita",
    "Marhaura",
    "Mariahu",
    "Mariani",
    "Marigaon",
    "Markapur",
    "Markayankottai",
    "Marma",
    "Maruadih Rly. Settlement",
    "Marudur",
    "Marungur",
    "Marwar Junction",
    "Masaurhi",
    "Maslandapur",
    "Maswasi",
    "Mataundh",
    "Mathabhanga",
    "Matheran",
    "Mathigiri",
    "Mathura",
    "Mathura",
    "Mattan",
    "Mattannur",
    "Mau",
    "Mau Aima",
    "Maudaha",
    "Mauganj",
    "Maunath Bhanjan",
    "Maur",
    "Mauranipur",
    "Maurawan",
    "Mavelikkara",
    "Mavilayi",
    "Mavoor",
    "Mawana",
    "Mawlai",
    "Mayang Imphal",
    "Mayiladuthurai",
    "Mecheri",
    "Medak",
    "Medinipur",
    "Meenambakkam",
    "Meerpet",
    "Meerut",
    "Meerut",
    "Meghahatuburu Forest village",
    "Meghnagar",
    "Meghraj",
    "Mehara Gaon",
    "Mehatpur Basdehra",
    "Mehdawal",
    "Mehgaon",
    "Mehkar",
    "Mehmand",
    "Mehnagar",
    "Mekliganj",
    "Melacheval",
    "Melachokkanathapuram",
    "Melagaram",
    "Melamadai",
    "Melamaiyur",
    "Melathiruppanthuruthi",
    "Melattur",
    "Melpattampakkam",
    "Melur",
    "Melvisharam",
    "Memari",
    "Memnagar",
    "Mendu",
    "Mera",
    "Merta City",
    "Meru",
    "Methala",
    "Mettupalayam",
    "Mettupalayam",
    "Mettur",
    "Mhasla",
    "Mhaswad",
    "Mhow Cantt.",
    "Mhowgaon",
    "Mihijam",
    "Mihona",
    "Milak",
    "Minicoy",
    "Minjur",
    "Mira-Bhayandar",
    "Miranpur",
    "Mirganj",
    "Mirganj",
    "Mirik",
    "Mirpur Turk",
    "Miryalguda",
    "Mirzapur-cum-Vindhyachal",
    "Misrikh-cum-Neemsar",
    "Mithapur",
    "Mithe Pur",
    "Modak",
    "Modakurichi",
    "Modasa",
    "Modinagar",
    "Moga",
    "Mogra Badshahpur",
    "Mogravadi",
    "Mohammadabad",
    "Mohammadabad",
    "Mohammadi",
    "Mohan",
    "Mohanpur",
    "Mohanpur Mohammadpur",
    "Mohanur",
    "Mohgaon",
    "Mohiuddinagar",
    "Mohiuddinpur",
    "Mohpa",
    "Mohpada Alias Wasambe",
    "Moirang",
    "Mokameh",
    "Mokokchung",
    "Molakalmuru",
    "Molar Band",
    "Mon",
    "Monoharpur",
    "Moolakaraipatti",
    "Moonak",
    "Moovarasampettai",
    "Mopperipalayam",
    "Moradabad",
    "Moragudi",
    "Moran Town",
    "Moranhat",
    "Morar Cantt.",
    "Moreh",
    "Morena",
    "Morinda",
    "Mormugao",
    "Morshi",
    "Morvi",
    "Motera",
    "Moth",
    "Motihari",
    "Motipur",
    "Mount Abu",
    "Mowa",
    "Mowad",
    "Mrigala",
    "Mubarakpur",
    "Mudalgi",
    "Mudbidri",
    "Muddebihal",
    "Mudgal",
    "Mudhol",
    "Mudigere",
    "Mudkhed",
    "Mudukulathur",
    "Mudushedde",
    "Mughalsarai",
    "Mughalsarai Rly. Settlement",
    "Mugma",
    "Muhamma",
    "Muhammadabad",
    "Mukandgarh",
    "Mukasipidariyur",
    "Mukerian",
    "Mukhed",
    "Mukhiguda",
    "Mukkudal",
    "Mukrampur Khema",
    "Muktsar",
    "Mul",
    "Mulagumudu",
    "Mulanur",
    "Mulavukad",
    "Mulbagal",
    "Mulgund",
    "Mulki",
    "Mullanpur Dakha",
    "Mullanpur- Garibdas",
    "Multai",
    "Mulur",
    "Mundargi",
    "Mundera Bazar",
    "Munderi",
    "Mundgod",
    "Mundi",
    "Mundia",
    "Mundka",
    "Mundra",
    "Mundwa",
    "Mungaoli",
    "Mungeli",
    "Munger",
    "Muni Ki Reti",
    "Munirabad Project Area",
    "Munnur",
    "Muradnagar",
    "Muragachha",
    "Murbad",
    "Murgathaul",
    "Murgud",
    "Muri",
    "Murliganj",
    "Mursan",
    "Murshidabad",
    "Murtijapur",
    "Murud",
    "Muruganpalayam",
    "Murum",
    "Murwara (Katni)",
    "Musabani",
    "Musafirkhana",
    "Musiri",
    "Mussoori",
    "Mustafabad",
    "Mustafabad",
    "Muthupet",
    "Muthur",
    "Muttayyapuram",
    "Muvattupuzha",
    "Muzaffarnagar",
    "Muzaffarpur",
    "Muzhappilangad",
    "Myladi",
    "Mysore",
    "N. Vanlaiphai",
    "N.Kawnpui",
    "Nabadwip",
    "Nabagram",
    "Nabagram Colony",
    "Nabarangapur",
    "Nabgram",
    "Nabha",
    "Nabinagar",
    "Nachane",
    "Nachhratpur Katabari",
    "Nadathara",
    "Nadaun",
    "Nadbai",
    "Nadiad",
    "Nadigaon",
    "Naduvattam",
    "Nagai Chaudhry",
    "Nagamangala",
    "Nagaon",
    "Nagapattinam",
    "Nagapur",
    "Nagar",
    "Nagardeole",
    "Nagari",
    "Nagarkurnool",
    "Nagaur",
    "Nagavakulam",
    "Nagda",
    "Nagercoil",
    "Nagina",
    "Nagla",
    "Nagod",
    "Nagojanahalli",
    "Nagothana",
    "Nagpur",
    "Nagram",
    "Nagri",
    "Nagri Kalan",
    "Nagrota Bagwan",
    "Nahan",
    "Naharkatiya",
    "Naharlagun",
    "Nai Bazar",
    "Nai Garhi",
    "Naihati",
    "Naila Janjgir",
    "Naina Devi",
    "Nainana Jat",
    "Nainital",
    "Nainital",
    "Nainpur",
    "Nainwa",
    "Najibabad",
    "Nakoda",
    "Nakodar",
    "Nakur",
    "Nala Sopara",
    "Nalagarh",
    "Nalbari",
    "NALCO",
    "Naldurg",
    "Nalgonda",
    "Nalkheda",
    "Nallampatti",
    "Nallur",
    "Namagiripettai",
    "Namakkal",
    "Nambiyur",
    "Nambol",
    "Namchi",
    "Namli",
    "Namna Kalan",
    "Namrup",
    "Namsai",
    "Nanakvada",
    "Nanauta",
    "Nand Prayag",
    "Nandambakkam",
    "Nanded-Waghala",
    "Nandej",
    "Nandesari",
    "Nandesari INA",
    "Nandgaon",
    "Nandgaon",
    "Nandivaram-Guduvancheri",
    "Nandura",
    "Nandurbar",
    "Nandyal",
    "Nangal",
    "Nangal Dewat",
    "Nangal Thakran",
    "Nangavalli",
    "Nangavaram",
    "Nangloi Jat",
    "Nanguneri",
    "Nanjangud",
    "Nanjikottai",
    "Nannilam",
    "Nanpara",
    "Naoriya Pakhanglakpa",
    "Naraingarh",
    "Naraini",
    "Naranammalpuram",
    "Naranapuram",
    "Narasaraopet",
    "Narasimhanaickenpalayam",
    "Narasimharajapura",
    "Narasingapuram",
    "Narasingapuram",
    "Narath",
    "Narauli",
    "Naraura",
    "Naravarikuppam",
    "Narayanavanam",
    "Narayangarh",
    "Narayanpet",
    "Naregal",
    "Narendra Nagar",
    "Nargund",
    "Narkanda",
    "Narkatiaganj",
    "Narkhed",
    "Narnaul",
    "Narnaund",
    "Narsapur",
    "Narsimhapur",
    "Narsingarh",
    "Narsinghgarh",
    "Narsingi",
    "Narsipatnam",
    "Narwana",
    "Narwar",
    "Nashik",
    "Nasirabad",
    "Nasiyanur",
    "Naspur",
    "Nasra",
    "Nasrullaganj",
    "Natham",
    "Nathampannai",
    "Nathdwara",
    "Natibpur",
    "Natrampalli",
    "Nattapettai",
    "Nattarasankottai",
    "Naubaisa Gaon",
    "Naudhia",
    "Naugachhia",
    "Naugawan Sadat",
    "Naupala",
    "Nautanwa",
    "NavagamGhed",
    "Navalgund",
    "Navalpattu",
    "Navelim",
    "Navghar-Manikpur",
    "Navi Mumbai",
    "Navi Mumbai (Panvel", "Raigarh)",
    "Navsari",
    "Nawa",
    "Nawabganj",
    "Nawabganj",
    "Nawabganj",
    "Nawabganj",
    "Nawada",
    "Nawalgarh",
    "Nawanshahr",
    "Nawapur",
    "Naya Baradwar",
    "Nayabazar",
    "Nayagarh",
    "Nazerath",
    "Nazira",
    "Nebadhai Duttapukur",
    "Nedumangad",
    "Needamangalam",
    "Neelagiri",
    "Neelankarai",
    "Neem-Ka-Thana",
    "Neemuch",
    "Nehon",
    "Nehtaur",
    "Neikkarapatti",
    "Neiyyur",
    "Nellikuppam",
    "Nellimaria",
    "Nelliyalam",
    "Nellore",
    "Nelmangala",
    "Nemili",
    "Nenmenikkara",
    "Nepanagar",
    "Neral",
    "Neripperichal",
    "Nerkunram",
    "Nerkuppai",
    "Nerunjipettai",
    "Neuton Chikhli Kalan",
    "New Barrackpur",
    "New Bongaigaon Rly. Colony",
    "New Delhi Municipal Council",
    "New Mahe",
    "Newa Talai",
    "Neykkarappatti",
    "Neyveli",
    "Neyyattinkara",
    "Nibra",
    "Nichlaul",
    "Nidadavole",
    "Nidhauli Kalan",
    "Nilagiri",
    "Nilakkottai",
    "Nilanga",
    "Nildoh",
    "Nilokheri",
    "Nimapada",
    "Nimbahera",
    "Nimbhore Budruk",
    "Ningthoukhong",
    "Nipani",
    "Nirmal",
    "Nirmali",
    "Nirsa",
    "Niwai",
    "Niwari",
    "Niwari",
    "Nizamabad",
    "Nizamabad",
    "Noamundi",
    "Nohar",
    "Noida",
    "Nokha",
    "Nokha",
    "Nokpul",
    "Nongmynsong",
    "Nongpoh",
    "Nongstoin",
    "Nongthymmai",
    "Noor Mahal",
    "Noorpur",
    "North Barrackpur",
    "North Dumdum",
    "North Guwahati",
    "North Lakhimpur",
    "Northern Rly. Colony",
    "Nowgaon",
    "Nowrozabad(Khodargama)",
    "Nowshehra",
    "Nuapatna",
    "Nuh",
    "Numaligarh Refinery Township",
    "Nurpur",
    "Nuzvid",
    "Nyoria Husainpur",
    "Nyotini",
    "O' Valley",
    "O.F.Khamaria",
    "Obedullaganj",
    "Obra",
    "OCL Industrialship",
    "Odaipatti",
    "Odaiyakulam",
    "Oddanchatram",
    "Ode",
    "Odugathur",
    "Oel Dhakwa",
    "Oggiyamduraipakkam",
    "Oinam",
    "Okha port",
    "Okni NO.II",
    "Olagadam",
    "Olavanna",
    "Old Maldah",
    "Omalur",
    "Omerkhan daira",
    "Omkareshwar",
    "Ondal",
    "Ongole",
    "Orachha",
    "Orai",
    "Oran",
    "Orathanadu (Mukthambalpuram)",
    "Ordinance Factory Itarsi",
    "Ordnance Factory Muradnagar",
    "Orla",
    "Osmanabad",
    "Osmania University",
    "Othakadai",
    "Othakalmandapam",
    "Ottappalam",
    "Ottapparai",
    "Ozar",
    "Ozhukarai",
    "P. J. Cholapuram",
    "P.Mettupalayam",
    "P.N.Patti",
    "Pachgaon",
    "Pachmarhi Cantt",
    "Pachora",
    "Pachore",
    "Pachperwa",
    "Pacode",
    "Padagha",
    "Padaiveedu",
    "Padampur",
    "Paddhari",
    "Padianallur",
    "Padirikuppam",
    "Padmanabhapuram",
    "Padmapur",
    "Padra",
    "Padrauna",
    "Paduvilayi",
    "Pahalgam",
    "Paharpur",
    "Pahasu",
    "Paintepur",
    "Pairagachha",
    "Paithan",
    "Pakaur",
    "Pal Chourai",
    "Palacole",
    "Palaganangudy",
    "Palai",
    "Palakkad",
    "Palakkodu",
    "Palakurthi",
    "Palamedu",
    "Palampur",
    "Palani",
    "Palani Chettipatti",
    "Palanpur",
    "Palasa @ Kasibugga",
    "Palasbari",
    "Palashban",
    "Palavakkam",
    "Palavansathu",
    "Palawa",
    "Palayad",
    "Palayam",
    "Palda",
    "Pale",
    "Palej",
    "Palera",
    "Palghar",
    "Pali",
    "Pali",
    "Pali",
    "Pali",
    "Pali",
    "Palia Kalan",
    "Palissery",
    "Palitana",
    "Palladam",
    "Pallapalayam",
    "Pallapalayam",
    "Pallapatti",
    "Pallapatti",
    "Pallapatti",
    "Pallathur",
    "Pallavaram",
    "Pallikaranai",
    "Pallikkunnu",
    "Pallikonda",
    "Pallipalayam",
    "Pallipalayam Agraharam",
    "Pallipattu",
    "Paluvai",
    "Palwal",
    "Palwancha",
    "Pammal",
    "Pampore",
    "Pamur",
    "Panagar",
    "Panagudi",
    "Panaimarathupatti",
    "Panaji",
    "Panapakkam",
    "Panara",
    "Panboli",
    "Panchet",
    "Panchgani",
    "Panchkula Urban Estate",
    "Panchla",
    "Panchpara",
    "Pandamangalam",
    "Pandariya",
    "Pandavapura",
    "Pandhana",
    "Pandharkaoda",
    "Pandharpur",
    "Pandharpur",
    "Pandhurna",
    "Pandua",
    "Pangachhiya (B)",
    "Panhala",
    "Paniara",
    "Panihati",
    "Panipat",
    "Panipat Taraf Ansar",
    "Panipat Taraf Rajputan",
    "Panipt Taraf Makhdum Zadgan",
    "Panna",
    "Pannaikadu",
    "Pannaipuram",
    "Panniyannur",
    "Panoor",
    "Panposh",
    "Panruti",
    "Pansemal",
    "Pantheeramkavu",
    "Panuhat",
    "Panvel",
    "Paonta Sahib",
    "Papampeta",
    "Papanasam",
    "Pappankurichi",
    "Papparapatti",
    "Papparapatti",
    "Pappinisseri",
    "Pappireddipatti",
    "Par Beliya",
    "Paradip",
    "Paramakudi",
    "Paramathi",
    "Paranda",
    "Parangipettai",
    "Parashkol",
    "Parasi",
    "Parasia",
    "Paratdih",
    "Paravai",
    "Paravoor",
    "Paravur",
    "Parbatsar",
    "Parbbatipur",
    "Parbhani",
    "Parcem",
    "Pardi",
    "Parichha",
    "Parikshitgarh",
    "Parlakhemundi",
    "Parli",
    "Parnera",
    "Parola",
    "Parole",
    "Parsadepur",
    "Partapur",
    "Partur",
    "Parvat",
    "Parvathipuram",
    "Parwanoo",
    "Pasan",
    "Paschim Jitpur",
    "Paschim Punropara",
    "Pasighat",
    "Pasthal",
    "Pasur",
    "Patala",
    "Patan",
    "Patan",
    "Patan",
    "Patan",
    "Patancheru",
    "Pataudi",
    "Pathalgaon",
    "Pathamadai",
    "Pathanamthitta",
    "Pathankot",
    "Pathardi",
    "Pathardih",
    "Patharia",
    "Pathiriyad",
    "Pathri",
    "Pathsala",
    "Patiala",
    "Patiyali",
    "Patna",
    "Patnagarh",
    "Patratu",
    "Pattabong Tea Garden",
    "Pattamundai",
    "Pattan",
    "Pattanagere",
    "Patti",
    "Patti",
    "Pattinam",
    "Pattiom",
    "Pattiveeranpatti",
    "Pattran",
    "Pattukkottai",
    "Patuli",
    "Patulia",
    "Patur",
    "Pauni",
    "Pauri",
    "Pavagada",
    "Pavaratty",
    "Pawai",
    "Payal",
    "Payyannur",
    "Pazhugal",
    "Pedana",
    "Peddapuram",
    "Peerkankaranai",
    "Pehlad Pur Bangar",
    "Pehowa",
    "Pen",
    "Pendra",
    "Penha-de-Franca",
    "Pennadam",
    "Pennagaram",
    "Pennathur",
    "Peraiyur",
    "Perakam",
    "Peralam",
    "Peralasseri",
    "Perambalur",
    "Peranamallur",
    "Peravurani",
    "Peringathur",
    "Perinthalmanna",
    "Periya Negamam",
    "Periyakodiveri",
    "Periyakulam",
    "Periyanaickenpalayam",
    "Periyapatti",
    "Periyasemur",
    "Pernampattu",
    "Pernem",
    "Perumagalur",
    "Perumandi",
    "Perumbaikad",
    "Perumbavoor",
    "Perumuchi",
    "Perundurai",
    "Perungalathur",
    "Perungudi",
    "Perungulam",
    "Perur",
    "Peth Umri",
    "Pethampalayam",
    "Pethanaickenpalayam",
    "Petlad",
    "Petlawad",
    "Petro Chemical Complex INA",
    "Phagwara",
    "Phalauda",
    "Phalna",
    "Phalodi",
    "Phaltan",
    "Phaphund",
    "Phek",
    "Phillaur",
    "Phulabani",
    "Phulera",
    "Phulia",
    "Phulpur",
    "Phulpur",
    "Phulwari Sharif",
    "Phulwaria",
    "Phunderdihari",
    "Phuphkalan",
    "Phusro",
    "Pichhore",
    "Pichhore",
    "Pihani",
    "Pilani",
    "Pilibanga",
    "Pilibhit",
    "Pilkhana",
    "Pilkhuwa",
    "Pillanallur",
    "Pimpri Chinchwad",
    "Pinahat",
    "Pinarayi",
    "Pindwara",
    "Pinjore",
    "Pipalsana Chaudhari",
    "Piparcity",
    "Pipariya",
    "Pipariya",
    "Pipiganj",
    "Pipili",
    "Piploda",
    "Piplya Mandi",
    "Pipraich",
    "Pipri",
    "Pirawa",
    "Piriyapatna",
    "Piro",
    "Pitapuram",
    "Pithampur",
    "Pithora",
    "Pithoragarh",
    "Podara",
    "Pokaran",
    "Poladpur",
    "Polasara",
    "Polay Kalan",
    "Polichalur",
    "Pollachi",
    "Polur",
    "Ponda",
    "Pondar Kanali",
    "Pondicherry",
    "Ponmani",
    "Ponnamaravathi",
    "Ponnampatti",
    "Ponnani",
    "Ponneri",
    "Ponnur",
    "Poolambadi",
    "Poolampatti",
    "Pooluvapatti",
    "Poonamallee",
    "Pooth Kalan",
    "Pooth Khurd",
    "Porbandar",
    "Porompat",
    "Porsa",
    "Port Blair",
    "Porur",
    "Pothanur",
    "Pothatturpettai",
    "Pottore",
    "Powayan",
    "Prantij",
    "Pratapgarh",
    "Pratapgarh",
    "Pratapgarh City",
    "Pratapsasan",
    "Pratitnagar",
    "Prayagpur",
    "Prithvipur",
    "Proddatur",
    "Pudu",
    "Pudukadai",
    "Pudukkottai",
    "Pudukkottai",
    "Pudupalaiyam Aghraharam",
    "Pudupalayam",
    "Pudupatti",
    "Pudupatti",
    "Pudupattinam",
    "Pudur (S)",
    "Puduvayal",
    "Pujali",
    "Pukhrayan",
    "Pul Pehlad",
    "Pulgaon",
    "Puliyankudi",
    "Puliyur",
    "Pullampadi",
    "Pulwama",
    "Punahana",
    "Punalur",
    "Punch",
    "Pundri",
    "Pune",
    "Pune",
    "Punganur",
    "Punjai Thottakurichi",
    "Punjaipugalur",
    "Punjaipuliampatti",
    "Puranattukara",
    "Puranpur",
    "Purba Tajpur",
    "Purdilnagar",
    "Puri",
    "Purna",
    "Purnia",
    "Purquazi",
    "Puruliya",
    "Purushottamnagar",
    "Purusottampur",
    "Purwa",
    "Pusad",
    "Pushkar",
    "Puthalam",
    "Puthukkad",
    "Puthunagaram",
    "Puttur",
    "Puttur",
    "Puvalur",
    "Puzhal",
    "Puzhathi",
    "Puzhithivakkam (Ullagaram)",
    "Pynthorumkhrah",
    "Qadian",
    "Qasimpur Power House Colony",
    "Qazigund",
    "Quammruddin Nagar",
    "Quepem",
    "Queula",
    "Quilandy",
    "Quthbullapur",
    "R.Pudupatti",
    "R.S.Mangalam",
    "Rabkavi Banhatti",
    "Rabupura",
    "Radaur",
    "Radhakund",
    "Radhanpur",
    "Rae Bareli",
    "Rafiganj",
    "Raghogarh -Vijaypur",
    "Raghudebbati",
    "Raghunathchak",
    "Raghunathpur",
    "Raghunathpur",
    "Raghunathpur (PS-Dankuni)",
    "Raghunathpur (PS-Magra)",
    "Rahatgarh",
    "Rahimatpur",
    "Rahon",
    "Rahta Pimplas",
    "Rahuri",
    "Raichur",
    "Raigachhi",
    "Raiganj",
    "Raigarh",
    "Raikot",
    "Raipur",
    "Raipur",
    "Raipur Rani",
    "Rairangpur",
    "Raisen",
    "Raisinghnagar",
    "Raja Ka Rampur",
    "Rajagangapur",
    "Rajahmundry",
    "Rajakhedi",
    "Rajakhera",
    "Rajaldesar",
    "Rajam",
    "Rajapalayam",
    "Rajapur",
    "Rajapur",
    "Rajarhat Gopalpur",
    "Rajasansi",
    "Rajauri",
    "Rajendranagar",
    "Rajgamar",
    "Rajgarh",
    "Rajgarh",
    "Rajgarh",
    "Rajgarh",
    "Rajgarh",
    "Rajgir",
    "Rajgurunagar (Khed)",
    "Rajkot",
    "Rajmahal",
    "Rajnagar",
    "Rajnandgaon",
    "Rajokri",
    "Rajpipla",
    "Rajpur",
    "Rajpur Sonarpur",
    "Rajpura",
    "Rajsamand",
    "Rajula",
    "Rajur",
    "Rajura",
    "Ram Garh",
    "Ramachandrapuram",
    "Ramachandrapuram",
    "Ramachandrapuram-Bhel Township )",
    "Ramagundam",
    "Raman",
    "Ramanagaram",
    "Ramanathapuram",
    "Ramanattukara",
    "Ramanayyapeta",
    "Ramanujganj",
    "Ramapuram",
    "Ramapuram",
    "Ramban",
    "Rambha",
    "Ramchandrapur",
    "Ramdass",
    "Ramdurg",
    "Rameswaram",
    "Rameswaram",
    "Ramganj Mandi",
    "Ramgarh",
    "Ramgarh Cantonment",
    "Ramjibanpur",
    "Ramkola",
    "Ramnagar",
    "Ramnagar",
    "Ramnagar",
    "Ramnagar",
    "Ramnagar",
    "Ramnagar",
    "Ramol",
    "Rampachodavaram",
    "Rampur",
    "Rampur",
    "Rampur Baghelan",
    "Rampur Bhawanipur",
    "Rampur Karkhana",
    "Rampur Maniharan",
    "Rampur Naikin",
    "Rampura",
    "Rampura",
    "Rampura Phul",
    "Rampurhat",
    "Ramtek",
    "Ranaghat",
    "Ranapur",
    "Ranavav",
    "Ranbir Singh Pora",
    "Ranchi",
    "Rangapara",
    "Rangia",
    "Rangpo",
    "Rani",
    "Rania",
    "Ranibennur",
    "Raniganj",
    "Ranikhet",
    "Ranip",
    "Ranipettai",
    "Ranipur",
    "Ranirbazar",
    "Ranoli",
    "Rapar",
    "Rashidpur Garhi",
    "Rasipuram",
    "Rasra",
    "Rasulabad",
    "Ratangarh",
    "Ratangarh",
    "Ratannagar",
    "Ratanpur",
    "Rath",
    "Ratia",
    "Ratibati",
    "Ratlam",
    "Ratlam Rly. Colony (Ratlam Kasba)",
    "Ratnagiri",
    "Rau",
    "Raurkela",
    "Raurkela Industrialship",
    "Raver",
    "Rawalsar",
    "Rawatbhata",
    "Rawatsar",
    "Raxaul Bazar",
    "Raya",
    "Rayachoti",
    "Rayadurg",
    "Rayagada",
    "Rayagiri",
    "Raybag",
    "Rayya",
    "Reasi",
    "Redhakhol",
    "Reengus",
    "Reethapuram",
    "Rehambal",
    "Rehli",
    "Rehti",
    "Reis Magos",
    "Religara alias Pachhiari",
    "Remuna",
    "Rengali Dam Projectship",
    "Renigunta",
    "Renukoot",
    "Reoti",
    "Repalle",
    "Resubelpara",
    "Revelganj",
    "Rewa",
    "Rewari",
    "Rewari (Rural)",
    "Richha",
    "Rikhabdeo",
    "Rishikesh",
    "Rishra",
    "Rishra",
    "Risia Bazar",
    "Risod",
    "Rithora",
    "Rly. Settlement Roza",
    "Robertsganj",
    "Robertson Pet",
    "Roha Ashtami",
    "Rohraband",
    "Rohru",
    "Rohtak",
    "Roing",
    "Ron",
    "Roorkee",
    "Roorkee",
    "Rosalpatti",
    "Rosera",
    "Roshan Pura alias Dichaon Khurd",
    "Rudauli",
    "Rudayan",
    "Rudra Prayag",
    "Rudrapur",
    "Rudrapur",
    "Rudravathi",
    "Ruiya",
    "Runji Gautampura",
    "Rupnagar",
    "Rura",
    "Rurki Kasba",
    "Rustamnagar Sahaspur",
    "S. Kannanur",
    "S.A.S. Nagar (Mohali)",
    "S.Kodikulam",
    "S.Nallur",
    "S.T. Power Project Town",
    "Sabalgarh",
    "Sabathu",
    "Sabroom",
    "Sachin",
    "Sachin INA",
    "Sadabad",
    "Sadalgi",
    "Sadasivpet",
    "Sadat",
    "Sadat Pur Gujran",
    "Sadaura",
    "Sadpur",
    "Sadri",
    "Sadulshahar",
    "Safidon",
    "Safipur",
    "Sagar",
    "Sagar",
    "Sagar Cantt.",
    "Sagwara",
    "Sahajadpur",
    "Sahanpur",
    "Sahapur",
    "Saharanpur",
    "Saharsa",
    "Sahaspur",
    "Sahaswan",
    "Sahatwar",
    "Sahawar",
    "Sahibabad Daulat Pur",
    "Sahibganj",
    "Sahjanwa",
    "Sahnewal",
    "Sahnidih",
    "Sahpau NP",
    "Saidpur",
    "Saidpur",
    "Saidul Ajaib",
    "Saiha",
    "Sailana",
    "Sailu",
    "Sainthal",
    "Sainthia",
    "Sairang",
    "Saitual",
    "Saiyad Raja",
    "Sakhanu",
    "Sakit",
    "Sakleshpur",
    "Sakti",
    "Salakati",
    "Salangapalayam",
    "Salap",
    "Salarpur Khadar",
    "Salaya",
    "Salem",
    "Salempur",
    "Saligao",
    "Saligram",
    "Salon",
    "Salumbar",
    "Salur",
    "Samalapuram",
    "Samalkha",
    "Samalkota",
    "Samana",
    "Samastipur",
    "Samathur",
    "Samba",
    "Sambalpur",
    "Sambavar Vadagarai",
    "Sambhal",
    "Sambhalka",
    "Sambhar",
    "Samdhan",
    "Samrala",
    "Samthar",
    "Samurou",
    "Sanand",
    "Sanaur",
    "Sanawad",
    "Sanchi",
    "Sanchore",
    "Sancoale",
    "Sandi",
    "Sandila",
    "Sandor",
    "Sandur",
    "Sangamner",
    "Sangareddy",
    "Sangaria",
    "Sangat",
    "Sangli-Miraj & Kupwad",
    "Sangod",
    "Sangole",
    "Sangrur",
    "Sanguem",
    "Sankaramanallur",
    "Sankarankoil",
    "Sankarapuram",
    "Sankari",
    "Sankarnagar",
    "Sankarpur",
    "Sankeshwar",
    "Sankhol",
    "Sankrail",
    "Sanoth",
    "Sanquelim",
    "Sansarpur",
    "Santipur",
    "Santokhgarh",
    "Santoshpur",
    "Santrampur",
    "Sanvordem",
    "Sao Jose-de-Areal",
    "Sapatgram",
    "Sarai Aquil",
    "Sarai Mir",
    "Saraidhela",
    "Saraipali",
    "Sarangarh",
    "Sarangpur",
    "Sarapaka",
    "Saravanampatti",
    "Sarbhog",
    "Sarcarsamakulam",
    "Sardarpur",
    "Sardarshahar",
    "Sardhana",
    "Sardulgarh",
    "Sarenga",
    "Sarigam INA",
    "Sarila",
    "Sarjamda",
    "Sarkaghat",
    "Sarkhej-Okaf",
    "Sarni",
    "Sarpi",
    "Sarsawan",
    "Sarthebari",
    "Sarupathar",
    "Sarupathar Bengali",
    "Sarwar",
    "Sasaram",
    "Sasauli",
    "Sasni",
    "Sasti",
    "Sasvad",
    "Satai",
    "Satalkheri",
    "Satana",
    "Satara",
    "Sathankulam",
    "Sathiyavijayanagaram",
    "Sathuvachari",
    "Sathyamangala",
    "Sathyamangalam",
    "Satigachha",
    "Satna",
    "Satrikh",
    "Sattenapalle",
    "Sattur",
    "Satwas",
    "Saunda",
    "Saundatti-Yellamma",
    "Saunkh",
    "Saurikh",
    "Sausar",
    "Savanur",
    "Savarkundla",
    "Savda",
    "Savner",
    "Sawai Madhopur",
    "Sawantwadi",
    "Sawari Jawharnagar",
    "Sawer",
    "Sayalgudi",
    "Sayan",
    "Sayapuram",
    "Secunderabad",
    "Sedam",
    "Seerapalli",
    "Seevur",
    "Sehore",
    "Seithur",
    "Sekmai Bazar",
    "Semaria",
    "Sembakkam",
    "Semmipalayam",
    "Senchoa Gaon",
    "Sendhwa",
    "Senthamangalam",
    "Sentharapatti",
    "Senur",
    "Seohara",
    "Seondha",
    "Seoni",
    "Seoni",
    "Seoni Malwa",
    "Seppa",
    "Seraikela",
    "Serampore",
    "Serchhip",
    "Serilingampalle",
    "Serpur",
    "Sethia",
    "Sethiathoppu",
    "Sevilimedu",
    "Sevugampatti",
    "Sewai",
    "Sewalkhas",
    "Sewarhi",
    "Shahabad",
    "Shahabad",
    "Shahabad",
    "Shahabad ACC",
    "Shahade",
    "Shahapur",
    "Shahbad",
    "Shahdol",
    "Shahganj",
    "Shahgarh",
    "Shahi",
    "Shahjahanpur",
    "Shahjahanpur",
    "Shahkot",
    "Shahpur",
    "Shahpur",
    "Shahpur",
    "Shahpur",
    "Shahpur",
    "Shahpur",
    "Shahpura",
    "Shahpura",
    "Shahpura",
    "Shahpura",
    "Shajapur",
    "Shaktigarh",
    "Shaktinagar",
    "Shamchaurasi",
    "Shamgarh",
    "Shamli",
    "Shamsabad",
    "Shamsabad",
    "Shankargarh",
    "Shankhanagar",
    "Shegaon",
    "Sheikhpura",
    "Shekhpura",
    "Shelar",
    "Shenbakkam",
    "Shendurjana",
    "Shenkottai",
    "Sheoganj",
    "Sheohar",
    "Sheopur",
    "Shergarh",
    "Sherghati",
    "Sherkot",
    "Shiggaon",
    "Shikarpur",
    "Shikarpur",
    "Shikohabad",
    "Shillong",
    "Shillong Cantt.",
    "Shimla",
    "Shimoga",
    "Shirdi",
    "Shirhatti",
    "Shirpur-Warwade",
    "Shirur",
    "Shirwal",
    "Shishgarh",
    "Shivajinagar",
    "Shivatkar (Nira)",
    "Shivdaspur",
    "Shivli",
    "Shivpuri",
    "Shivrajpur",
    "Shivrinarayan",
    "Shohratgarh",
    "Sholavandan",
    "Sholinganallur",
    "Sholingur",
    "Sholur",
    "Shoranur",
    "Shorapur",
    "Shrigonda",
    "Shrirampur",
    "Shrirampur(Rural)",
    "Shrirangapattana",
    "Shrivardhan",
    "Shujalpur",
    "Shupiyan",
    "Siana",
    "Sibsagar",
    "Siddapur",
    "Siddhaur",
    "Siddipet",
    "Sidhauli",
    "Sidhi",
    "Sidhpur",
    "Sidhpura",
    "Sidlaghatta",
    "Siduli",
    "Sihor",
    "Sihora",
    "Sijhua",
    "Sijua",
    "Sikanderpur",
    "Sikanderpur",
    "Sikandra",
    "Sikandra Rao",
    "Sikandrabad",
    "Sikar",
    "Sikhong Sekmai",
    "Sikka",
    "Sikkarayapuram",
    "Silao",
    "Silapathar",
    "Silchar",
    "Silchar Part-X",
    "Siliguri",
    "Sillewada",
    "Sillod",
    "Silvassa",
    "Simdega",
    "Simga",
    "Simla",
    "Sindgi",
    "Sindhnur",
    "Sindi",
    "Sindi Turf Hindnagar",
    "Sindkhed Raja",
    "Sindri",
    "Sinduria",
    "Singahi Bhiraura",
    "Singampuneri",
    "Singaperumalkoil",
    "Singapur",
    "Singarayakonda",
    "Singarva",
    "Singnapur",
    "Singoli",
    "Singrauli",
    "Singtam",
    "Singur",
    "Sinhasa",
    "Sini",
    "Sinnar",
    "Siolim",
    "Sira",
    "Siralkoppa",
    "Siras Pur",
    "Sirathu",
    "Sirauli",
    "Sirgiti",
    "Sirgora",
    "Sirhind -Fategarh",
    "Sirka",
    "Sirkali",
    "Sirmaur",
    "Sirohi",
    "Sironj",
    "Sirsa",
    "Sirsa",
    "Sirsaganj",
    "Sirsha",
    "Sirsi",
    "Sirsi",
    "Sirsilla",
    "Sirugamani",
    "Siruguppa",
    "Sirumugai",
    "Sisauli",
    "Siswa Bazar",
    "Sitamarhi",
    "Sitamau",
    "Sitapur",
    "Sitarganj",
    "Sithayankottai",
    "Sithurajapuram",
    "Siuliban",
    "Sivaganga",
    "Sivagiri",
    "Sivagiri",
    "Sivakasi",
    "Sivanthipuram",
    "Siwan",
    "Siwani",
    "Sobhaganj",
    "Socorro (Serula)",
    "Sogariya",
    "Sohagpur",
    "Sohna",
    "Sojat",
    "Sojat Road",
    "Solan",
    "Solapur",
    "Som",
    "Someshwar",
    "Sompeta",
    "Somvarpet",
    "Sonamukhi",
    "Sonamura",
    "Sonapur",
    "Sonari",
    "Sonatikiri",
    "Sonegaon (Nipani)",
    "Sonepur",
    "Songadh",
    "Sonipat",
    "Sonkatch",
    "Sonpeth",
    "Sopore",
    "Sorab",
    "Soro",
    "Soron",
    "South Dumdum",
    "Soyagaon",
    "Soyatkalan",
    "Sri Hargobindpur",
    "Sri Madhopur",
    "Srikakulam",
    "Srikalahasti",
    "Srikantabati",
    "Srimushnam",
    "Srinagar",
    "Srinagar",
    "Sringeri",
    "Srinivaspur",
    "Sriperumbudur",
    "Sriramapuram",
    "Sriramnagar",
    "Srirampur",
    "Srisailam Project (RFC) Township",
    "Srisailamgudem Devasthanam",
    "Srivaikuntam",
    "Srivilliputhur",
    "St.Thomas Mount-cum-Pallavaram",
    "Sualkuchi",
    "Suar",
    "Suchindram",
    "Sugauli",
    "Sugnu",
    "Suhagi",
    "Sujangarh",
    "Sujanpur",
    "Sukdal",
    "Suket",
    "Sukhmalpur Nizamabad",
    "Sukhrali",
    "Suleeswaranpatti",
    "Sultan Pur",
    "Sultan Pur Majra",
    "Sultanganj",
    "Sultanpur",
    "Sultanpur",
    "Sultanpur",
    "Sultanpur Lodhi",
    "Sulur",
    "Sulya",
    "Sumbal",
    "Sumerpur",
    "Sumerpur",
    "Sunabeda",
    "Sunam",
    "Sundarapandiam",
    "Sundarapandiapuram",
    "Sundargarh",
    "Sundarnagar",
    "Sunderbani",
    "Supaul",
    "Surada",
    "Surajgarh",
    "Surajkaradi",
    "Surajpur",
    "Surampatti",
    "Surandai",
    "Surat",
    "Suratgarh",
    "Surendranagar Dudhrej",
    "Surgana",
    "Suri",
    "Suriyampalayam",
    "Suriyawan",
    "Suryapet",
    "Suryaraopeta",
    "Susner",
    "Suthaliya",
    "Swamibagh",
    "Swamimalai",
    "T.Kallupatti",
    "Tadepalligudem",
    "Tadpatri",
    "Taherpur",
    "Taj Pul",
    "Takhatgarh",
    "Takhatpur",
    "Taki",
    "Tal",
    "Talai",
    "Talaja",
    "Talbandha",
    "Talbehat",
    "Talcher",
    "Talcher Thermal Power Station Township",
    "Talegaon Dabhade",
    "Talen",
    "Talgram",
    "Talikota",
    "Taliparamba",
    "Tallapalle",
    "Talod",
    "Talode",
    "Taloje Panchnad",
    "Talwandi Bhai",
    "Talwara",
    "Talwara",
    "Tambaram",
    "Tambaur-cum-Ahmadabad",
    "Tamluk",
    "Tanakpur",
    "Tanda",
    "Tanda",
    "Tandur",
    "Tangla",
    "Tanuku",
    "Taoru",
    "Tappa",
    "Tarakeswar",
    "Tarana",
    "Taranagar",
    "Taraori",
    "Tarapur",
    "Tarbha",
    "Taricharkalan",
    "Tarikere",
    "Tarn Taran",
    "Tarsali",
    "Tasgaon",
    "Tatarpur Lallu",
    "Tathavade",
    "Tati",
    "Tawang",
    "Tayilupatti",
    "Teegalapahad",
    "Tehri",
    "Tekadi",
    "Tekanpur",
    "Tekari",
    "Tekkalakota",
    "Telgaon",
    "Telhara",
    "Teliamura",
    "Tenali",
    "Tendu Kheda",
    "Tenkasi",
    "Tensa",
    "Tentulkuli",
    "Tenu Dam-cum- Kathhara",
    "Teonthar",
    "Terdal",
    "Tetri Bazar",
    "Tezpur",
    "Tezu",
    "Thadikombu",
    "Thaikkad",
    "Thakkolam",
    "Thakurdwara",
    "Thakurganj",
    "Thalainayar",
    "Thalakudi",
    "Thalassery",
    "Thaltej",
    "Thamaraikulam",
    "Thammampatti",
    "Thana Bhawan",
    "Thanamandi",
    "Thandla",
    "Thane",
    "Thanesar",
    "Thangadh",
    "Thanjavur",
    "Thanthoni",
    "Tharad",
    "Tharamangalam",
    "Tharangambadi",
    "Thathaiyangarpet",
    "Thedavur",
    "Thenambakkam",
    "Thengampudur",
    "Theni Allinagaram",
    "Thenkarai",
    "Thenkarai",
    "Thenthamaraikulam",
    "Thenthiruperai",
    "Thenzawl",
    "Theog",
    "Thesur",
    "Thevaram",
    "Thevur",
    "Thiagadurgam",
    "Thingalnagar",
    "Thiriya Nizamat Khan",
    "Thirukarungudi",
    "Thirukattupalli",
    "Thirumalayampalayam",
    "Thirumangalam",
    "Thirumazhisai",
    "Thirunagar",
    "Thirunageswaram",
    "Thiruneermalai",
    "Thirunindravur",
    "Thiruparankundram",
    "Thiruparappu",
    "Thiruporur",
    "Thiruppanandal",
    "Thirupuvanam",
    "Thirupuvanam",
    "Thiruthangal",
    "Thiruthuraipoondi",
    "Thiruvaiyaru",
    "Thiruvalam",
    "Thiruvalla",
    "Thiruvallur",
    "Thiruvankulam",
    "Thiruvarur",
    "Thiruvattaru",
    "Thiruvenkatam",
    "Thiruvennainallur",
    "Thiruverumbur",
    "Thiruvidaimarudur",
    "Thiruvithankodu",
    "Thisayanvilai",
    "Thittacheri",
    "Thodupuzha",
    "Thokur-62",
    "Thondamuthur",
    "Thondi",
    "Thongkhong Laxmi Bazar",
    "Thoothukkudi",
    "Thorapadi",
    "Thorapadi",
    "Thottada",
    "Thottipalayam",
    "Thottiyam",
    "Thoubal",
    "Thrippunithura",
    "Thrissur",
    "Thudiyalur",
    "Thumbe",
    "Thuraiyur",
    "Thuthipattu",
    "Thuvakudi",
    "Tigri",
    "Tihu",
    "Tijara",
    "Tikait Nagar",
    "Tikamgarh",
    "Tikri",
    "Tilda Newra",
    "Tilhar",
    "Tilpat",
    "Timarni",
    "Timiri",
    "Tindivanam",
    "Tindwari",
    "Tinsukia",
    "Tiptur",
    "Tira Sujanpur",
    "Tirodi",
    "Tirora",
    "Tirthahalli",
    "Tiruchanur",
    "Tiruchendur",
    "Tiruchengode",
    "Tiruchirappalli",
    "Tirukalukundram",
    "Tirukkoyilur",
    "Tirumakudal-Narsipur",
    "Tirumala",
    "Tirunelveli",
    "Tirupathur",
    "Tirupathur",
    "Tirupati",
    "Tirupati (NMA)",
    "Tiruppur",
    "Tirur",
    "Tirusulam",
    "Tiruttani",
    "Tiruvannamalai",
    "Tiruverkadu",
    "Tiruvethipuram",
    "Tiruvottiyur",
    "Tirwaganj",
    "Tisra",
    "Titabor Town",
    "Titagarh",
    "Titlagarh",
    "Titron",
    "Tittakudi",
    "Tlabung",
    "TNPL Pugalur",
    "Todabhim",
    "Todaraisingh",
    "Todra",
    "Tohana",
    "Tondi Fatehpur",
    "Tonk",
    "Topa",
    "Topchanchi",
    "Torban (Khetri Leikai)",
    "Tosham",
    "Totaladoh",
    "Tral",
    "Trimbak",
    "Trivandrum",
    "Tuensang",
    "Tufanganj",
    "Tuljapur",
    "Tulsipur",
    "Tumkur",
    "Tumsar",
    "Tundla",
    "Tundla Kham",
    "Tundla Rly. Colony",
    "Tuni",
    "Tura",
    "Turuvekere",
    "Uchana",
    "Uchgaon",
    "Udaipur",
    "Udaipur",
    "Udaipura",
    "Udaipurwati",
    "Udala",
    "Udalguri",
    "Udangudi",
    "Udayarpalayam",
    "Udgir",
    "Udhagamandalam",
    "Udhampur",
    "Udma",
    "Udpura",
    "Udumalaipettai",
    "Udupi",
    "Ugu",
    "Ujhani",
    "Ujhari",
    "Ujjain",
    "Ukai",
    "Ukhra",
    "Uklanamandi",
    "Ukwa",
    "Ulhasnagar",
    "Ullal",
    "Ullur",
    "Uluberia",
    "Ulundurpettai",
    "Umarga",
    "Umaria",
    "Umarkhed",
    "Umarkote",
    "Umarsara",
    "Umbar Pada Nandade",
    "Umbergaon",
    "Umbergaon INA",
    "Umrangso",
    "Umred",
    "Umreth",
    "Umri",
    "Umri Kalan",
    "Umri Pragane Balapur",
    "Un",
    "Un",
    "Una",
    "Una",
    "Uncha Siwana",
    "Unchahar",
    "Unchehara",
    "Unhel",
    "Uniara",
    "Unjalaur",
    "Unjha",
    "Unnamalaikadai",
    "Unnao",
    "Upleta",
    "Uppal Kalan",
    "Upper Sileru Project Site Camp",
    "Upper Tadong",
    "Uppidamangalam",
    "Uppiliapuram",
    "Uran",
    "Uran Islampur",
    "Urapakkam",
    "Uravakonda",
    "Uri",
    "Urla",
    "Urmar Tanda",
    "Usawan",
    "Usehat",
    "Usilampatti",
    "Utekhol",
    "Uthamapalayam",
    "Uthangarai",
    "Uthayendram",
    "Uthiramerur",
    "Uthukkottai",
    "Uthukuli",
    "Utran",
    "Utraula",
    "Uttar Bagdogra",
    "Uttar Durgapur",
    "Uttar Goara",
    "Uttar Kalas",
    "Uttar Kamakhyaguri",
    "Uttar Krishnapur Part-I",
    "Uttar Latabari",
    "Uttar Mahammadpur",
    "Uttar Pirpur",
    "Uttar Raypur",
    "Uttarahalli",
    "Uttarkashi",
    "Uttarpara Kotrung",
    "V. Pudur",
    "Vada",
    "Vadakara",
    "Vadakarai Keezhpadugai",
    "Vadakkanandal",
    "Vadakkuvalliyur",
    "Vadalur",
    "Vadamadurai",
    "Vadavalli",
    "Vadgaon",
    "Vadgaon Kasba",
    "Vadia",
    "Vadipatti",
    "Vadnagar",
    "Vadodara",
    "Vadugapatti",
    "Vadugapatti",
    "Vaghodia INA",
    "Vaijapur",
    "Vaikom",
    "Vairengte",
    "Vaitheeswarankoil",
    "Valangaiman",
    "Valapattanam",
    "Valasaravakkam",
    "Valavanur",
    "Vallabh Vidhyanagar",
    "Vallachira",
    "Vallam",
    "Valparai",
    "Valpoi",
    "Valsad",
    "Valsad INA",
    "Valvaithankoshtam",
    "Vanasthali",
    "Vanavasi",
    "Vandalur",
    "Vandavasi",
    "Vandiyur",
    "Vaniputhur",
    "Vaniyambadi",
    "Vanthali",
    "Vanvadi (Sadashivgad)",
    "Vaparala",
    "Vapi",
    "Vapi INA",
    "Varadarajanpettai",
    "Varam",
    "Varanasi",
    "Varanasi",
    "Varappuzha",
    "Varca",
    "Varkala",
    "Vartej",
    "Vasai",
    "Vasantnagar",
    "Vashind",
    "Vasna Borsad INA",
    "Vastral",
    "Vastrapur",
    "Vasudevanallur",
    "Vathirairuppu",
    "Vazhakkala",
    "Vazhapadi",
    "Vedapatti",
    "Vedaranyam",
    "Vedasandur",
    "Veeraganur",
    "Veerakeralam",
    "Veerakkalpudur",
    "Veerapandi",
    "Veerapandi",
    "Veerapandi",
    "Veerappanchatram",
    "Veeravanallur",
    "Veerbhadra",
    "Vehicle Fac. Jabalpur",
    "Vejalpur",
    "Velampalayam",
    "Velankanni",
    "Vellakinar",
    "Vellakoil",
    "Vellalur",
    "Vellimalai",
    "Vellore",
    "Vellottamparappu",
    "Velur",
    "Vengampudur",
    "Vengathur",
    "Vengurla",
    "Venkarai",
    "Venkatagiri",
    "Venkatapura",
    "Venmanad",
    "Vennanthur",
    "Vepagunta",
    "Veppathur",
    "Veraval",
    "Verkilambi",
    "Vetapalem",
    "Vettaikaranpudur",
    "Vettavalam",
    "Vicarabad",
    "Vidisha",
    "Vidyavihar",
    "Vijaigarh",
    "Vijainagar",
    "Vijainagar",
    "Vijalpor",
    "Vijapur",
    "Vijay Pur",
    "Vijayapura",
    "Vijayapuri",
    "Vijayapuri (North)",
    "Vijayawada",
    "Vijayraghavgarh",
    "Vikasnagar",
    "Vikramasingapuram",
    "Vikravandi",
    "Vilangudi",
    "Vilankurichi",
    "Vilapakkam",
    "Vilathikulam",
    "Vilavur",
    "Villiappally",
    "Villukuri",
    "Viluppuram",
    "Vinukonda",
    "Virajpet",
    "Viramgam",
    "Virar",
    "Viratnagar",
    "Virudhachalam",
    "Virudhunagar",
    "Virupakshipuram",
    "Visakhapatnam",
    "Visavadar",
    "Vishrampur",
    "Visnagar",
    "Viswanatham",
    "Vita",
    "Vitthal Udyognagar INA",
    "Vizianagaram",
    "Vrindavan",
    "Vyara",
    "Wadgaon Road",
    "Wadhwan",
    "Wadi",
    "Wadi",
    "Wadi ACC",
    "Waghapur",
    "Wai",
    "Wajegaon",
    "Walajabad",
    "Walajapet",
    "Walani",
    "Waliv",
    "Wanadongri",
    "Wanaparthi",
    "Wangjing",
    "Wangoi",
    "Wani",
    "Wankaner",
    "Wara Seoni",
    "Warangal",
    "Wardha",
    "Warhapur",
    "Warisaliganj",
    "Warora",
    "Warud",
    "Washim",
    "Wazirganj",
    "Weir",
    "Wellington",
    "Williamnagar",
    "Wokha",
    "Yadagirigutta",
    "Yadgir",
    "Yairipok",
    "Yamunanagar",
    "Yanam",
    "Yavatmal",
    "Yawal",
    "Yelahanka",
    "Yelandur",
    "Yelbarga",
    "Yellandu",
    "Yellapur",
    "Yemmiganur",
    "Yenagudde",
    "Yerkheda",
    "Yerraguntla",
    "Yevla",
    "Yol",
    "Zahirabad",
    "Zaidpur",
    "Zalod",
    "Zamania",
    "Zamin Uthukuli",
    "Zawlnuam",
    "Ziauddin Pur",
    "Zira",
    "Zirakpur",
    "Ziro",
    "Zunheboto"
]
  