import {
  Box,
  SimpleGrid,
  Image as ChakraImage,
  HStack,
  VStack,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import CreationCard from "./CreationCard";
import VideoCard from "./VideoCard";
const VideoGrid = ({ shortVideoData }) => {

  return (
    <SimpleGrid my={"2rem"}  gap={['0.5rem','0.6rem','1rem',"1rem"]} columns={[3]}>
      {shortVideoData
        .map((el) => (
          <VideoCard data={el} />
        ))}
    </SimpleGrid>
  );
};

export default VideoGrid;
