import { CREATE_COMMENT_ERROR, CREATE_COMMENT_LOADING, CREATE_COMMENT_SUCCESS, DELETE_COMMENT_ERROR, DELETE_COMMENT_LOADING, DELETE_COMMENT_SUCCESS, GET_COMMENT_BY_POST_ERROR, GET_COMMENT_BY_POST_LOADING, GET_COMMENT_BY_POST_SUCCESS, UPDATE_COMMENT_ERROR, UPDATE_COMMENT_LOADING, UPDATE_COMMENT_SUCCESS } from "./comment.types";

const initialState = {
  allComments:[],
  message:'',
  get_all_comments_loading: false,
  get_all_comments_error: '',
  get_posts_by_user_error: '',
  error: '',
  delete_loading:false
};
export const commentReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_COMMENT_BY_POST_LOADING: {
      return {
        ...state,
        get_all_comments_error: '',
        get_all_comments_loading: true,
        allComments:[]
      };
    }
    case GET_COMMENT_BY_POST_SUCCESS: {
      return {
        ...state,
        get_all_comments_error: '',
        get_all_comments_loading: false,
        allComments: payload
      };
    }
    case GET_COMMENT_BY_POST_ERROR: {
      return {
        ...state,
        get_all_comments_error:payload,
        get_all_comments_loading: false,
      };
    }
    case CREATE_COMMENT_LOADING: {
      return {
        ...state,
        error: '',
        message: ''
      };
    }
    case CREATE_COMMENT_SUCCESS: {
      state.allComments.push(payload);
      return {
        ...state,
        error: '',
        message:'comment created!!!'
      };
    }
    case CREATE_COMMENT_ERROR: {
      return {
        ...state,
        error: payload,
        message:''
      };
    }
    case UPDATE_COMMENT_LOADING: {
      return {
        ...state,
        error: '',
        message: ''
      };
    }
    case UPDATE_COMMENT_SUCCESS: {
      return {
        ...state,
        error: '',
        message: payload
      };
    }
    case UPDATE_COMMENT_ERROR: {
      return {
        ...state,
        error: payload,
        message:''
      };
    }
    case DELETE_COMMENT_LOADING: {
      return {
        ...state,
        error: '',
        message: '',
        delete_loading:true
      };
    }
    case DELETE_COMMENT_SUCCESS: {
      const filteredAllComments = state.allComments.filter((post)=>post.id!==payload);
      return {
        ...state,
        error: '',
        allComments:filteredAllComments,
        delete_loading:false
      };
    }
    case DELETE_COMMENT_ERROR: {
      return {
        ...state,
        error: payload,
        message:'',
        delete_loading:false
      };
    }
    default: {
      return state;
    }
  }
};
