export const DESIGNER_ROLE = 'designer';
export const USER_ROLE= 'user';
export const MODEL_ROLE = 'model';

export const RoleName = (role)=>{
    switch (role) {
        case DESIGNER_ROLE:
            return 'Fashion Designer'    
        case USER_ROLE:
            return 'User'    
        case MODEL_ROLE:
            return 'Model'    
        default:
            return '';
    }
}