import {
  Button,
  FormControl,
  FormLabel,
  HStack,
  Image,
  Input,
  ListItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  Textarea,
  UnorderedList,
  VStack,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import React from "react";
import Logo from "../../images/Logo.png";
import { useNavigate } from "react-router-dom";
import { themePrimaryColor } from "../../utils/theme";
import Footer from "../../components/Footer";

const Contact = () => {
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();
  return (
    <VStack
    // background={"linear-gradient(to bottom, #A0D5CB, #2dc9acb0)"}
     w={"100%"}
      minH={"100vh"}
      pt={"2rem"}
      justify={'space-between'}
    >
      <VStack w={"100%"}>
        <HStack justify={"center"}>
          {" "}
          <Image
            w={"6rem"}
            cursor={"pointer"}
            onClick={() => navigate("/")}
            // style={{
            //   backgroundImage:
            //     "linear-gradient(to right, rgb(37 151 217) 0%, rgb(163 73 191) 51%, rgb(37 151 217) 100%)",
            //   transition: "0.5s",
            //   backgroundSize: "200% auto",
            //   color: "white",
            //   boxShadow: "0 0 20px #eee",
            //   display: "block",
            // }}
            // _hover={{ backgroundPosition: "right center" }}
            // border={`1px solid ${themePrimaryColor}`}
            src={Logo}
          />{" "}
        </HStack>

        <VStack gap={'2rem'}w={['96%','96%','100%','100%']}>
          <Text
            fontSize={"1.5rem"}
            textAlign={"center"}
            color={themePrimaryColor}
            lineHeight={'1.7rem'}
          >
            Drodel Fashion Private Limited
          </Text>
          <UnorderedList
            w={["100%", "100%", "55%", "50%"]}
            fontSize={["0.85rem", "1rem", "1.2rem", "1.2rem"]}
            styleType="none"
          >
            <ListItem>
              Address: SRPL Chandranagar,SRPL Chandranagar, Arunachal Pradesh,
              India, 791113
            </ListItem>
            <ListItem>Email: help@drodelfashion.com</ListItem>
            <ListItem>Contact : +91 87945 43318</ListItem>
            <ListItem
              w={"100%"}
              cursor={"pointer"}
              textAlign={"center"}
              onClick={onOpen}
              pt={"1.3rem"}
            >
              Need Help?
            </ListItem>
          </UnorderedList>
        </VStack>
      </VStack>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Contact US</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <FormControl>
              <FormLabel>Full Name</FormLabel>
              <Input placeholder="Full Name" />
            </FormControl>

            <FormControl mt={4}>
              <FormLabel>Contact No</FormLabel>
              <Input placeholder="Contact No" />
            </FormControl>
            <FormControl mt={4}>
              <FormLabel>Email</FormLabel>
              <Input placeholder="Email" type="email" />
            </FormControl>
            <FormControl mt={4}>
              <FormLabel>Message</FormLabel>
              <Textarea placeholder={"type message"} />
            </FormControl>
          </ModalBody>

          <ModalFooter>
            <Button
              color={"white"}
              bgColor={themePrimaryColor}
              mr={3}
              onClick={() => {
                onClose();
                toast({
                  isClosable: true,
                  status: "success",
                  duration: 1000,
                  title: "form has been submitted",
                });
              }}
            >
              Submit
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Footer />
    </VStack>
  );
};

export default Contact;
