//Get COMMENT types
export const GET_COMMENT_BY_POST_LOADING = "get/comment/loading";
export const GET_COMMENT_BY_POST_SUCCESS= "get/comment/success";
export const GET_COMMENT_BY_POST_ERROR= "get/comment/error";

//Get COMMENT types
export const CREATE_COMMENT_LOADING = "create/comment/loading";
export const CREATE_COMMENT_SUCCESS= "create/comment/success";
export const CREATE_COMMENT_ERROR = "create/comment/error";

//Update COMMENT types
export const UPDATE_COMMENT_LOADING = "update/comment/loading";
export const UPDATE_COMMENT_SUCCESS= "update/comment/success";
export const UPDATE_COMMENT_ERROR = "update/comment/error";

//Delete COMMENT types
export const DELETE_COMMENT_LOADING = "delete/comment/loading";
export const DELETE_COMMENT_SUCCESS= "delete/comment/success";
export const DELETE_COMMENT_ERROR = "delete/comment/error";