import {
  Avatar,
  Box,
  HStack,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  VStack,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FaSearch } from "react-icons/fa";
import { themePrimaryColor } from "../../utils/theme";
import { RoleName } from "../../utils/constant";
import { useNavigate } from "react-router-dom";
import SuggestionsUser from "../../components/SuggestionsUser";

const Search = () => {
  const dispatch = useDispatch();
  const { allUsers, profileData,myFollowing } = useSelector((store) => store.userData);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [suggestionsUser, setSugggestionsUser] = useState([]);

  const navigate = useNavigate();
  const handleChange = (event) => {
    setSearchTerm(event.target.value);
    if (!event.target.value.length) {
      return;
    }
    const filteredResults = allUsers.filter(
      (user) =>
        (user.fullName
          .toLowerCase()
          .includes(event.target.value.toLowerCase()) ||
          user.email
            .toLowerCase()
            .includes(event.target.value.toLowerCase())) &&
        user.id !== profileData.id
    );
    setSearchResults(filteredResults);
  };
 
  useEffect(() => {
    if (!allUsers.length) {
    }
  }, []);
  return (
    <VStack w={["100%", "100%", "87%", "78%"]} pb={"1rem"}>
      <VStack
        gap={"2rem"}
        w={["98%", "85%", "68%", "55%"]}
        p={["0.3rem", "1.5rem", "2rem", "2rem"]}
      >
        <InputGroup size="md">
          <Input
            pr="4.5rem"
            type={"text"}
            placeholder="Search user"
            onChange={handleChange}
          />
          <InputRightElement>
            <Box
              as={FaSearch}
              color={themePrimaryColor}
              cursor={"pointer"}
            ></Box>
          </InputRightElement>
        </InputGroup>
        <VStack
          top={"1rem"}
          gap={"1.5rem"}
          overflowY={"auto"}
          w={"100%"}
          h={"55vh"}
          overflowX={"hidden"}
        >
          {!searchResults.length && searchTerm.length && (
            <Text>No user found</Text>
          )}
          {searchResults?.map((userData) => (
            <HStack
              cursor={"pointer"}
              onClick={() => navigate(`/${userData.id}`)}
              _hover={{ bgColor: "#f1ecec4f" }}
              p={"0.2rem"}
              w={"100%"}
              border={`1px solid ${themePrimaryColor}`}
              rounded={"0.2rem"}
              justify={"start"}
              flexWrap={"wrap"}
            >
              <HStack w={"100%"}>
                <Avatar
                  src={userData.profilePicture}
                  size={["md", "lg", "lg", "lg"]}
                />
                <VStack
                  gap={["0", "0.5rem", "0.5rem", "0.5rem"]}
                  align={"start"}
                  w={"80%"}
                >
                  <Text fontWeight={600} width={"100%"} lineHeight={"1rem"}>
                    {userData.fullName}
                  </Text>
                  <HStack
                    gap={["0.2rem", "0.4rem", "0.5rem", "0.5rem"]}
                    w={"100%"}
                    flexWrap={"wrap"}
                    justify={"space-between"}
                  >
                    {/* <Text fontWeight={500} color={"rgb(168, 168, 168)"}>
                    {userData.fullName}
                  </Text> */}
                    <Text ml={"auto"} fontWeight={500}>
                      {RoleName(userData.role)}
                    </Text>
                  </HStack>
                </VStack>
              </HStack>
            </HStack>
          ))}
        </VStack>
    <SuggestionsUser/>
      </VStack>
    </VStack>
  );
};

export default Search;
