import { createContext, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllUserProfile,
  getMyFollower,
  getMyFollowing,
  getUserProfile,
} from "../store/user/user.action";
import {
  getPostByUser,
  getAllPosts,
  getTrendingPosts,
  getAdminAllPost,
} from "../store/post/post.action";
import {
  collection,
  doc,
  documentId,
  onSnapshot,
  query,
  setDoc,
  Timestamp,
  where,
} from "firebase/firestore";
import { v4 } from "uuid";
import { db } from "../firebaseFile";
import { getSavedPosts } from "../store/saved/saved.action";
export const AppContext = createContext();
export const AppContextProvider = ({ children }) => {
  const [postModalData, setPostModalData] = useState({});
  const [postModalOpen, setPostModalOpen] = useState(false);
  const [trialModalOpen, setTrialModalOpen] = useState(false);
  const [currentPostCommentCount, setCurrentPostCommentCount] = useState(0);
  const [commentModalOpen, setCommentModalOpen] = useState(false);
  const [showMenuHamburger, setShowMenuHamburger] = useState(true);
  const [commentsData, setCommentsData] = useState({});
  const [firebaseUserId, setFirebaseUserId] = useState({});
  const [currentChatUser, setCurrentChatUser] = useState("");
  const [FooterComponent,setFooterComponent] = useState(null);
  const [fullScreen,setFullScreen] = useState('');
  const [tempBackgroundUrl, setTempBackgroundUrl] = useState("");
  const { profileData } = useSelector((store) => store.userData);
  const currentVideoRef = useRef(null);
  const { Token,isAuth,isAdmin,AdminToken } = useSelector((store) => store.authData);
  const dispatch = useDispatch();
  useEffect(() => {
    setTimeout(() => {
      if(isAdmin||isAuth){
        dispatch(getUserProfile(isAdmin?AdminToken:Token));
      };
    }, 2000);
  }, [Token]);
  useEffect(() => {
    if (Token && profileData.email?.length) {
      dispatch(getPostByUser(Token, profileData.id));
      dispatch(getAllPosts(Token, profileData.id));
      dispatch(getMyFollower(Token, profileData.id));
      dispatch(getMyFollowing(Token, profileData.id));
      dispatch(getSavedPosts(Token, profileData.id));
      dispatch(getTrendingPosts(Token, profileData.id));
    }
  }, [Token, profileData]);
  useEffect(() => {
    if ((isAdmin||isAuth) && profileData.email?.length) {
      dispatch(getAllUserProfile(isAdmin?AdminToken:Token));
      if(AdminToken){
        dispatch(getAdminAllPost(AdminToken,profileData.id));
      }
    }
  }, [Token,AdminToken, profileData]);
  const profileCreation = (user) => {
    if (user) {
      try {
        setDoc(doc(db, "users", user.uid), {
          uid: user.uid,
          displayName: user.displayName
            ? user.displayName
            : user.email.match(/^([^@]*)@/)[1],
          profile_pictures: user.photoURL ?? "",
          created_at: Timestamp.now(),
          email: user.email,
        }).then((res) => {
          // navigate('/chat');
        });
      } catch (err) {
        console.log(err, "firestore err");
        window.confirm(
          "Something went wrong during profile creation, Please Retry"
        ) === true && window.location.reload();
      }
    }
  };
  useEffect(() => {
    if (profileData?.email.length&&!isAdmin) {
      const q = query(
        collection(db, "users"),
        where("email", "==", profileData?.email)
      );
      onSnapshot(q, (querySnapshot) => {
        if (!querySnapshot.docs.length) {
          profileCreation({
            uid: v4(),
            email: profileData.email,
            displayName: profileData.fullName,
            photoUrl: profileData.profilePicture,
          });
        } else {
          setFirebaseUserId(querySnapshot.docs[0].data());
        }
      });
    }
  }, [profileData]);
  
  const handlePlay = (videoRef) => {
    if (
      currentVideoRef.current?.getInternalPlayer() &&
      currentVideoRef.current !== videoRef.current
    ) {
      currentVideoRef.current.getInternalPlayer().pause();// Pause the currently playing video
    }
    currentVideoRef.current = videoRef.current;
  };


  const contextValue = {
    postModalData,
    tempBackgroundUrl,
    setTempBackgroundUrl,
    setPostModalData,
    postModalOpen,
    setPostModalOpen,
    currentPostCommentCount,
    setCurrentPostCommentCount,
    commentModalOpen,
    setCommentModalOpen,
    commentsData,
    setCommentsData,
    firebaseUserId,
    setFirebaseUserId,
    showMenuHamburger,
    setShowMenuHamburger,
    currentChatUser,
    setCurrentChatUser,
    trialModalOpen,
    setTrialModalOpen,
    handlePlay,
    setFullScreen,
    fullScreen,
    FooterComponent,setFooterComponent
  };
  return (
    <AppContext.Provider value={contextValue}>{children}</AppContext.Provider>
  );
};
