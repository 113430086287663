import {
  Center,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  VStack,
  Button,
  Box,
  Image,
  useToast,
  Alert,
  AlertIcon,
  Image as ChakraImage,
  HStack,
  Checkbox,
} from "@chakra-ui/react";
import { Formik, Field } from "formik";
import React, { useContext, useEffect, useState } from "react";
import { BiHide, BiShow } from "react-icons/bi";
import Logo from "../../images/Logo.png";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { BsFacebook } from "react-icons/bs";
//   import { AppContext } from "../../context/AppContext";
import { useDispatch, useSelector } from "react-redux";
import Footer from "../../components/Footer";
import Begin from "../../components/Begin";
import HomeTrending from "../../components/HomeTrending";
import FollowUS from "../../components/FollowUS";
import { authSignupApi } from "../../store/auth/auth.action";
import { AUTH_SIGNUP_ERROR } from "../../store/auth/auth.types";
import axios from "axios";
import { baseurl } from "../../utils/api";
import { themePrimaryColor } from "../../utils/theme";
//   import {
//     AUTH_SIGNUP_ERROR,
//     AUTH_SIGNUP_SUCCESS,
//   } from "../../store/auth/auth.types";
//   import { authSignupApi } from "../../store/auth/auth.action";
const Roles = [
  { name: "Fashion Designer", id: "designer" },
  { name: "Model", id: "model" },
  { name: "User", id: "user" },
];
const Signup = () => {
  const [show, setShow] = useState(false);
  const [role, setRole] = useState();
  const toast = useToast();
  const { isAuth, signupMessage } = useSelector((store) => store.authData);
  const dispatch = useDispatch();
  const [error, setError] = useState("");
  let navigate = useNavigate();
  useEffect(() => {
    if (signupMessage.length) {
      toast({
        status: "success",
        title: "User Signup Successfully",
        isClosable: true,
        duration: 1800,
      });
    }
  }, [signupMessage, dispatch]);
  if (isAuth) {
    return <Navigate to={"/setting"} />;
  }
  const googleLogin = async () => {
    const response = await axios.get(`${baseurl}`);
    if (response.status == 200) {
      window.location.href = `${baseurl}/google`;
    }
  };

  return (
    <VStack w={"100%"} p={["0.5rem", "1rem", "1rem", "1rem"]} gap={"2rem"}>
      <VStack
        w={["90%", "70%", "48%", "32%"]}
        border={"1px solid rgb(219, 219, 219)"}
        py={"2rem"}
      >
        <ChakraImage
          w={"10rem"}
          // style={{
          //   backgroundImage:
          //     "linear-gradient(to right, rgb(37 151 217) 0%, rgb(163 73 191) 51%, rgb(37 151 217) 100%)",
          //   transition: "0.5s",
          //   backgroundSize: "200% auto",
          //   color: "white",
          //   boxShadow: "0 0 20px #eee",
          //   display: "block",
          // }}
          // _hover={{ backgroundPosition: "right center" }}
          // border={`1px solid ${themePrimaryColor}`}
          src={Logo}
        />
        <Heading
          fontSize={["1.7rem", "1.85rem", "1.9rem", "2rem"]}
          textAlign={"center"}
          fontWeight={100}
          fontFamily={" Lora, serif"}
        >
          CREATE ACCOUNT
        </Heading>
        <VStack mt={"1rem"} width={["93%", "93%", "90%", "90%"]}>
          <Formik
            initialValues={{
              fullName: "",
              email: "",
              password: "",
            }}
            onSubmit={(values) => {
              let { fullName, email, password } = values;
              if (!role) {
                toast({
                  status: "warning",
                  title: "Please select role",
                  isClosable: true,
                  duration: 1800,
                });
                return;
              }
              dispatch(
                authSignupApi({
                  fullName,
                  role: role,
                  email,
                  password,
                  userName: email,
                })
              ).then((res) => {
                if (res?.type == AUTH_SIGNUP_ERROR) {
                  toast({
                    status: "error",
                    title: res.payload,
                    isClosable: true,
                    duration: 1800,
                  });
                }
              });
            }}
          >
            {({ handleSubmit, errors, touched }) => (
              <form style={{ width: "100%" }} onSubmit={handleSubmit}>
                <VStack
                  gap={["1.1rem", "1.3rem", "1.4rem", "1.4rem"]}
                  align="flex-start"
                >
                  {error?.length && (
                    <Alert mt={"1rem"} status="error">
                      <AlertIcon />
                      {error}
                    </Alert>
                  )}
                  <FormControl
                    position={"relative"}
                    isInvalid={!!errors.role && touched.role}
                  >
                    <FormLabel m={"0"} htmlFor="role" fontWeight={700}>
                      Choose Role
                    </FormLabel>
                    <HStack gap={"0.5rem"} justify={'space-between'}>
                      {Roles?.map((el) => (
                        <Checkbox
                          onChange={(e)=>{
                            if(e.target.checked){
                              setRole(el.id);
                            }else{
                              setRole('');
                            }
                          }}
                          size={["sm", "md", "md", "lg"]}
                          colorScheme="orange"
                          isChecked={role===el.id}
                        >
                          {el.name}
                        </Checkbox>
                      ))}
                    </HStack>
                  </FormControl>
                  <FormControl
                    position={"relative"}
                    isInvalid={!!errors.email && touched.email}
                  >
                    <FormLabel m={"0"} htmlFor="email">
                      Email
                    </FormLabel>
                    <Field
                      as={Input}
                      id="email"
                      width={"100%"}
                      name="email"
                      type="email"
                      variant="outline"
                      borderRadius={"0"}
                      border="1.5px solid black"
                      _hover={{ background: "#ffffff", color: "black" }}
                      validate={(value) => {
                        let error;
                        if (!value) {
                          error = "Required";
                        } else if (
                          !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(
                            value
                          )
                        ) {
                          error = "Invalid email address";
                        }
                        return error;
                      }}
                    />
                    <FormErrorMessage mt={"0.2rem"} position={"absolute"}>
                      {errors.email}
                    </FormErrorMessage>
                  </FormControl>
                  <FormControl
                    position={"relative"}
                    isInvalid={!!errors.fullName && touched.fullName}
                  >
                    <FormLabel m={"0"} htmlFor="fullName">
                      Full Name
                    </FormLabel>
                    <Field
                      as={Input}
                      id="fullName"
                      width={"100%"}
                      name="fullName"
                      type="text"
                      variant="outline"
                      borderRadius={"0"}
                      border="1.5px solid black"
                      _hover={{ background: "#ffffff", color: "black" }}
                      validate={(value) => {
                        let error;
                        if (!value) {
                          error = "Required";
                        }
                        return error;
                      }}
                    />
                    <FormErrorMessage position={"absolute"} mt={"0.2rem"}>
                      {errors.fullName}
                    </FormErrorMessage>
                  </FormControl>
                  <FormControl
                    isInvalid={!!errors.password && touched.password}
                  >
                    <FormLabel m={"0"} htmlFor="password">
                      Password
                    </FormLabel>
                    <InputGroup position={"relative"}>
                      <Field
                        as={Input}
                        id="password"
                        name="password"
                        borderRadius={"0"}
                        border="1.5px solid black"
                        type={show ? "text" : "password"}
                        _hover={{ background: "#ffffff", color: "black" }}
                        validate={(value) => {
                          let error;

                          if (value.length < 7) {
                            error =
                              "Password must contain at least 8 characters";
                          }

                          return error;
                        }}
                      />
                      <InputRightElement width="3rem">
                        <Center
                          fontSize="30px"
                          pt={"0.2rem"}
                          bg="none"
                          onClick={() => setShow(!show)}
                        >
                          {show ? (
                            <BiHide style={{ cursor: "pointer" }} />
                          ) : (
                            <BiShow style={{ cursor: "pointer" }} />
                          )}
                        </Center>
                      </InputRightElement>
                    </InputGroup>
                    <FormErrorMessage mt={"0.2rem"} position={"absolute"}>
                      {" "}
                      {errors.password}
                    </FormErrorMessage>
                  </FormControl>
                  <Button
                    _hover={{
                      color: "color",
                      background: "#0064E0",
                    }}
                    type="submit"
                    bg="black"
                    color={"white"}
                    width="full"
                    outline={"none"}
                    border={"none"}
                    cursor={"pointer"}
                    rounded={"0rem"}
                    // h="48px"
                    mt={"1rem"}
                    letterSpacing={"3px"}
                  >
                    Lets Begin...
                  </Button>
                </VStack>
              </form>
            )}
          </Formik>
          <Text textAlign={"center"} mt={"1.5rem"}>
            Already have an account? <Link to="/login" style={{color:'#4285F4'}}>Login</Link>{" "}
          </Text>
          <Flex width={"100%"} align="center" justify="space-around">
            <Box borderBottom="2px solid gray" w="42%"></Box>
            <Text fontWeight="bold">OR</Text>
            <Box borderBottom="2px solid gray" w="42%"></Box>
          </Flex>
          <Flex
            align="center"
            bg="white"
            color="rgb(66,133,244)"
            cursor={"pointer"}
            rounded={"0.5rem"}
            w={"90%"}
            p={["0.1rem", 2, 2, 2]}
            gap={"0.5rem"}
            height={"42px"}
            justify={"center"}
            // onClick={signInWithGoogle}
            border={"1.5px solid #4285F4"}
            // _hover={{ background: "#ffebd7" }}
          >
            <Image
              w={["16px", "20px", "25px", "28px"]}
              src={"https://img.icons8.com/color/452/google-logo.png"}
            />
            <Text
              ml={["2px", "5px", "7px", "10px"]}
              letterSpacing={["1px", "2px", "2px", "2px"]}
              fontSize={["0.8rem", "0.97rem", "0.97rem", "0.97rem"]}
              onClick={googleLogin}
              fontWeight="bold"
              textAlign={"center"}
            >
              SIGN WITH THE GOOGLE
            </Text>
          </Flex>
        </VStack>
      </VStack>
      <Begin />
      <HomeTrending />
      <FollowUS />
      <Footer />
    </VStack>
    // D33C99
  );
};

export default Signup;
