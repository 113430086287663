import {
    Button,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalOverlay,
    VStack,
    useDisclosure,
    useToast,
    Image as ChakraImage,
  } from "@chakra-ui/react";
  import React, { useEffect, useState } from "react";
  import { FaChartLine, FaUserCircle } from "react-icons/fa";
  import { FiLogOut } from "react-icons/fi";
  import { MdOutlineQuiz } from "react-icons/md";
import Logo from '../../../images/Logo.png'
  import { useNavigate } from "react-router-dom";
import { themePrimaryColor } from "../../../utils/theme";
import { authLogoutApi } from "../../../store/auth/auth.action";
import { useDispatch, useSelector } from "react-redux";
import { SiPostcss } from "react-icons/si";

  const ButtonsList = [
    {
      title: "Users",
      icon: <FaUserCircle style={{ fontSize: "1.5rem" }} />,
      link: "/admin/users",
    },
    {
      title: "Posts",
      icon: <SiPostcss style={{ fontSize: "1.5rem" }} />,
      link: "/admin/posts",
    },
    { title: "Logout", icon: <FiLogOut style={{ fontSize: "1.5rem" }} /> },
  ];
  const AdminLeftSideBar = () => {
    const [active, setActive] = useState("");
    const toast = useToast();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const initialRef = React.useRef(null);
    const { AdminToken } = useSelector((store) => store.authData);
    const dispatch = useDispatch();
    const finalRef = React.useRef(null);
    const navigate = useNavigate();
    useEffect(() => {
  
    }, [])
    const handleLogout = () => {
      onClose();
      dispatch(authLogoutApi(AdminToken));
      toast({
        status: "success",
        title: "Successfully logout",
        isClosable: true,
        duration: 2000,
      });
    };
    return (
      <VStack borderRight={'1px solid'}       position={'sticky'}
      left={0}
      top={0}
   w={"18%"}>
    <ChakraImage
        w={['5rem','5rem','6rem',"7.5rem"]}
        onClick={()=>{navigate('/');setActive('/')}}
        cursor={'pointer'}
        // style={{
        //   backgroundImage:
        //     "linear-gradient(to right, rgb(37 151 217) 0%, rgb(163 73 191) 51%, rgb(37 151 217) 100%)",
        //   transition: "0.5s",
        //   backgroundSize: "200% auto",
        //   color: "white",
        //   boxShadow: "0 0 20px #eee",
        //   display: "block",
        // }}
        // _hover={{ backgroundPosition: "right center" }}
        src={Logo}
        // border={`1px solid ${themePrimaryColor}`}
      />
        <VStack w={"89.5%"} gap={"1rem"} minH={'100vh'} pt={"1rem"} align={"center"}>
          {ButtonsList.map((el) => (
            <Button
              key={el.title}
              outline={"none !important"}
              onClick={() => {
                setActive(el.title);
                if ("Logout" == el.title) {
                  onOpen();
                } else {
                  navigate(el.link);
                }
              }}
              _hover={{
                background: "#fafafa !important",
                color: `${themePrimaryColor} !important`,
              }}
              style={
                active === el.title
                  ? {
                    background: "white",
                    color: themePrimaryColor,
                  }
                  : {
                    background: "transparent",
                    color:  "black",
                  }
              }
              gap={"0.6rem"}
              fontWeight={400}
              // rounded={"6rem"}
              border={"none !important"}
              fontSize={"1.15rem"}
              p={"1.4rem 0.6rem"}
              w={"95%"}
              justifyContent={"start"}
              leftIcon={el.icon}
              colorScheme="white"
              color={"black"}
            >
              {el.title}
            </Button>
          ))}
        </VStack>
        <Modal
          initialFocusRef={initialRef}
          finalFocusRef={finalRef}
          isOpen={isOpen}
          onClose={onClose}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalCloseButton />
            <ModalBody
              display={"flex"}
              justifyContent={"center"}
              minH={"30vh"}
              gap={"1rem"}
              alignItems={"center"}
              pb={6}
            >
              <Button
                onClick={handleLogout}
                _hover={{
                  bgColor: "white",
                  color: themePrimaryColor,
                }}
                color={"white"}
                bgColor={themePrimaryColor}
                border={"2.4px solid #2e4b90 !important"}
              >
                Logout
              </Button>
              <Button
                onClick={onClose}
                color={themePrimaryColor}
                bg={"white"}
                border={"2.4px solid #2e4b90 !important"}
                _hover={{
                  bgColor: themePrimaryColor,
                  color: "white",
                }}
              >
                Cancel
              </Button>
            </ModalBody>
          </ModalContent>
        </Modal>
      </VStack>
    );
  };
  
  export default AdminLeftSideBar;
  