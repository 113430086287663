import {
  HStack,
  Heading,
  VStack,
} from "@chakra-ui/react";
import { Splide } from "@splidejs/react-splide";
import React, { useEffect, useState } from "react";
import { themePrimaryColor } from "../utils/theme";
import {  useSelector } from "react-redux";
import SuggestionCard from "./SuggestionCard";
const SuggestionsUser = () => {
  const [suggestionsUser, setSugggestionsUser] = useState([]);
  const { allUsers, profileData,myFollowing } = useSelector((store) => store.userData);
  useEffect(() => {
    if (allUsers.length) {
      let randomUsers = [];
      let users = allUsers.filter(
        (user) =>user.id !== profileData.id);
      let notFollowingUsers = [...users].filter(user =>( !myFollowing?.some(followingUser => followingUser.id === user.id)&&user.id !== profileData.id));
      for (let i = 0; i < (notFollowingUsers.length>10?10:notFollowingUsers.length); i++) {
        var randomIndex = Math.floor(Math.random() * notFollowingUsers.length);
        randomUsers.push(notFollowingUsers[randomIndex]);
        notFollowingUsers.splice(randomIndex, 1); // Remove the selected user to ensure uniqueness
      };
      setSugggestionsUser(randomUsers);
    }
  }, [allUsers]);

  return (   
    <>
    {
      suggestionsUser.length?
          <VStack
          maxW={"100%"}
          w={"100%"}
          align={"start"}
          gap={"1rem"}
        >
        
          <Heading
            color={themePrimaryColor}
            pt={"1rem"}
            fontSize={['1.45rem','1.5rem','1.8rem',"2rem"]}
            fontWeight={100}
            fontFamily={" Lora, serif"}
            pl={['0.5rem','0','0','0']}
          >
            Suggested for you
          </Heading>
          <HStack justify={"center"}  w={["100%"]} h={"100%"} align={"center"}>

          <Splide
          options={{
            rewind: true,
            gap: "0.2rem",
            type: "loop",
            autoplay: false,
            arrows: true,
            pagination: false,
            snap: false,
            speed: 800, 
            width:'100%',
            perPage: 2,
            perMove: 1,
            breakpoints: {
              769: {
                perPage: 1,
                perMove:1
              },
            },
          }}
        >

          {
            suggestionsUser?.map((el, i)=>(
              <SuggestionCard  userData={el}/>
            ))
          }
          </Splide>
          </HStack>
          </VStack>:<></>
    }
    
    
    </>     
  );
};

export default SuggestionsUser;
