import { HStack, Heading, VStack, useMediaQuery } from '@chakra-ui/react'
import React from 'react'
import ImageSlider from './ImageSlider';
import { v4 } from 'uuid';
import trending1 from '../images/trending/trending1.png'
import trending2 from '../images/trending/trending2.png'
import trending3 from '../images/trending/trending3.png'
import trending4 from '../images/trending/trending4.png'
import trending5 from '../images/trending/trending5.png'
const bannerData = [
    {
      img:trending1,
    },
    {
      img:trending2,
    },
    {
      img:trending3,
    },
    {
      img:trending4,
    },
    {
      img:trending5,
    },
  ];
const HomeTrending = () => {
  const [isLargerThen800] = useMediaQuery('(min-width: 800px)')
  const [isLargerThen600] = useMediaQuery('(min-width: 600px)')
  return (
    <VStack w={'100%'}   align={'start'}>
        <Heading px={['2rem','3rem','4rem','6rem']} color={'#0064E0'}  fontSize={['1.4rem','1.5rem','1.7rem','2rem']}>Express Your Fashion</Heading>      
        <VStack gap={['0.9rem','1.3rem','1.8rem','1.8rem']} align={'start'} w={'100%'} p={['1rem','1rem','3rem','3rem']} border={'1px solid transparent'}>
          <ImageSlider imagesHeight={!isLargerThen600?'33vh':!isLargerThen800?'40vh':'50vh'} gap={!isLargerThen600?'1rem':!isLargerThen800?'1.8rem':'2rem'} bannerData={bannerData} perPage={!isLargerThen600?2:!isLargerThen800?4:5} perMove={1}/>
        </VStack>
    </VStack>
  )
}

export default HomeTrending
