import {
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Image as ChakraImage,
  VStack,
  Button,
  Spinner,
  useMediaQuery,
} from "@chakra-ui/react";
import React, { useContext, useEffect, useState } from "react";
import PostHeader from "./PostHeader";
import IndividualComment from "./IndividualComment";
import PostFooter from "./PostFooter";
import CommentInput from "./CommentInput";
import { useDispatch, useSelector } from "react-redux";
import { getCommentsByUser } from "../store/comment/comment.action";
import { AppContext } from "../context/AppContext";
import { themePrimaryColor } from "../utils/theme";
import CommentsModal from "./CommentsModal";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import { v4 } from "uuid";
import VideoPlayer from "./VideoPlayer";

const PostModal = () => {
  const dispatch = useDispatch();
  const { Token ,isAdmin,AdminToken} = useSelector((store) => store.authData);
  const {
    postModalOpen: handlePostModal,
    setPostModalOpen: setHandlePostModal,
    postModalData,
    currentPostCommentCount,
    setCommentModalOpen
  } = useContext(AppContext);
  const {
    caption = "",
    imageUrls = [],
    userData = {},
    likesCount = 0,
    sharesCount = 0,
    commentsCount = 0,
    postId = 0,
    isLikedByMe,
    videoUrl,
    postTime
  } = postModalData;
  const { get_all_comments_loading: commentLoading, allComments } = useSelector(
    (store) => store.commentData
  );
  const [isLargerThan766] = useMediaQuery("(min-width: 766px)");
  const ClosePostModal = () => {
    setHandlePostModal(false);
  };
  useEffect(() => {
    if (handlePostModal) {
      dispatch(getCommentsByUser(isAdmin?AdminToken:Token, postId));
    }
  }, [postModalData]);
  return (
    <HStack>
      <Modal
        onClose={ClosePostModal}
        isOpen={handlePostModal}
        isCentered
        closeOnOverlayClick={false}
      >
        <ModalOverlay />
        <ModalContent h={"90vh"} minW={["95%", "95%", "90%", "70% !important"]}>
          <ModalHeader
            textAlign={"center"}
            borderBottom={"0.2px solid #f5f1f1"}
          ></ModalHeader>
          <ModalCloseButton
            onClick={() => setHandlePostModal(false)}
            background={"white !important"}
          />
          <ModalBody w={"100%"}>
            <HStack
              w={"100%"}
              h={"100%"}
              flexDirection={["column", "column", "row", "row"]}
              align={["center", "center", "start", "start"]}
            >
              <HStack
                justify={"center"}
                w={["100%", "100%", "65%", "65%"]}
                h={"100%"}
                align={"center"}
              >
                {/* <ChakraImage maxH={'78vh'} w={'100%'} src={imageUrls[0]} /> */}
                {(imageUrls.length || videoUrl?.length) && (
                  <Splide
                    options={{
                      rewind: true,
                      gap: "0.2rem",
                      type: "fade",
                      autoplay: false,
                      arrows: (imageUrls.length == 1||(imageUrls.length==0&&videoUrl?.length)) ? false : true,
                      pagination: false,
                      snap: false,
                      speed: 1200,
                      perPage: 1,
                      perMove: 1,
                      breakpoints: {
                        640: {
                          perPage: 1,
                        },
                      },
                    }}
                  >
                    {[...imageUrls, videoUrl ? videoUrl : ""]
                      ?.filter((el) => el.length)
                      ?.map((el, i) => (
                        <SplideSlide style={{ width: "100%" }} key={v4()}>
                          <HStack w={"100%"} maxH={"78vh"} justify={'center'}>
                            {videoUrl?.length && i == imageUrls.length ? (
                              <>
                                                                          <VideoPlayer url={videoUrl}/>

                                {/* <ChakraImage
                                  h={"50%"}
                                  w={["75%", "75%", "50%", "40%"]}
                                  controls
                                  src={videoUrl}
                                  as={"video"}
                                /> */}
                              </>
                            ) : (
                              <ChakraImage
                                maxH={"78vh"}
                                maxW={"100%"}
                                objectFit={"cover"}
                                src={el}
                              />
                            )}
                          </HStack>
                        </SplideSlide>
                      ))}
                  </Splide>
                )}
              </HStack>
              {!isLargerThan766 && (
                <PostFooter
                  setCommentModalOpen={setCommentModalOpen}
                  isLikedByMe={isLikedByMe}
                  h={"6vh"}
                  postId={postId}
                  likesCount={likesCount}
                  sharesCount={sharesCount}
                  commentsCount={currentPostCommentCount}
                  isFullView={true}
                />
              )}
              <HStack
                display={["none", "none", "flex", "flex"]}
                align={"start"}
                h={"85vh"}
                w={"35%"}
              >
                <VStack
                  justify={"space-between"}
                  pt={"1rem"}
                  w={["100%"]}
                  h={"100%"}
                >
                  <PostHeader
                    h={"6vh"}
                    fullName={userData.fullName}
                    email={userData.email}
                    postId={postId}
                    role={userData.role}
                    isLikedByMe={isLikedByMe}
                    profilePicture={userData.profilePicture}
                    userId={userData.userId}
                    postTime={postTime}
                  />
                  <VStack
                    position={"relative"}
                    overflowY={"auto"}
                    borderY={"0.1px solid grey"}
                    w={"100%"}
                    height={"50vh"}
                  >
                    <IndividualComment
                      isContent={true}
                      userName={userData.email}
                      comment={caption}
                      profilePicture={userData.profilePicture}
                      fullName={userData.fullName}
                    />
                    {commentLoading && (
                      <Spinner
                        position={"absolute"}
                        top={"50%"}
                        size={"lg"}
                        thickness="3px"
                        color={themePrimaryColor}
                      />
                    )}
                    {allComments?.map((comment) => (
                      <IndividualComment
                        postId={postId}
                        commentId={comment.id}
                        userName={comment.userData.email}
                        comment={comment.content}
                        fullName={comment.userData.fullName}
                        profilePicture={comment.userData.profilePicture}
                      />
                    ))}
                  </VStack>
                  <PostFooter
                    isLikedByMe={isLikedByMe}
                    h={"6vh"}
                    postId={postId}
                    likesCount={likesCount}
                    sharesCount={sharesCount}
                    commentsCount={currentPostCommentCount}
                    isFullView={true}
                  />
                  <CommentInput
                    h={"10vh"}
                    setHandlePostModal={setHandlePostModal}
                    postId={postId}
                  />
                </VStack>
              </HStack>
            </HStack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </HStack>
  );
};

export default PostModal;
