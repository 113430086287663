import {  GET, apiRequest } from "../../utils/api";
import { unAuthorisedApi } from "../auth/auth.action";
import { GET_NOTIFICAITONS_ERROR, GET_NOTIFICAITONS_LOADING, GET_NOTIFICAITONS_SUCCESS } from "./notification.types";

export const getNotifications = (token,userId) => async (dispatch) => {
  try {
    dispatch({ type: GET_NOTIFICAITONS_LOADING});
    const res = await apiRequest({ type: GET, endpoint: `comments/getNotifications/${userId}`, token });
    return dispatch({ type: GET_NOTIFICAITONS_SUCCESS, payload: res.data});
  } catch (err) {
    err = err.response?.data?.message
      ? err.response?.data?.message
      : err.message;
    console.log(err,'checking  error in notification here');
    dispatch(unAuthorisedApi(err));
    return dispatch({
      type: GET_NOTIFICAITONS_ERROR ,
      payload: err
    });
  }
};
