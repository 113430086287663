import { Heading, Spinner, VStack } from "@chakra-ui/react";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import CreationGrid from "../../components/CreationGrid";
import { themePrimaryColor } from "../../utils/theme";
import VideoGrid from "../../components/VideoGrid";
import Empty from "../../components/Empty";

const ShortVideo = () => {
  const { getUserPosts, get_all_posts_error, get_posts_by_user_loading } = useSelector((store) => store.postData);
  
  return (
    <VStack w={["100%", "100%", "87%", "78%"]} pb={"1rem"}>
      <Heading
        color={themePrimaryColor}
        pt={"1rem"}
        fontSize={"2rem"}
        fontWeight={100}
        fontFamily={" Lora, serif"}
      >
        ShortVideos
      </Heading> 
      {get_posts_by_user_loading && (
        <Spinner
          position={"absolute"}
          top={"40%"}
          color={themePrimaryColor}
          thickness="4px"
          size={"xl"}
        />
      )}
      {(!get_posts_by_user_loading&&getUserPosts.filter((el) =>el.videoUrl?.length).length===0)?<Empty title="No ShortVideo Yet"/>: <VideoGrid shortVideoData={getUserPosts?.filter((el) =>el.videoUrl?.length)} />}
    </VStack>
  );
};

export default ShortVideo;
