import {
  Button,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Table,
  TableContainer,
  ModalBody,
  Tbody,
  Text,
  Th,
  Thead,
  Tr,
  VStack,
  useDisclosure,
  FormControl,
  Input,
  FormErrorMessage,
  InputRightElement,
  Center,
  InputGroup,
  HStack,
  useToast,
  Spinner,
  Select as ChakraSelect,
} from "@chakra-ui/react";
import React, { useContext, useState, useEffect, useRef } from "react";
import { Formik, Field, setNestedObjectValues } from "formik";
import { BiHide, BiShow } from "react-icons/bi";
import { FaArrowRight, FaArrowLeft } from "react-icons/fa";
import Select from 'react-select';
//   import {
//     AdminAllUsers,
//     AdminAllUsersPages,
//     AdminEditUser,
//     AdminGetSingleUser,
//   } from "../../../utils/api";
import UserCard from "../components/UserCard";
import { themePrimaryColor } from "../../../utils/theme";
import DeletePostModal from "../components/DeletePostModal";
import { apiRequest } from "../../../utils/api";
import { useSelector } from "react-redux";
import DeleteUserModal from "../components/DeleteUserModal";
const usersData = [
  { email: "abc@gmail.com", fullName:"aman yadav",},
];
const Users = () => {
  const [pageNo, setPageNo] = useState(1);
  const [limitNo, setLimitNo] = useState(10);
  const [totalPages, setTotalPages] = useState(1);
  const toast = useToast();
  const [userId, setUserId] = useState("");
  const [data, setData] = useState(usersData);
  const [searched, setSearched] = useState({});
  const [searchData, setSearchData] = useState([]);
  const [singleService, setSingleService] = useState({});
  const passwordRef = useRef();
  const blockRef = useRef();
  const creditRef = useRef();
  const [deleteModal,setDeleteModal] = useState(false);
  const [deleteUserId,setDeleteUserID]= useState(null);
  const [selectedUser,setSelectedUser] = useState(null);
  const { AdminToken } = useSelector((store) => store.authData);
  const { profileData,allUsers ,loading} = useSelector((store) => store.userData);
  useEffect(() => {
    let searchFormat = allUsers?.map((el)=>{
      return {label:el.fullName,value:el.id}
    })
    setSearchData(searchFormat);
  }, [allUsers]);
  useEffect(() => {

  }, [limitNo, pageNo]);
  const handleChange = (event) => {
      if (event.value != "") {
        console.log(event.value,'here');
        let searchedUser = allUsers.find((user)=>user.id==event.value);
        if(searchedUser){
          setSearched(searchedUser);
        }
      }
  };
  return (
    <VStack
      gap={"1rem"}
      bg={"white"}
      align={"end"}
      justify={"start"}
      height={"100%"}
      minH={"100vh"}
      w={"79%"}
      p={"1.5rem"}
    >
      <VStack align={"self-end"} w={"100%"}>
        <HStack w={"40%"}>
          <Select
            onChange={handleChange}
            options={searchData}
            placeholder="Search User"
            theme={(theme) => ({
              ...theme,
              borderRadius: 0,
              colors: {
                ...theme.colors,
                primary25: "rgb(23 118 76 / 28%)",
                primary: "#2e4b90",
              },
            })}
          />
        </HStack>
        {searched?.email?.length && (
          <TableContainer
            boxShadow={"rgba(149, 157, 165, 0.2) 0px 8px 24px"}
            w={"100%"}
          >
                 <Table
          variant="simple"
          colorScheme="facebook"
          bgColor="white"
          size={["md", "lg", "lg"]}
          overflowX={"auto"}
          boxShadow={"rgba(149, 157, 165, 0.2) 0px 8px 24px"}
        >
              <Thead background={themePrimaryColor} color={"white !important"}>
            <Tr>
              {" "}
              <Th color={"white"}>ProfilePhoto</Th>
              <Th color={"white"}>FullName</Th>
              <Th color={"white"}>Username</Th>
              <Th color={"white"}>Delete</Th>
            </Tr>
          </Thead>
              <Tbody>
               <UserCard
                  id={searched.id}
                  email={searched.email}
                  fullName={searched.fullName}
                  profilePicture={searched.profilePicture}
                  setDeleteModal={setDeleteModal}
                  setDeleteUserID={setDeleteUserID}
                />
              </Tbody>
            </Table>
          </TableContainer>
        )}
      </VStack>
      {loading && (
          <VStack justify={"center"} w={"100%"} minH={"50vh"}>
              <Spinner color={themePrimaryColor} thickness="4px" size={"xl"} />
          </VStack>
        )}
      {allUsers.length && !loading ? (
      <TableContainer
        boxShadow={"rgba(149, 157, 165, 0.2) 0px 8px 24px"}
        w={"100%"}
      >
        <Table
          variant="simple"
          colorScheme="facebook"
          bgColor="white"
          size={["md", "lg", "lg"]}
          overflowX={"auto"}
          boxShadow={"rgba(149, 157, 165, 0.2) 0px 8px 24px"}
        >
          <Thead background={themePrimaryColor} color={"white !important"}>
            <Tr>
              {" "}
              <Th color={"white"}>ProfilePhoto</Th>
              <Th color={"white"}>FullName</Th>
              <Th color={"white"}>Username</Th>
              <Th color={"white"}>Delete</Th>
            </Tr>
          </Thead>
          <Tbody>
            {allUsers?.map((el) => {
              return (
                <UserCard
                  key={el.id}
                  id={el.id}
                  email={el.email}
                  fullName={el.fullName}
                  profilePicture={el.profilePicture}
                  setDeleteModal={setDeleteModal}
                  setDeleteUserID={setDeleteUserID}
                />
              );
            })}
          </Tbody>
        </Table>
      </TableContainer>
    ) : (
          <></>
        )}
      {totalPages > 2 && (
        <HStack
          flexDirection={["column", "column", "row", "row"]}
          gap={"1rem"}
          align={"flex-end"}
          w={["100%", "100%", "auto", "auto"]}
          justify={"flex-end"}
        >
          <ChakraSelect
            variant="flushed"
            // color="black"
            // outlineColor={'green'}
            // borderColor={'green'}
            className="options"
            onChange={(e) => {
              setLimitNo(Number(e.target.value));
              setPageNo(1);
            }}
          >
            <option value={10}>10</option>
            <option value={20}>20</option>
            <option value={40}>40</option>
            <option value={60}>60</option>
            <option value={80}>80</option>
            <option value={100}>100</option>
          </ChakraSelect>
          <HStack
            w={["100%", "100%", "auto", "auto"]}
            gap={["0.1rem", "0.3rem", "0.8rem", "1rem"]}
          >
            <Button
              onClick={() => {
                setPageNo(1);
              }}
              _hover={{
                bgColor: "#2e4b90",
                color: "white",
              }}
              color={"#2e4b90"}
              bgColor={"white"}
              border={"1px solid #2e4b90"}
              padding={"0.5rem 1.3rem"}
              width={"fit-content"}
            >
              First
            </Button>
            <Button
              padding={0}
              _hover={{
                bgColor: "white",
                color: "#2e4b90",
              }}
              isDisabled={pageNo == 1}
              color={"white"}
              bgColor={"#2e4b90"}
              border={"1px solid #2e4b90"}
              onClick={() => {
                setPageNo((pre) => {
                  if (pre > 1) {
                    return pre - 1;
                  } else {
                    return pre;
                  }
                });
              }}
            >
              <FaArrowLeft />
            </Button>
            <Text
              padding={"0.5rem 1rem"}
              border={"1px solid gray"}
              borderRadius={"5px"}
            >
              {pageNo}
            </Text>
            <Text>/</Text>
            <Text
              padding={"0.5rem 1rem"}
              border={"1px solid gray"}
              borderRadius={"5px"}
            >
              {totalPages}
            </Text>
            <Button
              padding={0}
              _hover={{
                bgColor: "white",
                color: "#2e4b90",
              }}
              isDisabled={pageNo == totalPages}
              color={"white"}
              bgColor={"#2e4b90"}
              border={"1px solid #2e4b90"}
              onClick={() => {
                setPageNo((pre) => {
                  if (pre < totalPages) {
                    return pre + 1;
                  } else {
                    return pre;
                  }
                });
              }}
            >
              <FaArrowRight />
            </Button>
            <Button
              onClick={() => {
                setPageNo(totalPages);
              }}
              _hover={{
                bgColor: "#2e4b90",
                color: "white",
              }}
              color={"#2e4b90"}
              bgColor={"white"}
              border={"1px solid #2e4b90"}
              padding={"0.5rem 1.3rem"}
              width={"fit-content"}
            >
              Last
            </Button>
          </HStack>
        </HStack>
      )}
      {/* {!data.length && loading ? (
          <Empty title={"No reseller found!!!"} />
        ) : (
          <></>
        )} */}
        <DeleteUserModal        deleteUserId={deleteUserId} isReset={allUsers?.filter(el=>el.id==deleteUserId)}   setSearched={setSearched}         setDeleteUserID={setDeleteUserID}
 deleteModal={deleteModal} setDeleteModal={setDeleteModal}/>
    </VStack>
  );
};

export default Users;
