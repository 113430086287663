import { Heading, Spinner, VStack } from "@chakra-ui/react";
import React, { useEffect } from "react";
import { themePrimaryColor } from "../../utils/theme";
import { useSelector } from "react-redux";
import CreationGrid from "../../components/CreationGrid";
import Empty from "../../components/Empty";

const Saved = () => {
  const { savedPosts ,get_saved_posts_loading} = useSelector((store) => store.savedData);
  useEffect(()=>{
    console.log(savedPosts,'saved posts here ');
  },[savedPosts])
  return (
    <VStack w={["100%", "100%", "87%", "78%"]} pb={"1rem"}>
      <Heading
        color={themePrimaryColor}
        pt={"1rem"}
        fontSize={"2rem"}
        fontWeight={100}
        fontFamily={" Lora, serif"}
      >
        Collection
      </Heading>
      {get_saved_posts_loading && (
        <Spinner
          position={"absolute"}
          top={"40%"}
          color={themePrimaryColor}
          thickness="4px"
          size={"xl"}
        />
      )}
      {(!get_saved_posts_loading&&savedPosts.length===0)?<Empty title="No Collection Yet"/>: <CreationGrid creationData={savedPosts} isCollection={true}/>}
    </VStack>
  );
};

export default Saved;
