import { ADD_SAVED_POST_ERROR, ADD_SAVED_POST_LOADING, ADD_SAVED_POST_SUCCESS,  GET_SAVED_POSTS_ERROR, GET_SAVED_POSTS_LOADING, GET_SAVED_POSTS_SUCCESS, UPDATE_SAVED_POST_ERROR, UPDATE_SAVED_POST_LOADING, UPDATE_SAVED_POST_SUCCESS } from "./saved.types";



const initialState = {
  savedPosts:[],
  get_saved_posts_loading: false,
  get_saved_posts_error: '',
  error: '',
  message:"",
  loading:false,
};
export const savedReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_SAVED_POSTS_LOADING: {
      return {
        ...state,
        get_saved_posts_error: '',
        get_saved_posts_loading: true,
      };
    }
    case GET_SAVED_POSTS_SUCCESS: {
      return {
        ...state,
        get_saved_posts_error: '',
        get_saved_posts_loading: false,
        savedPosts: payload
      };
    }
    case GET_SAVED_POSTS_ERROR: {
      return {
        ...state,
        get_saved_posts_error: payload,
        get_saved_posts_loading: false,
      };
    }
    case ADD_SAVED_POST_LOADING: {
      return {
        ...state,
        error: '',
        message: '',
        loading:true,
      };
    }
    case ADD_SAVED_POST_SUCCESS: {
      return {
        ...state,
        error: '',
        message: payload,
        loading:false
      };
    }
    case ADD_SAVED_POST_ERROR: {
      return {
        ...state,
        error: payload,
        message:'',
        loading:false
      };
    }
    case UPDATE_SAVED_POST_LOADING: {
      return {
        ...state,
        get_saved_posts_error: '',
        get_saved_posts_loading: true,
      };
    }
    case UPDATE_SAVED_POST_SUCCESS: {
      return {
        ...state,
        get_saved_posts_error: '',
        get_saved_posts_loading: false,
        savedPosts: [payload,...state.savedPosts]
      };
    }
    case UPDATE_SAVED_POST_ERROR: {
      return {
        ...state,
        get_saved_posts_error: payload,
        get_saved_posts_loading: false,
      };
    }
    default: {
      return state;
    }
  }
};
