//Get post types
export const GET_POSTS_LOADING = "get/posts/loading";
export const GET_POSTS_SUCCESS= "get/posts/success";
export const GET_POSTS_ERROR = "get/posts/error";
//trending posts
export const GET_TRENDING_POSTS_LOADING = "get/trending-posts/loading";
export const GET_TRENDING_POSTS_SUCCESS= "get/trending-posts/success";
export const GET_TRENDING_POSTS_ERROR = "get/trending-posts/error";
//trial room
export const GET_TRIAL_POSTS_LOADING = "get/trial-posts/loading";
export const GET_TRIAL_POSTS_SUCCESS= "get/trial-posts/success";
export const GET_TRIAL_POSTS_ERROR = "get/trial-posts/error";

export const GET_POSTS_BY_USER_LOADING = "get/posts-by-user/loading";
export const GET_POSTS_BY_USER_SUCCESS= "get/posts-by-user/success";
export const GET_POSTS_BY_USER_ERROR = "get/posts-by-user/error";

export const GET_POSTS_BY_ANOTHER_USER_LOADING = "get/posts-by-user-another/loading";
export const GET_POSTS_BY_ANOTHER_USER_SUCCESS= "get/posts-by-user-another/success";
export const GET_POSTS_BY_ANOTHER_USER_ERROR = "get/posts-by-user-another/error";

export const GET_SINGLE_POST_LOADING = "get-single/post/loading";
export const GET_SINGLE_POST_SUCCESS= "get-single/post/success";
export const GET_SINGLE_POST_ERROR = "get-single/post/error";

//Get post types
export const CREATE_POST_LOADING = "create/post/loading";
export const CREATE_POST_SUCCESS= "create/post/success";
export const CREATE_POST_ERROR = "create/post/error";

//Update post types
export const UPDATE_POST_LOADING = "update/post/loading";
export const UPDATE_POST_SUCCESS= "update/post/success";
export const UPDATE_POST_ERROR = "update/post/error";

//Delete post types
export const DELETE_POST_LOADING = "delete/post/loading";
export const DELETE_POST_SUCCESS= "delete/post/success";
export const DELETE_POST_ERROR = "delete/post/error";

//Like post types
export const LIKE_POST_LOADING = "like/post/loading";
export const LIKE_POST_SUCCESS= "like/post/success";
export const LIKE_POST_ERROR = "like/post/error";

//Like post types
export const UNLIKE_POST_LOADING = "unilke/post/loading";
export const UNLIKE_POST_SUCCESS= "unlilke/post/success";
export const UNLIKE_POST_ERROR = "unlilke/post/error";

//Like post types
export const UPDATE_POST_COMMENT_COUNT_LOADING = "update-post/comment-count/loading";
export const UPDATE_POST_COMMENT_COUNT_SUCCESS= "update-post/comment-count/success";

export const UPDATE_POST_COMMENT_COUNT_ERROR= "update-post/comment-count/error";