import {
  Box,
  Image as ChakraImage,
  VStack,
  HStack,
  useMediaQuery,
  Skeleton,
} from "@chakra-ui/react";
import React, { useContext, useEffect, useState } from "react";
import { FaRegCommentAlt } from "react-icons/fa";
import { GrLike } from "react-icons/gr";
import { themePrimaryColor } from "../utils/theme";
import { AppContext } from "../context/AppContext";

const CreationCard = ({ data,isCollection }) => {
  const [isHover, setIsHover] = useState();
  const [isLoaded, setIsLoaded] = useState(false);
  const {
    caption,
    likesCount,
    sharesCount,
    commentsCount,
    id: postId,
    imageUrls,
    userData,
    isLikedByMe,
    videoUrl,
    createdAt
  } = data;
  const { setPostModalData, setPostModalOpen, setCurrentPostCommentCount } =
    useContext(AppContext);
  const handlePostModal = () => {
    setPostModalOpen(true);
    setPostModalData(isCollection?{
      caption,
      likesCount,
      sharesCount,
      commentsCount,
      postId,
      imageUrls,
      userData,
      isLikedByMe,
      videoUrl,
      postTime:createdAt
    }:{
      caption,
      likesCount,
      sharesCount,
      commentsCount,
      postId,
      imageUrls,
      userData,
      isLikedByMe,
      postTime:createdAt
    });
    setCurrentPostCommentCount(Number(commentsCount)||0);
  };
  useEffect(() => {
      setIsLoaded(true);
  }, []);
  return (
    <Box
      cursor={"pointer"}
      width={["30vw", "30vw", "25vw", "20vw"]}
      height={["30vw", "30vw", "25vw", "20vw"]}
      position="relative"
      overflow="hidden"
      onClick={handlePostModal}
      _hover={{ opacity: 0.8 }}
      onMouseLeave={() => setIsHover(false)}
      onMouseOver={() => setIsHover(true)}
    >
      {/* {!isLoaded?<Skeleton h={'100%'} w={'100%'} fitContent={true}>
    <
       } */}
      <Skeleton h={"100%"} isLoaded={isLoaded} w={"100%"} fitContent={true}>
        {
          (imageUrls.length===0&&videoUrl.length)?<ChakraImage
          h={"100%"}
          w={"100%"}
          objectFit={"cover"}
          as={'video'}
          src={videoUrl}
        />:  <ChakraImage
          h={"100%"}
          w={"100%"}
          objectFit={"cover"}
          src={imageUrls[0]}
        />
        }
      
      </Skeleton>
      {isLoaded && isHover && (
        <HStack
          w={"100%"}
          position={"absolute"}
          top={"40%"}
          left={"40%"}
          zIndex={1000}
          justify={"start"}
          gap={"1rem"}
        >
          <VStack gap={"0rem"}>
            <GrLike
              style={{
                cursor: "pointer",
                fontSize: "1.5rem",
                color: themePrimaryColor,
              }}
            />
            <Box as="span" color={"black"} fontSize={"0.9rem"}>
              {likesCount}
            </Box>
          </VStack>
          <VStack gap={"0rem"}>
            <FaRegCommentAlt
              style={{
                cursor: "pointer",
                fontSize: "1.5rem",
                color: themePrimaryColor,
              }}
            />
            <Box as="span" color={"black"} fontSize={"0.9rem"}>
              {commentsCount}
            </Box>
          </VStack>
        </HStack>
      )}
    </Box>
  );
};

export default CreationCard;
