import { Button, HStack, Image, Text, VStack } from "@chakra-ui/react";
import React from "react";
import Logo from "../../images/Logo.png";
import founder from "../../images/founder.jpg";
import styles from "./About.module.css";
import { useNavigate } from "react-router-dom";
import { themePrimaryColor } from "../../utils/theme";
import FollowUS from "../../components/FollowUS";
import Footer from "../../components/Footer";

function AboutUs() {
  const navigate = useNavigate();

  const handleLoginClick = () => {
    navigate("/login");
  };
  // className={styles.userProfile} 
  return (
    <VStack
      pt={"2rem"}
    >
          <VStack w={["98%", "95%", "90%", "80%"]}>
          <HStack justify={"center"}>
          <Image
            w={"6rem"}
            cursor={"pointer"}
            onClick={() => navigate("/")}
            // style={{
            //   backgroundImage:
            //     "linear-gradient(to right, rgb(37 151 217) 0%, rgb(163 73 191) 51%, rgb(37 151 217) 100%)",
            //   transition: "0.5s",
            //   backgroundSize: "200% auto",
            //   color: "white",
            //   boxShadow: "0 0 20px #eee",
            //   display: "block",
            // }}
            // _hover={{ backgroundPosition: "right center" }}
            src={Logo}
          />{" "}
        </HStack>
          <VStack>
            <Text
              color={themePrimaryColor}
              fontSize={['1.5rem','1.5rem','1.8rem',"2rem"]}
              fontWeight={"600"}
              textAlign={"center"}
            >
              About Us
            </Text>
            <Image    className={styles.userProfile}
 rounded={"50%"} w={['85vw','85vw','23vw',"23vw"]} src={founder} />
            <Text color={themePrimaryColor} fontSize={"1.5rem"} className={styles.PlatypiFont} fontWeight={600}>
              Lobsang Drodel
            </Text>
            <Text fontSize={"1.5rem"}  className={styles.PlatypiFont } fontWeight={600}>
              Founder & CEO
            </Text>
          </VStack>
          <VStack gap={'1rem'} align={'start'} p={["0.7rem", "0.7rem", "0.8rem", "0.5rem"]}>
            <p class="text-black text-md   text-[16px] text-spacing leading-5 ">
              Welcome to Drodel Fashion! We are a platform dedicated to
              connecting and empowering fashion designers, models, enthusiasts,
              and professionals.
            </p>

            <p class="text-black text-md   text-[16px] text-spacing leading-5 ">
              Our mission is to foster a vibrant and supportive community where
              fashion designers can showcase their talent, exchange ideas,
              collaborate on projects, and stay up-to-date with the latest
              industry trends. We believe in the power of collaboration and aim
              to create a space where designers can network, learn, and grow
              together.
            </p>

            <p class="text-black text-md   text-[16px] text-spacing leading-5 ">
              Whether you are an established fashion designer, an aspiring
              designer, or someone who simply appreciates the art of fashion,
              our community is the perfect place for you. Here, you can showcase
              your designs, receive feedback, and gain inspiration from fellow
              members.
            </p>
            <p class="text-black text-md   text-[16px] text-spacing leading-5 ">
              At the Drodel Fashion, you will find a rich array of resources,
              including articles, interviews, and tutorials, covering a wide
              range of topics such as design techniques, fabric selection,
              sustainable fashion, and industry news. We aim to provide valuable
              insights and knowledge to help you stay informed and navigate the
              dynamic world of fashion.
            </p>
            <p class="text-black text-md   text-[16px] text-spacing leading-5 ">
              Additionally, we regularly organize contests, workshops, and
              events to provide opportunities for designers to showcase their
              skills and connect with industry professionals. Through these
              initiatives, we strive to create a supportive and inclusive
              environment that celebrates the diversity and creativity of
              fashion.
            </p>
            <p class="text-black text-md   text-[16px] text-spacing leading-5 ">
              We value the unique perspectives and contributions of every member
              and promote a culture of respect and collaboration. We encourage
              constructive discussions, sharing of ideas, and creating
              meaningful connections within our community.
            </p>
            <p class="text-black text-md   text-[16px] text-spacing leading-5 ">
              Join us today and become a part of the vibrant Drodel Fashion
              community. Together, let's inspire, innovate, and shape the future
              of fashion.
            </p>
          </VStack>

          <div class="flex justify-center my-5 ">
            <Button
              onClick={handleLoginClick}
              bgColor={themePrimaryColor}
              color={'white'}
              w={'15rem'}
              _hover={{
                color:'black',
              }}
            >
              Get Started
            </Button>
          </div>  
      </VStack>
      <FollowUS/>
      <Footer/>
    </VStack>
  );
}

export default AboutUs;
