import {
    Center,
    FormControl,
    FormErrorMessage,
    FormLabel,
    Heading,
    Input,
    InputGroup,
    InputRightElement,
    VStack,
    Button,
    useToast,
    Image as ChakraImage,
  } from "@chakra-ui/react";
  import { Formik, Field } from "formik";
  import React, { useState } from "react";
  import { BiHide, BiShow } from "react-icons/bi";
  import Logo from "../../images/Logo.png";
  import {  useNavigate, useSearchParams } from "react-router-dom";
  import Footer from "../../components/Footer";
  import Swal from "sweetalert2";
  import { GET, POST, apiRequest, baseurl } from "../../utils/api";
import { themePrimaryColor } from "../../utils/theme";

  const ForgotPassword = () => {
    const [show, setShow] = useState(false);
    const [searchParams] = useSearchParams();
    let navigate = useNavigate();
    const toast = useToast();
    let token = searchParams.get("token");
    const handleForgotPassword = async(newPassword,confirmPassword)=>{
      apiRequest({ type: GET, endpoint: "auth/get-user-by-token", token })
      .then((res) => {
        apiRequest({
          type: POST,
          endpoint: "auth/resetPassword",
          token,
          data: { email: res.data.email, newPassword,confirmPassword },
        })
          .then(() => {
            Swal.fire({
              icon: "success",
              title: "Password reset successfully",
              showConfirmButton: false,
              timer: 1500,
            });
            setTimeout(() => {
              navigate("/login");
            }, 800);
          })
          .catch((err) => {
            err = err.response?.data?.message
              ? err.response?.data?.message
              : err.message;
            toast({
              status: "error",
              title: err,
              isClosable: true,
              duration: 1200,
            });
            // navigate("/login");
          });
      })
      .catch((err) => {
        err = err.response?.data?.message
          ? err.response?.data?.message
          : err.message;
        toast({
          status: "error",
          title: err,
          isClosable: true,
          duration: 1200,
        });
        // navigate("/login");
      });
    }
 
    return (
      <VStack w={"100%"} p={["0.5rem", "1rem", "1rem", "1rem"]} gap={"2rem"}>
        <VStack
          w={["90%", "70%", "48%", "32%"]}
          border={"1px solid rgb(219, 219, 219)"}
          py={"2rem"}
        >Confirm
          <ChakraImage
            w={"10rem"}
            cursor={'pointer'}
            // style={{
            //   backgroundImage:
            //     "linear-gradient(to right, rgb(37 151 217) 0%, rgb(163 73 191) 51%, rgb(37 151 217) 100%)",
            //   transition: "0.5s",
            //   backgroundSize: "200% auto",
            //   color: "white",
            //   boxShadow: "0 0 20px #eee",
            //   display: "block",
            // }}
            // onClick={()=>navigate('/')}
            // _hover={{ backgroundPosition: "right center" }}
            // border={`1px solid ${themePrimaryColor}`}
            src={Logo}
          />
          <Heading
            fontSize={["1.7rem", "1.85rem", "1.9rem", "2rem"]}
            textAlign={"center"}
            fontWeight={100}
            fontFamily={" Lora, serif"}
          >
            Forgot Password
          </Heading>
          <VStack mt={"1rem"} width={["93%", "93%", "90%", "90%"]}>
            <Formik
              initialValues={{
                confirmPassword: "",
                newPassword: "",
              }}
              onSubmit={(values) => {
                let { newPassword,confirmPassword } = values;
                handleForgotPassword(newPassword,confirmPassword)
              }}
              validate={(values) => {
                const errors = {};
                if (values.confirmPassword.length>6&&values.newPassword !== values.confirmPassword) {
                  errors.confirmPassword = "Passwords do not match";
                };
                return errors;
              }}
            
            >
              {({ handleSubmit, errors, touched }) => (
                <form style={{ width: "100%" }} onSubmit={handleSubmit}>
                  <VStack
                    gap={["1.1rem", "1.3rem", "1.4rem", "1.4rem"]}
                    align="flex-start"
                  >
                    <FormControl
                      isInvalid={!!errors.newPassword && touched.newPassword}
                    >
                      <FormLabel m={"0"} htmlFor="newPassword">
                        New Password
                      </FormLabel>
                      <InputGroup position={"relative"}>
                        <Field
                          as={Input}
                          id="newPassword"
                          name="newPassword"
                          borderRadius={"0"}
                          border="1.5px solid black"
                          type={show ? "text" : "password"}
                          _hover={{ background: "#ffffff", color: "black" }}
                          validate={(value) => {
                            let error;
  
                            if (value.length < 7) {
                              error =
                                "Password must contain at least 8 characters";
                            }
  
                            return error;
                          }}
                        />
                        <InputRightElement width="3rem">
                          <Center
                            fontSize="30px"
                            pt={"0.2rem"}
                            bg="none"
                            onClick={() => setShow(!show)}
                          >
                            {show ? (
                              <BiHide style={{ cursor: "pointer" }} />
                            ) : (
                              <BiShow style={{ cursor: "pointer" }} />
                            )}
                          </Center>
                        </InputRightElement>
                      </InputGroup>
                      <FormErrorMessage mt={"0.2rem"} position={"absolute"}>
                        {" "}
                        {errors.newPassword}
                      </FormErrorMessage>
                    </FormControl>
                    <FormControl
                      isInvalid={!!errors.confirmPassword && touched.confirmPassword}
                    >
                      <FormLabel m={"0"} htmlFor="confirmPassword">
                       Confirm Password
                      </FormLabel>
                      <InputGroup position={"relative"}>
                        <Field
                          as={Input}
                          id="confirmPassword"
                          name="confirmPassword"
                          borderRadius={"0"}
                          border="1.5px solid black"
                          type={show ? "text" : "password"}
                          _hover={{ background: "#ffffff", color: "black" }}
                          validate={(value) => {
                            let error;
                            if (value.length < 7) {
                              error =
                                "Password must contain at least 8 characters";
                            }
  
                            return error;
                          }}
                        />
                        <InputRightElement width="3rem">
                          <Center
                            fontSize="30px"
                            pt={"0.2rem"}
                            bg="none"
                            onClick={() => setShow(!show)}
                          >
                            {show ? (
                              <BiHide style={{ cursor: "pointer" }} />
                            ) : (
                              <BiShow style={{ cursor: "pointer" }} />
                            )}
                          </Center>
                        </InputRightElement>
                      </InputGroup>
                      <FormErrorMessage mt={"0.2rem"} position={"absolute"}>
                        {" "}
                        {errors.confirmPassword}
                      </FormErrorMessage>
                    </FormControl>
                    <Button
                      _hover={{
                        color: "color",
                        background: "#0064E0",
                      }}
                      type="submit"
                      bg="black"
                      color={"white"}
                      width="full"
                      outline={"none"}
                      border={"none"}
                      cursor={"pointer"}
                      rounded={"0rem"}
                      // h="48px"
                      mt={"1rem"}
                      letterSpacing={"3px"}
                    >
                      Reset Password
                    </Button>
                  </VStack>
                </form>
              )}
            </Formik>
          </VStack>
        </VStack>
        <Footer />
      </VStack>
      // D33C99
    );
  };
  
  export default ForgotPassword;
  