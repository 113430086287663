import {
  CREATE_POST_ERROR,
  CREATE_POST_LOADING,
  CREATE_POST_SUCCESS,
  DELETE_POST_ERROR,
  DELETE_POST_LOADING,
  DELETE_POST_SUCCESS,
  GET_POSTS_BY_ANOTHER_USER_ERROR,
  GET_POSTS_BY_ANOTHER_USER_LOADING,
  GET_POSTS_BY_ANOTHER_USER_SUCCESS,
  GET_POSTS_BY_USER_ERROR,
  GET_POSTS_BY_USER_LOADING,
  GET_POSTS_BY_USER_SUCCESS,
  GET_POSTS_ERROR,
  GET_POSTS_LOADING,
  GET_POSTS_SUCCESS,
  GET_SINGLE_POST_ERROR,
  GET_SINGLE_POST_LOADING,
  GET_SINGLE_POST_SUCCESS,
  LIKE_POST_ERROR,
  LIKE_POST_LOADING,
  LIKE_POST_SUCCESS,
  UNLIKE_POST_ERROR,
  UNLIKE_POST_LOADING,
  UNLIKE_POST_SUCCESS,
  UPDATE_POST_COMMENT_COUNT_ERROR,
  UPDATE_POST_COMMENT_COUNT_LOADING,
  UPDATE_POST_COMMENT_COUNT_SUCCESS,
  UPDATE_POST_ERROR,
  UPDATE_POST_LOADING,
  UPDATE_POST_SUCCESS,
  GET_TRENDING_POSTS_LOADING,
  GET_TRENDING_POSTS_SUCCESS,
  GET_TRENDING_POSTS_ERROR,
  GET_TRIAL_POSTS_LOADING,
  GET_TRIAL_POSTS_SUCCESS,
  GET_TRIAL_POSTS_ERROR,
} from "./post.types";

const initialState = {
  allPosts: [],
  trendingPosts: [],
  getUserPosts: [],
  trialRoomPosts:[],
  singlePost: {},
  message: "",
  get_all_posts_loading: true,
  get_all_posts_error: "",
  get_trending_posts_loading: true,
  get_trending_posts_error: "",
  get_trial_posts_loading: true,
  get_trial_posts_error: "",
  get_posts_by_user_loading: false,
  get_posts_by_user_error: "",
  get_single_post_loading: true,
  get_single_post_error: "",
  userPosts: [],
  error: "",
  get_posts_by_another_user_loading: false,
  get_posts_by_another_user_error: "",
  like_loading: false,
};
export const postReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_POSTS_BY_USER_LOADING: {
      return {
        ...state,
        get_posts_by_user_error: "",
        get_posts_by_user_loading: true,
      };
    }
    case GET_POSTS_BY_USER_SUCCESS: {
      return {
        ...state,
        get_posts_by_user_error: "",
        get_posts_by_user_loading: false,
        getUserPosts: payload,
      };
    }
    case GET_POSTS_BY_USER_ERROR: {
      return {
        ...state,
        get_posts_by_user_error: payload,
        get_posts_by_user_loading: false,
      };
    }
    case GET_POSTS_BY_ANOTHER_USER_LOADING: {
      return {
        ...state,
        get_posts_by_another_user_error: "",
        get_posts_by_another_user_loading: true,
        userPosts: [],
      };
    }
    case GET_POSTS_BY_ANOTHER_USER_SUCCESS: {
      return {
        ...state,
        get_posts_by_another_user_error: "",
        get_posts_by_another_user_loading: false,
        userPosts: payload,
      };
    }
    case GET_POSTS_BY_ANOTHER_USER_ERROR: {
      return {
        ...state,
        get_posts_by_another_user_error: payload,
        get_posts_by_another_user_loading: false,
        userPosts: [],
      };
    }
    case GET_POSTS_LOADING: {
      return {
        ...state,
        get_all_posts_error: "",
        get_all_posts_loading: true,
      };
    }
    case GET_POSTS_SUCCESS: {
      return {
        ...state,
        get_all_posts_error: "",
        get_all_posts_loading: false,
        allPosts: payload,
      };
    }
    case GET_POSTS_ERROR: {
      return {
        ...state,
        get_all_posts_error: payload,
        get_all_posts_loading: false,
      };
    }
    case GET_TRENDING_POSTS_LOADING: {
      return {
        ...state,
        get_trending_posts_error: "",
        get_trending_posts_loading: true,
      };
    }
    case GET_TRENDING_POSTS_SUCCESS: {
      return {
        ...state,
        get_trending_posts_error: "",
        get_trending_posts_loading: false,
        trendingPosts: payload,
      };
    }
    case GET_TRENDING_POSTS_ERROR: {
      return {
        ...state,
        get_trending_posts_error: payload,
        get_trending_posts_loading: false,
      };
    }
    case GET_TRIAL_POSTS_LOADING: {
      return {
        ...state,
        get_trial_posts_error: "",
        get_trial_posts_loading: true,
        trialRoomPosts: []
      };
    }
    case GET_TRIAL_POSTS_SUCCESS: {
      return {
        ...state,
        get_trial_posts_error: "",
        get_trial_posts_loading: false,
        trialRoomPosts: payload,
      };
    }
    case GET_TRIAL_POSTS_ERROR: {
      console.log('ERror here of trial',payload)
      return {
        ...state,
        get_trial_posts_error: payload,
        get_trial_posts_loading: false,
        trialRoomPosts: []
      };
    }
    case GET_SINGLE_POST_LOADING: {
      return {
        ...state,
        get_single_post_error: "",
        get_single_post_loading: true,
      };
    }
    case GET_SINGLE_POST_SUCCESS: {
      return {
        ...state,
        get_single_post_error: "",
        get_single_post_loading: false,
        singlePost: payload,
      };
    }
    case GET_SINGLE_POST_ERROR: {
      return {
        ...state,
        get_single_post_error: payload,
        get_single_post_loading: false,
      };
    }
    case CREATE_POST_LOADING: {
      return {
        ...state,
        error: "",
        message: "",
      };
    }
    case CREATE_POST_SUCCESS: {
      return {
        ...state,
        error: "",
        message: payload,
      };
    }
    case CREATE_POST_ERROR: {
      return {
        ...state,
        error: payload,
        message: "",
      };
    }
    case UPDATE_POST_LOADING: {
      return {
        ...state,
        error: "",
        message: "",
      };
    }
    case UPDATE_POST_SUCCESS: {
      return {
        ...state,
        error: "",
        message: payload,
      };
    }
    case UPDATE_POST_ERROR: {
      return {
        ...state,
        error: payload,
        message: "",
      };
    }
    case DELETE_POST_LOADING: {
      return {
        ...state,
        error: "",
        message: "",
      };
    }
    case DELETE_POST_SUCCESS: {
      const filteredGetUserPosts = state.getUserPosts.filter(
        (post) => post.id !== payload
      );
      const filterAllPosts = state.allPosts.filter(
        (post) => post.id !== payload
      );
      return {
        ...state,
        error: "",
        getUserPosts: filteredGetUserPosts,
        allPosts:filterAllPosts
      };
    }
    case DELETE_POST_ERROR: {
      return {
        ...state,
        error: payload,
        message: "",
      };
    }
    case LIKE_POST_LOADING: {
      return {
        ...state,
        error: "",
        message: "",
        like_loading: true,
      };
    }
    case LIKE_POST_SUCCESS: {
      let updateLikes = state.getUserPosts.map((post) => {
        if (post.id === payload.postId) {
          post.likesCount = Number(post.likesCount) + 1;
            post.isLikedByMe = true;
        }
        return post;
      });
      let updateTrendingLikes = state.trendingPosts.map((post) => {
        if (post.id === payload.postId) {
          post.likesCount = Number(post.likesCount) + 1;
            post.isLikedByMe = true;
        }
        return post;
      });
      let updateAllPosts = state.allPosts.map((post) => {
        if (post.id === payload.postId) {
          post.likesCount = Number(post.likesCount) + 1;
          post.isLikedByMe = true;
        }
        return post;
      });
      let updateTrialRoomPosts = state.trialRoomPosts.map((post) => {
        if (post.id === payload.postId) {
          post.likesCount = Number(post.likesCount) + 1;
          post.isLikedByMe = true;
        }
        return post;
      });
      let updateLikesAnother = state.userPosts.map((post) => {
        if (post.id === payload.postId) {
          post.likesCount = Number(post.likesCount) + 1;
            post.isLikedByMe = true;
        }
        return post;
      });
      return {
        ...state,
        error: "",
        getUserPosts: [...updateLikes],
        userPosts: [...updateLikesAnother],
        trialRoomPosts:[...updateTrialRoomPosts],
        allPosts: [...updateAllPosts],
        like_loading: false,
        trendingPosts:updateTrendingLikes
      };
    }
    case LIKE_POST_ERROR: {
      return {
        ...state,
        error: "",
        message: "",
        like_loading: false,
      };
    }
    case UNLIKE_POST_LOADING: {
      return {
        ...state,
        error: "",
        message: "",
        like_loading: true,
      };
    }
    case UNLIKE_POST_SUCCESS: {
      let updateLikes = state.getUserPosts.map((post) => {
        if (post.id === payload.postId) {
          post.likesCount = Number(post.likesCount) - 1;
          post.isLikedByMe = false;
        }
        return post;
      });
      let updateTrendingLikes = state.trendingPosts.map((post) => {
        if (post.id === payload.postId) {
          post.likesCount = Number(post.likesCount) - 1;
          post.isLikedByMe = false;
        }
        return post;
      });
      let updateTrialRoomPosts = state.trialRoomPosts.map((post) => {
        if (post.id === payload.postId) {
          post.likesCount = Number(post.likesCount) - 1;
          post.isLikedByMe = false;
        }
        return post;
      });

      let updateAllPosts = state.allPosts.map((post) => {
        if (post.id === payload.postId) {
          post.likesCount = Number(post.likesCount) - 1;
          post.isLikedByMe = false;
        }
        return post;
      });

      let updateLikesAnother = state.userPosts.map((post) => {
        if (post.id === payload.postId) {
          post.likesCount = Number(post.likesCount) - 1;
          post.isLikedByMe = false;
        }
        return post;
      });
      return {
        ...state,
        error: "",
        like_loading: false,
        getUserPosts: [...updateLikes],
        allPosts: [...updateAllPosts],
        userPosts: [...updateLikesAnother],
        trialRoomPosts: [...updateTrialRoomPosts],
        trendingPosts:updateTrendingLikes
      };
    }
    case UNLIKE_POST_ERROR: {
      return {
        ...state,
        error: "",
        message: "",
        like_loading: false,
      };
    }
    case UPDATE_POST_COMMENT_COUNT_LOADING: {
      return {
        ...state,
        error: "",
        message: "",
      };
    }
    case UPDATE_POST_COMMENT_COUNT_SUCCESS: {
      let updateCommnetCountOfPost = state.getUserPosts.map((post) => {
        if (post.id === payload.postId) {
          let commentCount = Number(post.commentsCount) + payload.value;
          post.commentsCount = commentCount;
        }
        return post;
      });
      let updateCommnetCountOfPostByAllPost = state.allPosts.map((post) => {
        if (post.id === payload.postId) {
          let commentCount = Number(post.commentsCount) + payload.value;
          post.commentsCount = commentCount;
        }
        return post;
      });
      let updateCommnetCountOfPostByTrending = state.trendingPosts.map((post) => {
        if (post.id === payload.postId) {
          let commentCount = Number(post.commentsCount) + payload.value;
          post.commentsCount = commentCount;
        }
        return post;
      });
      let updateCommentOfTrialRoom = state.trialRoomPosts.map((post) => {
        if (post.id === payload.postId) {
          let commentCount = Number(post.commentsCount) + payload.value;
          post.commentsCount = commentCount;
        }
        return post;
      });
      let updateCommnetCountOfPostByUser = state.userPosts.map((post) => {
        if (post.id === payload.postId) {
          let commentCount = Number(post.commentsCount) + payload.value;
          post.commentsCount = commentCount;
        }
        return post;
      });
      return {
        ...state,
        error: "",
        getUserPosts: [...updateCommnetCountOfPost],
        userPosts: [...updateCommnetCountOfPostByUser],
        allPosts: updateCommnetCountOfPostByAllPost,
        trialRoomPosts:updateCommentOfTrialRoom,
        trendingPosts:updateCommnetCountOfPostByTrending
      };
    }
    case UPDATE_POST_COMMENT_COUNT_ERROR: {
      return {
        ...state,
        error: payload,
        message: "",
      };
    }
    default: {
      return state;
    }
  }
};
