import { HStack } from "@chakra-ui/react";
import React, { useEffect } from "react";
import AdminLeftSideBar from "./components/AdminLeftSideBar";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import DashboardCard from "./components/DashboardCard";
import PostModal from "../../components/PostModal";
import TrialModal from "../../components/TrialModal";
import CommentsModal from "../../components/CommentsModal";
const Admin = () => {
  const location = useLocation();
  const navigate = useNavigate();
  useEffect(()=>{
    if(location.pathname=='/admin'||location.pathname=='/admin/'){
      navigate('/admin/users');
    }
  },[]);
  return (
    <>
    <HStack align={"start"}  justify={"start"} w={"100%"}>
      <AdminLeftSideBar />
      {location.pathname == "/admin" && <DashboardCard />}
      {location.pathname == "/admin/" && <DashboardCard />}
      <PostModal />
      <TrialModal />
      <CommentsModal/>
      <Outlet />
    </HStack>
    </>
  );
};

export default Admin;
