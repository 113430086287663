//LOGINAPI
export const USER_PROFILE_LOADING = "user/profile/loading";
export const USER_PROFILE_SUCCESS = "user/profile/success";
export const USER_PROFILE_ERROR = "user/profile/error";

export const UPDATE_USER_PROFILE_LOADING = "update-user/profile/loading";
export const UPDATE_USER_PROFILE_SUCCESS = "update-user/profile/success";
export const UPDATE_USER_PROFILE_ERROR = "update-user/profile/error";

export const GET_ALL_USER_PROFILE_LOADING = "get-all-user/profile/loading";
export const GET_ALL_USER_PROFILE_SUCCESS = "get-all-user/profile/success";
export const GET_ALL_USER_PROFILE_ERROR = "get-all-user/profile/error";

export const DELETE_USER_PROFILE_LOADING = "delete/profile/loading";
export const DELETE_USER_PROFILE_SUCCESS = "delete/profile/success";
export const DELETE_USER_PROFILE_ERROR = "delete/profile/error";

export const GET_ANOTHER_USER_PROFILE_LOADING = "get-ANOTHER-user/profile/loading";
export const GET_ANOTHER_USER_PROFILE_SUCCESS = "get-ANOTHER-user/profile/success";
export const GET_ANOTHER_USER_PROFILE_ERROR = "get-ANOTHER-user/profile/error";
//follow types
export const UPDATE_USER_FOLLOWER_LOADING = "update_user-follower/profile/loading";
export const UPDATE_USER_FOLLOWER_SUCCESS = "update_user-follower/profile/success";
export const UPDATE_USER_FOLLOWER_ERROR = "update_user-follower/profile/error";

export const UPDATE_USER_FOLLOWING_SUCCESS = "update-user-following/profile/success";
export const UPDATE_USER_FOLLOWING_ERROR = "update-user-following/profile/error";
export const UPDATE_USER_FOLLOWING_LOADING = "update-user-following/profile/loading";

export const GET_USER_FOLLOWING_SUCCESS = "get-user-following/profile/success";
export const GET_USER_FOLLOWING_ERROR = "get-user-following/profile/error";
export const GET_USER_FOLLOWING_LOADING = "get-user-following/profile/loading";

export const GET_USER_FOLLOWER_SUCCESS = "get-user-follower/profile/success";
export const GET_USER_FOLLOWER_ERROR = "get-user-follower/profile/error";
export const GET_USER_FOLLOWER_LOADING = "get-user-follower/profile/loading";

export const GET_MY_FOLLOWING_SUCCESS = "get-my-following/profile/success";
export const GET_MY_FOLLOWING_ERROR = "get-my-following/profile/error";
export const GET_MY_FOLLOWING_LOADING = "get-my-following/profile/loading";

export const GET_MY_FOLLOWER_SUCCESS = "get-my-follower/profile/success";
export const GET_MY_FOLLOWER_ERROR = "get-my-follower/profile/error";
export const GET_MY_FOLLOWER_LOADING = "get-my-follower/profile/loading";


// export const AUTH_SIGNUP_ERROR = "auth/signup/error"
// export const AUTH_SIGNUP_SUCCESS = "auth/signup/success"
// //LOGOUT API
// export const AUTH_LOGOUT_SUCCESS = "auth/logout"