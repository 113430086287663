import axios from "axios";
import {
  AUTH_LOGIN_ERROR,
  AUTH_LOGIN_SUCCESS,
  AUTH_LOGOUT_SUCCESS,
  AUTH_SIGNUP_SUCCESS,
  AUTH_SIGNUP_ERROR,
} from "./auth.types";
import { POST, apiRequest, baseurl } from "../../utils/api";
export const authLoginApi = (data) => async (dispatch) => {
  try {
    const res = await apiRequest({ type: POST, endpoint: 'auth/sign-in', data });
    return dispatch({ type: AUTH_LOGIN_SUCCESS, payload: {...res.data,email:res.data.data.email?.toLowerCase()?.trim()} });
  } catch (err) {
    return dispatch({
      type: AUTH_LOGIN_ERROR,
      payload: err.response?.data?.message
        ? err.response?.data?.message
        : err.message,
    });
  }
};
export const authSignupApi = (data) => async (dispatch) => {
  try {
    const res = await apiRequest({ type: POST, endpoint: 'auth/sign-up', data });
    dispatch({ type: AUTH_SIGNUP_SUCCESS, payload: res.data });
  } catch (err) {
    return dispatch({
      type: AUTH_SIGNUP_ERROR,
      payload: err.response?.data?.message
        ? err.response?.data?.message
        : err.message,
    });
  }
};
export const authGoogleApi = (data) => async (dispatch) => {
  try {
    const res = await axios.post(`${baseurl}/google`, data);
    dispatch({ type: AUTH_SIGNUP_SUCCESS, payload: res.data });
  } catch (err) {
    return dispatch({
      type: AUTH_SIGNUP_ERROR,
      payload: err.response?.data?.message
        ? err.response?.data?.message
        : err.message,
    });
  }
};
export const authLogoutApi = (token) => async (dispatch) => {
  try {
    const res = await apiRequest({type:POST,endpoint:'auth/logout',token,data:{}}); 
    console.log(res.data,'checking logout data here');
    dispatch({ type: AUTH_LOGOUT_SUCCESS });
  } catch (err) {
   err = err.response?.data?.message
        ? err.response?.data?.message
        : err.message;
   console.log(err,'error in logout');
   dispatch({ type: AUTH_LOGOUT_SUCCESS });
    // return dispatch({
    //   type: AUTH_SIGNUP_ERROR,
    //   payload: err.response?.data?.message
    //     ? err.response?.data?.message
    //     : err.message,
    // });
  }
};
export const unAuthorisedApi = (message) => async (dispatch) => {
  if (message === 'Unauthorized') {
    // alert("Seems to unauthorize");
    // dispatch({ type: AUTH_LOGOUT_SUCCESS });
  }
};
