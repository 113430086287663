import { Button, HStack, Modal, ModalBody, ModalContent, ModalOverlay, Text, VStack, useToast } from '@chakra-ui/react'
import React, { useContext } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { deletePostApi } from '../store/post/post.action';
import { DELETE_POST_SUCCESS } from '../store/post/post.types';
import { AppContext } from '../context/AppContext';


const DeletePostModal = ({deleteModal,setDeleteModal,postId}) => {
    const dispatch = useDispatch();
    const toast = useToast();   
    const {
        setPostModalOpen: setHandlePostModal,
        postModalData,
      } = useContext(AppContext);    
    const { Token,isAdmin,AdminToken } = useSelector((store) => store.authData);
    const handleDeletePost = ()=>{
        dispatch(deletePostApi(isAdmin?AdminToken:Token,postId)).then((res)=>{
            if(res.type===DELETE_POST_SUCCESS){
                setDeleteModal(false);
                toast({title:"Post has been deleted",status:'success',isClosable:true,duration:1800});
                if(postModalData){
                    setHandlePostModal(false);
                }
            }
        })
    };
  return (
    <Modal size={'md'} isOpen={deleteModal} isCentered >
    <ModalOverlay />
    <ModalContent>
        <ModalBody w={'100%'}>
            <VStack minH={'20vh'} align={'center'} justify={'center'}>
                <Text>Are you sure to delete post?</Text>
                <HStack gap={'1rem'}>
                    <Button colorScheme='red' onClick={handleDeletePost}>Confirm</Button>
                    <Button colorScheme={'twitter'} onClick={()=>setDeleteModal(false)}>Cancel</Button>
                </HStack>
            </VStack>
        </ModalBody>
    </ModalContent>
</Modal>
)
}

export default DeletePostModal