import axios from "axios";

export const baseurl = process.env.REACT_APP_NODE_ENV=='production'?process.env.REACT_APP_PROD_URL:process.env.REACT_APP_DEV_URL;
console.log(baseurl,'chekcing api  here');
export const GET = 'GET';
export const POST = 'POST';
export const UPDATE = 'UPDATE';
export const DELETE = 'DELETE';
export const PATCH = 'PATCH';
export const apiRequest = ({ type, endpoint, token, data }) => {
    switch (type) {
        case GET:
            return axios.get(`${baseurl}/${endpoint}`, {
                headers: {
                    authorization: `Bearer ${token}`,
                },
            });
        case POST:
            return axios.post(`${baseurl}/${endpoint}`, data, {
                headers: {
                    authorization: `Bearer ${token}`,
                },
            });
        case UPDATE:
            return axios.put(`${baseurl}/${endpoint}`, data, {
                headers: {
                    authorization: `Bearer ${token}`,
                },
            });
        case PATCH:
            return axios.patch(`${baseurl}/${endpoint}`, data, {
                headers: {
                    authorization: `Bearer ${token}`,
                },
            });
        case DELETE:
            return axios.delete(`${baseurl}/${endpoint}`, {
                headers: {
                    authorization: `Bearer ${token}`,
                },
            });
        default:
            throw new Error('METHOD NOT ALLOWED');
    }
}       