import { HStack, Image, VStack } from "@chakra-ui/react";
import React from "react";
import Logo from "../../images/Logo.png";

import { useNavigate } from "react-router-dom";
import Footer from "../../components/Footer";
import { themePrimaryColor } from "../../utils/theme";

function Privacy() {
  const navigate = useNavigate();

  const handleLoginClick = () => {
    navigate("/getStarted");
  };

  return (
    <VStack
      pt={"2rem"}
      // background={"linear-gradient(to bottom, #A0D5CB, #2dc9acb0)"}
    >
      <VStack
        w={["98%", "95%", "90%", "80%"]}
      >
         <HStack justify={"center"}>
          <Image
            w={"6rem"}
            cursor={"pointer"}
            onClick={() => navigate("/")}
            // style={{
            //   backgroundImage:
            //     "linear-gradient(to right, rgb(37 151 217) 0%, rgb(163 73 191) 51%, rgb(37 151 217) 100%)",
            //   transition: "0.5s",
            //   backgroundSize: "200% auto",
            //   color: "white",
            //   boxShadow: "0 0 20px #eee",
            //   display: "block",
            // }}
            // _hover={{ backgroundPosition: "right center" }}
            // border={`1px solid ${themePrimaryColor}`}
            src={Logo}
          />{" "}
        </HStack>
        <VStack p={["0.7rem", "0.7rem", "0.8rem", "0.5rem"]}>
          <div class=" flex flex-col ">
            <p class="text-black text-sm mt-2 font-normal">
              This Privacy Policy outlines the practices of our website with
              regards to the collection, use, and protection of personal
              information. By accessing or using our website, you agree to the
              terms of this Privacy Policy.
            </p>
            <p class="text-black text-sm mt-2 font-bold">
              1.Information Collected
            </p>
            <p class="text-black text-sm mt-2 font-normal">
              a. User-Provided Information: When you create an account or use
              our website, you may provide us with personal information such as
              your name, email address, username, and profile information.{" "}
              <br />
              b.Automatically Collected Information: We may collect certain
              information automatically when you use our website, including your
              IP address, browser type, device information, and usage data.
              <br /> c. Cookies and Similar Technologies: We use cookies and
              similar technologies to enhance your experience and analyze usage
              patterns. You may configure your browser settings to reject
              cookies, but this may limit certain functionalities.
            </p>
            <p class="text-black text-sm mt-2 font-bold">
              2.Use of Information
            </p>
            <p class="text-black text-sm mt-2 font-normal">
              a. We may use the information collected to provide and improve our
              website, personalize your experience, communicate with you, and
              manage your account. <br />
              b. We may use your email address to send you notifications,
              updates, and relevant content. <br />
              c. We may analyze user data, trends, and preferences to improve
              our service, develop new features, and conduct research.
            </p>
            <p class="text-black text-sm mt-2 font-bold">
              3.Sharing of Information
            </p>
            <p class="text-black text-sm mt-2 font-normal">
              a. We may share your personal information with third-party service
              providers who assist with the operation of our website, such as
              hosting, data analysis, and customer support. <br />
              b. We may disclose information if required by law or as necessary
              to protect our legal rights, comply with legal processes, or
              respond to valid requests from public authorities.
              <br /> c.We may share de-identified or aggregated data that cannot
              be used to identify you with third parties for various purposes,
              including analytics, research, and marketing.
            </p>
            <p class="text-black text-sm mt-2 font-bold">4.Data Security</p>
            <p class="text-black text-sm mt-2 font-normal">
              a. We implement reasonable security measures to protect your
              personal information from unauthorized access, disclosure,
              alteration, or destruction. <br />
              b. However, no method of transmission over the internet or
              electronic storage is completely secure. Therefore, we cannot
              guarantee the absolute security of your information.
            </p>
            <p class="text-black text-sm mt-2 font-bold">
              5.Third-Party Links and Services
            </p>
            <p class="text-black text-sm mt-2 font-normal">
              Our website may contain links to third-party websites or services.
              We are not responsible for the privacy practices or content of
              those websites. We encourage you to review the privacy policies of
              any third-party websites or services that you visit.
            </p>
            <p class="text-black text-sm mt-2 font-bold">
              6.Children's Privacy
            </p>
            <p class="text-black text-sm mt-2 font-normal">
              Our website is not directed to individuals under the age of 18. We
              do not knowingly collect personal information from children. If we
              become aware that we have inadvertently collected information from
              a child under 18, we will take reasonable steps to delete the
              information as soon as possible.
            </p>
            <p class="text-black text-sm mt-2 font-bold">
              7.Changes to the Privacy Policy
            </p>
            <p class="text-black text-sm mt-2 font-normal">
              We may update this Privacy Policy from time to time. We will
              notify you of any significant changes by posting the updated
              Privacy Policy on our website. Your continued use of our website
              after any modifications indicates your acceptance of the revised
              Privacy Policy.
            </p>

            <p class="text-black text-sm mt-2 font-bold">8.Contact Us</p>
            <p class="text-black text-sm mt-2 font-normal">
              If you have any questions or concerns regarding these Terms,
              please contact us through the provided channels on our website.
            </p>
          </div>
        </VStack>
      </VStack>
      <Footer />
    </VStack>
  );
}

export default Privacy;
