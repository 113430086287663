import { Grid, GridItem, HStack, VStack, Text, Box } from "@chakra-ui/react";
import React, { useContext,useState,useEffect } from "react";
import axios from "axios";
const DashboardCard = () => {
  useEffect(() => {
 
  }, []);
  return (
    <VStack
      gap={"1rem"}
      bg={"white"}
      align={"center"}
      justify={"start"}
      height={"100%"}
      minH={"100vh"}
      boxSizing="border-box"
      w={"79%"}
      p={"1rem"}
    >
      <Text fontSize={"26px"} fontWeight={700} align={"start"}>
        Hi, Welcome Back
      </Text>
      <HStack
        w={"100%"}
        boxSizing="border-box"
        p={"1rem 2rem"}
        gap={"1rem"}
        align={"center"}
        justify={"center"}
      >
  
      </HStack>
    </VStack>
  );
};

export default DashboardCard;