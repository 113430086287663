import {
  Avatar,
  Box,
  Image,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  VStack,
  ModalBody
} from "@chakra-ui/react";
import React from "react";

const PhotoFullView = ({
  openPhotoModal,
  setOpenPhotoModal,
  displayFullViewImage,
}) => {
  return (
    <Modal
      isOpen={openPhotoModal}
      onClose={() => setOpenPhotoModal(false)}
      isCentered
    >
      <ModalOverlay
        bg="blackAlpha.300"
        backdropFilter="blur(10px) hue-rotate(90deg)"
      />

      <ModalContent
        bg={"transparent"}
        border={"transparent"}
        boxShadow={"none"}
      >
        <ModalCloseButton left={"0"} />
        <ModalBody>
          <VStack>
            {displayFullViewImage.avatar ? (
              <Avatar
                size={"xl"}
                src={displayFullViewImage.image}
                w={["80vw", "60vw", "35vw", "26vw"]}
                h={["80vw", "60vw", "35vw", "26vw"]}
              />
            ) : (
              <Image src={displayFullViewImage.image} width={"100%"}/>
            )}
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default PhotoFullView;
