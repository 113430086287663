import { HStack, Heading, Text, VStack } from '@chakra-ui/react'
import React from 'react'
import ImageSlider from './ImageSlider'
import { v4 } from 'uuid';
import womanImg from '../images/woman.jpeg'

const QAndA  = [
  {id:'1234#234234',question:"Step 1: Choose Your Role",answer:"Are you a Model, User, or Designer? Select your role to get started."},
  {id:'12345234#23234234',question:"Step 2: Explore and Connect",answer:"Discover a vibrant community of creators like you. Explore profiles, portfolios, and connect with others who share your interests"},
  {id:'1223434#2323424234',question:"Step 3: Showcase your Creations",answer:"Share your designs, outfits, or creations with the world. Post images, videos, or even virtual try-ons to inspire and collaborate with others."},
];
const bannerData = [
  {
    img: womanImg
  },
  {
    img: womanImg
  },
  {
    img: womanImg
  }
];
const Begin = () => {
  return (
    <HStack  flexDirection={['column','column','row','row']} w={'100%'}  px={['2rem','3rem','4rem','6rem']} justify={'space-between'} gap={['1rem','1rem','1.2rem','2rem']}>
        <VStack gap={['1.4rem','1.5rem','1.6rem','1.8rem']} align={'start'} w={['100%','100%','50%','45%']} border={'1px solid transparent'}>
            <Heading color={'#0064E0'} fontSize={['1.4rem','1.5rem','1.7rem','2rem']}>Begin in few steps</Heading>
            <VStack align={'start'} gap={['1.2rem','1.3rem','1.4rem','1.5rem']}> 
                {
                  QAndA.map((el,i)=>(
                    <VStack key={v4()} align={'start'}>
                      <Text fontSize={['1.1rem','1.2rem','1.3rem','1.3rem']} fontWeight={700}>
                          {el.question}
                      </Text>
                      <Text fontSize={['1rem','1.1rem','1.1rem','1.2rem']} w={['97%','85%','75%','70%']}>
                          {el.answer}
                      </Text>
                    </VStack>
                  ))
                }
            </VStack>
        </VStack>
        <VStack gap={'1.8rem'} align={'start'} w={['100%','100%','40%','30%']}  border={'1px solid transparent'}>
          <ImageSlider  bannerData={bannerData}/>
        </VStack>
    </HStack>
  )
}

export default Begin