import { Avatar, Box, Button, HStack, Modal, ModalBody, ModalContent, ModalOverlay, Text, VStack, useEditable } from "@chakra-ui/react";
import React, { useContext, useEffect, useState } from "react";
import { BsThreeDots } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { deleteCommentApi } from "../store/comment/comment.action";
import { DELETE_COMMENT_SUCCESS } from "../store/comment/comment.types";
import { AppContext } from "../context/AppContext";
const IndividualComment = ({userName,profilePicture,comment,commentId,postId,isContent=false,fullName}) => {
  const [deleteCommentModal,setDeleteCommentModal] = useState(false);
  const {Token,isAdmin,AdminToken} = useSelector((store)=>store.authData);
  const { profileData} = useSelector((store) => store.userData);
  const {setCurrentPostCommentCount} = useContext(AppContext);
  const {delete_loading:Loading} = useSelector((store)=>store.commentData);
  const dispatch = useDispatch();
  const handleDeleteComment = ()=>{
      dispatch(deleteCommentApi(isAdmin?AdminToken:Token,commentId,postId)).then((res)=>{
        if(res.type===DELETE_COMMENT_SUCCESS){
          setDeleteCommentModal(false);
        }
      });
      setCurrentPostCommentCount(pre=>Number(pre)-1);
  };
  return (
    <HStack position={'relative'} p={'0.25rem 0.2rem'} align={'start'} w={"100%"}>
      <Avatar src={profilePicture} size={"sm"} />
      <HStack>
      <Text fontSize={"0.9rem"}>
          <Box fontWeight={600} as="span">{fullName}</Box>  <Box  fontWeight={500} as="span">{comment}</Box>
      </Text>
      </HStack>
     {  
      (!isContent&&(profileData.email==userName||profileData.email==process.env.REACT_APP_ADMIN_ID))&&<BsThreeDots onClick={()=>setDeleteCommentModal(true)} style={{ fontSize: '1rem', cursor: 'pointer',position:'absolute', bottom:0,right:'1rem' }} />
     } 
  <Modal size={'md'} isOpen={deleteCommentModal}  isCentered >
    <ModalOverlay />
    <ModalContent>
        <ModalBody w={'100%'}>
                <VStack gap={'0.5rem'}>
                    <Button w={'100%'} isLoading={Loading}
    loadingText='Deleting'
  colorScheme='red' onClick={handleDeleteComment}>Delete</Button>
                    <Button w={'100%'} colorScheme={'twitter'} onClick={()=>setDeleteCommentModal(false)}>Cancel</Button>
                </VStack>
        </ModalBody>
    </ModalContent>
</Modal>
    </HStack>
  );
};

export default IndividualComment;
